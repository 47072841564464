import { useEffect, useState } from 'react';
import { Button, Divider, message } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  LoadingOutlined,
  WarningOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import { calcularBruto } from '../../../../../services/RemurenacionesService';
import { formatPesos } from '../../../../../utils/formatPesos';
import getServiceAmounts from '../../../../../utils/getServiceAmounts';
import useSolicitud from '../../../../../hooks/useSolicitud';
import { useSelector } from 'react-redux';
import { generateTurns } from '../../../../../services/RequisitionsService';
import requiereContrato from '../../../../../utils/requiereContrato';

export default function Calculate({
  validation,
  isValidate,
  setIsValidate,
  next,
  prev,
}) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const { solicitud, campos, handleChange } = useSolicitud();
  const razonSocialActual = campos.razonesSociales.find(
    (x) => x.value === solicitud.infoBasica.razonsocial
  );
  const grupoContrato = requiereContrato(campos.empresa.contratos.tipoContrato);
  const { values } = validation;
  const { pago, gratificacion, valorcolacion, transporte } = values;
  const [sueldo, setSueldo] = useState(null);
  const [validating, setValidating] = useState(false);
  const [procesando, setProcesando] = useState(false);

  useEffect(() => {
    setIsValidate(null);
    let timer = null;
    if (pago) {
      timer = setTimeout(calcularBrutos, 1000);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  async function calcularBrutos() {
    setValidating(true);
    if (!grupoContrato) {
      setValidating(false);
      setIsValidate(true);
      return;
    }
    try {
      const response = await calcularBruto({
        liquido: pago,
        gratificacion: gratificacion || false,
        colacion: valorcolacion,
        transporte,
      });
      const salarioMinimo = 2556;
      setSueldo(response);
      setIsValidate(
        response?.bruto / values.fixHoras >=
          (response?.minimoHora || salarioMinimo)
      );
    } catch (error) {
      message.error('Error al calcular los montos');
    } finally {
      setValidating(false);
    }
  }

  async function handleTurnsFinish() {
    setProcesando(true);
    const turnos = [
      {
        id: values.id,
        fechaInicio: values.fechas.inicio.format('YYYY-MM-DD'),
        fechaFin: values.fechas.fin.format('YYYY-MM-DD'),
        horaInicio: values.entrada.format('HH:mm'),
        horaFin: values.salida.format('HH:mm'),
        horasTotales: values.fixHoras,
        valorUnitario: values.unitario,
        cantidadTrabajadores: values.vacantes,
        liquidoAPagar: values.pago * values.vacantes,
        liquidoAPagarXTrabajador: values.pago,
        bono: values.bono || 0,
        diasTrabajo: values.dias,
        tiempoColacion: values.colacion ? values.colacion : 0,
        unidad: values.tipopago === 'jornada' ? 'Jornada' : 'Hora',
      },
    ];

    const { totalBruto, totalLiquido, costoServicio, totalNeto } =
      getServiceAmounts({ turnos }, razonSocialActual.contribucion);

    const datos = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: solicitud.id,
      turnos,
      gratificacion: values.gratificacion || false,
      transporte: values.transporte || 0,
      colacion: values.valorcolacion || 0,
      liquidoTotal: totalLiquido,
      brutoTotal: totalBruto,
      costoServicio: costoServicio,
      costoNeto: totalNeto,
    };

    try {
      await generateTurns(datos);
      handleChange({
        turnos: [
          {
            dias: values.dias,
            entrada: values.entrada,
            fechas: {
              inicio: values.fechas.inicio,
              fin: values.fechas.fin,
            },
            numero: values.id,
            pago: values.unitario,
            salida: values.salida,
            tipopago: values.tipopago === 'jornada' ? 'Jornada' : 'Hora',
            vacantes: values.vacantes,
            colacion: values.colacion ? values.colacion : 0,
            gratificacion: values.gratificacion || false,
            bono: values.bono || 0,
            transporte: values.transporte || 0,
            valorcolacion: values.valorcolacion || 0,
          },
        ],
      });
      message.info('Turnos guardados correctamente');
      next();
    } catch (error) {
      message.error('Error al guardar turnos');
    } finally {
      setProcesando(false);
    }
  }

  // if (validating) {
  //   return (
  //     <Result
  //       status="info"
  //       title="Validando"
  //       subTitle="Estamos calculando si el salario cumple con la normativa vigente."
  //     />
  //   );
  // }

  const brutoCalculado = isValidate !== null ? formatPesos(sueldo?.bruto) : '';
  const salarioCumpleNormativa = isValidate !== null && isValidate;
  const salarioPorHora = formatPesos(sueldo?.bruto / values.fixHoras, 2);

  return (
    <>
      {grupoContrato && (
        <div className=" bg-gray-50 p-5 rounded max-w-4xl mx-auto">
          <div className="flex space-x-2 mb-2">
            <Title level={5} style={{ marginBottom: 0 }}>
              Bruto Calculado:{' '}
              <span className="font-normal">
                {validating
                  ? 'Calculando'
                  : isValidate === null
                  ? 'Sin validar'
                  : brutoCalculado}
              </span>
            </Title>
            <div className="scale-150">
              {validating ? (
                <LoadingOutlined />
              ) : isValidate === null ? (
                <WarningOutlined />
              ) : salarioCumpleNormativa ? (
                <CheckOutlined style={{ color: 'green' }} />
              ) : (
                <CloseOutlined style={{ color: 'red' }} />
              )}
            </div>
          </div>
          <p className="mb-0 text-xs text-gray-500">
            {isValidate === null
              ? 'Debes completar todos los campos para validar el salario.'
              : salarioCumpleNormativa
              ? `El salario ingresado cumple con la normativa vigente del ingreso mínimo mensual. Calculas ${salarioPorHora} por hora en ${values.fixHoras} horas totales.`
              : `El salario ingresado no cumple con la normativa vigente del ingreso mínimo mensual. Calculas ${salarioPorHora} por hora en ${values.fixHoras} horas totales.`}
          </p>
        </div>
      )}
      <Divider />
      <div className="flex justify-between">
        <Button data-cy="botonVolver" onClick={prev}>
          Volver
        </Button>
        <Button
          data-cy="botonContinuar"
          type="primary"
          key="console"
          disabled={isValidate === null || !isValidate}
          loading={procesando}
          onClick={handleTurnsFinish}
        >
          Continuar
        </Button>
      </div>
    </>
  );
}

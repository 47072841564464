import { API } from 'aws-amplify';

export async function getComentarios(empresaId, trabajadorId) {
  const { data } = await API.get('xinerlink', `/empresa/v1/comentarios`, {
    headers: {},
    response: true,
    queryStringParameters: {
      empresaId,
      trabajadorId,
    },
  });
  if (data.ok) return data.comentarios;
  else return [];
}

export async function createComentario(body) {
  const { data } = await API.post('xinerlink', `/empresa/v1/comentarios`, {
    headers: {},
    response: true,
    body,
  });
  if (data.ok) return data.comentario;
  else return [];
}

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRequisition } from '../../../services/RequisitionsService';
import { getRequisitionCandidates } from '../../../services/CandidateService';
import { PageContainer } from '@ant-design/pro-components';
import { Button, Card, message } from 'antd';
import { MobileOutlined, ReloadOutlined } from '@ant-design/icons';
import itemRender from '../../../components/layouts/components/itemRender';
import Meta from '../../../components/layouts/Meta';
import Information from './components/Information';
import TurnosTab from './components/TurnosTab';
import HireModal from './components/HireModal';
import DownloadExcel from './components/DownloadExcel';
import mapRequisition from './utils/mapRequisition';

export default function Solicitud() {
  const { enterprise } = useSelector((state) => state.enterprise);
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [solicitud, setSolicitud] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    obtenerSolicitud();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function obtenerSolicitud() {
    setLoading(true);
    try {
      const [requisition, candidates] = await Promise.all([
        getRequisition(enterprise.empresaId, params.id.replace('-', '#')),
        getRequisitionCandidates(
          enterprise.empresaId,
          params.id.replace('-', '#')
        ),
      ]);

      const mapedRequisitions = mapRequisition(requisition, candidates);
      setSolicitud(mapedRequisitions);

      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error(
        'Error al obtener la solicitud: ' + params.id.replace('-', '#')
      );
      navigate('/solicitudes');
    }
  }

  async function refreshCandidates() {
    setRefreshing(true);
    try {
      const candidates = await getRequisitionCandidates(
        enterprise.empresaId,
        params.id.replace('-', '#')
      );
      const mapedRequisitions = mapRequisition(solicitud, candidates);
      setSolicitud(mapedRequisitions);
      return true;
    } catch (error) {
      return false;
    } finally {
      setRefreshing(false);
    }
  }

  function getTotalCandidatesSelected() {
    if (!solicitud) return 0;
    const total = solicitud.turnos.reduce((acc, turno) => {
      const validatedStatus = [
        'SeleccionadoXEmpresa',
        'ProvisionadoXEmpresa',
        'PendienteXFirma',
      ];
      return (
        acc +
        turno.candidatos.filter((candidate) =>
          validatedStatus.includes(candidate.estadoActual)
        ).length
      );
    }, 0);
    return total;
  }
  const totalSelected = getTotalCandidatesSelected();
  return (
    <PageContainer
      header={{
        title: loading
          ? 'Cargando...'
          : `#${solicitud.idOferta} - ${solicitud.tipoTrabajo} en ${
              solicitud.ubicacion.comuna || solicitud.ubicacion.nombre
            }`,
        ghost: true,
        breadcrumb: {
          routes: [
            { path: '/solicitudes', breadcrumbName: 'Solicitudes' },
            { path: params.id, breadcrumbName: 'Detalle de Solicitud' },
          ],
          itemRender,
        },
        extra: !loading && [
          <LinkToWorker
            id={params.id}
            disabled={solicitud?.estadoActual === 'EsperandoValidacion'}
            key="1"
          />,
        ],
      }}
      loading={loading}
    >
      <Meta title={'Detalle de Solicitud #' + params.id.split('-')[1]} />
      <Card>
        <Information solicitud={solicitud} />
      </Card>
      {solicitud?.turnos.length > 0 && (
        <>
          <Card className="mt-6">
            <div className="relative z-10">
              <div className="absolute right-0 space-x-4">
                <DownloadExcel solicitud={solicitud} />
                <HireModal
                  solicitud={solicitud}
                  total={totalSelected}
                  id={solicitud.pais_consecutivoOferta}
                  refresh={refreshCandidates}
                />
                <Button onClick={refreshCandidates} icon={<ReloadOutlined />}>
                  Recargar candidatos
                </Button>
              </div>
            </div>
            <TurnosTab
              turnos={solicitud?.turnos}
              refreshing={refreshing}
              refreshCandidates={refreshCandidates}
              solicitud={solicitud}
            />
          </Card>
        </>
      )}
    </PageContainer>
  );
}

const LinkToWorker = ({ id, disabled }) => {
  const { enterprise } = useSelector((state) => state.enterprise);
  const { REACT_APP_WORKER_URL } = process.env;
  const url = `${REACT_APP_WORKER_URL}/trabajo/${enterprise.empresaId}/${id}`;
  function handleUrl() {
    window.open(url, '_blank');
  }
  return (
    <Button
      type="primary"
      disabled={disabled}
      onClick={handleUrl}
      icon={<MobileOutlined />}
    >
      Abrir en APP
    </Button>
  );
};

import React from 'react';
import { ConfigProvider } from 'antd';
import esES from 'antd/locale/es_ES';
import Router from './router';
import StoreProvider from './components/providers/StoreProvider';

export default function App() {
  return (
    <StoreProvider>
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            borderRadius: 4,
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </StoreProvider>
  );
}

import { Badge, Space, Tag, Tooltip } from 'antd';
import {
  getStatusColor,
  getStatusName,
} from '../../../utils/getStatusSolicitud';
import Cobertura from '../../../components/tables/Cobertura';
import TableActions from './TableActions';
import capitalize from '../../../utils/capitalize';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es';
import getEventoDatesString from '../../../utils/getEventosDates';
dayjs.extend(utc);
dayjs.extend(timezone);

export const columns = (buscarSolicitudes) => [
  {
    title: 'ID',
    key: 'idOferta',
    dataIndex: 'idOferta',
    sorter: (a, b) => parseInt(a.idOferta) - parseInt(b.idOferta),
    showSorterTooltip: false,
    width: 75,
  },
  {
    title: 'Cargo',
    key: 'tipoTrabajo',
    dataIndex: 'tipoTrabajo',
    render(text, item) {
      return (
        <Tooltip title={getStatusName(item.estadoActual)}>
          <Space>
            <Badge status={getStatusColor(item.estadoActual)} />
            {text}
            {item.privacidad !== 'publica' && (
              <Tag
                color={item.privacidad === 'privada' ? 'black' : 'blue'}
                bordered={false}
              >
                {item.privacidad === 'privada' ? 'Privada' : 'Mi comunidad'}
              </Tag>
            )}
          </Space>
        </Tooltip>
      );
    },
  },
  {
    title: 'Motivo',
    key: 'motivoTrabajo',
    dataIndex: 'nombreContrato',
    width: 180,
    render: (text) => {
      if (text.length > 20)
        return <Tooltip title={text}>{text.substring(0, 18) + '...'}</Tooltip>;
      return text;
    },
  },
  {
    title: 'Fecha de Evento',
    key: 'fechaInicio',
    dataIndex: 'fechaInicio',
    sorter: (a, b) => dayjs(a.fechaInicio).unix() - dayjs(b.fechaInicio).unix(),
    showSorterTooltip: false,
    width: 180,
    render: (text, record) => {
      if (!text) return null;
      const fechaInicio = dayjs(text + 'Z').tz('America/Santiago');
      const fechaFin = dayjs(record.fechaFin + 'Z').tz('America/Santiago');
      return (
        <div>
          <Tag>
            {capitalize(
              dayjs(text + 'Z')
                .format('ddd')
                .replace('.', '')
            )}
          </Tag>
          <span>{getEventoDatesString(fechaInicio, fechaFin)}</span>
          {/* Se agregó la Z porque no se está enviando desde el Backend */}
        </div>
      );
    },
  },
  {
    title: 'Hora',
    key: 'horaInicio',
    dataIndex: 'fechaInicio',
    width: 75,
    render: (text) => {
      return (
        <span>
          {dayjs(text + 'Z')
            .tz('America/Santiago')
            .format('HH:mm')}
        </span>
      );
    },
  },
  {
    title: 'Ubicación',
    key: 'ubicacion',
    dataIndex: ['ubicacion', 'nombre'],
    // render(text, item) {
    //   return (
    //     <Tooltip title={item.ubicacion.direccion}>
    //       <span>{text}</span>
    //     </Tooltip>
    //   );
    // },
    render: (text) => {
      if (text.length > 20)
        return <Tooltip title={text}>{text.substring(0, 18) + '...'}</Tooltip>;
      return text;
    },
  },
  {
    title: 'Cobertura',
    key: 'cobertura',
    dataIndex: 'contador',
    render(text, item) {
      return <Cobertura item={item} />;
    },
  },
  {
    title: () => (
      <img
        src="/favicon.ico"
        alt="icon"
        className="w-5 h-5 ml-2 grayscale opacity-50"
      />
    ),
    key: 'acciones',
    render(text, item) {
      return <TableActions item={item} buscarSolicitudes={buscarSolicitudes} />;
    },
  },
];

export default function getServiceAmounts(solicitud, contribucion) {
  const totalLiquido = solicitud.turnos[0].liquidoAPagar;
  const totalBruto = totalLiquido * 1.33;
  const costoServicio = (totalBruto * contribucion) / (1 - contribucion);
  const totalNeto = totalBruto + costoServicio;

  return {
    totalLiquido,
    totalBruto,
    costoServicio,
    totalNeto,
  };
}

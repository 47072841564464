import { Badge, Button, message, Modal } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { hireCandidates } from '../../../../services/CandidateService';

export default function HireModal({ total, id, refresh, solicitud }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [showModal, setShowModal] = useState(false);
  const [hiring, setHiring] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  async function handleHire() {
    messageApi.open({
      key: 'hiring',
      type: 'loading',
      content: 'Enviando candidatos...',
      duration: 0,
    });
    setHiring(true);
    try {
      const register = {
        empresaId: enterprise.empresaId,
        pais_consecutivoOferta: id,
      };
      await hireCandidates(register, messageApi);
      messageApi.open({
        key: 'hiring',
        type: 'success',
        content: 'Candidatos contratados',
        duration: 2,
      });
      setShowModal(false);
      refresh();
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: 'hiring',
        type: 'error',
        content: 'Error al contratar candidatos',
        duration: 2,
      });
    } finally {
      setHiring(false);
    }
  }

  const validateStatus = ['Activa', 'EnPausa', 'Ejecutando'].includes(
    solicitud?.estadoActual
  );
  if (!validateStatus) return null;

  return (
    <>
      {contextHolder}
      <Badge count={total}>
        <Button
          data-cy="botonContratarSeleccionados"
          type="primary"
          disabled={total < 1}
          onClick={() => setShowModal(true)}
        >
          Contratar seleccionados
        </Button>
      </Badge>
      <Modal
        data-cy="modalContratarCandidatos"
        open={showModal}
        onCancel={() => setShowModal(false)}
        okText="Contratar"
        destroyOnClose
        closable={!hiring}
        maskClosable={!hiring}
        title={'Contratar candidatos a ' + solicitud?.tipoTrabajo}
        onOk={handleHire}
        okButtonProps={{ loading: hiring }}
        cancelButtonProps={{ disabled: hiring }}
      >
        <p>
          Estás a punto de contratar{' '}
          <b>{total === 1 ? '1 candidato' : `${total} candidatos`}</b> para tu
          oferta de trabajo{' '}
          {`${solicitud?.pais_consecutivoOferta} por ${solicitud?.nombreContrato}`}
          . Una vez lo hagas, el trabajador será notificado y deberás esperar
          por su confirmación.
        </p>
      </Modal>
    </>
  );
}

import { useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import menuItems from './menuItems';
import { useSelector } from 'react-redux';

const { Sider } = Layout;

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  return (
    <Sider
      theme="light"
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        backgroundColor: '#F9F9F9',
        borderRight: '1px solid #e8e8e8',
        flex: 'relative',
      }}
      width={250}
    >
      <Menu
        mode="inline"
        onClick={(e) => navigate(e.key)}
        className="select-none"
        style={{ backgroundColor: '#F9F9F9', paddingTop: '12px', border: '0' }}
        items={menuItems(user)}
        // items={
        //   isFirmante
        //     ? consultaAmbiente()
        //       ? [...menuItems[0], ...menuItems[1], ...menuItems[2]]
        //       : [...menuItems[0], ...menuItems[1]]
        //     : menuItems[0]
        // }
        selectedKeys={[location.pathname]}
      />
      <ToggleCircle collapsed={collapsed} setCollapsed={setCollapsed} />
    </Sider>
  );
}

function ToggleCircle({ collapsed, setCollapsed }) {
  const styleButtom = {
    color: 'rgba(0,0,0,0.25)',
    transform: 'scale(0.75)',
  };
  return (
    <div className="absolute top-6 -right-3 z-50">
      <Button
        onClick={() => setCollapsed(!collapsed)}
        shape="circle"
        icon={
          collapsed ? (
            <RightOutlined style={styleButtom} />
          ) : (
            <LeftOutlined style={styleButtom} />
          )
        }
        size="small"
        className="shadow border-0"
        style={{ backgroundColor: '#fafafa' }}
      />
    </div>
  );
}

import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Tooltip } from 'antd';

export default function Filter({ setFilters }) {
  const [form] = Form.useForm();

  function handleSubmit(values) {
    if (values.buscar === undefined) values.buscar = null;
    if (values.buscar === '') values.buscar = null;
    setFilters(values.buscar);
  }

  function handleReset() {
    setFilters(null);
    form.resetFields();
  }

  return (
    <div className="mb-6 flex justify-between">
      <Form
        layout="inline"
        initialValues={{
          buscar: null,
        }}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          data-cy="itemBuscarCandidato"
          label="Buscar candidato"
          name="buscar"
        >
          <Input
            data-cy="inputBuscarCandidato"
            placeholder="Nombre Apellido / 000000-0"
            allowClear
            style={{ width: 300 }}
          />
        </Form.Item>
        <Space size="small">
          <Button
            data-cy="botonBuscar"
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>
          <Tooltip title="Limpiar Filtros">
            <Button
              data-cy="botonLimpiarFiltros"
              type="dashed"
              onClick={handleReset}
              icon={<ClearOutlined />}
            />
          </Tooltip>
        </Space>
      </Form>
    </div>
  );
}

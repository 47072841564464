import { API, graphqlOperation } from 'aws-amplify';
import { listNotificacions } from '../graphql/queries';
import { updateNotificacion } from '../graphql/mutations';
import { onCreateNotificacion } from '../graphql/subscriptions';

export async function getNotifications(enterprise, filter) {
  const request = await API.graphql({
    query: listNotificacions,
    variables: {
      destinatarioId: enterprise.empresaId,
      nextToken: null,
      filter: filter,
      sortDirection: 'DESC',
    },
  });
  const { data } = request;
  return data.listNotificacions.items;
}

export function markAsRead(notification) {
  return API.graphql({
    query: updateNotificacion,
    variables: {
      input: {
        destinatarioId: notification.destinatarioId,
        fecha: notification.fecha,
        leida: Boolean(true),
      },
    },
  });
}

export function subscribeToNotifications(callback) {
  return API.graphql(graphqlOperation(onCreateNotificacion)).subscribe({
    next: ({ value }) => {
      const { onCreateNotificacion: notification } = value.data;
      callback(notification);
    },
  });
}

import { useSelector } from 'react-redux';
import {
  selectCandidate,
  unselectCandidate,
} from '../../../../../services/CandidateService';
import { Button, message, Tooltip } from 'antd';
import { CheckOutlined, UndoOutlined } from '@ant-design/icons';

export default function SelectCandidate({ candidate, refreshCandidates }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  async function handleSelect() {
    message.loading('Seleccionando candidato');
    try {
      const datos = {
        empresaId: enterprise.empresaId,
        pais_consecutivoOferta: candidate.turnoInfo.pais_consecutivoOferta,
        correo: candidate.correo,
        turno: candidate.turnoInfo.id,
      };
      const result = await selectCandidate(datos);
      if (result?.message) {
        message.warning(result.message);
      } else {
        message.success(`Candidato ${candidate.nombre} seleccionado`);
      }
      refreshCandidates();
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
  }
  async function handleUnselect() {
    message.loading('Deseleccionando candidato');
    try {
      const datos = {
        empresaId: enterprise.empresaId,
        pais_consecutivoOferta: candidate.turnoInfo.pais_consecutivoOferta,
        correo: candidate.correo,
        turno: candidate.turnoInfo.id,
      };
      await unselectCandidate(datos);
      message.success(`Candidato ${candidate.nombre} deseleccionado`);
      refreshCandidates();
    } catch (error) {
      console.error(error);
      message.error('Error al deseleccionar al candidato');
    }
  }

  const noOperables = [
    'Desierta',
    'Finalizada',
    'Guardada',
    'EsperandoFacturacion',
    'FacturaGenerada',
    'FacturaEnviada',
    'Facturada',
  ];

  return (
    <>
      {!noOperables.includes(candidate.turnoInfo.estado) && (
        <>
          {candidate.estadoActual === 'Postulado' && (
            <Tooltip title="Seleccionar">
              <Button
                type="text"
                icon={<CheckOutlined />}
                onClick={handleSelect}
              />
            </Tooltip>
          )}
          {candidate.estadoActual === 'SeleccionadoXEmpresa' && (
            <Tooltip title="Deseleccionar">
              <Button
                type="text"
                icon={<UndoOutlined />}
                onClick={handleUnselect}
              />
            </Tooltip>
          )}

        </>
      )}
    </>
  );
}

function sumarRestarHorasExtraordinariasArray(array) {
  let totalHoras = array[0].diferenciaHoras;
  for (let i = 1; i < array.length; i++) {
    let diferenciaHoras = array[i].diferenciaHoras;

    if (diferenciaHoras.charAt(0) === '-') {
      totalHoras = restarHoras(totalHoras, diferenciaHoras.substring(1));
    } else {
      totalHoras = sumarHoras(totalHoras, diferenciaHoras);
    }
  }

  return totalHoras;
}

function sumarHoras(hora1, hora2) {
  let [h1, m1, s1] = hora1.split(':').map(Number);
  let [h2, m2, s2] = hora2.split(':').map(Number);
  let segundos = s1 + s2;
  let minutos = m1 + m2;
  let horas = h1 + h2;
  if (segundos >= 60) {
    minutos++;
    segundos -= 60;
  }
  if (minutos >= 60) {
    horas++;
    minutos -= 60;
  }
  return formatoHora(horas, minutos, segundos);
}
function restarHoras(hora1, hora2) {
  // Convertimos las horas en objetos Date

  const date1 = new Date(`2023-05-04T${hora1}`);
  const date2 = new Date(`2023-05-04T${hora2}`);

  // Restamos las horas
  const diferenciaHoras = new Date(date1.getTime() - date2.getTime());

  // Formateamos la respuesta en formato HH:MM:SS
  const horas = Math.abs(diferenciaHoras.getUTCHours())
    .toString()
    .padStart(2, '0');
  const minutos = Math.abs(diferenciaHoras.getUTCMinutes())
    .toString()
    .padStart(2, '0');
  const segundos = Math.abs(diferenciaHoras.getUTCSeconds())
    .toString()
    .padStart(2, '0');
  const horaFormateada = `${horas}:${minutos}:${segundos}`;

  // Retornamos la hora formateada
  return horaFormateada;
}

function formatoHora(horas, minutos, segundos) {
  return `${formatoNumero(horas)}:${formatoNumero(minutos)}:${formatoNumero(
    segundos
  )}`;
}

function formatoNumero(numero) {
  return Math.abs(numero) < 10 ? `0${numero}` : `${numero}`;
}

const sumarRestarHorasExtraordinarias = {
  sumarRestarHorasExtraordinariasArray,
  sumarHoras,
  restarHoras,
  formatoHora,
};

export default sumarRestarHorasExtraordinarias;

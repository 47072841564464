import { Layout, Space } from 'antd';
import image from '../../../assets/images/xinerlink-icon.png';
import NameDropdown from './NameDropdown';
import Notifications from './Notifications';
import { Link } from 'react-router-dom';
import { SwitchEnterprise } from './SwitchEnterprise';
import { ChileanClock } from './ChileanClock';
const { Header } = Layout;

export default function AppHeader() {
  return (
    <Header
      style={{
        background: 'white',
        borderBottom: '1px solid #e8e8e8',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
      }}
    >
      <Link to="/">
        <Space align="center" className="select-none">
          <img src={image} alt="logo" className="w-8 pb-1" />
          <span className="hidden md:flex text-base text-black">
            Xinerlink Clientes
          </span>
        </Space>
      </Link>
      <div className="flex space-x-4 items-center">
        <ChileanClock />
        <SwitchEnterprise />
        <Notifications />
        <NameDropdown />
      </div>
    </Header>
  );
}

import {
  AlertOutlined,
  AppstoreAddOutlined,
  BarsOutlined,
  CheckCircleOutlined,
  DatabaseOutlined,
  ExceptionOutlined,
  FileDoneOutlined,
  FolderOutlined,
  FormOutlined,
  HighlightOutlined,
  HistoryOutlined,
  // HomeOutlined,
  InboxOutlined,
  PaperClipOutlined,
  PlusOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import consultaAmbiente from '../../../utils/consultaAmbiente';

export default function menuItems(user) {
  const isFirmante = user.tipoUsuario.includes('Firmante');
  const isSupervisor = user.tipoUsuario.includes('Supervisor');
  const isSolicitanteTrabajadores = user.tipoUsuario.includes(
    'SolicitanteTrabajadores'
  );
  const isGeneradorMasivo = user.tipoUsuario.includes('GeneradorMasivo');

  return [
    // {
    //   key: '/',
    //   icon: <HomeOutlined />,
    //   label: 'Escritorio',
    // },
    isSolicitanteTrabajadores && {
      key: '/solicitudes/formulario',
      icon: <FormOutlined />,
      label: 'Nueva Solicitud',
    },
    isGeneradorMasivo && {
      key: '/solicitudes/generar-masivas',
      icon: <AppstoreAddOutlined />,
      label: 'Generar Solicitudes',
    },
    isSupervisor && {
      key: '/dotacion',
      icon: <UserSwitchOutlined />,
      label: 'Dotación Diaria',
    },
    {
      key: '/trabajadores',
      icon: <DatabaseOutlined />,
      label: 'Trabajadores',
    },
    {
      key: 'solicitudes',
      icon: <InboxOutlined />,
      label: 'Solicitudes',
      children: [
        {
          key: '/solicitudes',
          icon: <BarsOutlined />,
          label: 'Solicitudes Actuales',
        },
        {
          key: '/solicitudes/formulario',
          icon: <PlusOutlined />,
          label: 'Crear Solicitud',
        },
        {
          key: '/solicitudes/generar-masivas',
          icon: <AppstoreAddOutlined />,
          label: 'Generar Masivas',
        },
        {
          key: '/solicitudes/archivo',
          icon: <HistoryOutlined />,
          label: 'Archivo Histórico',
        },
      ],
    },

    isFirmante && {
      key: 'anpd',
      icon: <HighlightOutlined />,
      label: 'Firma Digital',
      children: [
        {
          key: '/anpd',
          icon: <ExceptionOutlined />,
          label: 'Pendientes de Firma',
        },
        {
          key: '/anpd/archivo',
          icon: <FileDoneOutlined />,
          label: 'Contratos Firmados',
        },
      ],
    },

    consultaAmbiente() && {
      key: 'reportes',
      icon: <PaperClipOutlined />,
      label: 'Reportes DT',
      children: [
        {
          key: '/reportes/asistencia',
          icon: <FolderOutlined />,
          label: 'Asistencias',
        },
        {
          key: '/reportes/jornada-diaria',
          icon: <FolderOutlined />,
          label: 'Jornada Diaria',
        },
        {
          key: '/reportes/domingos-festivos',
          icon: <FolderOutlined />,
          label: (
            <>
              <ul style={{ lineHeight: '1.5' }}>Domingos y/o</ul>
              <ul style={{ lineHeight: '1.5' }}>festivos trabajados</ul>
            </>
          ),
        },
        {
          key: '/reportes/modificaciones',
          icon: <FolderOutlined />,
          label: (
            <>
              <ul style={{ lineHeight: '1.5' }}>Modificaciones y/o</ul>
              <ul style={{ lineHeight: '1.5' }}>alteraciones de turnos </ul>
            </>
          ),
        },
      ],
    },

    consultaAmbiente() && {
      key: 'extra',
      icon: <AppstoreAddOutlined />,
      label: 'Extra',
      children: [
        {
          key: '/extra/justificar',
          icon: <CheckCircleOutlined />,
          label: 'Justificar Asistencia',
        },
        {
          key: '/extra/incidencias',
          icon: <AlertOutlined />,
          label: 'Registro de Incidencias',
        },
      ],
    },
  ];
}

import dayjs from 'dayjs';
import Acciones from './Acciones';
import { Tag } from 'antd';

export default function columns(buscarContratos, firmar) {
  const initials = [
    {
      title: '# Oferta',
      dataIndex: 'idOferta',
      key: 'idOferta',
      sorter: (a, b) => a.idOferta - b.idOferta,
      width: 100,
    },
    {
      title: 'Cargo',
      dataIndex: 'rubro',
      key: 'rubro',
      width: 200,
    },
    {
      title: 'Fecha Inicio',
      dataIndex: 'fechaInicio',
      key: 'fechaInicio',
      render: (text) => dayjs(text).format('DD/MM/YYYY'),
      sorter: (a, b) => dayjs(a.fechaInicio).isAfter(dayjs(b.fechaInicio)),
      width: 150,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (text, record) => {
        return (
          <div>
            <p className="-mb-1">{text}</p>
            <span className="text-xs text-gray-400">{record.correo}</span>
          </div>
        );
      },
    },
    {
      title: 'RUT',
      dataIndex: 'dni',
      key: 'dni',
      width: 150,
    },
    {
      title: 'ID Contrato',
      dataIndex: 'idContrato',
      key: 'idContrato',
      sorter: (a, b) => a.idContrato - b.idContrato,
      width: 150,
    },
  ];

  const status = [
    {
      title: 'Estado',
      dataIndex: 'estadoContrato',
      key: 'estadoContrato',
      render: (text) => {
        if (text === 'ContratoFirmadoXRepLegal') {
          return <Tag color="green">Firmado</Tag>;
        } else return <Tag color="red">Pendiente Xinerlink</Tag>;
      },
    },
  ];

  const actions = [
    {
      title: 'Acciones',
      key: 'acciones',
      render: (text, record) => (
        <Acciones
          contrato={record}
          actualizar={buscarContratos}
          firmar={firmar}
        />
      ),
    },
  ];

  if (firmar) return [...initials, ...actions];
  return [...initials, ...status, ...actions];
}

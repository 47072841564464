import dayjs from 'dayjs';

import { Space, Tooltip, Image, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import DotacionActions from './DotacionActions';

export default function TablaDotacion({ trabajadores }) {
  return (
    <Table
      dataSource={trabajadores}
      loading={!trabajadores}
      rowKey={(record) => record.correo + record.turno}
      scroll={{ x: 1500 }}
      columns={[
        {
          title: 'Nombre',
          dataIndex: 'nombre',
          key: 'nombre',
          width: 400,
          render: (text, record) => (
            <Space size="middle">
              <Image
                width={45}
                height={45}
                src={record.enlaceFoto}
                alt={record.correo}
                style={{ objectFit: 'cover', borderRadius: '50%' }}
              />

              <div>
                <h3 className="text-sm font-bold mb-0">
                  {record.nombre} {record.primerApellido}{' '}
                  {record.segundoApellido}
                </h3>
                <span className="text-xs">{record.rut}</span>
              </div>
            </Space>
          ),
        },
        {
          title: 'Oferta',
          dataIndex: 'pais_consecutivoOferta',
          key: 'pais_consecutivoOferta',
          render: (text) => (text ? text.split('#')[1] : null),
        },
        {
          title: 'Cargo',
          dataIndex: 'tipoTrabajo',
          key: 'tipoTrabajo',
        },
        {
          title: 'Motivo',
          dataIndex: 'nombreContrato',
          key: 'nombreContrato',
        },
        {
          title: 'Ubicación',
          dataIndex: 'ubicacion',
          key: 'ubicacion',
          render: (text, item) => (
            <Tooltip title={item.direccion}>
              {text} <InfoCircleOutlined />
            </Tooltip>
          ),
        },
        {
          title: 'Fecha',
          dataIndex: 'entrada',
          key: 'entrada',
          render: (text) => dayjs(text + 'Z').format('DD/MM/YYYY'),
        },
        {
          title: 'Entrada',
          dataIndex: 'entrada',
          key: 'entrada',
          render: (text) => dayjs(text + 'Z').format('HH:mm'),
        },
        {
          title: 'Salida',
          dataIndex: 'salida',
          key: 'salida',
          render: (text) => dayjs(text + 'Z').format('HH:mm'),
        },
        {
          title: 'Acciones',
          dataIndex: 'acciones',
          key: 'acciones',
          render: (text, record) => <DotacionActions record={record} />,
        },
      ]}
    />
  );
}

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import AuthProvider from '../components/providers/AuthProvider';
import PublicProvider from './PublicProvider';
import PrivateProvider from './PrivateProvider';
import LoginPage from '../pages/ingreso';
import RecuperarPage from '../pages/ingreso/recuperar';
import ConfirmarPage from '../pages/ingreso/recuperar/confirmar';
import SolicitudesPage from '../pages/solicitudes';
import FormularioSolicitudPage from '../pages/solicitudes/nueva-solicitud';
import ArchivoPage from '../pages/solicitudes/archivo-historico';
import SolicitudPage from '../pages/solicitudes/solicitud';
import AnpdPage from '../pages/anpd';
import ArchivoAnpdPage from '../pages/anpd/archivo';
import Asistencia from '../pages/dt/asistencia/Asistencia';
import JornadaDiaria from '../pages/dt/jornadaDiaria/JornadaDiaria';
import DomigosFestivos from '../pages/dt/domigosFestivos/DomigosFestivos';
import Modificaciones from '../pages/dt/modificaciones/ModificacionesAlteraciones';
import ReporteIncidentes from '../pages/dt/ReporteIncidentes';
import Justificacioncambios from '../pages/dt/Justificacioncambios';
import consultaAmbiente from '../utils/consultaAmbiente';
import DotacionDiaria from '../pages/dotacion';
import Trabajadores from '../pages/trabajadores';
import GenerarMasivas from '../pages/solicitudes/generar-masivas';
import Notificaciones from '../pages/notificaciones';

export default function index() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/auth" element={<PublicProvider />}>
            <Route index element={<LoginPage />} />
            <Route path="recuperar">
              <Route index element={<RecuperarPage />} />
              <Route path="confirmar" element={<ConfirmarPage />} />
            </Route>
          </Route>
          <Route path="/" element={<PrivateProvider />}>
            <Route index element={<Navigate to="/solicitudes" replace />} />
            <Route path="notificaciones" element={<Notificaciones />} />
            <Route path="dotacion" element={<DotacionDiaria />} />
            <Route path="trabajadores" element={<Trabajadores />} />
            <Route path="solicitudes">
              <Route index element={<SolicitudesPage />} />
              <Route path="formulario" element={<FormularioSolicitudPage />} />
              <Route path="generar-masivas" element={<GenerarMasivas />} />
              <Route path="archivo" element={<ArchivoPage />} />
              <Route path=":id" element={<SolicitudPage />} />
            </Route>
            <Route path="anpd">
              <Route index element={<AnpdPage />} />
              <Route path="archivo" element={<ArchivoAnpdPage />} />
            </Route>
            {consultaAmbiente() && (
              <>
                <Route path="reportes">
                  <Route index element={<Navigate to="/" replace />} />
                  <Route path="asistencia" element={<Asistencia />} />
                  <Route path="jornada-diaria" element={<JornadaDiaria />} />
                  <Route
                    path="domingos-festivos"
                    element={<DomigosFestivos />}
                  />
                  <Route path="modificaciones" element={<Modificaciones />} />
                </Route>
                <Route path="extra">
                  <Route index element={<Navigate to="/" replace />} />
                  <Route path="justificar" element={<Justificacioncambios />} />
                  <Route path="incidencias" element={<ReporteIncidentes />} />
                </Route>
              </>
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

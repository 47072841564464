import { Empty } from 'antd';
import NotificationCard from '../../../components/layouts/components/notifications/NotificationCard';

export default function ListadoNotificaciones({ notifications }) {
  if (notifications.length < 1)
    return <Empty description="No tienes ninguna notificación" />;

  return (
    <div>
      {notifications.map((notification) => {
        const customId = notification.createdAt + notification.urlDestino;
        return <NotificationCard key={customId} notification={notification} />;
      })}
    </div>
  );
}

import { API } from 'aws-amplify';

import createParams from '../utils/createApiParams';
import customFetch from '../utils/customFetch';

// async function getToken() {
//   // @ts-ignore
//   const { accessToken } = await Auth.currentSession();
//   return accessToken.jwtToken;
// }

const apiName = 'dt';

const BuscarTrabajadores = async (
  // /** @type {any} */ userToken,
  /** @type {string} */ tipo,
  /** @type {string} */ nombre,
  /** @type {string} */ primerApellido,
  /** @type {string} */ segundoApellido,
  /** @type {string} */ rut,
  /** @type {string} */ desde,
  /** @type {string} */ hasta,
  /** @type {string} */ ubicacion,
  /** @type {string} */ cargo,
  /** @type {string} */ correos,
  /** @type {string} */ tipoBusqueda,
  /** @type {string} */ jornada,
  /** @type {string} */ empresaId
) => {
  const path = '/buscarReportes';
  const myInit = createParams();
  myInit.queryStringParameters = {
    // userToken: await getToken(),
    tipo,
    nombre,
    primerApellido,
    segundoApellido,
    rut,
    desde,
    hasta,
    ubicacion,
    cargo,
    correos,
    tipoBusqueda,
    jornada,
    empresaId,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};
// const ObtenerRepositorioTrabajadores = async (
//   /** @type {any} */ userToken,
//   /** @type {any} */ fechaInicio,
//   /** @type {any} */ fechaFin
// ) => {
//   const path = "/obtenerTrabajadores";
//   const myInit = createParams();
//   myInit.queryStringParameters = {
//     userToken: await getToken(),
//     fechaInicio,
//     fechaFin,
//   };
//   const res = customFetch(() => API.get(apiName, path, myInit));
//   return res;
// };

const ValidationIP = async () => {
  const path = '/ip-validation';
  const myInit = createParams();
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

// consultaUbicaciones
const consultarinformacionBuscador = async (tipo, empresaId) => {
  const path = '/ubicacionesCargos';
  const myInit = createParams();
  myInit.queryStringParameters = {
    tipo,
    empresaId,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const buscarInfoCargos = async (tipo, correo) => {
  const path = '/ubicacionesCargos';
  const myInit = createParams();
  myInit.queryStringParameters = {
    // userToken: await getToken(),
    tipo,
    correo,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const RegisterNewPassword = async (
  /** @type {any} */ email,
  /** @type {any} */ password
) => {
  const path = '/register';
  const myInit = createParams();
  myInit.body = {
    email,
    password,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const AuthenticateUser = async (/** @type {any} */ password) => {
  const path = '/authentication';
  const myInit = createParams();
  myInit.body = { password };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const RequestTemporaryPassword = async (/** @type {any} */ email) => {
  const path = '/user';
  const myInit = createParams();
  myInit.body = { email };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const dtService = {
  BuscarTrabajadores,
  // ObtenerRepositorioTrabajadores,
  ValidationIP,
  RegisterNewPassword,
  AuthenticateUser,
  RequestTemporaryPassword,
  buscarInfoCargos,
  consultarinformacionBuscador,
};

export default dtService;

import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

// Funcion para darle un delay a las peticiones
// function delay(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export async function getRequisitions(empresaId, estado) {
  const token = await getToken();
  const { data } = await API.get('empresa', '/verOfertas', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
      estado,
    },
  });
  if (data.existe) return data.registro.Items;
  else return [];
}

export async function getPlantillas(empresaId) {
  const token = await getToken();
  const { data } = await API.get(
    'xinerlink',
    '/empresa/v1/ofertasPlantilla/verPlantillas',
    {
      headers: {},
      response: true,
      queryStringParameters: {
        userToken: token,
        empresaId,
      },
    }
  );
  if (data.ok) return data.plantillas;
  else return [];
}

export async function getRequisition(empresaId, id) {
  const token = await getToken();
  const { data } = await API.get('oferta', '/verDetalle', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
      pais_consecutivoOferta: id,
    },
  });
  if (data.existe) return data.registro;
  throw new Error('No se pudo obtener la solicitud');
}

export async function changeStatusRequisition(status, registro) {
  const token = await getToken();
  const { data } = await API.post('oferta', `/${status}`, {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro,
    },
  });
  return data;
}

export async function generateRequisition(empresaId, datos, id) {
  const token = await getToken();
  const { data } = await API.post('oferta', '/crearInformacionBasica', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: {
        empresaId,
        pais_consecutivoOferta: id,
        cargo: datos.cargo,
        razonSocial: datos.razonsocial,
        ubicacion: datos.ubicacion,
        nombreContrato: datos.motivo,
        causalContrato: datos.causal,
        nombreReemplazado: datos.nombreReemplazado,
        rutReemplazado: datos.rutReemplazado,
        privacidad: datos.privacidad,
        // isPublic: !datos.privacidad,
        guardar: true,
      },
    },
  });
  if (data.existe) return data.registro;
  return data;
}

export async function generateTurns(datos) {
  const token = await getToken();
  const { data } = await API.post('oferta', '/crearTurnosYValores', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: datos,
    },
  });
  if (data.existe) return data.registro;
  throw new Error('No se pudo agregar turnos');
}

export async function saveMultimedia(datos) {
  const token = await getToken();
  const { data } = await API.post('oferta', '/crearMultimedia', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: datos,
    },
  });
  if (data.existe) return data.registro;
  throw new Error('No se pudo agregar multimedia');
}

export async function saveRequeriments(datos) {
  const token = await getToken();
  const { data } = await API.post('oferta', '/crearRequisitosYHabilidades', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: datos,
    },
  });
  if (data.existe) return data.registro;
  throw new Error('No se pudo agregar requerimientos');
}

export async function saveOnboarding(datos) {
  const token = await getToken();
  const { data } = await API.post('oferta', '/crearInformacionOnboarding', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: datos,
    },
  });
  if (data.existe) return data.registro;
  throw new Error('No se pudo agregar onboarding');
}

export async function changeSchedules(datos) {
  const token = await getToken();
  const { data } = await API.post('xinerlink', '/oferta2/v1/cambiarHorario', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      ...datos,
    },
  });
  console.log(data);
  return data;
}

export async function deleteRequisition(datos) {
  const token = await getToken();
  const { data } = await API.post('xinerlink', '/oferta2/v1/cancelarOferta', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      ...datos,
    },
  });
  return data;
}

export async function validarOferta({
  empresaId,
  pais_consecutivoOferta,
  aprobada,
  comentarios = 'Sin comentarios',
  notificarTrabajadores = true,
}) {
  const { data } = await API.post('xinerlink', '/oferta/v1/validar', {
    headers: {},
    response: true,
    body: {
      empresaId,
      pais_consecutivoOferta,
      aprobada,
      comentarios,
      notificarTrabajadores,
    },
  });

  return data;
}

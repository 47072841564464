import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth';
import enterpriseReducer from './features/enterprise';

export default configureStore({
  reducer: {
    auth: authReducer,
    enterprise: enterpriseReducer,
  },
});

import React from 'react';
import { Button } from 'antd';
import {
  FileWordOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import PageHeader from '../headers/PageHeader';

export default function headerReportes({
  name = 'Reporte',
  firstTable = [],
  setexportarPdf,
  setexportarWord,
  setexportarExcel,
  DatosSeleccionados,
}) {
  return (
    <PageHeader title={name} head="">
      <Button
        className="mr-2"
        icon={<FileExcelOutlined />}
        onClick={() => setexportarExcel(true)}
        disabled={DatosSeleccionados.length === 0} // disabled={firstTable.length === 0}
      >
        Exportar a Excel
      </Button>
      <Button
        className="mr-2"
        icon={<FileWordOutlined />}
        onClick={() => setexportarWord(true)}
        disabled={DatosSeleccionados.length === 0}
      >
        Exportar a word
      </Button>
      <Button
        className="mr-2"
        icon={<FilePdfOutlined />}
        onClick={() => setexportarPdf(true)}
        disabled={DatosSeleccionados.length === 0}
      >
        Exportar a pdf
      </Button>
    </PageHeader>
  );
}

import { Button, Space, Tooltip, Dropdown } from 'antd';
import {
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  MessageOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import LlegoNoLlego from './LlegoNoLlego';

export default function DotacionActions({ record }) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Space size="small">
      <Dropdown
        menu={{
          items: [
            {
              key: 0,
              label: (
                <Space onClick={() => openInNewTab('tel:' + record?.telefono)}>
                  <PhoneOutlined />
                  <span>Llamar por teléfono</span>
                </Space>
              ),
            },
            {
              key: 1,
              label: (
                <Space onClick={() => openInNewTab('mailto:' + record?.correo)}>
                  <MailOutlined />
                  <span>Enviar correo</span>
                </Space>
              ),
            },
            {
              key: 2,
              label: (
                <Space
                  onClick={() =>
                    openInNewTab(
                      'https://wa.me/' + record?.telefono?.replace('+', '')
                    )
                  }
                >
                  <WhatsAppOutlined />
                  <span>Abrir Whastapp</span>
                </Space>
              ),
            },
          ],
        }}
      >
        <Tooltip title="Contactar">
          <Button type="text" icon={<MessageOutlined />} />
        </Tooltip>
      </Dropdown>
      {record.cartaAviso && record.cartaAviso !== '' && (
        <Tooltip title="Carta Aviso">
          <a href={record.cartaAviso} target="_blank" rel="noreferrer">
            <Button type="text" icon={<FilePdfOutlined />} />
          </a>
        </Tooltip>
      )}
      <LlegoNoLlego user={record} />
    </Space>
  );
}

import React from 'react';
import { Divider } from 'antd';
import Title from 'antd/lib/typography/Title';

export default function PageHeader({
  children,
  title = 'Backoffice',
  head = 'Administración',
}) {
  return (
    <div>
      <div className="md:flex justify-between items-center">
        <div>
          <span>{head}</span>
          <Title level={3}>{title}</Title>
        </div>
        <div>{children}</div>
      </div>
      <Divider />
    </div>
  );
}

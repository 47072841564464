import { Descriptions, Popover, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function CandidateOthers({ postulaciones, id }) {
  const {
    Postulado,
    SeleccionadoXEmpresa,
    AceptadoXEmpresa,
    AceptadoXTrabajador,
  } = postulaciones;

  if (
    Postulado.length < 1 &&
    SeleccionadoXEmpresa.length < 1 &&
    AceptadoXEmpresa.length < 1 &&
    AceptadoXTrabajador.length < 1
  )
    return null;

  return (
    <Popover
      content={
        <div className="infoPostulaciones">
          <Descriptions
            style={{ width: 300 }}
            column={1}
            layout="vertical"
            items={[
              Postulado.length > 0 && {
                key: '1',
                label: 'Postulado',
                children: Postulado?.join(', ') || '-',
              },
              SeleccionadoXEmpresa.length > 0 && {
                key: '2',
                label: 'Seleccionado',
                children: SeleccionadoXEmpresa?.join(', ') || '-',
              },
              AceptadoXEmpresa.length > 0 && {
                key: '3',
                label: 'Aceptado',
                children: AceptadoXEmpresa?.join(', ') || '-',
              },
              AceptadoXTrabajador.length > 0 && {
                key: '4',
                label: 'Aceptado por trabajador',
                children: AceptadoXTrabajador?.join(', ') || '-',
              },
            ]}
          />
        </div>
      }
      title="Postulaciones simultáneas"
    >
      <Space size={5} className="text-xs cursor-help">
        {Postulado.length > 0 && <span>+{Postulado.length}P</span>}
        {SeleccionadoXEmpresa.length > 0 && (
          <span>+{SeleccionadoXEmpresa.length}S</span>
        )}
        {AceptadoXEmpresa.length > 0 && (
          <span>+{AceptadoXEmpresa.length}A</span>
        )}
        {AceptadoXTrabajador.length > 0 && (
          <span>+{AceptadoXTrabajador.length}C</span>
        )}
        <InfoCircleOutlined />
      </Space>
    </Popover>
  );
}

import { Table, Space, Badge } from 'antd';
import { useEffect, useState } from 'react';
import getFiltereds from '../../../../utils/getFiltereds';
import { columns } from '../../components/columns';

export default function SolicitudesTable({
  solicitudes,
  filters,
  buscarSolicitudes,
}) {
  const [data, setData] = useState(solicitudes);

  useEffect(() => {
    filterSolicitudes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitudes, filters]);

  function filterSolicitudes() {
    if (!solicitudes) return;
    const filtered = getFiltereds(solicitudes, filters);
    setData(filtered);
  }

  return (
    <Table
      dataSource={data}
      columns={columns(buscarSolicitudes)}
      loading={!solicitudes}
      scroll={{ x: 1080 }}
      size="middle"
      rowKey={(record) => record.pais_consecutivoOferta}
      footer={() => (
        <div className="p">
          <h4 className="font-semibold text-xs">Estados de solicitudes</h4>
          <Space size="large">
            <Badge status="warning" text="Guardada" />
            <Badge status="default" text="Desierta" />
            <Badge status="processing" text="Esperando Facturación" />
            <Badge status="processing" text="Factura Generada" />
            <Badge status="processing" text="Factura Enviada" />
            <Badge status="success" text="Facturada" />
            <Badge status="success" text="Finalizada" />
          </Space>
        </div>
      )}
    />
  );
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBackground = /* GraphQL */ `
  subscription OnCreateBackground {
    onCreateBackground {
      anoInicio
      cargoTitulo
      createdAt
      descripcion
      duracionMeses
      institucion
      tipo_uuid
      trabajadorId
      updatedAt
      validadoXEST
    }
  }
`;
export const onCreateCalificacion = /* GraphQL */ `
  subscription OnCreateCalificacion {
    onCreateCalificacion {
      createdAt
      detalles
      empresaId
      fecha
      pais_consecutivoOferta_trabajadorId
      tipo
      updatedAt
    }
  }
`;
export const onCreateComentariosOfertaTrabajo = /* GraphQL */ `
  subscription OnCreateComentariosOfertaTrabajo {
    onCreateComentariosOfertaTrabajo {
      comentario
      createdAt
      empresaId
      pais_consecutivoOferta_fecha
      updatedAt
    }
  }
`;
export const onCreateComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  subscription OnCreateComunaTipoTrabajoRecomendaciones {
    onCreateComunaTipoTrabajoRecomendaciones {
      createdAt
      pais
      region_ciudad_comuna_rubro_tipoTrabajo
      rubro_tipoTrabajo
      updatedAt
      valorMaxRecomendado
      valorMinLegal
      valorMinRecomendado
    }
  }
`;
export const onCreateContador = /* GraphQL */ `
  subscription OnCreateContador {
    onCreateContador {
      contadorAceptadoXEmpresa
      contadorAceptadoXTrabajador
      contadorAceptadosTurno
      contadorContratoAnuladoXBackoffice
      contadorContratoAnuladoXTrabajador
      contadorContratoCerrado
      contadorContratoFirmadoXTrabajador
      contadorDesistidaXTrabajador
      contadorEjecutando
      contadorPostulado
      contadorPreGuardado
      contadorProvisionadoXEmpresa
      contadorRechazadoXEmpresa
      contadorRechazadoXTrabajador
      contadorSeleccionadoXEmpresa
      contadorSeleccionadosTurno
      contadorTerminado
      contadorValidado
      contadorVisitasUsuarioAutenticado
      contadorVisitasUsuarioNoAutenticado
      contratoAnuladoXEmpresa
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const onCreateContrato = /* GraphQL */ `
  subscription OnCreateContrato {
    onCreateContrato {
      cantidadPersonasAContratar
      createdAt
      empresaId
      estadoActual
      historialEstados
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const onCreateContratoPendiente = /* GraphQL */ `
  subscription OnCreateContratoPendiente {
    onCreateContratoPendiente {
      createdAt
      empresaId
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const onCreateDetalleFacturacion = /* GraphQL */ `
  subscription OnCreateDetalleFacturacion {
    onCreateDetalleFacturacion {
      aprobadoPor
      createdAt
      dni
      duracion
      empresaId
      empresaId_paisConsecutivoOferta
      estadoAprobacion
      estadoEntrada
      estadoFacturacion
      estadoSalida
      factorMultiplicador
      facturacion
      fechaFin
      fechaInicio
      fecha_pais_consecutivoOferta_trabajadorId
      nombre
      primerApellido
      registroEntradaPor
      registroSalidaPor
      segundoApellido
      tipoTrabajo
      trabajadorId
      unidad
      updatedAt
      valorAFacturar
      valorAPagar
      valorBruto
      valorContribucion
      valorIVA
      valorImposicion
      valorLiquidado
      valorNeto
      valorPresupuestado
      valorUnitario
    }
  }
`;
export const onCreateDocumentoEmpresa = /* GraphQL */ `
  subscription OnCreateDocumentoEmpresa {
    onCreateDocumentoEmpresa {
      createdAt
      empresaId
      llave
      nombre
      updatedAt
    }
  }
`;
export const onCreateDocumentoTrabajador = /* GraphQL */ `
  subscription OnCreateDocumentoTrabajador {
    onCreateDocumentoTrabajador {
      createdAt
      fechaVencimiento
      nombre
      tipo
      trabajadorId
      updatedAt
      url
      validadoXEST
    }
  }
`;
export const onCreateEmpresa = /* GraphQL */ `
  subscription OnCreateEmpresa {
    onCreateEmpresa {
      calificacionPromedio
      contadorOfertaTrabajo
      createdAt
      credito
      descripcion
      empresaId
      enlaceLogo
      estId
      fechaCreacion
      giro
      nombre
      saldo
      updatedAt
    }
  }
`;
export const onCreateFacturacion = /* GraphQL */ `
  subscription OnCreateFacturacion {
    onCreateFacturacion {
      cargo
      createdAt
      empresa
      empresaId
      estadosFacturacion
      facturado
      liquidoFacturado
      liquidoPresupuestado
      mesFacturado
      numeroContable
      pais_consecutivoOferta_fecha
      trabajadores
      ubicacion
      updatedAt
    }
  }
`;
export const onCreateFaq = /* GraphQL */ `
  subscription OnCreateFaq {
    onCreateFaq {
      categoria
      createdAt
      faqId
      pregunta
      respuesta
      updatedAt
    }
  }
`;
export const onCreateGeografia = /* GraphQL */ `
  subscription OnCreateGeografia {
    onCreateGeografia {
      createdAt
      idRegion_idCiudad_idComuna
      pais
      region_ciudad_comuna
      updatedAt
    }
  }
`;
export const onCreateHabilidades = /* GraphQL */ `
  subscription OnCreateHabilidades {
    onCreateHabilidades {
      createdAt
      descripcion
      estado
      examen
      icono
      id
      nombre
      tipo
      updatedAt
      visibilidad
    }
  }
`;
export const onCreateHistorialPagos = /* GraphQL */ `
  subscription OnCreateHistorialPagos {
    onCreateHistorialPagos {
      createdAt
      fecha
      historialPagoId
      motivo
      tipoTransaccion
      updatedAt
      valor
    }
  }
`;
export const onCreateHistoricoTrabajador = /* GraphQL */ `
  subscription OnCreateHistoricoTrabajador {
    onCreateHistoricoTrabajador {
      createdAt
      duracionUltimoTrabajo
      empresaId
      fechaUltimoTrabajo
      nombreCortoTipoTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const onCreateIdentificadorFirmante = /* GraphQL */ `
  subscription OnCreateIdentificadorFirmante {
    onCreateIdentificadorFirmante {
      correo
      createdAt
      identificadorTributario
      updatedAt
    }
  }
`;
export const onCreateIdentificadorTributarioEmpresa = /* GraphQL */ `
  subscription OnCreateIdentificadorTributarioEmpresa {
    onCreateIdentificadorTributarioEmpresa {
      activo
      comuna
      correoRepresentanteLegal
      createdAt
      direccion
      empresaId
      identificadorTributario
      margenContribucion
      nombreFantasia
      nombreRepresentanteLegal
      razonSocial
      rutRepresentanteLegal
      updatedAt
    }
  }
`;
export const onCreateLogCambiosTrabajador = /* GraphQL */ `
  subscription OnCreateLogCambiosTrabajador {
    onCreateLogCambiosTrabajador {
      cambios
      comentarioBackoffice
      correo
      createdAt
      fecha
      updatedAt
    }
  }
`;
export const onCreateMultimediaEmpresa = /* GraphQL */ `
  subscription OnCreateMultimediaEmpresa {
    onCreateMultimediaEmpresa {
      correoUsuarioEmpresaId_uuid
      createdAt
      empresaId
      llave
      tag
      updatedAt
    }
  }
`;
export const onCreateNotificacion = /* GraphQL */ `
  subscription OnCreateNotificacion {
    onCreateNotificacion {
      createdAt
      destinatarioId
      fecha
      leida
      mensaje
      payload
      remitenteId
      tipoNotificacion
      updatedAt
      urlDestino
    }
  }
`;
export const onCreateOfertaActiva = /* GraphQL */ `
  subscription OnCreateOfertaActiva {
    onCreateOfertaActiva {
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const onCreateOfertaTrabajo = /* GraphQL */ `
  subscription OnCreateOfertaTrabajo {
    onCreateOfertaTrabajo {
      brutoTotal
      causalContrado
      costoNeto
      costoServicio
      createdAt
      descripcionTipoTrabajo
      empresaId
      estadoActual
      facturado
      facturadoRealFinal
      fechaCaducidad
      firmaContrato
      historialEstados
      identificadorTributario
      infoOnboarding
      liquidoTotal
      multimediaOfertaId
      nombreContrato
      nuevosPostulantes
      pais_consecutivoOferta
      provisionFondos
      razonSocial
      rubro
      tipoTrabajo
      totalHoras
      totalVacantes
      turnos
      ubicacion
      updatedAt
      usuariosInvitados
    }
  }
`;
export const onCreateOfertaTrabajoHabilidades = /* GraphQL */ `
  subscription OnCreateOfertaTrabajoHabilidades {
    onCreateOfertaTrabajoHabilidades {
      createdAt
      empresaId_pais_consecutivoOferta
      habilidadId
      obligatoria
      updatedAt
    }
  }
`;
export const onCreatePorcentajeImposicionesPais = /* GraphQL */ `
  subscription OnCreatePorcentajeImposicionesPais {
    onCreatePorcentajeImposicionesPais {
      createdAt
      iva
      pais
      porcentaje
      updatedAt
      valorMinimoLegal
    }
  }
`;
export const onCreateReplicaTalana = /* GraphQL */ `
  subscription OnCreateReplicaTalana {
    onCreateReplicaTalana {
      codigo
      creacion
      createdAt
      nombre
      pais
      razonSocial
      rut
      talanaId
      tipo
      tipoReplica
      updatedAt
      vigente
    }
  }
`;
export const onCreateRequisito = /* GraphQL */ `
  subscription OnCreateRequisito {
    onCreateRequisito {
      createdAt
      descripcion
      empresaId
      fechaVencimiento
      pais_consecutivoOferta_uuid
      requiereArchivo
      updatedAt
    }
  }
`;
export const onCreateRequisitosLegales = /* GraphQL */ `
  subscription OnCreateRequisitosLegales {
    onCreateRequisitosLegales {
      createdAt
      pais
      porcentajeBase
      porcentajeHoraExtra
      porcentajeParafiscales
      porcentajeSemanaCorrida
      updatedAt
    }
  }
`;
export const onCreateRubroTipoTrabajo = /* GraphQL */ `
  subscription OnCreateRubroTipoTrabajo {
    onCreateRubroTipoTrabajo {
      createdAt
      descripcionRubro
      descripcionTipoTrabajo
      duracionMaximaTurno
      duracionMaximaTurnoParaColacion
      duracionMaximaTurnoXSemana
      habilidades
      horasMaximasXDia
      horasMaximasXMes
      horasMaximasXSemana
      nombreOficial
      pais
      porcentajeCostoFestivo
      rubro_tipoTrabajo
      tiempoColacion
      updatedAt
      valorMaxRecomendado
      valorMinRecomendado
    }
  }
`;
export const onCreateSeguridadSocial = /* GraphQL */ `
  subscription OnCreateSeguridadSocial {
    onCreateSeguridadSocial {
      createdAt
      nombre
      talanaId
      tipo
      trabajadorId
      updatedAt
    }
  }
`;
export const onCreateTipoUsuario = /* GraphQL */ `
  subscription OnCreateTipoUsuario {
    onCreateTipoUsuario {
      createdAt
      dotacionDiaria
      firmaPendiente
      gestionAsistencias
      idTipoUsuario
      menuSolicitudes
      multimediaEmpresa
      nombre
      nuevaSolicitud
      panelDeControl
      perfilEmpresa
      principal
      provisionPendiente
      recargar
      rutsAsociados
      solicitudes
      trabajosEnCurso
      transacciones
      ubicaciones
      updatedAt
      usuarios
      verTodasSolicitudes
    }
  }
`;
export const onCreateTrabajador = /* GraphQL */ `
  subscription OnCreateTrabajador {
    onCreateTrabajador {
      activo
      banco
      calificacionPromedio
      canalesNotificaciones
      ciudad
      clave
      comuna
      correo
      createdAt
      descripcion
      direccion
      dni
      enlaceFoto
      estId
      fechaNacimiento
      firebaseToken
      idBanco
      nacionalidad
      noCuentaBancaria
      nombre
      pais
      perfiles
      primerApellido
      region
      segundaClave
      segundoApellido
      telefono
      tipoCuenta
      ultimaFechaConsultaAnuncios
      updatedAt
      validadoXEST
      visibilidadFoto
    }
  }
`;
export const onCreateTrabajadorCalendario = /* GraphQL */ `
  subscription OnCreateTrabajadorCalendario {
    onCreateTrabajadorCalendario {
      aprobado
      confirmado
      createdAt
      duracion
      empresaId
      entrada
      estadoAprobacion
      estadoEntrada
      estadoSalida
      fechaFin
      fecha_empresaId_pais_consecutivoOferta
      pais_consecutivoOferta
      salida
      timeZone
      trabajadorId
      turno
      ubicacionId
      ultimoDia
      unidad
      updatedAt
      valorUnitario
    }
  }
`;
export const onCreateTrabajadorHabilidades = /* GraphQL */ `
  subscription OnCreateTrabajadorHabilidades {
    onCreateTrabajadorHabilidades {
      certificado
      correo
      createdAt
      estado
      fechaSolicitud
      habilidadId
      historialEstados
      infoAdicionalCertificado
      resultadoExamen
      tipoHabilidad
      updatedAt
    }
  }
`;
export const onCreateTrabajadorOferta = /* GraphQL */ `
  subscription OnCreateTrabajadorOferta {
    onCreateTrabajadorOferta {
      contratoEmpresa
      contratoId
      contratoTrabajador
      createdAt
      cumplimientoRequisito
      declaracionJuradaSalud
      desvinculacion
      empresaId
      empresaId_pais_consecutivoOferta
      estadoActual
      finiquito
      historialEstados
      infoOnboardingLeida
      pactoHorasExtra
      pais_consecutivoOferta_trabajadorId
      trabajadorId
      turnos
      turnosExtra
      updatedAt
      valorAPagar
    }
  }
`;
export const onCreateTrabajadorPago = /* GraphQL */ `
  subscription OnCreateTrabajadorPago {
    onCreateTrabajadorPago {
      concepto
      createdAt
      empresaId_pais_consecutivoOferta
      fecha_uuid
      historialPagoId
      trabajadorId
      updatedAt
      valor
    }
  }
`;
export const onCreateTrabajoFavorito = /* GraphQL */ `
  subscription OnCreateTrabajoFavorito {
    onCreateTrabajoFavorito {
      createdAt
      ofertasTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const onCreateTransaccion = /* GraphQL */ `
  subscription OnCreateTransaccion {
    onCreateTransaccion {
      correo
      createdAt
      detalle
      empresaId
      fecha_uuid
      monto
      observacion
      tipo
      updatedAt
    }
  }
`;
export const onCreateUbicacion = /* GraphQL */ `
  subscription OnCreateUbicacion {
    onCreateUbicacion {
      activa
      codigoPostal
      createdAt
      direccion
      empresaId
      nombre
      pais_region_ciudad_comuna_uuid
      timezone
      ubicacion
      updatedAt
    }
  }
`;
export const onCreateUbicacionSupervisor = /* GraphQL */ `
  subscription OnCreateUbicacionSupervisor {
    onCreateUbicacionSupervisor {
      correo
      createdAt
      pais_region_ciudad_comuna_uuid
      updatedAt
    }
  }
`;
export const onCreateUsuarioBackoffice = /* GraphQL */ `
  subscription OnCreateUsuarioBackoffice {
    onCreateUsuarioBackoffice {
      cargo
      correo
      createdAt
      nombre
      telefono
      updatedAt
    }
  }
`;
export const onCreateUsuarioEmpresa = /* GraphQL */ `
  subscription OnCreateUsuarioEmpresa {
    onCreateUsuarioEmpresa {
      activo
      cargo
      clave
      correo
      createdAt
      empresaId
      nombre
      telefono
      tipoUsuario
      updatedAt
    }
  }
`;
export const onDeleteBackground = /* GraphQL */ `
  subscription OnDeleteBackground {
    onDeleteBackground {
      anoInicio
      cargoTitulo
      createdAt
      descripcion
      duracionMeses
      institucion
      tipo_uuid
      trabajadorId
      updatedAt
      validadoXEST
    }
  }
`;
export const onDeleteCalificacion = /* GraphQL */ `
  subscription OnDeleteCalificacion {
    onDeleteCalificacion {
      createdAt
      detalles
      empresaId
      fecha
      pais_consecutivoOferta_trabajadorId
      tipo
      updatedAt
    }
  }
`;
export const onDeleteComentariosOfertaTrabajo = /* GraphQL */ `
  subscription OnDeleteComentariosOfertaTrabajo {
    onDeleteComentariosOfertaTrabajo {
      comentario
      createdAt
      empresaId
      pais_consecutivoOferta_fecha
      updatedAt
    }
  }
`;
export const onDeleteComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  subscription OnDeleteComunaTipoTrabajoRecomendaciones {
    onDeleteComunaTipoTrabajoRecomendaciones {
      createdAt
      pais
      region_ciudad_comuna_rubro_tipoTrabajo
      rubro_tipoTrabajo
      updatedAt
      valorMaxRecomendado
      valorMinLegal
      valorMinRecomendado
    }
  }
`;
export const onDeleteContador = /* GraphQL */ `
  subscription OnDeleteContador {
    onDeleteContador {
      contadorAceptadoXEmpresa
      contadorAceptadoXTrabajador
      contadorAceptadosTurno
      contadorContratoAnuladoXBackoffice
      contadorContratoAnuladoXTrabajador
      contadorContratoCerrado
      contadorContratoFirmadoXTrabajador
      contadorDesistidaXTrabajador
      contadorEjecutando
      contadorPostulado
      contadorPreGuardado
      contadorProvisionadoXEmpresa
      contadorRechazadoXEmpresa
      contadorRechazadoXTrabajador
      contadorSeleccionadoXEmpresa
      contadorSeleccionadosTurno
      contadorTerminado
      contadorValidado
      contadorVisitasUsuarioAutenticado
      contadorVisitasUsuarioNoAutenticado
      contratoAnuladoXEmpresa
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const onDeleteContrato = /* GraphQL */ `
  subscription OnDeleteContrato {
    onDeleteContrato {
      cantidadPersonasAContratar
      createdAt
      empresaId
      estadoActual
      historialEstados
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const onDeleteContratoPendiente = /* GraphQL */ `
  subscription OnDeleteContratoPendiente {
    onDeleteContratoPendiente {
      createdAt
      empresaId
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const onDeleteDetalleFacturacion = /* GraphQL */ `
  subscription OnDeleteDetalleFacturacion {
    onDeleteDetalleFacturacion {
      aprobadoPor
      createdAt
      dni
      duracion
      empresaId
      empresaId_paisConsecutivoOferta
      estadoAprobacion
      estadoEntrada
      estadoFacturacion
      estadoSalida
      factorMultiplicador
      facturacion
      fechaFin
      fechaInicio
      fecha_pais_consecutivoOferta_trabajadorId
      nombre
      primerApellido
      registroEntradaPor
      registroSalidaPor
      segundoApellido
      tipoTrabajo
      trabajadorId
      unidad
      updatedAt
      valorAFacturar
      valorAPagar
      valorBruto
      valorContribucion
      valorIVA
      valorImposicion
      valorLiquidado
      valorNeto
      valorPresupuestado
      valorUnitario
    }
  }
`;
export const onDeleteDocumentoEmpresa = /* GraphQL */ `
  subscription OnDeleteDocumentoEmpresa {
    onDeleteDocumentoEmpresa {
      createdAt
      empresaId
      llave
      nombre
      updatedAt
    }
  }
`;
export const onDeleteDocumentoTrabajador = /* GraphQL */ `
  subscription OnDeleteDocumentoTrabajador {
    onDeleteDocumentoTrabajador {
      createdAt
      fechaVencimiento
      nombre
      tipo
      trabajadorId
      updatedAt
      url
      validadoXEST
    }
  }
`;
export const onDeleteEmpresa = /* GraphQL */ `
  subscription OnDeleteEmpresa {
    onDeleteEmpresa {
      calificacionPromedio
      contadorOfertaTrabajo
      createdAt
      credito
      descripcion
      empresaId
      enlaceLogo
      estId
      fechaCreacion
      giro
      nombre
      saldo
      updatedAt
    }
  }
`;
export const onDeleteFacturacion = /* GraphQL */ `
  subscription OnDeleteFacturacion {
    onDeleteFacturacion {
      cargo
      createdAt
      empresa
      empresaId
      estadosFacturacion
      facturado
      liquidoFacturado
      liquidoPresupuestado
      mesFacturado
      numeroContable
      pais_consecutivoOferta_fecha
      trabajadores
      ubicacion
      updatedAt
    }
  }
`;
export const onDeleteFaq = /* GraphQL */ `
  subscription OnDeleteFaq {
    onDeleteFaq {
      categoria
      createdAt
      faqId
      pregunta
      respuesta
      updatedAt
    }
  }
`;
export const onDeleteGeografia = /* GraphQL */ `
  subscription OnDeleteGeografia {
    onDeleteGeografia {
      createdAt
      idRegion_idCiudad_idComuna
      pais
      region_ciudad_comuna
      updatedAt
    }
  }
`;
export const onDeleteHabilidades = /* GraphQL */ `
  subscription OnDeleteHabilidades {
    onDeleteHabilidades {
      createdAt
      descripcion
      estado
      examen
      icono
      id
      nombre
      tipo
      updatedAt
      visibilidad
    }
  }
`;
export const onDeleteHistorialPagos = /* GraphQL */ `
  subscription OnDeleteHistorialPagos {
    onDeleteHistorialPagos {
      createdAt
      fecha
      historialPagoId
      motivo
      tipoTransaccion
      updatedAt
      valor
    }
  }
`;
export const onDeleteHistoricoTrabajador = /* GraphQL */ `
  subscription OnDeleteHistoricoTrabajador {
    onDeleteHistoricoTrabajador {
      createdAt
      duracionUltimoTrabajo
      empresaId
      fechaUltimoTrabajo
      nombreCortoTipoTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const onDeleteIdentificadorFirmante = /* GraphQL */ `
  subscription OnDeleteIdentificadorFirmante {
    onDeleteIdentificadorFirmante {
      correo
      createdAt
      identificadorTributario
      updatedAt
    }
  }
`;
export const onDeleteIdentificadorTributarioEmpresa = /* GraphQL */ `
  subscription OnDeleteIdentificadorTributarioEmpresa {
    onDeleteIdentificadorTributarioEmpresa {
      activo
      comuna
      correoRepresentanteLegal
      createdAt
      direccion
      empresaId
      identificadorTributario
      margenContribucion
      nombreFantasia
      nombreRepresentanteLegal
      razonSocial
      rutRepresentanteLegal
      updatedAt
    }
  }
`;
export const onDeleteLogCambiosTrabajador = /* GraphQL */ `
  subscription OnDeleteLogCambiosTrabajador {
    onDeleteLogCambiosTrabajador {
      cambios
      comentarioBackoffice
      correo
      createdAt
      fecha
      updatedAt
    }
  }
`;
export const onDeleteMultimediaEmpresa = /* GraphQL */ `
  subscription OnDeleteMultimediaEmpresa {
    onDeleteMultimediaEmpresa {
      correoUsuarioEmpresaId_uuid
      createdAt
      empresaId
      llave
      tag
      updatedAt
    }
  }
`;
export const onDeleteNotificacion = /* GraphQL */ `
  subscription OnDeleteNotificacion {
    onDeleteNotificacion {
      createdAt
      destinatarioId
      fecha
      leida
      mensaje
      payload
      remitenteId
      tipoNotificacion
      updatedAt
      urlDestino
    }
  }
`;
export const onDeleteOfertaActiva = /* GraphQL */ `
  subscription OnDeleteOfertaActiva {
    onDeleteOfertaActiva {
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const onDeleteOfertaTrabajo = /* GraphQL */ `
  subscription OnDeleteOfertaTrabajo {
    onDeleteOfertaTrabajo {
      brutoTotal
      causalContrado
      costoNeto
      costoServicio
      createdAt
      descripcionTipoTrabajo
      empresaId
      estadoActual
      facturado
      facturadoRealFinal
      fechaCaducidad
      firmaContrato
      historialEstados
      identificadorTributario
      infoOnboarding
      liquidoTotal
      multimediaOfertaId
      nombreContrato
      nuevosPostulantes
      pais_consecutivoOferta
      provisionFondos
      razonSocial
      rubro
      tipoTrabajo
      totalHoras
      totalVacantes
      turnos
      ubicacion
      updatedAt
      usuariosInvitados
    }
  }
`;
export const onDeleteOfertaTrabajoHabilidades = /* GraphQL */ `
  subscription OnDeleteOfertaTrabajoHabilidades {
    onDeleteOfertaTrabajoHabilidades {
      createdAt
      empresaId_pais_consecutivoOferta
      habilidadId
      obligatoria
      updatedAt
    }
  }
`;
export const onDeletePorcentajeImposicionesPais = /* GraphQL */ `
  subscription OnDeletePorcentajeImposicionesPais {
    onDeletePorcentajeImposicionesPais {
      createdAt
      iva
      pais
      porcentaje
      updatedAt
      valorMinimoLegal
    }
  }
`;
export const onDeleteReplicaTalana = /* GraphQL */ `
  subscription OnDeleteReplicaTalana {
    onDeleteReplicaTalana {
      codigo
      creacion
      createdAt
      nombre
      pais
      razonSocial
      rut
      talanaId
      tipo
      tipoReplica
      updatedAt
      vigente
    }
  }
`;
export const onDeleteRequisito = /* GraphQL */ `
  subscription OnDeleteRequisito {
    onDeleteRequisito {
      createdAt
      descripcion
      empresaId
      fechaVencimiento
      pais_consecutivoOferta_uuid
      requiereArchivo
      updatedAt
    }
  }
`;
export const onDeleteRequisitosLegales = /* GraphQL */ `
  subscription OnDeleteRequisitosLegales {
    onDeleteRequisitosLegales {
      createdAt
      pais
      porcentajeBase
      porcentajeHoraExtra
      porcentajeParafiscales
      porcentajeSemanaCorrida
      updatedAt
    }
  }
`;
export const onDeleteRubroTipoTrabajo = /* GraphQL */ `
  subscription OnDeleteRubroTipoTrabajo {
    onDeleteRubroTipoTrabajo {
      createdAt
      descripcionRubro
      descripcionTipoTrabajo
      duracionMaximaTurno
      duracionMaximaTurnoParaColacion
      duracionMaximaTurnoXSemana
      habilidades
      horasMaximasXDia
      horasMaximasXMes
      horasMaximasXSemana
      nombreOficial
      pais
      porcentajeCostoFestivo
      rubro_tipoTrabajo
      tiempoColacion
      updatedAt
      valorMaxRecomendado
      valorMinRecomendado
    }
  }
`;
export const onDeleteSeguridadSocial = /* GraphQL */ `
  subscription OnDeleteSeguridadSocial {
    onDeleteSeguridadSocial {
      createdAt
      nombre
      talanaId
      tipo
      trabajadorId
      updatedAt
    }
  }
`;
export const onDeleteTipoUsuario = /* GraphQL */ `
  subscription OnDeleteTipoUsuario {
    onDeleteTipoUsuario {
      createdAt
      dotacionDiaria
      firmaPendiente
      gestionAsistencias
      idTipoUsuario
      menuSolicitudes
      multimediaEmpresa
      nombre
      nuevaSolicitud
      panelDeControl
      perfilEmpresa
      principal
      provisionPendiente
      recargar
      rutsAsociados
      solicitudes
      trabajosEnCurso
      transacciones
      ubicaciones
      updatedAt
      usuarios
      verTodasSolicitudes
    }
  }
`;
export const onDeleteTrabajador = /* GraphQL */ `
  subscription OnDeleteTrabajador {
    onDeleteTrabajador {
      activo
      banco
      calificacionPromedio
      canalesNotificaciones
      ciudad
      clave
      comuna
      correo
      createdAt
      descripcion
      direccion
      dni
      enlaceFoto
      estId
      fechaNacimiento
      firebaseToken
      idBanco
      nacionalidad
      noCuentaBancaria
      nombre
      pais
      perfiles
      primerApellido
      region
      segundaClave
      segundoApellido
      telefono
      tipoCuenta
      ultimaFechaConsultaAnuncios
      updatedAt
      validadoXEST
      visibilidadFoto
    }
  }
`;
export const onDeleteTrabajadorCalendario = /* GraphQL */ `
  subscription OnDeleteTrabajadorCalendario {
    onDeleteTrabajadorCalendario {
      aprobado
      confirmado
      createdAt
      duracion
      empresaId
      entrada
      estadoAprobacion
      estadoEntrada
      estadoSalida
      fechaFin
      fecha_empresaId_pais_consecutivoOferta
      pais_consecutivoOferta
      salida
      timeZone
      trabajadorId
      turno
      ubicacionId
      ultimoDia
      unidad
      updatedAt
      valorUnitario
    }
  }
`;
export const onDeleteTrabajadorHabilidades = /* GraphQL */ `
  subscription OnDeleteTrabajadorHabilidades {
    onDeleteTrabajadorHabilidades {
      certificado
      correo
      createdAt
      estado
      fechaSolicitud
      habilidadId
      historialEstados
      infoAdicionalCertificado
      resultadoExamen
      tipoHabilidad
      updatedAt
    }
  }
`;
export const onDeleteTrabajadorOferta = /* GraphQL */ `
  subscription OnDeleteTrabajadorOferta {
    onDeleteTrabajadorOferta {
      contratoEmpresa
      contratoId
      contratoTrabajador
      createdAt
      cumplimientoRequisito
      declaracionJuradaSalud
      desvinculacion
      empresaId
      empresaId_pais_consecutivoOferta
      estadoActual
      finiquito
      historialEstados
      infoOnboardingLeida
      pactoHorasExtra
      pais_consecutivoOferta_trabajadorId
      trabajadorId
      turnos
      turnosExtra
      updatedAt
      valorAPagar
    }
  }
`;
export const onDeleteTrabajadorPago = /* GraphQL */ `
  subscription OnDeleteTrabajadorPago {
    onDeleteTrabajadorPago {
      concepto
      createdAt
      empresaId_pais_consecutivoOferta
      fecha_uuid
      historialPagoId
      trabajadorId
      updatedAt
      valor
    }
  }
`;
export const onDeleteTrabajoFavorito = /* GraphQL */ `
  subscription OnDeleteTrabajoFavorito {
    onDeleteTrabajoFavorito {
      createdAt
      ofertasTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const onDeleteTransaccion = /* GraphQL */ `
  subscription OnDeleteTransaccion {
    onDeleteTransaccion {
      correo
      createdAt
      detalle
      empresaId
      fecha_uuid
      monto
      observacion
      tipo
      updatedAt
    }
  }
`;
export const onDeleteUbicacion = /* GraphQL */ `
  subscription OnDeleteUbicacion {
    onDeleteUbicacion {
      activa
      codigoPostal
      createdAt
      direccion
      empresaId
      nombre
      pais_region_ciudad_comuna_uuid
      timezone
      ubicacion
      updatedAt
    }
  }
`;
export const onDeleteUbicacionSupervisor = /* GraphQL */ `
  subscription OnDeleteUbicacionSupervisor {
    onDeleteUbicacionSupervisor {
      correo
      createdAt
      pais_region_ciudad_comuna_uuid
      updatedAt
    }
  }
`;
export const onDeleteUsuarioBackoffice = /* GraphQL */ `
  subscription OnDeleteUsuarioBackoffice {
    onDeleteUsuarioBackoffice {
      cargo
      correo
      createdAt
      nombre
      telefono
      updatedAt
    }
  }
`;
export const onDeleteUsuarioEmpresa = /* GraphQL */ `
  subscription OnDeleteUsuarioEmpresa {
    onDeleteUsuarioEmpresa {
      activo
      cargo
      clave
      correo
      createdAt
      empresaId
      nombre
      telefono
      tipoUsuario
      updatedAt
    }
  }
`;
export const onUpdateBackground = /* GraphQL */ `
  subscription OnUpdateBackground {
    onUpdateBackground {
      anoInicio
      cargoTitulo
      createdAt
      descripcion
      duracionMeses
      institucion
      tipo_uuid
      trabajadorId
      updatedAt
      validadoXEST
    }
  }
`;
export const onUpdateCalificacion = /* GraphQL */ `
  subscription OnUpdateCalificacion {
    onUpdateCalificacion {
      createdAt
      detalles
      empresaId
      fecha
      pais_consecutivoOferta_trabajadorId
      tipo
      updatedAt
    }
  }
`;
export const onUpdateComentariosOfertaTrabajo = /* GraphQL */ `
  subscription OnUpdateComentariosOfertaTrabajo {
    onUpdateComentariosOfertaTrabajo {
      comentario
      createdAt
      empresaId
      pais_consecutivoOferta_fecha
      updatedAt
    }
  }
`;
export const onUpdateComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  subscription OnUpdateComunaTipoTrabajoRecomendaciones {
    onUpdateComunaTipoTrabajoRecomendaciones {
      createdAt
      pais
      region_ciudad_comuna_rubro_tipoTrabajo
      rubro_tipoTrabajo
      updatedAt
      valorMaxRecomendado
      valorMinLegal
      valorMinRecomendado
    }
  }
`;
export const onUpdateContador = /* GraphQL */ `
  subscription OnUpdateContador {
    onUpdateContador {
      contadorAceptadoXEmpresa
      contadorAceptadoXTrabajador
      contadorAceptadosTurno
      contadorContratoAnuladoXBackoffice
      contadorContratoAnuladoXTrabajador
      contadorContratoCerrado
      contadorContratoFirmadoXTrabajador
      contadorDesistidaXTrabajador
      contadorEjecutando
      contadorPostulado
      contadorPreGuardado
      contadorProvisionadoXEmpresa
      contadorRechazadoXEmpresa
      contadorRechazadoXTrabajador
      contadorSeleccionadoXEmpresa
      contadorSeleccionadosTurno
      contadorTerminado
      contadorValidado
      contadorVisitasUsuarioAutenticado
      contadorVisitasUsuarioNoAutenticado
      contratoAnuladoXEmpresa
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const onUpdateContrato = /* GraphQL */ `
  subscription OnUpdateContrato {
    onUpdateContrato {
      cantidadPersonasAContratar
      createdAt
      empresaId
      estadoActual
      historialEstados
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const onUpdateContratoPendiente = /* GraphQL */ `
  subscription OnUpdateContratoPendiente {
    onUpdateContratoPendiente {
      createdAt
      empresaId
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const onUpdateDetalleFacturacion = /* GraphQL */ `
  subscription OnUpdateDetalleFacturacion {
    onUpdateDetalleFacturacion {
      aprobadoPor
      createdAt
      dni
      duracion
      empresaId
      empresaId_paisConsecutivoOferta
      estadoAprobacion
      estadoEntrada
      estadoFacturacion
      estadoSalida
      factorMultiplicador
      facturacion
      fechaFin
      fechaInicio
      fecha_pais_consecutivoOferta_trabajadorId
      nombre
      primerApellido
      registroEntradaPor
      registroSalidaPor
      segundoApellido
      tipoTrabajo
      trabajadorId
      unidad
      updatedAt
      valorAFacturar
      valorAPagar
      valorBruto
      valorContribucion
      valorIVA
      valorImposicion
      valorLiquidado
      valorNeto
      valorPresupuestado
      valorUnitario
    }
  }
`;
export const onUpdateDocumentoEmpresa = /* GraphQL */ `
  subscription OnUpdateDocumentoEmpresa {
    onUpdateDocumentoEmpresa {
      createdAt
      empresaId
      llave
      nombre
      updatedAt
    }
  }
`;
export const onUpdateDocumentoTrabajador = /* GraphQL */ `
  subscription OnUpdateDocumentoTrabajador {
    onUpdateDocumentoTrabajador {
      createdAt
      fechaVencimiento
      nombre
      tipo
      trabajadorId
      updatedAt
      url
      validadoXEST
    }
  }
`;
export const onUpdateEmpresa = /* GraphQL */ `
  subscription OnUpdateEmpresa {
    onUpdateEmpresa {
      calificacionPromedio
      contadorOfertaTrabajo
      createdAt
      credito
      descripcion
      empresaId
      enlaceLogo
      estId
      fechaCreacion
      giro
      nombre
      saldo
      updatedAt
    }
  }
`;
export const onUpdateFacturacion = /* GraphQL */ `
  subscription OnUpdateFacturacion {
    onUpdateFacturacion {
      cargo
      createdAt
      empresa
      empresaId
      estadosFacturacion
      facturado
      liquidoFacturado
      liquidoPresupuestado
      mesFacturado
      numeroContable
      pais_consecutivoOferta_fecha
      trabajadores
      ubicacion
      updatedAt
    }
  }
`;
export const onUpdateFaq = /* GraphQL */ `
  subscription OnUpdateFaq {
    onUpdateFaq {
      categoria
      createdAt
      faqId
      pregunta
      respuesta
      updatedAt
    }
  }
`;
export const onUpdateGeografia = /* GraphQL */ `
  subscription OnUpdateGeografia {
    onUpdateGeografia {
      createdAt
      idRegion_idCiudad_idComuna
      pais
      region_ciudad_comuna
      updatedAt
    }
  }
`;
export const onUpdateHabilidades = /* GraphQL */ `
  subscription OnUpdateHabilidades {
    onUpdateHabilidades {
      createdAt
      descripcion
      estado
      examen
      icono
      id
      nombre
      tipo
      updatedAt
      visibilidad
    }
  }
`;
export const onUpdateHistorialPagos = /* GraphQL */ `
  subscription OnUpdateHistorialPagos {
    onUpdateHistorialPagos {
      createdAt
      fecha
      historialPagoId
      motivo
      tipoTransaccion
      updatedAt
      valor
    }
  }
`;
export const onUpdateHistoricoTrabajador = /* GraphQL */ `
  subscription OnUpdateHistoricoTrabajador {
    onUpdateHistoricoTrabajador {
      createdAt
      duracionUltimoTrabajo
      empresaId
      fechaUltimoTrabajo
      nombreCortoTipoTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const onUpdateIdentificadorFirmante = /* GraphQL */ `
  subscription OnUpdateIdentificadorFirmante {
    onUpdateIdentificadorFirmante {
      correo
      createdAt
      identificadorTributario
      updatedAt
    }
  }
`;
export const onUpdateIdentificadorTributarioEmpresa = /* GraphQL */ `
  subscription OnUpdateIdentificadorTributarioEmpresa {
    onUpdateIdentificadorTributarioEmpresa {
      activo
      comuna
      correoRepresentanteLegal
      createdAt
      direccion
      empresaId
      identificadorTributario
      margenContribucion
      nombreFantasia
      nombreRepresentanteLegal
      razonSocial
      rutRepresentanteLegal
      updatedAt
    }
  }
`;
export const onUpdateLogCambiosTrabajador = /* GraphQL */ `
  subscription OnUpdateLogCambiosTrabajador {
    onUpdateLogCambiosTrabajador {
      cambios
      comentarioBackoffice
      correo
      createdAt
      fecha
      updatedAt
    }
  }
`;
export const onUpdateMultimediaEmpresa = /* GraphQL */ `
  subscription OnUpdateMultimediaEmpresa {
    onUpdateMultimediaEmpresa {
      correoUsuarioEmpresaId_uuid
      createdAt
      empresaId
      llave
      tag
      updatedAt
    }
  }
`;
export const onUpdateNotificacion = /* GraphQL */ `
  subscription OnUpdateNotificacion {
    onUpdateNotificacion {
      createdAt
      destinatarioId
      fecha
      leida
      mensaje
      payload
      remitenteId
      tipoNotificacion
      updatedAt
      urlDestino
    }
  }
`;
export const onUpdateOfertaActiva = /* GraphQL */ `
  subscription OnUpdateOfertaActiva {
    onUpdateOfertaActiva {
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const onUpdateOfertaTrabajo = /* GraphQL */ `
  subscription OnUpdateOfertaTrabajo {
    onUpdateOfertaTrabajo {
      brutoTotal
      causalContrado
      costoNeto
      costoServicio
      createdAt
      descripcionTipoTrabajo
      empresaId
      estadoActual
      facturado
      facturadoRealFinal
      fechaCaducidad
      firmaContrato
      historialEstados
      identificadorTributario
      infoOnboarding
      liquidoTotal
      multimediaOfertaId
      nombreContrato
      nuevosPostulantes
      pais_consecutivoOferta
      provisionFondos
      razonSocial
      rubro
      tipoTrabajo
      totalHoras
      totalVacantes
      turnos
      ubicacion
      updatedAt
      usuariosInvitados
    }
  }
`;
export const onUpdateOfertaTrabajoHabilidades = /* GraphQL */ `
  subscription OnUpdateOfertaTrabajoHabilidades {
    onUpdateOfertaTrabajoHabilidades {
      createdAt
      empresaId_pais_consecutivoOferta
      habilidadId
      obligatoria
      updatedAt
    }
  }
`;
export const onUpdatePorcentajeImposicionesPais = /* GraphQL */ `
  subscription OnUpdatePorcentajeImposicionesPais {
    onUpdatePorcentajeImposicionesPais {
      createdAt
      iva
      pais
      porcentaje
      updatedAt
      valorMinimoLegal
    }
  }
`;
export const onUpdateReplicaTalana = /* GraphQL */ `
  subscription OnUpdateReplicaTalana {
    onUpdateReplicaTalana {
      codigo
      creacion
      createdAt
      nombre
      pais
      razonSocial
      rut
      talanaId
      tipo
      tipoReplica
      updatedAt
      vigente
    }
  }
`;
export const onUpdateRequisito = /* GraphQL */ `
  subscription OnUpdateRequisito {
    onUpdateRequisito {
      createdAt
      descripcion
      empresaId
      fechaVencimiento
      pais_consecutivoOferta_uuid
      requiereArchivo
      updatedAt
    }
  }
`;
export const onUpdateRequisitosLegales = /* GraphQL */ `
  subscription OnUpdateRequisitosLegales {
    onUpdateRequisitosLegales {
      createdAt
      pais
      porcentajeBase
      porcentajeHoraExtra
      porcentajeParafiscales
      porcentajeSemanaCorrida
      updatedAt
    }
  }
`;
export const onUpdateRubroTipoTrabajo = /* GraphQL */ `
  subscription OnUpdateRubroTipoTrabajo {
    onUpdateRubroTipoTrabajo {
      createdAt
      descripcionRubro
      descripcionTipoTrabajo
      duracionMaximaTurno
      duracionMaximaTurnoParaColacion
      duracionMaximaTurnoXSemana
      habilidades
      horasMaximasXDia
      horasMaximasXMes
      horasMaximasXSemana
      nombreOficial
      pais
      porcentajeCostoFestivo
      rubro_tipoTrabajo
      tiempoColacion
      updatedAt
      valorMaxRecomendado
      valorMinRecomendado
    }
  }
`;
export const onUpdateSeguridadSocial = /* GraphQL */ `
  subscription OnUpdateSeguridadSocial {
    onUpdateSeguridadSocial {
      createdAt
      nombre
      talanaId
      tipo
      trabajadorId
      updatedAt
    }
  }
`;
export const onUpdateTipoUsuario = /* GraphQL */ `
  subscription OnUpdateTipoUsuario {
    onUpdateTipoUsuario {
      createdAt
      dotacionDiaria
      firmaPendiente
      gestionAsistencias
      idTipoUsuario
      menuSolicitudes
      multimediaEmpresa
      nombre
      nuevaSolicitud
      panelDeControl
      perfilEmpresa
      principal
      provisionPendiente
      recargar
      rutsAsociados
      solicitudes
      trabajosEnCurso
      transacciones
      ubicaciones
      updatedAt
      usuarios
      verTodasSolicitudes
    }
  }
`;
export const onUpdateTrabajador = /* GraphQL */ `
  subscription OnUpdateTrabajador {
    onUpdateTrabajador {
      activo
      banco
      calificacionPromedio
      canalesNotificaciones
      ciudad
      clave
      comuna
      correo
      createdAt
      descripcion
      direccion
      dni
      enlaceFoto
      estId
      fechaNacimiento
      firebaseToken
      idBanco
      nacionalidad
      noCuentaBancaria
      nombre
      pais
      perfiles
      primerApellido
      region
      segundaClave
      segundoApellido
      telefono
      tipoCuenta
      ultimaFechaConsultaAnuncios
      updatedAt
      validadoXEST
      visibilidadFoto
    }
  }
`;
export const onUpdateTrabajadorCalendario = /* GraphQL */ `
  subscription OnUpdateTrabajadorCalendario {
    onUpdateTrabajadorCalendario {
      aprobado
      confirmado
      createdAt
      duracion
      empresaId
      entrada
      estadoAprobacion
      estadoEntrada
      estadoSalida
      fechaFin
      fecha_empresaId_pais_consecutivoOferta
      pais_consecutivoOferta
      salida
      timeZone
      trabajadorId
      turno
      ubicacionId
      ultimoDia
      unidad
      updatedAt
      valorUnitario
    }
  }
`;
export const onUpdateTrabajadorHabilidades = /* GraphQL */ `
  subscription OnUpdateTrabajadorHabilidades {
    onUpdateTrabajadorHabilidades {
      certificado
      correo
      createdAt
      estado
      fechaSolicitud
      habilidadId
      historialEstados
      infoAdicionalCertificado
      resultadoExamen
      tipoHabilidad
      updatedAt
    }
  }
`;
export const onUpdateTrabajadorOferta = /* GraphQL */ `
  subscription OnUpdateTrabajadorOferta {
    onUpdateTrabajadorOferta {
      contratoEmpresa
      contratoId
      contratoTrabajador
      createdAt
      cumplimientoRequisito
      declaracionJuradaSalud
      desvinculacion
      empresaId
      empresaId_pais_consecutivoOferta
      estadoActual
      finiquito
      historialEstados
      infoOnboardingLeida
      pactoHorasExtra
      pais_consecutivoOferta_trabajadorId
      trabajadorId
      turnos
      turnosExtra
      updatedAt
      valorAPagar
    }
  }
`;
export const onUpdateTrabajadorPago = /* GraphQL */ `
  subscription OnUpdateTrabajadorPago {
    onUpdateTrabajadorPago {
      concepto
      createdAt
      empresaId_pais_consecutivoOferta
      fecha_uuid
      historialPagoId
      trabajadorId
      updatedAt
      valor
    }
  }
`;
export const onUpdateTrabajoFavorito = /* GraphQL */ `
  subscription OnUpdateTrabajoFavorito {
    onUpdateTrabajoFavorito {
      createdAt
      ofertasTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const onUpdateTransaccion = /* GraphQL */ `
  subscription OnUpdateTransaccion {
    onUpdateTransaccion {
      correo
      createdAt
      detalle
      empresaId
      fecha_uuid
      monto
      observacion
      tipo
      updatedAt
    }
  }
`;
export const onUpdateUbicacion = /* GraphQL */ `
  subscription OnUpdateUbicacion {
    onUpdateUbicacion {
      activa
      codigoPostal
      createdAt
      direccion
      empresaId
      nombre
      pais_region_ciudad_comuna_uuid
      timezone
      ubicacion
      updatedAt
    }
  }
`;
export const onUpdateUbicacionSupervisor = /* GraphQL */ `
  subscription OnUpdateUbicacionSupervisor {
    onUpdateUbicacionSupervisor {
      correo
      createdAt
      pais_region_ciudad_comuna_uuid
      updatedAt
    }
  }
`;
export const onUpdateUsuarioBackoffice = /* GraphQL */ `
  subscription OnUpdateUsuarioBackoffice {
    onUpdateUsuarioBackoffice {
      cargo
      correo
      createdAt
      nombre
      telefono
      updatedAt
    }
  }
`;
export const onUpdateUsuarioEmpresa = /* GraphQL */ `
  subscription OnUpdateUsuarioEmpresa {
    onUpdateUsuarioEmpresa {
      activo
      cargo
      clave
      correo
      createdAt
      empresaId
      nombre
      telefono
      tipoUsuario
      updatedAt
    }
  }
`;

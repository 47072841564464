import React from 'react';
import TablaTotalTrabajador from './TablaTotalTrabajador';
import { Typography } from 'antd';
const { Text } = Typography;

export default function TablaPrueba({ items }) {
  function calcularSumaLongitudArrays(arrayDeArrays) {
    let sumaLongitud = 0;

    for (let i = 0; i < arrayDeArrays.length; i++) {
      const arrayActual = arrayDeArrays[i];

      if (Array.isArray(arrayActual)) {
        const asistencias = arrayActual.filter(
          (item) => item.asistencia === 'SI'
        );
        sumaLongitud += asistencias.length;
      }
    }

    return sumaLongitud;
  }
  return (
    <div>
      {items.map((itemTrabajos) => (
        <TablaTotalTrabajador data={itemTrabajos} />
      ))}
      <div style={{ margin: '15px' }}>
        <Text strong>
          TOTAL DOMINGO Y FESTIVO GLOBAL: {calcularSumaLongitudArrays(items)}
        </Text>
      </div>
    </div>
  );
}

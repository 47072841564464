import React, { useEffect, useState } from 'react';
import { Table, Col, Row } from 'antd';
import TablaJornadaDiariaReporte from './TablaJornadaDiariaReporte';
import CartaMarcacion from './CartaMarcacion';
import CosultaResolucion from '../../../../altUtils/CosultaResolucion';
import sumarRestarHorasExtraordinarias from '../../../../altUtils/sumarRestarHorasExtraordinarias';
import Labels from '../../../../altUtils/Labels.json';

export default function CartaRegistrosSemanales({ record }) {
  const [total, seTotal] = useState('00:00:00');

  useEffect(() => {
    const totalDiferenciasHoras =
      sumarRestarHorasExtraordinarias.sumarRestarHorasExtraordinariasArray(
        record
      );

    let [h1, m1, s1] = totalDiferenciasHoras.split(':').map(Number);

    if (h1 >= 45) {
      h1 = h1 - 45;
      seTotal(sumarRestarHorasExtraordinarias.formatoHora(h1, m1, s1));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expandedRowRender = (datos) => {
    return (
      <div>
        <Row gutter={20}>
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
          <CartaMarcacion
            items={datos?.marcaje?.entrada || null}
            title="Entrada"
          />
          <CartaMarcacion
            items={datos?.marcaje?.justificacionEntrada || null}
            title="Justificación Entrada"
          />
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
        </Row>
        <Row gutter={20} style={{ marginTop: '16px' }}>
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
          <CartaMarcacion
            items={datos?.marcaje?.salida || null}
            title="Salida"
          />
          <CartaMarcacion
            items={datos?.marcaje?.justificacionSalida || null}
            title="Justificación Salida"
          />
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
        </Row>
      </div>
    );
  };
  return (
    <Table
      columns={TablaJornadaDiariaReporte(record)}
      locale={Labels['tableLabels ']}
      expandable={{
        expandedRowRender,
      }}
      dataSource={record}
      pagination={false}
      summary={() => (
        <Table.Summary fixed={'bottom'}>
          <Table.Summary.Row>
            <Table.Summary.Cell index={1} colSpan={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={2} colSpan={3}>
              Total Horas Extraordinarias Semanales:
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} colSpan={1}>
              {total}
            </Table.Summary.Cell>
          </Table.Summary.Row>
          <Table.Summary.Row>
            <Table.Summary.Cell index={7} colSpan={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={4} colSpan={3}>
              Compensación:
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5} colSpan={1}>
              {total === '00:00:00' ? <>No</> : <>Si</>}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    />
  );
}

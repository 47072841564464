export default function consultaJustificacion(record) {
  const justificacionEntrada = record?.justificacionEntrada || null;
  const justificacionSalida = record?.justificacionSalida || null;

  if (justificacionEntrada !== null || justificacionSalida !== null) {
    return false;
  } else {
    return true;
  }
}

import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function calcularBruto(datos) {
  const token = await getToken();
  const { data } = await API.post(
    'xinerlink',
    '/remuneraciones/v1/calcularBruto',
    {
      headers: {},
      response: true,
      body: {
        userToken: token,
        ...datos,
      },
    }
  );
  return data;
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  consultarCargos,
  consultarRazones,
  consultarUbicaciones,
  getConfiguracionEmpresa,
} from '../../../services/EmpresaService';
import SolicitudProvider from '../../../context/SolicitudProvider';
import causales from './json/causales.json';
// components
import { message, Alert } from 'antd';
import { PageContainer } from '@ant-design/pro-components';
import StepsContainer from './components/StepsContainer';
import itemRender from '../../../components/layouts/components/itemRender';
import { useLocation } from 'react-router-dom';
import { getRequisition } from '../../../services/RequisitionsService';
import formatRequisition from './functions/formatRequisition';
import Meta from '../../../components/layouts/Meta';
import dayjs from 'dayjs';

export default function NuevaSolicitud() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const requisitionId = params.get('editar')?.replace('-', '#');
  const cloneId = params.get('clonar')?.replace('-', '#');
  const { enterprise } = useSelector((state) => state.enterprise);
  const [loading, setLoading] = useState(true);
  const [datos, setDatos] = useState({
    cargos: [],
    razonesSociales: [],
    ubicaciones: [],
    causales,
  });

  useEffect(() => {
    getDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getDatos() {
    try {
      const [cargos, razonesSociales, ubicaciones, empresa] = await Promise.all(
        [
          consultarCargos(enterprise.empresaId),
          consultarRazones(enterprise.empresaId),
          consultarUbicaciones(enterprise.empresaId),
          getConfiguracionEmpresa(enterprise.empresaId),
        ]
      );
      const data =
        requisitionId || cloneId
          ? await getRequisition(enterprise.empresaId, requisitionId || cloneId)
          : null;
      const requisition = formatRequisition(data);
      setDatos({
        ...datos,
        ubicaciones: ubicaciones.map((ubicacion) => ({
          label: ubicacion.nombre,
          value: ubicacion.pais_region_ciudad_comuna_uuid,
          data: { ...ubicacion.ubicacion, ...ubicacion.direccion },
        })),
        comentarios: data?.comentarios,
        razonesSociales: razonesSociales.map((razon) => ({
          label: `${razon.razonSocial} (${razon.identificadorTributario})`,
          value: razon.razonSocial,
          contribucion: razon.margenContribucion,
        })),
        estadoActual: data?.estadoActual,
        cargos: cargos.map((cargo) => ({
          label: cargo.rubro_tipoTrabajo.split('#')[1],
          value: cargo.rubro_tipoTrabajo,
          reglas: {
            colacion: cargo.tiempoColacion,
            horasMaximas: cargo.duracionMaximaTurno,
            horasParaColacion: cargo.duracionMaximaTurnoParaColacion,
            margenContribucion: cargo.margenContribucion,
          },
        })),
        requisition: {
          ...requisition,
          id: requisitionId ? requisitionId : null,
          turnos: cloneId ? [] : requisitionId ? requisition.turnos : null,
        },
        empresa,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error('Error al obtener datos');
    }
  }

  return (
    <PageContainer
      header={{
        title: requisitionId ? 'Editar Solicitud' : 'Crear Solicitud',
        ghost: true,
        breadcrumb: {
          routes: [
            { path: '/solicitudes', breadcrumbName: 'Solicitudes' },
            {
              path: '/formulario',
              breadcrumbName: 'Formulario',
            },
          ],
          itemRender,
        },
      }}
      loading={loading}
    >
      <Meta
        title={`${requisitionId ? 'Editar Solicitud' : 'Crear Solicitud'}`}
      />

      {datos.estadoActual === 'NoAprobada' &&
        datos?.comentarios?.map((comment, index) => {
          return (
            <Alert
              key={index}
              message={`ATENCIÓN: ${comment.comentario.trim()} (${dayjs(
                comment.createdAt
              ).fromNow()})`}
              type="error"
              showIcon
              className="mb-4"
              closable
            />
          );
        })}
      <SolicitudProvider datos={datos}>
        <StepsContainer />
      </SolicitudProvider>
    </PageContainer>
  );
}

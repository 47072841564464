export default function TablaAsistenciaInfo(items) {
  return [
    {
      title: 'Nombre Trabajador',
      dataIndex: 'nombres',
      key: 'nombres',

      aling: 'center',
      width: 180,
    },
    {
      title: 'Primer apellido',
      dataIndex: 'primerApellido',
      key: 'primerApellido',

      aling: 'center',
      width: 180,
    },
    {
      title: 'Segundo apellido',
      dataIndex: 'segundoApellido',
      key: 'segundoApellido',
      filterSearch: true,
      aling: 'center',
      width: 180,
    },
    {
      title: 'Rut',
      dataIndex: 'rut',
      key: 'rut',
      aling: 'center',
      width: 80,
    },
    {
      title: 'Empresa - Rut',
      dataIndex: 'empresaRut',
      key: 'empresaRut',

      aling: 'center',
      width: 100,
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',

      aling: 'center',
      width: 100,
    },
  ];
}

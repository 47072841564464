import dayjs from 'dayjs';
import { Button, Card, Tooltip } from 'antd';
import { CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';

export default function NotificationCard({ notification, handleClick }) {
  const customId = notification.createdAt + notification.urlDestino;
  const handleLink = () => {
    window.open(notification.urlDestino, '_blank');
  };
  return (
    <Card id={customId} className="mb-2" size="small">
      <div className="flex justify-between items-center">
        <span className="font-bold text-xs">
          {dayjs(notification.fecha).fromNow()}{' '}
          <span className="text-xs font-thin text-gray-300">
            - {dayjs(notification.fecha).format('DD/MM/YYYY h:mm a')}
          </span>
        </span>
        {/* marcar como leida */}
        <div className="flex">
          <Tooltip title="Ver notificacion">
            <Button
              type="link"
              size="small"
              style={{
                color: '#000000',
                opacity: 0.5,
              }}
              onClick={handleLink}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
          {!notification.leida && (
            <Tooltip title="Marcar como leída">
              <Button
                type="link"
                size="small"
                style={{
                  color: '#000000',
                  opacity: 0.5,
                }}
                onClick={() => handleClick(notification)}
                disabled={notification.leida}
              >
                <CheckCircleOutlined />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <p className="text-xs text-gray-400 mt-2 mb-0">{notification.mensaje}</p>
    </Card>
  );
}

import React, { useState } from 'react';
import { PageContainer } from '@ant-design/pro-components';
import Meta from '../../../components/layouts/Meta';
import itemRender from '../../../components/layouts/components/itemRender';
import { Card, Divider } from 'antd';
import SelectorBase from './components/SelectorBase';
import FormMasivo from './components/FormMasivo';

export default function GenerarMasivas() {
  const [solicitud, setSolicitud] = useState(null);
  const [dataSolicitud, setDataSolicitud] = useState(null);
  const [etiquetas, setEtiquetas] = useState(null);

  return (
    <PageContainer
      header={{
        title: 'Generar Masivas',
        ghost: true,
        breadcrumb: {
          routes: [
            { path: '/solicitudes', breadcrumbName: 'Solicitudes' },
            {
              path: '/generar-masivas',
              breadcrumbName: 'Masivas',
            },
          ],
          itemRender,
        },
      }}
      // loading={loading}
    >
      <Meta title="Generar Masivas" />
      <Card>
        <SelectorBase
          solicitud={solicitud}
          setSolicitud={setSolicitud}
          setDataSolicitud={setDataSolicitud}
          dataSolicitud={dataSolicitud}
          setEtiquetas={setEtiquetas}
        />
        <Divider />
        {solicitud && (
          <FormMasivo
            solicitud={solicitud}
            dataSolicitud={dataSolicitud}
            etiquetas={etiquetas}
          />
        )}
      </Card>
    </PageContainer>
  );
}

import React, { useState, useEffect } from 'react';
import PageHeader from '../../components/headers/PageHeader';
import TablaReportesIncidentes from './components/TablaReportesIncidentes';
import { listRegistroIncidentes } from '../../graphql/queries';
import { API } from 'aws-amplify';

export default function ReporteIncidentes() {
  const [loading, setLoading] = useState(false);
  const [reporteIncidentes, setReporteIncidentes] = useState([]);

  useEffect(() => {
    const request = API.graphql({
      query: listRegistroIncidentes,
      variables: { limit: 10000 },
    });
    GetIncidencias(request);
    return () => {
      API.cancel(request, 'Se desmontó el componente');
    };
  }, []);

  const GetIncidencias = async (request) => {
    const { data } = await request;

    setReporteIncidentes(data.listRegistroIncidentes.items);
    setLoading(false);
  };

  return (
    <div className="p-10">
      <PageHeader title="Reporte de Incidentes" head="Reportes"></PageHeader>
      <TablaReportesIncidentes loading={loading} items={reporteIncidentes} />
    </div>
  );
}

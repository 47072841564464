import { API } from 'aws-amplify';
import getToken from '../utils/getToken';
import { getRequisition } from './RequisitionsService';

export async function getTrabajadores(empresa) {
  const token = await getToken();
  const { data } = await API.get(
    'xinerlink',
    `/empresa/v1/trabajadores/${empresa}`,
    {
      headers: {},
      response: true,
      queryStringParameters: {
        userToken: token,
      },
    }
  );
  if (data.results) return data.results;
  else return [];
}

export async function contratarDirecto(empresa, oferta, emails = []) {
  const requisition = await getRequisition(empresa, oferta);
  const promises = emails.map(async (email) => {
    const { data } = await API.post('xinerlink', '/api/postulacion/directa', {
      headers: {},
      response: true,
      body: {
        trabajador: {
          trabajadorId: email,
        },
        oferta: {
          empresaId: empresa,
          pais_consecutivoOferta: requisition.pais_consecutivoOferta,
          turnos: [requisition.turnos[0].id],
          requisitos: requisition.requisitos?.map((requisito) => ({
            uuid: requisito.pais_consecutivoOferta_uuid,
            respuesta: 'si',
          })),
          habilidades: [],
        },
      },
    });
    return data;
  });

  const results = await Promise.all(promises);
  return results;
}

import {
  ClearOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Badge, Button, Form, Input, Select, Space, Tooltip } from 'antd';
const { Option } = Select;

export default function Filters({
  setFilters,
  initialFilters,
  refresh,
  etiquetas,
}) {
  const [form] = Form.useForm();

  function handleSubmit(values) {
    setFilters(values);
  }

  function handleReset() {
    setFilters(initialFilters);
    form.resetFields();
  }

  return (
    <div className="mb-5 flex justify-between">
      <Form
        layout="inline"
        initialValues={initialFilters}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item label="Buscar Trabajador" name="buscar">
          <Input
            placeholder="Nombre Apellido / 000000-0 / @"
            allowClear
            style={{ width: 300 }}
          />
        </Form.Item>
        <Form.Item label="Etiquetas" name="etiquetas">
          <Select
            placeholder="Buscar etiquetas"
            allowClear
            style={{ width: 250, marginBottom: 10 }}
          >
            {etiquetas?.map((etiqueta) => (
              <Option key={etiqueta.etiquetaId} value={etiqueta.etiquetaId}>
                <Space>
                  <span role="img" aria-label={etiqueta.nombre}>
                    <Badge color={etiqueta.color} />
                  </span>
                  <span>
                    {etiqueta.nombre}{' '}
                    <span className="text-gray-400">
                      ({etiqueta.totalTrabajadores})
                    </span>
                  </span>
                </Space>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Space size="small" style={{ marginBottom: 10 }}>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Buscar
          </Button>
          <Tooltip title="Limpiar Filtros">
            <Button
              type="dashed"
              onClick={handleReset}
              icon={<ClearOutlined />}
            />
          </Tooltip>
        </Space>
      </Form>
      <Tooltip title="Recargar Trabajadores">
        <Button onClick={refresh} icon={<ReloadOutlined />} />
      </Tooltip>
    </div>
  );
}

import {
  ClearOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Space, Tooltip } from 'antd';

export default function Filter({
  buscarContratos,
  setFilters,
  initialFilters,
}) {
  const [form] = Form.useForm();

  function handleSubmit(values) {
    setFilters(values);
  }

  function handleReset() {
    setFilters(initialFilters);
    form.resetFields();
  }

  return (
    <div className="mb-5 flex justify-between">
      <Form
        layout="inline"
        initialValues={initialFilters}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item label="Oferta" name="oferta">
          <InputNumber
            placeholder="# Oferta"
            style={{ width: 110 }}
            controls={false}
          />
        </Form.Item>
        <Form.Item label="Contrato" name="id">
          <InputNumber
            placeholder="ID Contrato"
            style={{ width: 110 }}
            controls={false}
          />
        </Form.Item>
        <Form.Item label="RUT" name="rut">
          <Input placeholder="0000000-0" allowClear style={{ width: 125 }} />
        </Form.Item>
        <Form.Item label="Nombre o Correo" name="nombre">
          <Input placeholder="Buscar..." allowClear />
        </Form.Item>
        <Space size="small">
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Buscar
          </Button>
          <Tooltip title="Limpiar Filtros">
            <Button
              type="dashed"
              onClick={handleReset}
              icon={<ClearOutlined />}
            />
          </Tooltip>
        </Space>
      </Form>
      <Tooltip title="Recargar Contratos">
        <Button onClick={buscarContratos} icon={<ReloadOutlined />} />
      </Tooltip>
    </div>
  );
}

import { useEffect, useState } from 'react';
import useSolicitud from '../../../../hooks/useSolicitud';
import { useSelector } from 'react-redux';
import {
  getMultimedia,
  putMultimedia,
} from '../../../../services/MultimediaService';
import { Alert, Button, Card, Divider, message, Progress, Spin } from 'antd';
import { LoadingOutlined, PictureOutlined } from '@ant-design/icons';
import { Storage } from 'aws-amplify';
import { saveMultimedia } from '../../../../services/RequisitionsService';

export default function MultimediaForm({ next, prev }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const { solicitud, handleChange } = useSolicitud();
  const [multimedia, setMultimedia] = useState(null);
  const [showNewMedia, setShowNewMedia] = useState(false);
  const [procesando, setProcesando] = useState(false);

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getFiles() {
    setMultimedia(null);
    const files = await getMultimedia(enterprise.empresaId);
    setMultimedia(files);
  }

  function handleImage(image) {
    const imageExist = solicitud.multimedia.find(
      (img) => img === image.correoUsuarioEmpresaId_uuid
    );
    if (imageExist) {
      const newMultimedia = solicitud.multimedia.filter(
        (img) => img !== image.correoUsuarioEmpresaId_uuid
      );
      handleChange({ multimedia: newMultimedia });
    } else {
      const newMultimedia = [
        ...solicitud.multimedia,
        image.correoUsuarioEmpresaId_uuid,
      ];
      handleChange({ multimedia: newMultimedia });
    }
  }

  async function handleSubmit() {
    setProcesando(true);

    const datos = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: solicitud.id,
      etapa: 'Multimedia',
      multimediaOfertaId: solicitud.multimedia,
    };

    try {
      await saveMultimedia(datos);
      message.info('Guardados archivos multimedia');
      next();
    } catch (error) {
      message.error('Ocurrio un error al guardar los archivos multimedia');
    } finally {
      setProcesando(false);
    }
  }

  return (
    <>
      <h3 className="text-base font-semibold">
        Selecciona los archivos multimedia que deseas mostrar en la solicitud
      </h3>
      <p className="mb-5">
        Puedes seleccionar imagenes o videos. Si no encuentras lo que siempre
        puedes agregar nuevas opciones.
      </p>
      <Button
        data-cy="botonSubirArchivo"
        type="dashed"
        icon={<PictureOutlined />}
        onClick={() => setShowNewMedia(!showNewMedia)}
      >
        Subir nuevo archivo
      </Button>
      {showNewMedia && <NewMediaForm refresh={getFiles} />}
      <Divider />
      {!multimedia && <Spin indicator={<LoadingOutlined spin />} />}
      {multimedia?.length < 1 && (
        <Alert
          type="error"
          message="No tienes contenido multimedia para seleccionar."
        />
      )}
      <div className="grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5">
        {multimedia?.map((image, index) => (
          <ImageComponent
            data-cy={'image' + index}
            image={image}
            key={index}
            existe={solicitud.multimedia.find(
              (img) => img === image.correoUsuarioEmpresaId_uuid
            )}
            handleImage={handleImage}
          />
        ))}
      </div>
      <div className="flex justify-between mt-10">
        <Button onClick={prev}>Anterior</Button>
        <Button
          data-cy="botonSiguiente"
          type="primary"
          onClick={handleSubmit}
          disabled={solicitud.multimedia.length < 1}
          loading={procesando}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
}

function ImageComponent({ image, existe, handleImage }) {
  const stylesClass = existe
    ? 'object-cover w-full h-36 opacity-100 hover:opacity-100 transition cursor-pointer'
    : 'object-cover w-full h-36 opacity-50 hover:opacity-100 transition cursor-pointer';

  const extension = image.tag.split('.');
  const imagesFormat = [
    'jpg',
    'png',
    'jpeg',
    'gif',
    'svg',
    'PNG',
    'JPG',
    'JPEG',
    'GIF',
    'SVG',
  ];

  return (
    <Card
      size="small"
      className="transition"
      style={{
        border: existe ? '5px solid #60a5fa' : '5px solid rgba(0,0,0,0.1)',
      }}
      bodyStyle={{ padding: 0 }}
      onClick={() => handleImage(image)}
    >
      {imagesFormat.includes(extension[extension.length - 1]) && (
        <img src={image.llave} alt={image.tag} className={stylesClass} />
      )}
      {!imagesFormat.includes(extension[extension.length - 1]) && (
        <video
          src={image.llave}
          alt={image.tag}
          className={stylesClass}
          autoPlay
          muted
          loop
        />
      )}
    </Card>
  );
}

function NewMediaForm({ refresh }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [uploading, setUploading] = useState(0);
  async function uploadFile(file) {
    setUploading(0);
    const filetypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/gif',
      'image/svg',
      'video/mp4',
    ];
    if (!filetypes.includes(file.type)) {
      message.error('Archivo no permitido');
      return;
    }
    if (file.size > 10000000) {
      message.error('El archivo no puede ser mayor a 10MB');
      return;
    }
    try {
      const { key } = await Storage.put(
        `empresas/${enterprise.empresaId}/multimedia/${file.name}`,
        file,
        {
          contentType: file.type,
          progressCallback(progress) {
            setUploading(Math.round((progress.loaded / progress.total) * 100));
          },
        }
      );
      await putMultimedia({
        empresaId: enterprise.empresaId,
        multimedia: [
          {
            llave: key,
            tag: file.name,
          },
        ],
      });
      message.success('Archivo subido correctamente');
      refresh();
    } catch (error) {
      setUploading(0);
      message.error('Error al subir el archivo');
    }
  }

  return (
    <div className="mt-5 space-y-5 max-w-sm">
      <input type="file" onChange={(e) => uploadFile(e.target.files[0])} />
      <Progress percent={uploading} />
    </div>
  );
}

import React from 'react';

export default function TablainfoPersonas({ item }) {
  const styles = {
    tableReponsive: {
      maxWidth: '100%',
      marginLeft: '20px',
      marginRight: '20px',
      textAlign: 'center',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacig: '0',
      font: ' 11pt Arial',
    },
    td: {
      padding: '8px',
      textAlign: 'left',
      verticalAlign: 'middle',
      border: '1px solid #ddd',
      font: ' 11pt Arial',
    },
    td2: {
      padding: '8px',
      textAlign: 'center',
      verticalAlign: 'middle',
      border: '1px solid #ddd',
      font: ' 11pt Arial',
      //border: '1px solid',
    },
  };
  return (
    <table
      // style={{
      //   border: '1px solid',
      //   textAlign: 'center',
      //   marginBottom: '10px',
      //   width: widthTable,
      //   font: ' 11pt Arial',
      // }}
      style={styles.table}
    >
      <thead
        style={{
          backgroundColor: ' #C1CCAB',
          height: '60px',
        }}
      >
        <th tyle={styles.td}>
          {' '}
          Nombre Trabajador : {item.nombres} {item.primerApellido}{' '}
          {item.segundoApellido}
        </th>
        <th tyle={styles.td}>Rut: {item.rut}</th>

        <th> Cargo: {item.cargo}</th>
        <th tyle={styles.td}>Empresa: {item.empresaRut}</th>
      </thead>
    </table>
  );
}

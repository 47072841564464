import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Alert, Descriptions, Space, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  getStatusColor,
  getStatusName,
} from '../../../../utils/getStatusSolicitud';
import MoreDetails from './extras/MoreDetails';
import getEventoDatesString from '../../../../utils/getEventosDates';
dayjs.extend(relativeTime);
dayjs.locale('es');

export default function Information({ solicitud }) {
  const fechaInicio = dayjs(solicitud.fechaInicio).tz('America/Santiago');
  const fechaFin = dayjs(solicitud.fechaFin + 'Z').tz('America/Santiago');
  if (!solicitud) return null;
  return (
    <div className="relative">
      <div className="absolute right-0">
        <MoreDetails details={solicitud} />
      </div>
      <Descriptions title="Información de la Solicitud" size="middle" bordered>
        <Descriptions.Item label="Estado actual">
          <Tag color={getStatusColor(solicitud.estadoActual)}>
            {getStatusName(solicitud.estadoActual)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Cargo">
          <Space>
            <span>{solicitud.tipoTrabajo}</span>
            {solicitud.privacidad !== 'publica' && (
              <Tag
                color={solicitud.privacidad === 'privada' ? 'black' : 'blue'}
                bordered={false}
              >
                {solicitud.privacidad === 'privada'
                  ? 'Privada'
                  : 'Mi comunidad'}
              </Tag>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Contrato">
          <Tooltip title={solicitud.causalContrato}>
            <div className="flex items-center space-x-2 max-w-max">
              <span>{solicitud.nombreContrato}</span>
              <InfoCircleOutlined />
            </div>
          </Tooltip>
        </Descriptions.Item>
        {solicitud.nombreReemplazado && (
          <Descriptions.Item label="Reemplazado">
            {solicitud.nombreReemplazado} ({solicitud.rutReemplazado})
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Razon social">
          {solicitud.razonSocial}
        </Descriptions.Item>
        <Descriptions.Item label="Ubicación">
          <Tooltip title={solicitud.ubicacion.direccion}>
            <div className="flex items-center space-x-2 max-w-max">
              <span>{solicitud.ubicacion.nombre}</span>
              <InfoCircleOutlined />
            </div>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de evento">
          {solicitud.fechaInicio
            ? getEventoDatesString(fechaInicio, fechaFin)
            : 'No definida'}
        </Descriptions.Item>
      </Descriptions>
      <div className="flex justify-end mt-5"></div>
      {solicitud.estadoActual === 'NoAprobada' &&
        solicitud.comentarios.map((c, index) => {
          return (
            <Alert
              key={index}
              message={`ATENCIÓN: ${c.comentario.trim()} (${dayjs(
                c.createdAt
              ).fromNow()})`}
              type="error"
              showIcon
              className="mt-4"
            />
          );
        })}
      {solicitud.estadoActual === 'Guardada' && (
        <Alert
          message="ATENCIÓN: Esta solicitud aún no ha sido enviada. Puede terminarla y enviarla cuando lo desee."
          type="warning"
          showIcon
          className="mt-4"
        />
      )}
    </div>
  );
}

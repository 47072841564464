import { WhatsAppOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, message } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { notificarComunidad } from '../../../services/BackofficeService';

export default function NotificarComunidad({ seleccionados }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);

  async function handleNotify() {
    try {
      setLoading(true);
      await notificarComunidad({
        empresaId: enterprise.empresaId,
        trabajadores: seleccionados.map((t) => t.trabajadorId),
      });
      message.success('Notificados correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al enviar la notificación');
    } finally {
      setLoading(false);
      setShow(false);
    }
  }

  if (user?.empresas?.length < 2 ?? true) return null;

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        icon={<WhatsAppOutlined />}
        disabled={seleccionados?.length < 1 ?? true}
        loading={loading}
      >
        Notificar
      </Button>
      <Modal
        title="Notificar comunidad"
        open={show}
        onCancel={() => setShow(false)}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={() => setShow(false)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleNotify}
            loading={loading}
          >
            Notificar
          </Button>,
        ]}
      >
        <Divider />
        <p>
          ¿Está seguro que desea notificar a la comunidad de trabajadores
          seleccionados? <b>({seleccionados.length})</b>
        </p>
      </Modal>
    </>
  );
}

import useSolicitud from '../../../../hooks/useSolicitud';
import { Button, Divider, Form, Input, message, Select, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { saveRequeriments } from '../../../../services/RequisitionsService';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

export default function RequirementsForm({ next, prev }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const { solicitud, handleChange } = useSolicitud();
  const [procesando, setProcesando] = useState(false);

  const [form] = Form.useForm();
  function handleAdd(values) {
    if (solicitud.requisitos.find((r) => r.name === values.name)) {
      message.error('Ya existe un requisito con ese nombre');
      return;
    }
    handleChange({
      requisitos: [...solicitud.requisitos, values],
    });
    form.resetFields();
  }

  function handleDelete(name) {
    handleChange({
      requisitos: solicitud.requisitos.filter((r) => r.name !== name),
    });
  }

  async function handleSubmit() {
    if (solicitud.requisitos.length === 0) next();
    setProcesando(true);
    const datos = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: solicitud.id,
      requisitos: solicitud.requisitos?.map((r) => ({
        requiereArchivo: r.tipo === 'file',
        fechaVencimiento: dayjs().add(1, 'year').format('YYYY-MM-DD'),
        descripcion: r.name,
      })),
      habilidades: [],
    };
    try {
      await saveRequeriments(datos);
      message.info('Requisitos guardados');
      next();
    } catch (error) {
      message.error('Ocurrió un error al guardar la solicitud');
    } finally {
      setProcesando(false);
    }
  }

  return (
    <>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-20">
        <div>
          <h3 className="text-base font-semibold">Requisitos especiales</h3>
          <p className="mb-10">
            ¿Tienes alguna pregunta, requisito especial o requieres un
            certificado en particular (ej. OS10 carabineros, o un certificado de
            salud, etc) que quieres que los trabajadores respondan? Máx 8.
          </p>
          <Form layout="vertical" onFinish={handleAdd} form={form}>
            <Form.Item
              data-cy="itemNombreRequisito"
              label="Nombre del requisito"
              name="name"
              tooltip="No crees requisitos con el mismo nombre."
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Input data-cy="inputNombreRequisito" />
            </Form.Item>
            <Form.Item
              data-cy="itemTipoRequisito"
              label="Tipo de requisito"
              name="tipo"
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Select data-cy="selectTipoRequisito">
                <Select.Option
                  data-cy="selectOptionTipoRequisitoSiNo"
                  value="question"
                >
                  Si/No
                </Select.Option>
                <Select.Option
                  data-cy="selectOptionTipoRequisitoSubirArchivo"
                  value="file"
                >
                  Subir Archivo
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button data-cy="botonAgregar" type="primary" htmlType="submit">
                Agregar
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="xl:col-span-2">
          <h3 className="text-base font-semibold">Listado de requisitos</h3>
          <Table
            dataSource={solicitud.requisitos}
            rowKey="name"
            columns={[
              {
                title: 'Nombre',
                dataIndex: 'name',
              },
              {
                title: 'Tipo',
                dataIndex: 'tipo',
                width: 200,
                render: (text) => {
                  if (text === 'question') return 'Si/No';
                  if (text === 'file') return 'Subir Archivo';
                  return text;
                },
              },
              {
                title: 'Acciones',
                width: 100,
                render: (text, record) => (
                  <Button
                    data-cy={'botonEliminar' + record.name}
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(record.name)}
                  />
                ),
              },
            ]}
            pagination={false}
          />
        </div>
      </div>
      <Divider />
      <div className="flex justify-between mt-10">
        <Button data-cy="botonVolver" onClick={prev}>
          Anterior
        </Button>
        <Button
          data-cy="botonSiguiente"
          type="primary"
          onClick={handleSubmit}
          loading={procesando}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
}

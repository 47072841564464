import { API } from 'aws-amplify';
import { message } from 'antd';

export async function removeTrabajador(trabajadorId, refresh, enterprise) {
  try {
    await API.del(
      'xinerlink',
      `/empresa/v1/trabajadores/${enterprise.empresaId}/${trabajadorId}`,
      {
        headers: {},
        response: true,
      }
    );
    message.success('Trabajador eliminado: ' + trabajadorId);
    refresh();
  } catch (error) {
    message.error('No se ha podido eliminar el trabajador');
    console.error(error);
  }
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Form, Modal, Select, Space, message } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import {
  contratarDirecto,
  getTrabajadores,
} from '../../../../services/TrabajadoresService';

export default function ContratarDirecto({ item }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trabajadores, setTrabajadores] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (show) buscarTrabajadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  async function buscarTrabajadores() {
    try {
      setTrabajadores(null);
      const res = await getTrabajadores(enterprise.empresaId);
      setTrabajadores(res);
    } catch (error) {
      setTrabajadores([]);
      message.error('Error al obtener los trabajadores');
    }
  }

  async function handleSubmit() {
    try {
      const values = await form.validateFields();
      setLoading(true);
      await contratarDirecto(
        enterprise.empresaId,
        values.solicitud,
        values.trabajadores
      );
      handleCancel();
      message.success('Solicitud realizada con éxito');
    } catch (error) {
      console.error(error);
      message.error('Error al procesar la solicitud');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setShow(false);
    form.resetFields();
  }

  if (
    !(
      item.estadoActual === 'Activa' ||
      item.estadoActual === 'Ejecutando' ||
      item.estadoActual === 'EnPausa' ||
      item.estadoActual === 'Finalizada' ||
      item.estadoActual === 'Desierta'
    )
  )
    return null;

  const labelAction =
    item.tipoContrato === 'ContratoObraFaena'
      ? 'Asignar Trabajadores'
      : 'Contratar Directo';

  const labelButton =
    item.tipoContrato === 'ContratoObraFaena' ? 'Asignar' : 'Contratar';

  return (
    <>
      <Space onClick={() => setShow(true)}>
        <FileDoneOutlined />
        <span>{labelAction}</span>
      </Space>
      <Modal
        title={labelAction}
        open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}>
            {labelButton}
          </Button>,
        ]}>
        <Divider />
        <p>Selecciona los trabajadores que deseas para esta solicitud.</p>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            solicitud: item?.pais_consecutivoOferta,
            trabajadores: [],
          }}
          form={form}>
          <Form.Item
            label="Solicitud de trabajo"
            name="solicitud"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar una solicitud de trabajo',
              },
            ]}>
            <Select disabled>
              <Select.Option
                key={item.pais_consecutivoOferta}
                value={item.pais_consecutivoOferta}>
                #{item.idOferta} - {item.tipoTrabajo}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Trabajadores"
            name="trabajadores"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar al menos un trabajador',
              },
            ]}
            tooltip="Puedes filtrarlos por su nombre o RUT">
            <Select
              placeholder="Selecciona los trabajadores"
              loading={!trabajadores}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                const [rut, nombre] = option.key.split('#');
                return (
                  rut.toLowerCase().includes(input.toLowerCase()) ||
                  nombre.toLowerCase().includes(input.toLowerCase())
                );
              }}
              mode="multiple">
              {trabajadores?.map((t) => (
                <Select.Option
                  key={
                    t.dni +
                    '#' +
                    `${t.nombre} ${t.primerApellido} ${t.segundoApellido}` +
                    '#' +
                    t.trabajadorId
                  }
                  value={t.trabajadorId}>
                  <div className="flex justify-between items-center">
                    <span className="md:hidden">
                      {t.nombre.split(' ')[0]} {t.primerApellido}
                    </span>
                    <span className="hidden md:flex">
                      {t.nombre} {t.primerApellido} {t.segundoApellido}
                    </span>
                    <span className="text-xs text-gray-300 ml-5">
                      (RUT: {t.dni})
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

import React from 'react';
import { Space, Table } from 'antd';
import getOptions from '../../../altUtils/getOptions';
import FormarDateLocalTimeZone from '../../../altUtils/FormarDateLocalTimeZone';
import Labels from '../../../altUtils/Labels.json';

export default function TablaReportesIncidentes({ loading, items }) {
  const columns = [
    {
      title: 'Pais',
      dataIndex: 'pais',
      key: 'pais',
      width: 100,
      filterSearch: true,
      filters: getOptions(items, 'pais'),
      onFilter: (v, record) => record.pais.indexOf(v) === 0,
    },
    {
      title: 'Correo',
      dataIndex: 'trabajadorId',
      key: 'trabajadorId',
      width: 100,
      filterSearch: true,
      filters: getOptions(items, 'trabajadorId'),
      onFilter: (v, record) => record.trabajadorId.indexOf(v) === 0,
    },
    {
      title: 'Fecha Servidor',
      dataIndex: 'fecha',
      key: 'fecha',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Space>
          {text.length !== 0 && (
            <div>
              <ul style={{ marginBottom: '0' }}>
                {FormarDateLocalTimeZone(text.split('#')[0], '', 1, true).split(
                  'T'
                )[0] || 'Pendiente'}
              </ul>
              <ul style={{ marginBottom: '0' }}>
                {FormarDateLocalTimeZone(text.split('#')[0], '', 1, true).split(
                  'T'
                )[1] || 'Pendiente'}
              </ul>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: 'Fecha Marcaje',
      dataIndex: 'fechaMarcaje',
      key: 'fechaMarcaje',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Space>
          {text.length !== 0 && (
            <div>
              <ul style={{ marginBottom: '0' }}>
                {FormarDateLocalTimeZone(text, '', 1, true).split('T')[0] ||
                  'Pendiente'}
              </ul>
              <ul style={{ marginBottom: '0' }}>
                {FormarDateLocalTimeZone(text, '', 1, true).split('T')[1] ||
                  'Pendiente'}
              </ul>
            </div>
          )}
        </Space>
      ),
    },

    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 100,
      filterSearch: true,
      filters: getOptions(items, 'tipo'),
      onFilter: (v, record) => record.tipo.indexOf(v) === 0,
    },

    // {
    //   title: "Posición Correcta",
    //   dataIndex: "posicionCorrecta",
    //   key: "posicionCorrecta",
    //   width: 100,
    //   render: (text, record) => (
    //     <Space>
    //       {text && <p>En el lugar</p>}
    //       {!text && <p>Por fuera </p>}
    //     </Space>
    //   ),
    // },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
    },
  ];

  return (
    <Table
      locale={Labels['tableLabels']}
      loading={loading}
      rowKey="id"
      dataSource={items}
      columns={columns}
      scroll={{ x: 'max-content' }}
    />
  );
}

import dayjs from 'dayjs';
import { Divider } from 'antd';
import TurnosInformation from './extras/TurnosInformation';
import CandidatesTable from './CandidatesTable';
import Filter from './Filters';
import { useEffect, useState } from 'react';

export default function TurnosTab({
  turnos,
  refreshing,
  refreshCandidates,
  solicitud,
}) {
  if (turnos.length < 1) return null;

  const fechaInicio = `${turnos[0].fechaInicio}T${turnos[0].horaInicio}Z`;
  const fechaFin = `${turnos[0].fechaFin}T${turnos[0].horaFin}Z`;

  const isSameDay = dayjs(fechaInicio).isSame(dayjs(fechaFin), 'day');
  let label;
  if (isSameDay) {
    label = `${dayjs(fechaInicio).format('DD/MM/YYYY')}`;
  } else {
    label = `${dayjs(fechaInicio).format('DD/MM/YYYY')} al ${dayjs(
      fechaFin
    ).format('DD/MM/YYYY')}`;
  }

  return (
    <TurnosDetails
      turno={turnos[0]}
      label={label}
      refreshing={refreshing}
      refreshCandidates={refreshCandidates}
      solicitud={solicitud}
    />
  );
}

function TurnosDetails({
  turno,
  label,
  refreshing,
  refreshCandidates,
  solicitud,
}) {
  const [candidates, setCandidates] = useState(turno.candidatos);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    if (filters) {
      const filteredCandidates = turno.candidatos.filter((c) => {
        const nombre = `${c.nombre} ${c.primerApellido} ${c.segundoApellido}`;
        return (
          nombre.toLowerCase().includes(filters.toLowerCase()) ||
          c.dni.toLowerCase().includes(filters.toLowerCase())
        );
      });
      setCandidates(filteredCandidates);
    } else {
      setCandidates(turno.candidatos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, turno]);

  return (
    <div>
      <TurnosInformation turno={turno} label={label} />
      <Divider />
      <Filter setFilters={setFilters} />
      <CandidatesTable
        candidates={candidates}
        refreshing={refreshing}
        refreshCandidates={refreshCandidates}
        solicitud={solicitud}
      />
    </div>
  );
}

import { Layout } from 'antd';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import {
  DatabaseOutlined,
  // HomeOutlined,
  InboxOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const { Content } = Layout;

export default function Application({ children }) {
  return (
    <Layout
      style={{
        height: '100vh',
      }}
    >
      <Header />
      <Layout>
        <div className="hidden md:flex">
          <Sidebar />
        </div>
        <Content style={{ padding: '15px 0px', overflowY: 'scroll' }}>
          {children}
          <div className="h-16 md:hidden" />
          <FooterMobile />
        </Content>
      </Layout>
    </Layout>
  );
}

function FooterMobile() {
  const { user } = useSelector((state) => state.auth);
  const isSupervisor = user.tipoUsuario.includes('Supervisor');
  const links = [
    // {
    //   key: '/',
    //   icon: <HomeOutlined />,
    //   label: 'Escritorio',
    // },
    isSupervisor && {
      key: '/dotacion',
      icon: <UserSwitchOutlined />,
      label: 'Dotación Diaria',
    },
    {
      key: '/trabajadores',
      icon: <DatabaseOutlined />,
      label: 'Trabajadores',
    },
    {
      key: 'solicitudes',
      icon: <InboxOutlined />,
      label: 'Solicitudes',
    },
  ];

  return (
    <div
      className="md:hidden fixed bottom-0 w-full bg-white px-5"
      style={{
        borderTop: '1px solid #e8e8e8',
      }}
    >
      <div className="flex justify-between items-center h-14">
        {links.map((link) => (
          <Link
            key={link.key}
            to={link.key}
            className="text-base flex flex-col space-y-1 justify-center items-center text-black"
          >
            {link.icon}
            <span className="text-xs text-gray-400">{link.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';
import FormatoReportesAsistencias from './components/FormatoReportesAsistencias';
import FormatoReporteJornada from './components/FormatoReporteJornada';
import FormatoReporteFestivos from './components/FormatoReporteFestivos';
import FormatoReporteModificaciones from './components/FormatoReporteModificaciones';
import { API } from 'aws-amplify';

export default function BotonExportarPDFV2({
  name = 'jornada',
  formato = 'jornada',
  dataArray = [],
  setexportarpdf,
}) {
  async function Export2Word(element, filename = '') {
    const preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = '</body></html>';
    const html =
      preHtml + document.getElementById(element).innerHTML + postHtml;

    const data = await API.post('xinerlink', '/dt/v1/generarPdf', {
      body: { html: html },
    });
    window.open(data.url, '_blank');
    setexportarpdf(false);
  }

  useEffect(() => {
    Export2Word('exportContent', name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (formato === 'jornada') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',
          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReporteJornada dataArray={dataArray} widthTable="600px" />
      </div>
    );
  } else if (formato === 'asistencia') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',
          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReportesAsistencias dataArray={dataArray} widthTable="600px" />
      </div>
    );
  } else if (formato === 'festivos') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',
          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReporteFestivos dataArray={dataArray} widthTable="600px" />
      </div>
    );
  } else if (formato === 'modificaciones') {
    return (
      <div
        id="exportContent"
        style={{
          width: '21cm',
          margin: '30mm 45mm 30mm 45mm',
        }}
      >
        <FormatoReporteModificaciones
          dataArray={dataArray}
          widthTable="600px"
        />
      </div>
    );
  } else <div style={{ display: 'none' }}></div>;
}

import { Table } from 'antd';
import { columns } from './candidatesColumns';
import sortCandidates from '../../../../utils/sortCandidates';

export default function CandidatesTable({
  candidates,
  refreshing,
  refreshCandidates,
  solicitud,
}) {
  const getRowClassName = (record) => {
    if (record.postulacionesActivas.AceptadoXTrabajador.length > 0)
      return 'opacity-50';
  };

  return (
    <Table
      data-cy="tablaCandidatos"
      dataSource={sortCandidates(candidates)}
      size="middle"
      rowKey={(record) => record.correo}
      // columns={columns(refreshCandidates)}

      columns={columns(
        refreshCandidates,
        solicitud?.pais_consecutivoOferta,
        solicitud
      )}
      pagination={false}
      // className="mb-4"
      loading={refreshing}
      rowClassName={getRowClassName}
    />
  );
}

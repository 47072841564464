import React from 'react';
import Meta from '../../../components/layouts/Meta';
import Header from '../components/Header';
import SolicitarCodigo from './components/SolicitarCodigo';

export default function index() {
  return (
    <div className="h-screen flex items-center justify-center">
      <Meta title="Recuperar contraseña" />
      <div className="flex flex-col items-center">
        <Header text="Recuperar contraseña" />
        <div id="body" className="w-80 md:w-96 mt-10">
          <p className="text-gray-500 text-sm">
            Enviaremos un código a tu correo electrónico para validar que eres
            el propietario de la cuenta.
          </p>
          <SolicitarCodigo />
        </div>
      </div>
    </div>
  );
}

import { AutoComplete, Col, Form, InputNumber, Radio, Row, Select } from 'antd';
import useSolicitud from '../../../../../hooks/useSolicitud';
import requiereContrato from '../../../../../utils/requiereContrato';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';

export default function ConfigureValores({
  form,
  showColation,
  handleColacion,
  reglas,
  handleChanges,
}) {
  const { campos, solicitud } = useSolicitud();
  const initialTipo = 'jornada';
  const [tipoPago, setTipoPago] = useState(initialTipo);
  const [valoresSugeridos, setValoresSugeridos] = useState([]);
  const grupoContrato = requiereContrato(campos.empresa.contratos.tipoContrato);

  useEffect(() => {
    handleColacion();
    obtenerValoresSugeridos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function obtenerValoresSugeridos() {
    try {
      const { liquidoHistorico } = await API.get(
        'xinerlink',
        '/oferta/v1/liquidoHistorico',
        {
          queryStringParameters: {
            empresaId: campos?.empresa?.empresaId || null,
            rubro: solicitud?.infoBasica?.cargo?.split('#')[0] || null,
          },
        }
      );
      if (liquidoHistorico?.length > 0) {
        const noDuplicated = [...new Set(liquidoHistorico)]
          .sort((a, b) => a - b)
          .filter((v) => v > 0);
        setValoresSugeridos(
          noDuplicated.map((v) => ({
            value: v,
            label: `$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
          }))
        );
      }
    } catch (error) {
      console.error('Error al obtener valores sugeridos', error);
    }
  }

  function getPredeterminados() {
    const cargo = solicitud?.infoBasica?.cargo || '';
    const cargosDeEmpresa = campos?.empresa?.cargos || [];
    const cargoEncontrado = cargosDeEmpresa.find(
      (c) => c.rubro_tipoTrabajo === cargo
    );
    return cargoEncontrado;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <Form
        layout="vertical"
        form={form}
        onValuesChange={handleChanges}
        validateTrigger={['onBlur', 'onChange']}
        initialValues={{
          tipopago: initialTipo,
          colacion: solicitud?.turnos[0]?.colacion,
          vacantes: solicitud?.turnos[0]?.vacantes,
          pago: solicitud?.turnos[0]?.pago || getPredeterminados()?.sueldo,
          bono: solicitud?.turnos[0]?.bono || getPredeterminados()?.bono || 0,
          gratificacion:
            solicitud?.turnos[0]?.gratificacion ||
            getPredeterminados()?.gratificacion,
          transporte:
            solicitud?.turnos[0]?.transporte ||
            getPredeterminados()?.transporte,
          valorcolacion:
            solicitud?.turnos[0]?.valorcolacion ||
            getPredeterminados()?.colacion,
        }}
      >
        <Row gutter={20}>
          <div className="hidden">
            <Col xs={24}>
              <Form.Item
                data-cy="itemTipoPago"
                label="Tipo de pago"
                name="tipopago"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecciona un tipo de pago',
                  },
                ]}
                onChange={(e) => setTipoPago(e.target.value)}
              >
                <Radio.Group onChange={handleColacion} disabled>
                  {/* <Radio data-cy="radioHora" value="hora">
                    Hora
                  </Radio> */}
                  <Radio data-cy="radioJornada" value="jornada">
                    Jornada
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </div>
          {showColation && (
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                data-cy="itemTiempoColacion"
                label="Tiempo de colacion"
                name="colacion"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecciona un tiempo de colacion',
                  },
                ]}
              >
                <Select placeholder="Selecciona tiempo de colacion">
                  {reglas.colacion.map((value) => (
                    <Select.Option
                      data-cy={'selectOption' + value}
                      value={value}
                      key={value}
                    >
                      {value} minutos
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              data-cy="itemNumeroVacantes"
              label="Número de vacantes"
              name="vacantes"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa el número de vacantes',
                },
                {
                  type: 'number',
                  min: 1,
                  message: 'El número de vacantes debe ser mayor a 0',
                },
              ]}
            >
              <InputNumber
                data-cy="inputNumeroVacantes"
                style={{ width: '100%' }}
                placeholder="Ingresa vacantes"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              data-cy="itemLiquidoPago"
              label={`${grupoContrato ? 'Liquido' : 'Bruto'} a pagar ${
                tipoPago === 'hora'
                  ? 'por hora'
                  : tipoPago === 'jornada'
                  ? 'por toda la jornada'
                  : ''
              }`}
              name="pago"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa el pago',
                },
              ]}
              getValueProps={(value) => {
                return {
                  value: `$ ${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
                };
              }}
            >
              {/* <InputNumber
                data-cy="inputLiquidoPago"
                style={{ width: '100%' }}
                placeholder="Ingresa pago"
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                }
                parser={(value) => value.replace(/\D/g, '')}
                min={0}
                controls={false}
                disabled={!getPredeterminados()?.rrhh_vars_editables}
              /> */}
              <AutoComplete
                data-cy="inputLiquidoPago"
                style={{ width: '100%' }}
                placeholder="Ingresa pago"
                disabled={!getPredeterminados()?.rrhh_vars_editables}
                value={`$ ${form.getFieldValue('pago')}`}
                options={[
                  {
                    label: 'Montos sugeridos',
                    options: valoresSugeridos,
                  },
                ]}
                onChange={(value) => {
                  // if value is not a number remove all non numeric characters
                  if (isNaN(value)) {
                    const number = value?.replace(/\D/g, '');
                    form.setFieldsValue({ pago: number });
                    // run the onValuesChange function to update the state
                    handleChanges({ pago: number }, form.getFieldsValue());
                    return;
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={4}>
            <Form.Item
              label="Bono"
              name="bono"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa el pago del bono',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Ingresa pago"
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                }
                parser={(value) => value.replace(/\D/g, '')}
                controls={false}
                min={0}
                // disabled={!getPredeterminados()?.rrhh_vars_editables}
              />
            </Form.Item>
          </Col>
          {grupoContrato && (
            <>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  data-cy="itemGratificacion"
                  label="Gratificación"
                  name="gratificacion"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor selecciona una opción',
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={!getPredeterminados()?.rrhh_vars_editables}
                  >
                    <Radio data-cy="radioGratificacionSi" value={true}>
                      Si
                    </Radio>
                    <Radio data-cy="radioGratificacionNo" value={false}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  data-cy="itemPagoTransporte"
                  label="Transporte"
                  name="transporte"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el pago de transporte',
                    },
                  ]}
                >
                  <InputNumber
                    data-cy="inputPagoTransporte"
                    style={{ width: '100%' }}
                    placeholder="Ingresa pago"
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    }
                    parser={(value) => value.replace(/\D/g, '')}
                    controls={false}
                    min={0}
                    disabled={!getPredeterminados()?.rrhh_vars_editables}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  data-cy="itemValorColacion"
                  label="Colación"
                  name="valorcolacion"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el pago de colación',
                    },
                  ]}
                >
                  <InputNumber
                    data-cy="inputValorColacion"
                    style={{ width: '100%' }}
                    placeholder="Ingresa pago"
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    }
                    parser={(value) => value.replace(/\D/g, '')}
                    min={0}
                    controls={false}
                    disabled={!getPredeterminados()?.rrhh_vars_editables}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  );
}

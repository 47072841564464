import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { downloadExcel } from '../../../altUtils/DownloadExcelFile';

export default function DownloadExcel({ trabajadores }) {
  const columns = [
    {
      title: 'RUT',
      dataIndex: 'rut',
      key: 'rut',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Apellido Paterno',
      dataIndex: 'primerApellido',
      key: 'primerApellido',
    },
    {
      title: 'Apellido Materno',
      dataIndex: 'segundoApellido',
      key: 'segundoApellido',
    },
    {
      title: 'Edad',
      dataIndex: 'edad',
      key: 'edad',
    },
    {
      title: 'Telefono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Cargo',
      dataIndex: 'tipoTrabajo',
      key: 'tipoTrabajo',
    },
    {
      title: 'Contrato',
      dataIndex: 'nombreContrato',
      key: 'nombreContrato',
    },
    {
      title: 'Oferta',
      dataIndex: 'pais_consecutivoOferta',
      key: 'pais_consecutivoOferta',
      render: (text) => (text ? text.split('#')[1] : null),
    },
    {
      title: 'Ubicación',
      dataIndex: 'ubicacion',
      key: 'ubicacion',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccionTrabajador',
      key: 'direccionTrabajador',
    },
    {
      title: 'Fecha',
      dataIndex: 'entrada',
      key: 'entrada',
      render: (text) => dayjs(text + 'Z').format('DD/MM/YYYY'),
    },
    {
      title: 'Entrada',
      dataIndex: 'entrada',
      key: 'entrada',
      render: (text) => dayjs(text + 'Z').format('HH:mm A'),
    },
    {
      title: 'Salida',
      dataIndex: 'salida',
      key: 'salida',
      render: (text) => dayjs(text + 'Z').format('HH:mm A'),
    },
    {
      title: 'Unidad de pago',
      dataIndex: 'unidad',
      key: 'unidad',
    },
    {
      title: 'Pago',
      dataIndex: 'valorUnitario',
      key: 'valorUnitario',
    },
    {
      title: 'Ultimo dia',
      dataIndex: 'ultimoDia',
      key: 'ultimoDia',
    },
    {
      title: 'Llegada',
      dataIndex: 'llegada',
      key: 'llegada',
    },
  ];

  function handleClick() {
    downloadExcel(columns, trabajadores, 'Dotación');
  }

  return (
    <Button
      icon={<FileExcelOutlined />}
      onClick={handleClick}
      disabled={!trabajadores || trabajadores.length < 1}
    >
      Descargar Excel
    </Button>
  );
}

import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function getExcelCandidates(empresaId, id, estado = '') {
  const token = await getToken();
  const { data } = await API.get('backoffice', '/verCandidatos', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
      pais_consecutivoOferta: id,
      estadoActual: estado,
      cantidad: 500, // CANTIDAD DE ELEMENTOS QUE SE DESEAN MOSTRAR POR PAGINA (25, 50, 100, 300)
      LastEvaluatedKey: '',
    },
  });
  if (data.existe) return data.registro.Items;
  else return [];
}

export async function notificarComunidad(body) {
  const token = await getToken();
  const { data } = await API.post(
    'xinerlink',
    '/backoffice/notificarComunidad',
    {
      headers: {},
      response: true,
      body: {
        userToken: token,
        ...body,
      },
    }
  );
  return data;
}

/**
 * @param {{ etiquetas: string[], mensaje: string, empresaId: string }} body
 * @returns
 */
export async function notificarPorEtiquetas(body) {
  const token = await getToken();
  const { data } = await API.post(
    'xinerlink',
    '/backoffice/notificarPorEtiquetas',
    {
      headers: {},
      response: true,
      body: {
        userToken: token,
        ...body,
      },
    }
  );
  return data;
}

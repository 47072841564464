import {
  ClearOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
} from 'antd';
const { Option } = Select;

export default function Filter({
  buscarSolicitudes,
  setFilters,
  initialFilters,
  estados,
}) {
  const [form] = Form.useForm();

  function handleSubmit(values) {
    setFilters(values);
  }

  function handleReset() {
    setFilters(initialFilters);
    form.resetFields();
  }

  return (
    <div className="mb-6 flex justify-between">
      <Form
        layout="inline"
        initialValues={initialFilters}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item label="ID" name="id">
          <InputNumber
            placeholder="#"
            style={{ width: 60, marginBottom: 10 }}
            controls={false}
          />
        </Form.Item>
        <Form.Item label="Cargo" name="cargo">
          <Input
            placeholder="Buscar cargo"
            allowClear
            style={{ width: 180, marginBottom: 10 }}
          />
        </Form.Item>
        <Form.Item label="Motivo" name="motivo">
          <Input
            placeholder="Buscar por motivo"
            allowClear
            style={{ width: 180, marginBottom: 10 }}
          />
        </Form.Item>
        <Form.Item label="Fecha" name="fecha">
          <DatePicker.RangePicker
            placeholder={['Desde', 'Hasta']}
            style={{ width: 250, marginBottom: 10 }}
            format={'DD/MM/YYYY'}
          />
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select
            placeholder="Buscar estado"
            allowClear
            style={{ width: 150, marginBottom: 10 }}
          >
            {estados.map((estado) => (
              <Option key={estado.value} value={estado.value}>
                {estado.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Solo por cubrir"
          name="porcubrir"
          valuePropName="checked"
        >
          <Checkbox defaultChecked={false} />
        </Form.Item>
        <Space size="small" style={{ marginBottom: 10 }}>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Buscar
          </Button>
          <Tooltip title="Limpiar Filtros">
            <Button
              type="dashed"
              onClick={handleReset}
              icon={<ClearOutlined />}
            />
          </Tooltip>
        </Space>
      </Form>
      <Tooltip title="Recargar Solicitudes">
        <Button onClick={buscarSolicitudes} icon={<ReloadOutlined />} />
      </Tooltip>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequisition } from '../../../services/RequisitionsService';
import { getRequisitionCandidates } from '../../../services/CandidateService';
import { Button, Table } from 'antd';
import mapRequisition from '../solicitud/utils/mapRequisition';
import { columns } from '../solicitud/components/candidatesColumns';
import sortCandidates from '../../../utils/sortCandidates';
import { ReloadOutlined } from '@ant-design/icons';
import HireModal from '../solicitud/components/HireModal';

export default function CandidatesTable({ record }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [solicitud, setSolicitud] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    obtenerSolicitud();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  async function obtenerSolicitud() {
    setError(null);
    setRefreshing(true);
    setSolicitud(null);
    try {
      const [requisition, candidates] = await Promise.all([
        getRequisition(enterprise.empresaId, record.pais_consecutivoOferta),
        getRequisitionCandidates(
          enterprise.empresaId,
          record.pais_consecutivoOferta
        ),
      ]);
      const mapedRequisitions = mapRequisition(requisition, candidates);
      setSolicitud(mapedRequisitions);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setRefreshing(false);
    }
  }

  async function refreshCandidates() {
    setRefreshing(true);
    try {
      const candidates = await getRequisitionCandidates(
        enterprise.empresaId,
        record.pais_consecutivoOferta
      );
      const mapedRequisitions = mapRequisition(solicitud, candidates);
      setSolicitud(mapedRequisitions);
      return true;
    } catch (error) {
      return false;
    } finally {
      setRefreshing(false);
    }
  }

  if (error) return <p>Error al obtener los candidatos</p>;

  function getTotalCandidatesSelected() {
    if (!solicitud) return 0;
    const total = solicitud.turnos.reduce((acc, turno) => {
      const validatedStatus = [
        'SeleccionadoXEmpresa',
        'ProvisionadoXEmpresa',
        'PendienteXFirma',
      ];
      return (
        acc +
        turno.candidatos.filter((candidate) =>
          validatedStatus.includes(candidate.estadoActual)
        ).length
      );
    }, 0);
    return total;
  }
  return (
    <Table
      dataSource={sortCandidates(solicitud?.turnos[0]?.candidatos || [])}
      size="middle"
      rowKey={(record) => record.correo}
      columns={columns(refreshCandidates, solicitud?.pais_consecutivoOferta, solicitud)}
      pagination={false}
      loading={refreshing || !solicitud}
      footer={() => (
        <div className="flex space-x-2 justify-end">
          {solicitud && (
            <>
              <HireModal
                solicitud={solicitud}
                total={getTotalCandidatesSelected()}
                id={solicitud?.pais_consecutivoOferta}
                refresh={refreshCandidates}
              />
              <Button onClick={refreshCandidates} icon={<ReloadOutlined />}>
                Recargar candidatos
              </Button>
            </>
          )}
        </div>
      )}
    />
  );
}

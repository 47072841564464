import React from 'react';
import { Space, Table, Typography } from 'antd';
import FormatDateV2 from '../../../../altUtils/FormatDateV2';

const { Text } = Typography;

export default function TablaTotalTrabajador({ data }) {
  const columns = [
    {
      title: 'Beneficiarios de descansos o dominicales adicionales',
      dataIndex: 'beneficiario',
      aling: 'center',
      key: 'beneficiario',
      width: 100,
      render: (text) => {
        return <Space>{text}</Space>;
      },
    },
    {
      title: 'Domingo y/o festivos',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 100,
      render: (text) => {
        return <Space>{FormatDateV2(text).split('T')[0]}</Space>;
      },
    },
    {
      title: 'Asistencia',
      dataIndex: 'asistencia',
      aling: 'center',
      key: 'asistencia',
      width: 100,
      render: (text) => {
        return <Space>{text}</Space>;
      },
    },
    {
      title: 'Ausencia',
      dataIndex: 'ausencia',
      key: 'ausencia',
      width: 100,
    },
    {
      title: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 100,
    },
  ];

  const datatotales = data.filter((item) => item.asistencia === 'SI');

  const total = datatotales.length || 0;

  const footer = () => <Text strong>TOTAL DOMINGO Y FESTIVO : {total}</Text>;

  return (
    <div className="mx-auto mt-1" style={{ maxWidth: '70rem' }}>
      <Table
        columns={columns}
        dataSource={data}
        footer={footer}
        bordered
        pagination={false}
      />
    </div>
  );
}

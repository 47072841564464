import { Button, Divider, Form, Input, InputNumber, message } from 'antd';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import useSolicitud from '../../../../hooks/useSolicitud';
import { saveOnboarding } from '../../../../services/RequisitionsService';

export default function OnboardingForm({ prev, next }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const { solicitud, handleChange } = useSolicitud();
  const [procesando, setProcesando] = useState(false);
  const [form] = Form.useForm();

  async function handleSubmit(values) {
    setProcesando(true);
    handleChange({
      infoOnboarding: {
        ...solicitud.infoOnboarding,
        contacto: { ...values, telefono: values.telefono.toString() },
      },
    });
    const datos = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: solicitud.id,
      etapa: 'Informacion Onboarding',
      infoOnboarding: {
        ...solicitud.infoOnboarding,
        contacto: { ...values, telefono: values.telefono.toString() },
      },
    };
    try {
      await saveOnboarding(datos);
      message.success('Información guardada');
      next();
    } catch (error) {
      message.error('Ocurrió un error al guardar la información');
    } finally {
      setProcesando(false);
    }
  }

  function handleTexts(x, values) {
    handleChange({
      infoOnboarding: { ...solicitud.infoOnboarding, ...values },
    });
  }

  return (
    <>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-20">
        <div>
          <h3 className="text-base font-semibold">Información supervisor</h3>
          <p className="mb-10">
            La persona de contacto, aparecerá en la aplicación del trabajador,
            es un representante operativo de la empresa que el trabajador puede
            contactar vía whatsapp, teléfono o correo en caso de alguna duda.
          </p>
          <Form
            layout="vertical"
            initialValues={solicitud.infoOnboarding?.contacto}
            form={form}
            onFinish={handleSubmit}
            scrollToFirstError
          >
            <Form.Item
              data-cy="itemNombreApellido"
              label="Nombres y Apellidos"
              name="nombre"
              rules={[
                { required: true, message: 'Ingresa el nombre del supervisor' },
              ]}
            >
              <Input data-cy="inputNombreApellido" />
            </Form.Item>
            <Form.Item
              data-cy="itemTelefono"
              label="Teléfono"
              name="telefono"
              rules={[
                { required: true, message: 'Ingresa un número telefónico' },
              ]}
            >
              <InputNumber
                data-cy="inputTelefono"
                style={{ width: '100%' }}
                prefix="+56"
                maxLength={9}
                minLength={9}
                controls={false}
              />
            </Form.Item>
            <Form.Item
              data-cy="itemCorreo"
              label="Correo electrónico"
              name="correo"
              rules={[
                {
                  required: true,
                  message: 'Ingresa un correo electrónico',
                },
                { type: 'email', message: 'Ingresa un correo válido' },
              ]}
            >
              <Input data-cy="inputCorreo" />
            </Form.Item>
          </Form>
        </div>
        <div className="xl:col-span-2">
          <Form
            layout="vertical"
            onValuesChange={handleTexts}
            initialValues={solicitud.infoOnboarding}
          >
            <Form.Item
              data-cy="itemVestimenta"
              label="Vestimenta"
              name="vestimenta"
            >
              <ReactQuill data-cy="itemRounded" className="rounded" />
            </Form.Item>
            <Form.Item
              data-cy="itemTransporte"
              label="Transporte"
              name="transporte"
            >
              <ReactQuill />
            </Form.Item>
            <Form.Item data-cy="itemColacion" label="Colación" name="colacion">
              <ReactQuill />
            </Form.Item>
            <Form.Item
              data-cy="itemPrimerDia"
              label="Primer día"
              name="primerDia"
            >
              <ReactQuill />
            </Form.Item>
          </Form>
        </div>
      </div>
      <Divider />
      <div className="flex justify-between mt-10">
        <Button data-cy="botonVolver" onClick={prev}>
          Anterior
        </Button>
        <Button
          data-cy="botonFinalizar"
          type="primary"
          onClick={() => form.submit()}
          loading={procesando}
        >
          Finalizar
        </Button>
      </div>
    </>
  );
}

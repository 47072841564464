import dayjs from 'dayjs';
import { getNationalityFromAbreviation as getNationality } from '../../../../../utils/getNationalities';
import { Descriptions, Empty, List, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import isHired from '../../../../../utils/isHired';

export function Info({ candidate }) {
  const { comuna, ciudad, region, pais } = candidate.perfil;
  const direccion = `${comuna}, ${ciudad}, ${region}, ${pais}`;
  // obtener edad a partir de la fecha de nacimiento
  const edad = dayjs().diff(candidate.perfil.fechaNacimiento, 'year');

  return (
    <Descriptions
      column={3}
      layout="vertical"
      labelStyle={{
        fontWeight: 200,
        color: 'rgba(0,0,0,0.5)',
        fontSize: 12,
        marginBottom: -16,
      }}
    >
      <Descriptions.Item label="Fecha de nacimiento">
        <Tooltip title={`${edad} años`}>
          <Space>
            {dayjs(candidate.perfil.fechaNacimiento).format('DD/MM/YYYY')}
            <InfoCircleOutlined />
          </Space>
        </Tooltip>
      </Descriptions.Item>
      <Descriptions.Item label="Sexo">
        {candidate.perfil.sexo === 'M' ? 'Masculino' : 'Femenino'}
      </Descriptions.Item>
      <Descriptions.Item label="País de origen">
        {getNationality(candidate.perfil.nacionalidad)}
      </Descriptions.Item>
      <Descriptions.Item label="Dirección" span={3}>
        {direccion}
      </Descriptions.Item>
      <Descriptions.Item label="Descripción Personal" span={3}>
        {candidate.perfil.descripcion || 'No se ha descrito aún'}
      </Descriptions.Item>
      {isHired(candidate.estadoActual) && (
        <>
          <Descriptions.Item label="Correo Electrónico" span={3}>
            {candidate.correo}
          </Descriptions.Item>
          <Descriptions.Item label="Teléfono" span={3}>
            {candidate.perfil.telefono}
          </Descriptions.Item>
        </>
      )}
    </Descriptions>
  );
}

export function Experience({ candidate }) {
  if (candidate.perfil.experiencia.length === 0)
    return (
      <div className="p-10">
        <Empty description="No se han registrado experiencias laborales" />
      </div>
    );
  return (
    <List
      itemLayout="horizontal"
      dataSource={candidate.perfil.experiencia}
      renderItem={(item, index) => (
        <List.Item key={index} style={{ padding: '10px 0px' }}>
          <List.Item.Meta
            title={item.cargoTitulo}
            description={item.institucion + ' - ' + item.anoInicio}
          />
        </List.Item>
      )}
    />
  );
}

export function Education({ candidate }) {
  if (candidate.perfil.estudios.length === 0)
    return (
      <div className="p-10">
        <Empty description="No se han registrado estudios" />
      </div>
    );
  return (
    <List
      itemLayout="horizontal"
      dataSource={candidate.perfil.estudios}
      renderItem={(item, index) => (
        <List.Item key={index} style={{ padding: '10px 0px' }}>
          <List.Item.Meta
            title={item.cargoTitulo}
            description={item.institucion + ' - ' + item.anoInicio}
          />
        </List.Item>
      )}
    />
  );
}

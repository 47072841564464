import { useEffect } from 'react';

export default function Meta({ title }) {
  useEffect(() => {
    document.title = title + ' - Xinerlink';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return;
}

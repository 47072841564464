import { useState } from 'react';
import { Steps } from 'antd';
import BasicForm from './BasicForm';
import TurnForm from './TurnForm';
import MultimediaForm from './MultimediaForm';
import RequirementsForm from './RequirementsForm';
import OnboardingForm from './OnboardingForm';
import FinishForm from './FinishForm';

export default function StepsContainer() {
  const [current, setCurrent] = useState(0);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const steps = [
    { title: 'Información Básica', content: <BasicForm next={next} /> },
    {
      title: 'Fechas y Valores',
      content: <TurnForm next={next} prev={prev} />,
    },
    {
      title: 'Multimedia',
      content: <MultimediaForm next={next} prev={prev} />,
    },
    {
      title: 'Requisitos',
      content: <RequirementsForm next={next} prev={prev} />,
    },
    {
      title: 'Onboarding',
      content: <OnboardingForm next={next} prev={prev} />,
    },
    { title: 'Resumen', content: <FinishForm prev={prev} /> },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  return (
    <>
      <Steps current={current} items={items} size="small" />
      <div className="mt-10">{steps[current].content}</div>
    </>
  );
}

import { useEffect, useState } from 'react';
import { Card } from 'antd';
import { useSelector } from 'react-redux';
import { PictureOutlined } from '@ant-design/icons';
import { getMultimedia } from '../../../../../services/MultimediaService';

export default function Multimedia({ content }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    getUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUrls() {
    const allImages = await getMultimedia(enterprise.empresaId);
    const images = allImages.filter((item) =>
      content.some((id) => id === item.correoUsuarioEmpresaId_uuid)
    );
    setUrls(images);
  }

  const validateFormats = ['jpg', 'png', 'jpeg', 'gif'];
  const classes = 'object-cover w-full h-58 opacity-100';

  return (
    <div className="grid gap-5 max-h-96 overflow-y-auto">
      {urls.length === 0 && (
        <div className="flex flex-col items-center justify-center my-16">
          <PictureOutlined className="text-4xl text-gray-400 animate-pulse" />
          <p className="text-gray-400">Obteniendo archivos multimedia</p>
        </div>
      )}
      {urls?.map((image, index) => (
        <Card key={index} bodyStyle={{ padding: 0 }}>
          {validateFormats.includes(image.tag.split('.')[1]) ? (
            <img src={image.llave} alt={image.tag} className={classes} />
          ) : (
            <video
              src={image.llave}
              alt={image.tag}
              className={classes}
              autoPlay
              muted
              loop
            />
          )}
        </Card>
      ))}
    </div>
  );
}

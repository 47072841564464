/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const trabajadorCalendarioByEmpresaIdAndPaisConsecutivoOferta = /* GraphQL */ `
  query TrabajadorCalendarioByEmpresaIdAndPaisConsecutivoOferta(
    $empresaId: String!
    $filter: ModelTrabajadorCalendarioFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    TrabajadorCalendarioByEmpresaIdAndPaisConsecutivoOferta(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta: $pais_consecutivoOferta
      sortDirection: $sortDirection
    ) {
      items {
        aprobado
        confirmado
        createdAt
        duracion
        empresaId
        entrada
        estadoAprobacion
        estadoEntrada
        estadoSalida
        fechaFin
        fecha_empresaId_pais_consecutivoOferta
        pais_consecutivoOferta
        salida
        timeZone
        trabajadorId
        turno
        ubicacionId
        ultimoDia
        unidad
        updatedAt
        valorUnitario
      }
      nextToken
    }
  }
`;
export const trabajadorPagoByEmpresaIdPaisConsecutivoOferta = /* GraphQL */ `
  query TrabajadorPagoByEmpresaIdPaisConsecutivoOferta(
    $empresaId_pais_consecutivoOferta: String!
    $filter: ModelTrabajadorPagoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    TrabajadorPagoByEmpresaIdPaisConsecutivoOferta(
      empresaId_pais_consecutivoOferta: $empresaId_pais_consecutivoOferta
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        concepto
        createdAt
        empresaId_pais_consecutivoOferta
        fecha_uuid
        historialPagoId
        trabajadorId
        updatedAt
        valor
      }
      nextToken
    }
  }
`;
export const trabajadorPagoByhistorialPagoId = /* GraphQL */ `
  query TrabajadorPagoByhistorialPagoId(
    $filter: ModelTrabajadorPagoFilterInput
    $historialPagoId: String!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    TrabajadorPagoByhistorialPagoId(
      filter: $filter
      historialPagoId: $historialPagoId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        concepto
        createdAt
        empresaId_pais_consecutivoOferta
        fecha_uuid
        historialPagoId
        trabajadorId
        updatedAt
        valor
      }
      nextToken
    }
  }
`;
export const transaccionByCorreo = /* GraphQL */ `
  query TransaccionByCorreo(
    $correo: AWSEmail!
    $filter: ModelTransaccionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    TransaccionByCorreo(
      correo: $correo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        correo
        createdAt
        detalle
        empresaId
        fecha_uuid
        monto
        observacion
        tipo
        updatedAt
      }
      nextToken
    }
  }
`;
export const detalleFacturacionByTrabajadorIdAndEmpresaIdPaisConsecutivoOferta = /* GraphQL */ `
  query DetalleFacturacionByTrabajadorIdAndEmpresaIdPaisConsecutivoOferta(
    $empresaId_paisConsecutivoOferta: ModelStringKeyConditionInput
    $filter: ModelDetalleFacturacionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $trabajadorId: String!
  ) {
    detalleFacturacionByTrabajadorIdAndEmpresaIdPaisConsecutivoOferta(
      empresaId_paisConsecutivoOferta: $empresaId_paisConsecutivoOferta
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      trabajadorId: $trabajadorId
    ) {
      items {
        aprobadoPor
        createdAt
        dni
        duracion
        empresaId
        empresaId_paisConsecutivoOferta
        estadoAprobacion
        estadoEntrada
        estadoFacturacion
        estadoSalida
        factorMultiplicador
        facturacion
        fechaFin
        fechaInicio
        fecha_pais_consecutivoOferta_trabajadorId
        nombre
        primerApellido
        registroEntradaPor
        registroSalidaPor
        segundoApellido
        tipoTrabajo
        trabajadorId
        unidad
        updatedAt
        valorAFacturar
        valorAPagar
        valorBruto
        valorContribucion
        valorIVA
        valorImposicion
        valorLiquidado
        valorNeto
        valorPresupuestado
        valorUnitario
      }
      nextToken
    }
  }
`;
export const getBackground = /* GraphQL */ `
  query GetBackground($tipo_uuid: String!, $trabajadorId: String!) {
    getBackground(tipo_uuid: $tipo_uuid, trabajadorId: $trabajadorId) {
      anoInicio
      cargoTitulo
      createdAt
      descripcion
      duracionMeses
      institucion
      tipo_uuid
      trabajadorId
      updatedAt
      validadoXEST
    }
  }
`;
export const getCalificacion = /* GraphQL */ `
  query GetCalificacion(
    $empresaId: String!
    $pais_consecutivoOferta_trabajadorId: String!
  ) {
    getCalificacion(
      empresaId: $empresaId
      pais_consecutivoOferta_trabajadorId: $pais_consecutivoOferta_trabajadorId
    ) {
      createdAt
      detalles
      empresaId
      fecha
      pais_consecutivoOferta_trabajadorId
      tipo
      updatedAt
    }
  }
`;
export const getComentariosOfertaTrabajo = /* GraphQL */ `
  query GetComentariosOfertaTrabajo(
    $empresaId: String!
    $pais_consecutivoOferta_fecha: String!
  ) {
    getComentariosOfertaTrabajo(
      empresaId: $empresaId
      pais_consecutivoOferta_fecha: $pais_consecutivoOferta_fecha
    ) {
      comentario
      createdAt
      empresaId
      pais_consecutivoOferta_fecha
      updatedAt
    }
  }
`;
export const getComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  query GetComunaTipoTrabajoRecomendaciones(
    $pais: String!
    $region_ciudad_comuna_rubro_tipoTrabajo: String!
  ) {
    getComunaTipoTrabajoRecomendaciones(
      pais: $pais
      region_ciudad_comuna_rubro_tipoTrabajo: $region_ciudad_comuna_rubro_tipoTrabajo
    ) {
      createdAt
      pais
      region_ciudad_comuna_rubro_tipoTrabajo
      rubro_tipoTrabajo
      updatedAt
      valorMaxRecomendado
      valorMinLegal
      valorMinRecomendado
    }
  }
`;
export const getContador = /* GraphQL */ `
  query GetContador($empresaId: String!, $pais_consecutivoOferta: String!) {
    getContador(
      empresaId: $empresaId
      pais_consecutivoOferta: $pais_consecutivoOferta
    ) {
      contadorAceptadoXEmpresa
      contadorAceptadoXTrabajador
      contadorAceptadosTurno
      contadorContratoAnuladoXBackoffice
      contadorContratoAnuladoXTrabajador
      contadorContratoCerrado
      contadorContratoFirmadoXTrabajador
      contadorDesistidaXTrabajador
      contadorEjecutando
      contadorPostulado
      contadorPreGuardado
      contadorProvisionadoXEmpresa
      contadorRechazadoXEmpresa
      contadorRechazadoXTrabajador
      contadorSeleccionadoXEmpresa
      contadorSeleccionadosTurno
      contadorTerminado
      contadorValidado
      contadorVisitasUsuarioAutenticado
      contadorVisitasUsuarioNoAutenticado
      contratoAnuladoXEmpresa
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const getContrato = /* GraphQL */ `
  query GetContrato(
    $empresaId: String!
    $pais_consecutivoOferta_uuid: String!
  ) {
    getContrato(
      empresaId: $empresaId
      pais_consecutivoOferta_uuid: $pais_consecutivoOferta_uuid
    ) {
      cantidadPersonasAContratar
      createdAt
      empresaId
      estadoActual
      historialEstados
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const getContratoPendiente = /* GraphQL */ `
  query GetContratoPendiente(
    $empresaId: String!
    $pais_consecutivoOferta_uuid: String!
  ) {
    getContratoPendiente(
      empresaId: $empresaId
      pais_consecutivoOferta_uuid: $pais_consecutivoOferta_uuid
    ) {
      createdAt
      empresaId
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const getDetalleFacturacion = /* GraphQL */ `
  query GetDetalleFacturacion(
    $empresaId: String!
    $fecha_pais_consecutivoOferta_trabajadorId: String!
  ) {
    getDetalleFacturacion(
      empresaId: $empresaId
      fecha_pais_consecutivoOferta_trabajadorId: $fecha_pais_consecutivoOferta_trabajadorId
    ) {
      aprobadoPor
      createdAt
      dni
      duracion
      empresaId
      empresaId_paisConsecutivoOferta
      estadoAprobacion
      estadoEntrada
      estadoFacturacion
      estadoSalida
      factorMultiplicador
      facturacion
      fechaFin
      fechaInicio
      fecha_pais_consecutivoOferta_trabajadorId
      nombre
      primerApellido
      registroEntradaPor
      registroSalidaPor
      segundoApellido
      tipoTrabajo
      trabajadorId
      unidad
      updatedAt
      valorAFacturar
      valorAPagar
      valorBruto
      valorContribucion
      valorIVA
      valorImposicion
      valorLiquidado
      valorNeto
      valorPresupuestado
      valorUnitario
    }
  }
`;
export const getDocumentoEmpresa = /* GraphQL */ `
  query GetDocumentoEmpresa($empresaId: String!) {
    getDocumentoEmpresa(empresaId: $empresaId) {
      createdAt
      empresaId
      llave
      nombre
      updatedAt
    }
  }
`;
export const getDocumentoTrabajador = /* GraphQL */ `
  query GetDocumentoTrabajador($tipo: String!, $trabajadorId: String!) {
    getDocumentoTrabajador(tipo: $tipo, trabajadorId: $trabajadorId) {
      createdAt
      fechaVencimiento
      nombre
      tipo
      trabajadorId
      updatedAt
      url
      validadoXEST
    }
  }
`;
export const getEmpresa = /* GraphQL */ `
  query GetEmpresa($empresaId: ID!) {
    getEmpresa(empresaId: $empresaId) {
      calificacionPromedio
      contadorOfertaTrabajo
      createdAt
      credito
      descripcion
      empresaId
      enlaceLogo
      estId
      fechaCreacion
      giro
      nombre
      saldo
      updatedAt
    }
  }
`;
export const getFacturacion = /* GraphQL */ `
  query GetFacturacion(
    $empresaId: String!
    $pais_consecutivoOferta_fecha: String!
  ) {
    getFacturacion(
      empresaId: $empresaId
      pais_consecutivoOferta_fecha: $pais_consecutivoOferta_fecha
    ) {
      cargo
      createdAt
      empresa
      empresaId
      estadosFacturacion
      facturado
      liquidoFacturado
      liquidoPresupuestado
      mesFacturado
      numeroContable
      pais_consecutivoOferta_fecha
      trabajadores
      ubicacion
      updatedAt
    }
  }
`;
export const getFaq = /* GraphQL */ `
  query GetFaq($faqId: ID!) {
    getFaq(faqId: $faqId) {
      categoria
      createdAt
      faqId
      pregunta
      respuesta
      updatedAt
    }
  }
`;
export const getGeografia = /* GraphQL */ `
  query GetGeografia($pais: String!, $region_ciudad_comuna: String!) {
    getGeografia(pais: $pais, region_ciudad_comuna: $region_ciudad_comuna) {
      createdAt
      idRegion_idCiudad_idComuna
      pais
      region_ciudad_comuna
      updatedAt
    }
  }
`;
export const getHabilidades = /* GraphQL */ `
  query GetHabilidades($id: ID!) {
    getHabilidades(id: $id) {
      createdAt
      descripcion
      estado
      examen
      icono
      id
      nombre
      tipo
      updatedAt
      visibilidad
    }
  }
`;
export const getHistorialPagos = /* GraphQL */ `
  query GetHistorialPagos($historialPagoId: String!) {
    getHistorialPagos(historialPagoId: $historialPagoId) {
      createdAt
      fecha
      historialPagoId
      motivo
      tipoTransaccion
      updatedAt
      valor
    }
  }
`;
export const getHistoricoTrabajador = /* GraphQL */ `
  query GetHistoricoTrabajador($empresaId: String!, $trabajadorId: String!) {
    getHistoricoTrabajador(empresaId: $empresaId, trabajadorId: $trabajadorId) {
      createdAt
      duracionUltimoTrabajo
      empresaId
      fechaUltimoTrabajo
      nombreCortoTipoTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const getIdentificadorFirmante = /* GraphQL */ `
  query GetIdentificadorFirmante($correo: AWSEmail!) {
    getIdentificadorFirmante(correo: $correo) {
      correo
      createdAt
      identificadorTributario
      updatedAt
    }
  }
`;
export const getIdentificadorTributarioEmpresa = /* GraphQL */ `
  query GetIdentificadorTributarioEmpresa(
    $empresaId: String!
    $identificadorTributario: String!
  ) {
    getIdentificadorTributarioEmpresa(
      empresaId: $empresaId
      identificadorTributario: $identificadorTributario
    ) {
      activo
      comuna
      correoRepresentanteLegal
      createdAt
      direccion
      empresaId
      identificadorTributario
      margenContribucion
      nombreFantasia
      nombreRepresentanteLegal
      razonSocial
      rutRepresentanteLegal
      updatedAt
    }
  }
`;
export const getLogCambiosTrabajador = /* GraphQL */ `
  query GetLogCambiosTrabajador($correo: AWSEmail!) {
    getLogCambiosTrabajador(correo: $correo) {
      cambios
      comentarioBackoffice
      correo
      createdAt
      fecha
      updatedAt
    }
  }
`;
export const getMultimediaEmpresa = /* GraphQL */ `
  query GetMultimediaEmpresa(
    $correoUsuarioEmpresaId_uuid: String!
    $empresaId: String!
  ) {
    getMultimediaEmpresa(
      correoUsuarioEmpresaId_uuid: $correoUsuarioEmpresaId_uuid
      empresaId: $empresaId
    ) {
      correoUsuarioEmpresaId_uuid
      createdAt
      empresaId
      llave
      tag
      updatedAt
    }
  }
`;
export const getNotificacion = /* GraphQL */ `
  query GetNotificacion($destinatarioId: String!, $fecha: AWSDateTime!) {
    getNotificacion(destinatarioId: $destinatarioId, fecha: $fecha) {
      createdAt
      destinatarioId
      fecha
      leida
      mensaje
      payload
      remitenteId
      tipoNotificacion
      updatedAt
      urlDestino
    }
  }
`;
export const getOfertaActiva = /* GraphQL */ `
  query GetOfertaActiva($empresaId: String!, $pais_consecutivoOferta: String!) {
    getOfertaActiva(
      empresaId: $empresaId
      pais_consecutivoOferta: $pais_consecutivoOferta
    ) {
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const getOfertaTrabajo = /* GraphQL */ `
  query GetOfertaTrabajo(
    $empresaId: String!
    $pais_consecutivoOferta: String!
  ) {
    getOfertaTrabajo(
      empresaId: $empresaId
      pais_consecutivoOferta: $pais_consecutivoOferta
    ) {
      brutoTotal
      causalContrado
      costoNeto
      costoServicio
      createdAt
      descripcionTipoTrabajo
      empresaId
      estadoActual
      facturado
      facturadoRealFinal
      fechaCaducidad
      firmaContrato
      historialEstados
      identificadorTributario
      infoOnboarding
      liquidoTotal
      multimediaOfertaId
      nombreContrato
      nuevosPostulantes
      pais_consecutivoOferta
      provisionFondos
      razonSocial
      rubro
      tipoTrabajo
      totalHoras
      totalVacantes
      turnos
      ubicacion
      updatedAt
      usuariosInvitados
    }
  }
`;
export const getOfertaTrabajoHabilidades = /* GraphQL */ `
  query GetOfertaTrabajoHabilidades(
    $empresaId_pais_consecutivoOferta: String!
    $habilidadId: String!
  ) {
    getOfertaTrabajoHabilidades(
      empresaId_pais_consecutivoOferta: $empresaId_pais_consecutivoOferta
      habilidadId: $habilidadId
    ) {
      createdAt
      empresaId_pais_consecutivoOferta
      habilidadId
      obligatoria
      updatedAt
    }
  }
`;
export const getPorcentajeImposicionesPais = /* GraphQL */ `
  query GetPorcentajeImposicionesPais($pais: String!) {
    getPorcentajeImposicionesPais(pais: $pais) {
      createdAt
      iva
      pais
      porcentaje
      updatedAt
      valorMinimoLegal
    }
  }
`;
export const getReplicaTalana = /* GraphQL */ `
  query GetReplicaTalana($talanaId: Int!, $tipoReplica: String!) {
    getReplicaTalana(talanaId: $talanaId, tipoReplica: $tipoReplica) {
      codigo
      creacion
      createdAt
      nombre
      pais
      razonSocial
      rut
      talanaId
      tipo
      tipoReplica
      updatedAt
      vigente
    }
  }
`;
export const getRequisito = /* GraphQL */ `
  query GetRequisito(
    $empresaId: String!
    $pais_consecutivoOferta_uuid: String!
  ) {
    getRequisito(
      empresaId: $empresaId
      pais_consecutivoOferta_uuid: $pais_consecutivoOferta_uuid
    ) {
      createdAt
      descripcion
      empresaId
      fechaVencimiento
      pais_consecutivoOferta_uuid
      requiereArchivo
      updatedAt
    }
  }
`;
export const getRequisitosLegales = /* GraphQL */ `
  query GetRequisitosLegales($pais: String!) {
    getRequisitosLegales(pais: $pais) {
      createdAt
      pais
      porcentajeBase
      porcentajeHoraExtra
      porcentajeParafiscales
      porcentajeSemanaCorrida
      updatedAt
    }
  }
`;
export const getRubroTipoTrabajo = /* GraphQL */ `
  query GetRubroTipoTrabajo($pais: String!, $rubro_tipoTrabajo: String!) {
    getRubroTipoTrabajo(pais: $pais, rubro_tipoTrabajo: $rubro_tipoTrabajo) {
      createdAt
      descripcionRubro
      descripcionTipoTrabajo
      duracionMaximaTurno
      duracionMaximaTurnoParaColacion
      duracionMaximaTurnoXSemana
      habilidades
      horasMaximasXDia
      horasMaximasXMes
      horasMaximasXSemana
      nombreOficial
      pais
      porcentajeCostoFestivo
      rubro_tipoTrabajo
      tiempoColacion
      updatedAt
      valorMaxRecomendado
      valorMinRecomendado
    }
  }
`;
export const getSeguridadSocial = /* GraphQL */ `
  query GetSeguridadSocial($tipo: String!, $trabajadorId: String!) {
    getSeguridadSocial(tipo: $tipo, trabajadorId: $trabajadorId) {
      createdAt
      nombre
      talanaId
      tipo
      trabajadorId
      updatedAt
    }
  }
`;
export const getTipoUsuario = /* GraphQL */ `
  query GetTipoUsuario($idTipoUsuario: ID!) {
    getTipoUsuario(idTipoUsuario: $idTipoUsuario) {
      createdAt
      dotacionDiaria
      firmaPendiente
      gestionAsistencias
      idTipoUsuario
      menuSolicitudes
      multimediaEmpresa
      nombre
      nuevaSolicitud
      panelDeControl
      perfilEmpresa
      principal
      provisionPendiente
      recargar
      rutsAsociados
      solicitudes
      trabajosEnCurso
      transacciones
      ubicaciones
      updatedAt
      usuarios
      verTodasSolicitudes
    }
  }
`;
export const getTrabajador = /* GraphQL */ `
  query GetTrabajador($correo: AWSEmail!) {
    getTrabajador(correo: $correo) {
      activo
      banco
      calificacionPromedio
      canalesNotificaciones
      ciudad
      clave
      comuna
      correo
      createdAt
      descripcion
      direccion
      dni
      enlaceFoto
      estId
      fechaNacimiento
      firebaseToken
      idBanco
      nacionalidad
      noCuentaBancaria
      nombre
      pais
      perfiles
      primerApellido
      region
      segundaClave
      segundoApellido
      telefono
      tipoCuenta
      ultimaFechaConsultaAnuncios
      updatedAt
      validadoXEST
      visibilidadFoto
    }
  }
`;
export const getTrabajadorCalendario = /* GraphQL */ `
  query GetTrabajadorCalendario(
    $fecha_empresaId_pais_consecutivoOferta: String!
    $trabajadorId: String!
  ) {
    getTrabajadorCalendario(
      fecha_empresaId_pais_consecutivoOferta: $fecha_empresaId_pais_consecutivoOferta
      trabajadorId: $trabajadorId
    ) {
      aprobado
      confirmado
      createdAt
      duracion
      empresaId
      entrada
      estadoAprobacion
      estadoEntrada
      estadoSalida
      fechaFin
      fecha_empresaId_pais_consecutivoOferta
      pais_consecutivoOferta
      salida
      timeZone
      trabajadorId
      turno
      ubicacionId
      ultimoDia
      unidad
      updatedAt
      valorUnitario
    }
  }
`;
export const getTrabajadorHabilidades = /* GraphQL */ `
  query GetTrabajadorHabilidades($correo: AWSEmail!, $habilidadId: String!) {
    getTrabajadorHabilidades(correo: $correo, habilidadId: $habilidadId) {
      certificado
      correo
      createdAt
      estado
      fechaSolicitud
      habilidadId
      historialEstados
      infoAdicionalCertificado
      resultadoExamen
      tipoHabilidad
      updatedAt
    }
  }
`;
export const getTrabajadorOferta = /* GraphQL */ `
  query GetTrabajadorOferta(
    $empresaId: String!
    $pais_consecutivoOferta_trabajadorId: String!
  ) {
    getTrabajadorOferta(
      empresaId: $empresaId
      pais_consecutivoOferta_trabajadorId: $pais_consecutivoOferta_trabajadorId
    ) {
      contratoEmpresa
      contratoId
      createdAt
      cumplimientoRequisito
      empresaId
      empresaId_pais_consecutivoOferta
      estadoActual
      historialEstados
      infoOnboardingLeida
      pais_consecutivoOferta_trabajadorId
      trabajadorId
      turnos
      turnosExtra
      updatedAt
      valorAPagar
    }
  }
`;
export const getTrabajadorPago = /* GraphQL */ `
  query GetTrabajadorPago($fecha_uuid: AWSDate!, $trabajadorId: String!) {
    getTrabajadorPago(fecha_uuid: $fecha_uuid, trabajadorId: $trabajadorId) {
      concepto
      createdAt
      empresaId_pais_consecutivoOferta
      fecha_uuid
      historialPagoId
      trabajadorId
      updatedAt
      valor
    }
  }
`;
export const getTrabajoFavorito = /* GraphQL */ `
  query GetTrabajoFavorito($trabajadorId: String!) {
    getTrabajoFavorito(trabajadorId: $trabajadorId) {
      createdAt
      ofertasTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const getTransaccion = /* GraphQL */ `
  query GetTransaccion($empresaId: String!, $fecha_uuid: String!) {
    getTransaccion(empresaId: $empresaId, fecha_uuid: $fecha_uuid) {
      correo
      createdAt
      detalle
      empresaId
      fecha_uuid
      monto
      observacion
      tipo
      updatedAt
    }
  }
`;
export const getUbicacion = /* GraphQL */ `
  query GetUbicacion(
    $empresaId: String!
    $pais_region_ciudad_comuna_uuid: String!
  ) {
    getUbicacion(
      empresaId: $empresaId
      pais_region_ciudad_comuna_uuid: $pais_region_ciudad_comuna_uuid
    ) {
      activa
      codigoPostal
      createdAt
      direccion
      empresaId
      nombre
      pais_region_ciudad_comuna_uuid
      timezone
      ubicacion
      updatedAt
    }
  }
`;
export const getUbicacionSupervisor = /* GraphQL */ `
  query GetUbicacionSupervisor(
    $correo: AWSEmail!
    $pais_region_ciudad_comuna_uuid: String!
  ) {
    getUbicacionSupervisor(
      correo: $correo
      pais_region_ciudad_comuna_uuid: $pais_region_ciudad_comuna_uuid
    ) {
      correo
      createdAt
      pais_region_ciudad_comuna_uuid
      updatedAt
    }
  }
`;
export const getUsuarioBackoffice = /* GraphQL */ `
  query GetUsuarioBackoffice($correo: AWSEmail!) {
    getUsuarioBackoffice(correo: $correo) {
      cargo
      correo
      createdAt
      nombre
      telefono
      updatedAt
    }
  }
`;
export const getUsuarioEmpresa = /* GraphQL */ `
  query GetUsuarioEmpresa($correo: AWSEmail!, $empresaId: String!) {
    getUsuarioEmpresa(correo: $correo, empresaId: $empresaId) {
      activo
      cargo
      clave
      correo
      createdAt
      empresaId
      nombre
      telefono
      tipoUsuario
      updatedAt
    }
  }
`;
export const listBackgrounds = /* GraphQL */ `
  query ListBackgrounds(
    $filter: ModelBackgroundFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tipo_uuid: ModelStringKeyConditionInput
    $trabajadorId: String
  ) {
    listBackgrounds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      tipo_uuid: $tipo_uuid
      trabajadorId: $trabajadorId
    ) {
      items {
        anoInicio
        cargoTitulo
        createdAt
        descripcion
        duracionMeses
        institucion
        tipo_uuid
        trabajadorId
        updatedAt
        validadoXEST
      }
      nextToken
    }
  }
`;
export const listCalificacions = /* GraphQL */ `
  query ListCalificacions(
    $empresaId: String
    $filter: ModelCalificacionFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta_trabajadorId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listCalificacions(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta_trabajadorId: $pais_consecutivoOferta_trabajadorId
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        detalles
        empresaId
        fecha
        pais_consecutivoOferta_trabajadorId
        tipo
        updatedAt
      }
      nextToken
    }
  }
`;
export const listComentariosOfertaTrabajos = /* GraphQL */ `
  query ListComentariosOfertaTrabajos(
    $empresaId: String
    $filter: ModelComentariosOfertaTrabajoFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta_fecha: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listComentariosOfertaTrabajos(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta_fecha: $pais_consecutivoOferta_fecha
      sortDirection: $sortDirection
    ) {
      items {
        comentario
        createdAt
        empresaId
        pais_consecutivoOferta_fecha
        updatedAt
      }
      nextToken
    }
  }
`;
export const listComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  query ListComunaTipoTrabajoRecomendaciones(
    $filter: ModelComunaTipoTrabajoRecomendacionesFilterInput
    $limit: Int
    $nextToken: String
    $pais: String
    $region_ciudad_comuna_rubro_tipoTrabajo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listComunaTipoTrabajoRecomendaciones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais: $pais
      region_ciudad_comuna_rubro_tipoTrabajo: $region_ciudad_comuna_rubro_tipoTrabajo
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        pais
        region_ciudad_comuna_rubro_tipoTrabajo
        rubro_tipoTrabajo
        updatedAt
        valorMaxRecomendado
        valorMinLegal
        valorMinRecomendado
      }
      nextToken
    }
  }
`;
export const listContadors = /* GraphQL */ `
  query ListContadors(
    $empresaId: String
    $filter: ModelContadorFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listContadors(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta: $pais_consecutivoOferta
      sortDirection: $sortDirection
    ) {
      items {
        contadorAceptadoXEmpresa
        contadorAceptadoXTrabajador
        contadorAceptadosTurno
        contadorContratoAnuladoXBackoffice
        contadorContratoAnuladoXTrabajador
        contadorContratoCerrado
        contadorContratoFirmadoXTrabajador
        contadorDesistidaXTrabajador
        contadorEjecutando
        contadorPostulado
        contadorPreGuardado
        contadorProvisionadoXEmpresa
        contadorRechazadoXEmpresa
        contadorRechazadoXTrabajador
        contadorSeleccionadoXEmpresa
        contadorSeleccionadosTurno
        contadorTerminado
        contadorValidado
        contadorVisitasUsuarioAutenticado
        contadorVisitasUsuarioNoAutenticado
        contratoAnuladoXEmpresa
        createdAt
        empresaId
        pais_consecutivoOferta
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContratoPendientes = /* GraphQL */ `
  query ListContratoPendientes(
    $empresaId: String
    $filter: ModelContratoPendienteFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta_uuid: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listContratoPendientes(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta_uuid: $pais_consecutivoOferta_uuid
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        empresaId
        pais_consecutivoOferta_uuid
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContratoes = /* GraphQL */ `
  query ListContratoes(
    $empresaId: String
    $filter: ModelContratoFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta_uuid: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listContratoes(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta_uuid: $pais_consecutivoOferta_uuid
      sortDirection: $sortDirection
    ) {
      items {
        cantidadPersonasAContratar
        createdAt
        empresaId
        estadoActual
        historialEstados
        pais_consecutivoOferta_uuid
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDetalleFacturacions = /* GraphQL */ `
  query ListDetalleFacturacions(
    $empresaId: String
    $fecha_pais_consecutivoOferta_trabajadorId: ModelStringKeyConditionInput
    $filter: ModelDetalleFacturacionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDetalleFacturacions(
      empresaId: $empresaId
      fecha_pais_consecutivoOferta_trabajadorId: $fecha_pais_consecutivoOferta_trabajadorId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        aprobadoPor
        createdAt
        dni
        duracion
        empresaId
        empresaId_paisConsecutivoOferta
        estadoAprobacion
        estadoEntrada
        estadoFacturacion
        estadoSalida
        factorMultiplicador
        facturacion
        fechaFin
        fechaInicio
        fecha_pais_consecutivoOferta_trabajadorId
        nombre
        primerApellido
        registroEntradaPor
        registroSalidaPor
        segundoApellido
        tipoTrabajo
        trabajadorId
        unidad
        updatedAt
        valorAFacturar
        valorAPagar
        valorBruto
        valorContribucion
        valorIVA
        valorImposicion
        valorLiquidado
        valorNeto
        valorPresupuestado
        valorUnitario
      }
      nextToken
    }
  }
`;
export const listDocumentoEmpresas = /* GraphQL */ `
  query ListDocumentoEmpresas(
    $empresaId: String
    $filter: ModelDocumentoEmpresaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocumentoEmpresas(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        empresaId
        llave
        nombre
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDocumentoTrabajadors = /* GraphQL */ `
  query ListDocumentoTrabajadors(
    $filter: ModelDocumentoTrabajadorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tipo: ModelStringKeyConditionInput
    $trabajadorId: String
  ) {
    listDocumentoTrabajadors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      tipo: $tipo
      trabajadorId: $trabajadorId
    ) {
      items {
        createdAt
        fechaVencimiento
        nombre
        tipo
        trabajadorId
        updatedAt
        url
        validadoXEST
      }
      nextToken
    }
  }
`;
export const listEmpresas = /* GraphQL */ `
  query ListEmpresas(
    $empresaId: ID
    $filter: ModelEmpresaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmpresas(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        calificacionPromedio
        contadorOfertaTrabajo
        createdAt
        credito
        descripcion
        empresaId
        enlaceLogo
        estId
        fechaCreacion
        giro
        nombre
        saldo
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFacturacions = /* GraphQL */ `
  query ListFacturacions(
    $empresaId: String
    $filter: ModelFacturacionFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta_fecha: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listFacturacions(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta_fecha: $pais_consecutivoOferta_fecha
      sortDirection: $sortDirection
    ) {
      items {
        cargo
        createdAt
        empresa
        empresaId
        estadosFacturacion
        facturado
        liquidoFacturado
        liquidoPresupuestado
        mesFacturado
        numeroContable
        pais_consecutivoOferta_fecha
        trabajadores
        ubicacion
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $faqId: ID
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFaqs(
      faqId: $faqId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoria
        createdAt
        faqId
        pregunta
        respuesta
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGeografias = /* GraphQL */ `
  query ListGeografias(
    $filter: ModelGeografiaFilterInput
    $limit: Int
    $nextToken: String
    $pais: String
    $region_ciudad_comuna: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listGeografias(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais: $pais
      region_ciudad_comuna: $region_ciudad_comuna
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        idRegion_idCiudad_idComuna
        pais
        region_ciudad_comuna
        updatedAt
      }
      nextToken
    }
  }
`;
export const listHabilidades = /* GraphQL */ `
  query ListHabilidades(
    $filter: ModelHabilidadesFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHabilidades(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        descripcion
        estado
        examen
        icono
        id
        nombre
        tipo
        updatedAt
        visibilidad
      }
      nextToken
    }
  }
`;
export const listHistorialPagos = /* GraphQL */ `
  query ListHistorialPagos(
    $filter: ModelHistorialPagosFilterInput
    $historialPagoId: String
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHistorialPagos(
      filter: $filter
      historialPagoId: $historialPagoId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        fecha
        historialPagoId
        motivo
        tipoTransaccion
        updatedAt
        valor
      }
      nextToken
    }
  }
`;
export const listHistoricoTrabajadors = /* GraphQL */ `
  query ListHistoricoTrabajadors(
    $empresaId: String
    $filter: ModelHistoricoTrabajadorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $trabajadorId: ModelStringKeyConditionInput
  ) {
    listHistoricoTrabajadors(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      trabajadorId: $trabajadorId
    ) {
      items {
        createdAt
        duracionUltimoTrabajo
        empresaId
        fechaUltimoTrabajo
        nombreCortoTipoTrabajo
        trabajadorId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listIdentificadorFirmantes = /* GraphQL */ `
  query ListIdentificadorFirmantes(
    $correo: AWSEmail
    $filter: ModelIdentificadorFirmanteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIdentificadorFirmantes(
      correo: $correo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        correo
        createdAt
        identificadorTributario
        updatedAt
      }
      nextToken
    }
  }
`;
export const listIdentificadorTributarioEmpresas = /* GraphQL */ `
  query ListIdentificadorTributarioEmpresas(
    $empresaId: String
    $filter: ModelIdentificadorTributarioEmpresaFilterInput
    $identificadorTributario: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIdentificadorTributarioEmpresas(
      empresaId: $empresaId
      filter: $filter
      identificadorTributario: $identificadorTributario
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        activo
        comuna
        correoRepresentanteLegal
        createdAt
        direccion
        empresaId
        identificadorTributario
        margenContribucion
        nombreFantasia
        nombreRepresentanteLegal
        razonSocial
        rutRepresentanteLegal
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLogCambiosTrabajadors = /* GraphQL */ `
  query ListLogCambiosTrabajadors(
    $correo: AWSEmail
    $filter: ModelLogCambiosTrabajadorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogCambiosTrabajadors(
      correo: $correo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cambios
        comentarioBackoffice
        correo
        createdAt
        fecha
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMultimediaEmpresas = /* GraphQL */ `
  query ListMultimediaEmpresas(
    $correoUsuarioEmpresaId_uuid: ModelStringKeyConditionInput
    $empresaId: String
    $filter: ModelMultimediaEmpresaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMultimediaEmpresas(
      correoUsuarioEmpresaId_uuid: $correoUsuarioEmpresaId_uuid
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        correoUsuarioEmpresaId_uuid
        createdAt
        empresaId
        llave
        tag
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotificacions = /* GraphQL */ `
  query ListNotificacions(
    $destinatarioId: String
    $fecha: ModelStringKeyConditionInput
    $filter: ModelNotificacionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotificacions(
      destinatarioId: $destinatarioId
      fecha: $fecha
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        destinatarioId
        fecha
        leida
        mensaje
        payload
        remitenteId
        tipoNotificacion
        updatedAt
        urlDestino
      }
      nextToken
    }
  }
`;
export const listOfertaActivas = /* GraphQL */ `
  query ListOfertaActivas(
    $empresaId: String
    $filter: ModelOfertaActivaFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listOfertaActivas(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta: $pais_consecutivoOferta
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        empresaId
        pais_consecutivoOferta
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOfertaTrabajoHabilidades = /* GraphQL */ `
  query ListOfertaTrabajoHabilidades(
    $empresaId_pais_consecutivoOferta: String
    $filter: ModelOfertaTrabajoHabilidadesFilterInput
    $habilidadId: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOfertaTrabajoHabilidades(
      empresaId_pais_consecutivoOferta: $empresaId_pais_consecutivoOferta
      filter: $filter
      habilidadId: $habilidadId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        empresaId_pais_consecutivoOferta
        habilidadId
        obligatoria
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOfertaTrabajos = /* GraphQL */ `
  query ListOfertaTrabajos(
    $empresaId: String
    $filter: ModelOfertaTrabajoFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listOfertaTrabajos(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta: $pais_consecutivoOferta
      sortDirection: $sortDirection
    ) {
      items {
        brutoTotal
        causalContrado
        costoNeto
        costoServicio
        createdAt
        descripcionTipoTrabajo
        empresaId
        estadoActual
        facturado
        facturadoRealFinal
        fechaCaducidad
        firmaContrato
        historialEstados
        identificadorTributario
        infoOnboarding
        liquidoTotal
        multimediaOfertaId
        nombreContrato
        nuevosPostulantes
        pais_consecutivoOferta
        provisionFondos
        razonSocial
        rubro
        tipoTrabajo
        totalHoras
        totalVacantes
        turnos
        ubicacion
        updatedAt
        usuariosInvitados
      }
      nextToken
    }
  }
`;
export const listPorcentajeImposicionesPais = /* GraphQL */ `
  query ListPorcentajeImposicionesPais(
    $filter: ModelPorcentajeImposicionesPaisFilterInput
    $limit: Int
    $nextToken: String
    $pais: String
    $sortDirection: ModelSortDirection
  ) {
    listPorcentajeImposicionesPais(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais: $pais
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        iva
        pais
        porcentaje
        updatedAt
        valorMinimoLegal
      }
      nextToken
    }
  }
`;
export const listReplicaTalanas = /* GraphQL */ `
  query ListReplicaTalanas(
    $filter: ModelReplicaTalanaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $talanaId: ModelIntKeyConditionInput
    $tipoReplica: String
  ) {
    listReplicaTalanas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      talanaId: $talanaId
      tipoReplica: $tipoReplica
    ) {
      items {
        codigo
        creacion
        createdAt
        nombre
        pais
        razonSocial
        rut
        talanaId
        tipo
        tipoReplica
        updatedAt
        vigente
      }
      nextToken
    }
  }
`;
export const listRequisitos = /* GraphQL */ `
  query ListRequisitos(
    $empresaId: String
    $filter: ModelRequisitoFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta_uuid: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listRequisitos(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta_uuid: $pais_consecutivoOferta_uuid
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        descripcion
        empresaId
        fechaVencimiento
        pais_consecutivoOferta_uuid
        requiereArchivo
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRequisitosLegales = /* GraphQL */ `
  query ListRequisitosLegales(
    $filter: ModelRequisitosLegalesFilterInput
    $limit: Int
    $nextToken: String
    $pais: String
    $sortDirection: ModelSortDirection
  ) {
    listRequisitosLegales(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais: $pais
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        pais
        porcentajeBase
        porcentajeHoraExtra
        porcentajeParafiscales
        porcentajeSemanaCorrida
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRubroTipoTrabajos = /* GraphQL */ `
  query ListRubroTipoTrabajos(
    $filter: ModelRubroTipoTrabajoFilterInput
    $limit: Int
    $nextToken: String
    $pais: String
    $rubro_tipoTrabajo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listRubroTipoTrabajos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais: $pais
      rubro_tipoTrabajo: $rubro_tipoTrabajo
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        descripcionRubro
        descripcionTipoTrabajo
        duracionMaximaTurno
        duracionMaximaTurnoParaColacion
        duracionMaximaTurnoXSemana
        habilidades
        horasMaximasXDia
        horasMaximasXMes
        horasMaximasXSemana
        nombreOficial
        pais
        porcentajeCostoFestivo
        rubro_tipoTrabajo
        tiempoColacion
        updatedAt
        valorMaxRecomendado
        valorMinRecomendado
      }
      nextToken
    }
  }
`;
export const listSeguridadSocials = /* GraphQL */ `
  query ListSeguridadSocials(
    $filter: ModelSeguridadSocialFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tipo: ModelStringKeyConditionInput
    $trabajadorId: String
  ) {
    listSeguridadSocials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      tipo: $tipo
      trabajadorId: $trabajadorId
    ) {
      items {
        createdAt
        nombre
        talanaId
        tipo
        trabajadorId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTipoUsuarios = /* GraphQL */ `
  query ListTipoUsuarios(
    $filter: ModelTipoUsuarioFilterInput
    $idTipoUsuario: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTipoUsuarios(
      filter: $filter
      idTipoUsuario: $idTipoUsuario
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        dotacionDiaria
        firmaPendiente
        gestionAsistencias
        idTipoUsuario
        menuSolicitudes
        multimediaEmpresa
        nombre
        nuevaSolicitud
        panelDeControl
        perfilEmpresa
        principal
        provisionPendiente
        recargar
        rutsAsociados
        solicitudes
        trabajosEnCurso
        transacciones
        ubicaciones
        updatedAt
        usuarios
        verTodasSolicitudes
      }
      nextToken
    }
  }
`;
export const listTrabajadorCalendarios = /* GraphQL */ `
  query ListTrabajadorCalendarios(
    $fecha_empresaId_pais_consecutivoOferta: ModelStringKeyConditionInput
    $filter: ModelTrabajadorCalendarioFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $trabajadorId: String
  ) {
    listTrabajadorCalendarios(
      fecha_empresaId_pais_consecutivoOferta: $fecha_empresaId_pais_consecutivoOferta
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      trabajadorId: $trabajadorId
    ) {
      items {
        aprobado
        confirmado
        createdAt
        duracion
        empresaId
        entrada
        estadoAprobacion
        estadoEntrada
        estadoSalida
        fechaFin
        fecha_empresaId_pais_consecutivoOferta
        pais_consecutivoOferta
        salida
        timeZone
        trabajadorId
        turno
        ubicacionId
        ultimoDia
        unidad
        updatedAt
        valorUnitario
      }
      nextToken
    }
  }
`;
export const listTrabajadorHabilidades = /* GraphQL */ `
  query ListTrabajadorHabilidades(
    $correo: AWSEmail
    $filter: ModelTrabajadorHabilidadesFilterInput
    $habilidadId: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrabajadorHabilidades(
      correo: $correo
      filter: $filter
      habilidadId: $habilidadId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        certificado
        correo
        createdAt
        estado
        fechaSolicitud
        habilidadId
        historialEstados
        infoAdicionalCertificado
        resultadoExamen
        tipoHabilidad
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTrabajadorOfertas = /* GraphQL */ `
  query ListTrabajadorOfertas(
    $empresaId: String
    $filter: ModelTrabajadorOfertaFilterInput
    $limit: Int
    $nextToken: String
    $pais_consecutivoOferta_trabajadorId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listTrabajadorOfertas(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_consecutivoOferta_trabajadorId: $pais_consecutivoOferta_trabajadorId
      sortDirection: $sortDirection
    ) {
      items {
        contratoEmpresa
        contratoId
        contratoTrabajador
        createdAt
        cumplimientoRequisito
        declaracionJuradaSalud
        desvinculacion
        empresaId
        empresaId_pais_consecutivoOferta
        estadoActual
        finiquito
        historialEstados
        infoOnboardingLeida
        pactoHorasExtra
        pais_consecutivoOferta_trabajadorId
        trabajadorId
        turnos
        turnosExtra
        updatedAt
        valorAPagar
      }
      nextToken
    }
  }
`;
export const listTrabajadorPagos = /* GraphQL */ `
  query ListTrabajadorPagos(
    $fecha_uuid: ModelStringKeyConditionInput
    $filter: ModelTrabajadorPagoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $trabajadorId: String
  ) {
    listTrabajadorPagos(
      fecha_uuid: $fecha_uuid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      trabajadorId: $trabajadorId
    ) {
      items {
        concepto
        createdAt
        empresaId_pais_consecutivoOferta
        fecha_uuid
        historialPagoId
        trabajadorId
        updatedAt
        valor
      }
      nextToken
    }
  }
`;
export const listTrabajadors = /* GraphQL */ `
  query ListTrabajadors(
    $correo: AWSEmail
    $filter: ModelTrabajadorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrabajadors(
      correo: $correo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        activo
        banco
        calificacionPromedio
        canalesNotificaciones
        ciudad
        clave
        comuna
        correo
        createdAt
        descripcion
        direccion
        dni
        enlaceFoto
        estId
        fechaNacimiento
        firebaseToken
        idBanco
        nacionalidad
        noCuentaBancaria
        nombre
        pais
        perfiles
        primerApellido
        region
        segundaClave
        segundoApellido
        telefono
        tipoCuenta
        ultimaFechaConsultaAnuncios
        updatedAt
        validadoXEST
        visibilidadFoto
      }
      nextToken
    }
  }
`;
export const listTrabajoFavoritos = /* GraphQL */ `
  query ListTrabajoFavoritos(
    $filter: ModelTrabajoFavoritoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $trabajadorId: String
  ) {
    listTrabajoFavoritos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      trabajadorId: $trabajadorId
    ) {
      items {
        createdAt
        ofertasTrabajo
        trabajadorId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTransaccions = /* GraphQL */ `
  query ListTransaccions(
    $empresaId: String
    $fecha_uuid: ModelStringKeyConditionInput
    $filter: ModelTransaccionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTransaccions(
      empresaId: $empresaId
      fecha_uuid: $fecha_uuid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        correo
        createdAt
        detalle
        empresaId
        fecha_uuid
        monto
        observacion
        tipo
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUbicacionSupervisors = /* GraphQL */ `
  query ListUbicacionSupervisors(
    $correo: ModelStringKeyConditionInput
    $filter: ModelUbicacionSupervisorFilterInput
    $limit: Int
    $nextToken: String
    $pais_region_ciudad_comuna_uuid: String
    $sortDirection: ModelSortDirection
  ) {
    listUbicacionSupervisors(
      correo: $correo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_region_ciudad_comuna_uuid: $pais_region_ciudad_comuna_uuid
      sortDirection: $sortDirection
    ) {
      items {
        correo
        createdAt
        pais_region_ciudad_comuna_uuid
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUbicacions = /* GraphQL */ `
  query ListUbicacions(
    $empresaId: String
    $filter: ModelUbicacionFilterInput
    $limit: Int
    $nextToken: String
    $pais_region_ciudad_comuna_uuid: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listUbicacions(
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais_region_ciudad_comuna_uuid: $pais_region_ciudad_comuna_uuid
      sortDirection: $sortDirection
    ) {
      items {
        activa
        codigoPostal
        createdAt
        direccion
        empresaId
        nombre
        pais_region_ciudad_comuna_uuid
        timezone
        ubicacion
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsuarioBackoffices = /* GraphQL */ `
  query ListUsuarioBackoffices(
    $correo: AWSEmail
    $filter: ModelUsuarioBackofficeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsuarioBackoffices(
      correo: $correo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cargo
        correo
        createdAt
        nombre
        telefono
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsuarioEmpresas = /* GraphQL */ `
  query ListUsuarioEmpresas(
    $correo: ModelStringKeyConditionInput
    $empresaId: String
    $filter: ModelUsuarioEmpresaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsuarioEmpresas(
      correo: $correo
      empresaId: $empresaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        activo
        cargo
        clave
        correo
        createdAt
        empresaId
        nombre
        telefono
        tipoUsuario
        updatedAt
      }
      nextToken
    }
  }
`;
export const trabajadorHabilidadesByTipoHabilidadAndFechaSolicitud = /* GraphQL */ `
  query TrabajadorHabilidadesByTipoHabilidadAndFechaSolicitud(
    $fechaSolicitud: ModelStringKeyConditionInput
    $filter: ModelTrabajadorHabilidadesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tipoHabilidad: String!
  ) {
    trabajadorHabilidadesByTipoHabilidadAndFechaSolicitud(
      fechaSolicitud: $fechaSolicitud
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      tipoHabilidad: $tipoHabilidad
    ) {
      items {
        certificado
        correo
        createdAt
        estado
        fechaSolicitud
        habilidadId
        historialEstados
        infoAdicionalCertificado
        resultadoExamen
        tipoHabilidad
        updatedAt
      }
      nextToken
    }
  }
`;
export const trabajadorOfertaByTrabajadorIdAndEmpresaIdPaisConsecutivoOferta = /* GraphQL */ `
  query TrabajadorOfertaByTrabajadorIdAndEmpresaIdPaisConsecutivoOferta(
    $empresaId_pais_consecutivoOferta: ModelStringKeyConditionInput
    $filter: ModelTrabajadorOfertaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $trabajadorId: String!
  ) {
    trabajadorOfertaByTrabajadorIdAndEmpresaIdPaisConsecutivoOferta(
      empresaId_pais_consecutivoOferta: $empresaId_pais_consecutivoOferta
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      trabajadorId: $trabajadorId
    ) {
      items {
        contratoEmpresa
        contratoId
        contratoTrabajador
        createdAt
        cumplimientoRequisito
        declaracionJuradaSalud
        desvinculacion
        empresaId
        empresaId_pais_consecutivoOferta
        estadoActual
        finiquito
        historialEstados
        infoOnboardingLeida
        pactoHorasExtra
        pais_consecutivoOferta_trabajadorId
        trabajadorId
        turnos
        turnosExtra
        updatedAt
        valorAPagar
      }
      nextToken
    }
  }
`;

export const listRegistroIncidentes = /* GraphQL */ `
  query ListRegistroIncidentes(
    $fecha: ModelStringKeyConditionInput
    $filter: ModelRegistroIncidentesFilterInput
    $limit: Int
    $nextToken: String
    $pais: String
    $sortDirection: ModelSortDirection
  ) {
    listRegistroIncidentes(
      fecha: $fecha
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      pais: $pais
      sortDirection: $sortDirection
    ) {
      items {
        _deleted
        _version
        coordenadas
        createdAt
        descripcion
        fecha
        fechaMarcaje
        fechaServidor
        pais
        posicionCorrecta
        tipo
        trabajadorId
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

import dayjs from 'dayjs';

// Para llevar a archivo de Fechas
export const arrDays = [
  { label: 'Lunes', value: 1, disabled: true },
  { label: 'Martes', value: 2, disabled: true },
  { label: 'Miercoles', value: 3, disabled: true },
  { label: 'Jueves', value: 4, disabled: true },
  { label: 'Viernes', value: 5, disabled: true },
  { label: 'Sabado', value: 6, disabled: true },
  { label: 'Domingo', value: 0, disabled: true },
];

export function getAvailableDays(days, value) {
  const valueOfDaysBetweenTwoDates = dayjs(value[1]).diff(
    dayjs(value[0]),
    'days'
  );
  let arrDaysBetweenTwoDates = [];
  for (let i = 0; i <= valueOfDaysBetweenTwoDates; i++) {
    arrDaysBetweenTwoDates.push(dayjs(value[0]).add(i, 'days').day());
  }
  const arrDaysBetweenTwoDatesWithoutDuplicates = arrDaysBetweenTwoDates.filter(
    (item, index) => arrDaysBetweenTwoDates.indexOf(item) === index
  );
  return days.map((day) => {
    if (arrDaysBetweenTwoDatesWithoutDuplicates.includes(day.value)) {
      return { ...day, disabled: false };
    }
    return { ...day, disabled: true };
  });
}

export const getDisabledDate = (current, dates, maxDays) => {
  if (!current) return;
  const isBefore = current && current < dayjs().startOf('day');
  if (!dates) {
    return isBefore;
  }
  const tooLate = dates[0] && current.diff(dates[0], 'days') >= maxDays;
  const tooEarly = dates[1] && dates[1].diff(current, 'days') >= maxDays;
  return isBefore || tooLate || tooEarly;
};

export const getDisabledHours = (value, horasMaximas) => {
  if (!value) return;
  const maxTimeDate = dayjs(value)
    .add(horasMaximas, 'hours')
    .toDate()
    .getHours();
  const time = value.hour();
  const hours = [];
  for (let i = 0; i < 24; i++) {
    if (maxTimeDate < time) {
      if (i < time && i > maxTimeDate) {
        hours.push(i);
      }
    } else {
      if (i < time || i > maxTimeDate) {
        hours.push(i);
      }
    }
  }
  return hours;
};

export const getTotalHours = (start, end) => {
  const diff = dayjs(end).diff(dayjs(start), 'hours');
  return diff;
};

// Cuantas veces se repite un dia entre dos fechas
export const getTotalDaysBetweenToDates = (start, end, days) => {
  if (!days) return;
  const diff = dayjs(end).diff(dayjs(start), 'days');
  let arrDaysBetweenTwoDates = [];
  for (let i = 0; i <= diff; i++) {
    const day = dayjs(start).add(i, 'days').day();
    if (days.includes(day)) {
      arrDaysBetweenTwoDates.push(day);
    }
  }
  return arrDaysBetweenTwoDates.length;
};

// Diferencia entre dos horas
export const getDifferenceBetweenTwoHours = (start, end) => {
  if (dayjs(start).isAfter(dayjs(end).add(1, 'minute'))) {
    const newEnd = dayjs(end).add(1, 'minute').add(1, 'days');
    const diff = dayjs(newEnd).diff(dayjs(start), 'minutes') / 60;
    return Number(diff.toFixed(1));
  }
  const diff = dayjs(end).add(1, 'minute').diff(dayjs(start), 'minutes') / 60;
  return Number(diff.toFixed(1));
};

// Obtener el dia de inicio real de un turno
export const getStartDay = (start, arr) => {
  if (!arr) return;
  const days = arr.sort(sortDays);
  const day = dayjs(start).day();
  if (days.includes(day)) {
    return start;
  }
  let fisrtDay = null;
  for (let i = 1; i < 7; i++) {
    const newDay = dayjs(start).add(i, 'days').day();
    if (days.includes(newDay)) {
      fisrtDay = newDay;
      break;
    }
  }
  const diff = fisrtDay > day ? fisrtDay - day : 7 - day + fisrtDay;
  const startDay = dayjs(start).add(diff, 'days');
  return startDay;
};

// Obtener el dia de fin real de un turno
export const getEndDay = (end, arr) => {
  if (!arr) return;
  const days = arr.sort(sortDays);
  const day = dayjs(end).day();
  if (days.includes(day)) {
    return end;
  }
  let lastDay = null;
  for (let i = 1; i < 6; i++) {
    const newDay = dayjs(end).subtract(i, 'days').day();
    if (days.includes(newDay)) {
      lastDay = newDay;
      break;
    }
  }
  const diff = day > lastDay ? day - lastDay : 7 - lastDay + day;
  const endDay = dayjs(end).subtract(diff, 'days');
  return endDay;
};

// Sort numbers in array asc but 0 is the last
export const sortDays = (a, b) => {
  if (a === 0) {
    return 1;
  }
  if (b === 0) {
    return -1;
  }
  return a - b;
};

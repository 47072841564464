import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRequisitions } from '../../services/RequisitionsService';
// components
import { Button, Card, message } from 'antd';
import { PageContainer } from '@ant-design/pro-components';
import Table from './components/Table';
import Filter from './components/Filter';
import Meta from '../../components/layouts/Meta';
import { useSelector } from 'react-redux';

const initialFilters = {
  estado: null,
  cargo: null,
  motivo: null,
  id: null,
  fecha: null,
  porcubrir: false,
};

export default function Solicitudes() {
  const { enterprise } = useSelector((state) => state.enterprise);

  const [solicitudes, setSolicitudes] = useState(null);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    buscarSolicitudes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function buscarSolicitudes() {
    try {
      setSolicitudes(null);
      const res = await getRequisitions(
        enterprise.empresaId,
        'Activa,EsperandoValidacion,EnPausa,Ejecutando,NoAprobada'
      );
      setSolicitudes(res);
    } catch (error) {
      setSolicitudes([]);
      message.error('Error al obtener las solicitudes');
    }
  }

  const routes = [{ path: '', breadcrumbName: 'Solicitudes' }];

  return (
    <PageContainer
      header={{
        title: 'Solicitudes Actuales',
        ghost: true,
        breadcrumb: { routes },
        extra: [
          <Link to="/solicitudes/formulario" key="1">
            <Button data-cy="botonNuevaSolicitud" type="primary">
              Nueva Solicitud
            </Button>
          </Link>,
        ],
      }}
    >
      <Meta title="Solicitudes Actuales" />
      <Card>
        <Filter
          buscarSolicitudes={buscarSolicitudes}
          setFilters={setFilters}
          initialFilters={initialFilters}
          estados={[
            { value: 'EsperandoValidacion', label: 'Pendiente' },
            { value: 'NoAprobada', label: 'No Aprobada' },
            { value: 'EnPausa', label: 'En Pausa' },
            { value: 'Activa', label: 'Activa' },
            { value: 'Ejecutando', label: 'Ejecutando' },
          ]}
        />
        <Table
          solicitudes={solicitudes}
          filters={filters}
          buscarSolicitudes={buscarSolicitudes}
        />
      </Card>
    </PageContainer>
  );
}

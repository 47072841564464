export default function FormarDateLocalTimeZone(
  text = '00:00:0000',
  record = '00',
  factor = 1,
  completo = false
) {
  //const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timezone = 'America/Santiago';
  let fecha = '00';
  if (completo) {
    fecha = new Date(text);
  } else {
    fecha = new Date(record + 'T' + text + ':00');
  }

  try {
    const utcDate = new Date();
    const fechaTimeZone = new Date(
      new Date().toLocaleString('en-US', { timeZone: timezone })
    );

    const dias = fechaTimeZone.getDate() - utcDate.getUTCDate();
    let horas;
    if (dias === 0) {
      horas = fechaTimeZone.getHours() - utcDate.getUTCHours();
    } else if (dias < 0) {
      horas = fechaTimeZone.getHours() - utcDate.getUTCHours() - 24;
    } else if (dias > 0) {
      horas = 24 + (fechaTimeZone.getHours() - utcDate.getUTCHours());
    }
    const minutos = fechaTimeZone.getMinutes() - utcDate.getUTCMinutes();
    fecha.setHours(fecha.getHours() + factor * horas);
    fecha.setMinutes(fecha.getMinutes() + factor * minutos);

    return formatDate(fecha, completo);
  } catch (error) {
    return error;
  }
}

function formatDate(date, format) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    hora = '' + d.getHours(),
    minuto = '' + d.getMinutes(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hora.length < 2) hora = '0' + hora;
  if (minuto.length < 2) minuto = '0' + minuto;

  if (!format) {
    return `${[year, month, day].join('-')}T${[hora, minuto].join(':')}`;
  } else {
    return `${[day, month, year].join('/')}T${[hora, minuto].join(':')}`;
  }
}

import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { discardCandidate } from '../../../../../services/CandidateService';
import { useSelector } from 'react-redux';

export default function DiscardCandidate({ candidate, refreshCandidates }) {
    const { enterprise } = useSelector((state) => state.enterprise);

    async function handleSelect() {
        const confirm = window.confirm(
            '¿Está seguro que desea descartar al trabajador?'
        );
        if (!confirm) return;
        try {
            message.loading('Procesando...');
            const datos = {
                correo: candidate.correo,
                empresaId: enterprise.empresaId,
                pais_consecutivoOferta: candidate.turnoInfo.pais_consecutivoOferta,
            };
            await discardCandidate(datos);
            message.success('Trabajador Descartado');
            refreshCandidates();
        } catch (error) {
            console.error(error);
            message.error('Error al descartar trabajador');
        }
    }

    if (candidate.estadoActual === 'AceptadoXTrabajador') {
        return (
            <Tooltip title="Descartar trabajador">
                <Button
                    type="text"
                    icon={<CloseOutlined style={{ color: 'red' }} />}
                    onClick={handleSelect}
                />
            </Tooltip>
        );
    }
}

import { useEffect, useState } from 'react';
import { Table } from 'antd';
import getFiltereds from '../utils/getFiltereds';
import columns from './columns';
import FirmaMasiva from './FirmaMasiva';
import dayjs from 'dayjs';

export default function ContratosTable({
  contratos,
  filters,
  buscarContratos,
  firmar = true,
}) {
  const [seleccion, setSeleccion] = useState([]);
  const [data, setData] = useState(contratos);

  useEffect(() => {
    filterContratos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contratos, filters]);

  function filterContratos() {
    if (!contratos) return;
    const filtered = getFiltereds(contratos, filters);
    setData(filtered);
  }

  const getRowClassName = (record) => {
    if (!firmar) return '';
    const fechaInicio = dayjs(record.fechaInicio);
    const hoy = dayjs();
    if (fechaInicio.diff(hoy, 'hour') < 24) {
      return 'bg-red-100 text-red-800 font-semibold';
    }
    return '';
  };

  return (
    <>
      <FirmaMasiva
        firmar={firmar}
        seleccion={seleccion}
        setSeleccion={setSeleccion}
        actualizar={buscarContratos}
      />
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            setSeleccion(selectedRows);
          },
        }}
        rowClassName={getRowClassName}
        dataSource={data}
        columns={columns(buscarContratos, firmar)}
        loading={!contratos}
        size="middle"
        rowKey={(record) => record.idOferta + record.correo}
      />
    </>
  );
}

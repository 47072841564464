import { Button, Divider, Form, Modal, Select, message } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserAddOutlined } from '@ant-design/icons';
import * as mutations from '../../../graphql/mutations';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function AgregarTrabajador({ refresh }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  async function handleSubmit() {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const promises = values.trabajadores.map((t) =>
        API.graphql({
          query: mutations.createHistoricoTrabajador,
          variables: {
            input: {
              empresaId: enterprise.empresaId,
              trabajadorId: t,
              contadorTrabajos: 0,
              duracionUltimoTrabajo: 1,
              fechaUltimoTrabajo: dayjs().utc().format(),
              nombreCortoTipoTrabajo: 'Agregar Trabajador',
              razonSocialUltimoTrabajo: '',
            },
          },
        })
      );
      await Promise.all(promises);

      refresh();
      handleCancel();
      message.success('Trabajador/es agregado/s correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al agregar trabajador/es');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setShow(false);
    form.resetFields();
  }

  return (
    <>
      <Button onClick={() => setShow(true)} icon={<UserAddOutlined />}>
        Agregar
      </Button>
      <Modal
        title="Agregar trabajador"
        open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Agregar
          </Button>,
        ]}
      >
        <Divider />
        <p>
          A continuacion introduce el o los correos de los trabajadores que
          deseas agregar a tu empresa. Recuerda que los trabajadores deben estar
          registrados en la plataforma.
        </p>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            trabajadores: [],
          }}
          form={form}
        >
          <Form.Item
            name="trabajadores"
            label="Trabajadores"
            rules={[
              {
                required: true,
                message: 'Debes introducir al menos un correo',
              },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Introduce los correos de los trabajadores"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

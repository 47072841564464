import { API, Auth } from 'aws-amplify';

import createParams from '../utils/createApiParams';
import customFetch from '../utils/customFetch';

const apiName = 'empresa';

async function getToken() {
  const { accessToken } = await Auth.currentSession();
  return accessToken.jwtToken;
}

const verOferta = async (userToken, empresaId, pais_consecutivoOferta) => {
  const path = '/verOfertas';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    pais_consecutivoOferta,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

// apis Empresa-Razonsocial
const consultaRazonSocial = async (
  userToken,
  empresaId,
  identificadorTributario
) => {
  const path = '/razonSocial';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    identificadorTributario,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const crearRazonSocial = async (userToken, registro) => {
  const path = '/razonSocial';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.put(apiName, path, myInit));
  return res;
};
const editarRazonSocial = async (userToken, registro) => {
  const path = '/razonSocial';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
const eliminarRazonSocial = async (userToken, registro) => {
  const path = '/razonSocial';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.del(apiName, path, myInit));
  return res;
};
const ActivarRazonSocial = async (userToken, registro) => {
  const path = '/razonSocialReactivar';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
const SuspenderRazonSocial = async (userToken, registro) => {
  const path = '/razonSocialSuspender';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

//ubicaciones //

const consultaUbicacion = async (
  userToken,
  empresaId,
  pais_region_ciudad_comuna_uuid
) => {
  const path = '/ubicaciones';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    pais_region_ciudad_comuna_uuid,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const crearUbicacion = async (userToken, registro) => {
  const path = '/ubicaciones';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.put(apiName, path, myInit));
  return res;
};
const editarUbicacion = async (userToken, registro) => {
  const path = '/ubicaciones';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
const eliminarUbicacion = async (userToken, registro) => {
  const path = '/ubicaciones';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.del(apiName, path, myInit));
  return res;
};

const AprobacionPagosConsulta = async (
  userToken,
  empresaId,
  dia,
  cantidad,
  LastEvaluatedKey
) => {
  const path = '/pagosPendientes';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    dia,
    cantidad,
    LastEvaluatedKey,
  };

  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const ActivarUbicacion = async (userToken, registro) => {
  const path = '/ubicacionReactivar';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
const SuspenderUbicacion = async (userToken, registro) => {
  const path = '/ubicacionSuspender';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

//MULTIMEDIA
const consultaMultimedia = async (userToken, empresaId) => {
  const path = '/multimedia';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const RegistroMultimedia = async (userToken, registro) => {
  const path = '/multimedia';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.put(apiName, path, myInit));
  return res;
};
const eliminarMultimedia = async (userToken, registro) => {
  const path = '/multimedia';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.del(apiName, path, myInit));
  return res;
};

// invitaciones
const consultainvitados = async (userToken, empresaId) => {
  const path = '/verTrabajadoresRecientes';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

//saldo
const consultaSaldo = async (userToken, empresaId) => {
  const path = '/saldo';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

//Empresa-Usuario

const consultaUsuarios = async (userToken, empresaId, correo, tipoUsuario) => {
  const path = '/usuario';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    correo,
    tipoUsuario,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const crearUsuario = async (userToken, registro) => {
  const path = '/usuario';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.put(apiName, path, myInit));
  return res;
};
const modificarUsuario = async (userToken, registro) => {
  const path = '/usuario';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
const desactivarUsuario = async (userToken, registro) => {
  const path = '/usuarioSuspender';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const activarUsuario = async (userToken, registro) => {
  const path = '/usuarioReactivar';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/// perfil empresa

const modificarEmpresa = async (userToken, registro) => {
  const path = '/modificar';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const consultaPerfilEmpresa = async (userToken, empresaId) => {
  const path = '/ver';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
  };

  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

/// seleccionar cambios

const seleccionarCandidatos = async (userToken, registro) => {
  const path = '/seleccionar';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const deseleccionarCandidatos = async (userToken, registro) => {
  const path = '/deseleccionar';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const rechazarCandidatos = async (userToken, registro) => {
  const path = '/rechazar';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const reActivarCandidato = async (userToken, registro) => {
  const path = '/repostular';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

//Rubros
const consultaRubros = async (userToken, empresaId) => {
  const path = '/rubros';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
  };

  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

// permisos de usuario

const consultaPermisos = async (userToken) => {
  const path = '/usuarioPermisos';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
  };

  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

// transacciones

const consultaHistorico = async (
  userToken,
  empresaId,
  desde,
  hasta,
  cantidad,
  LastEvaluatedKey
) => {
  const path = '/transacciones';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    desde,
    hasta,
    cantidad,
    LastEvaluatedKey,
  };

  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const cargarSaldo = async (userToken, registro) => {
  const path = '/transacciones';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

// Dotacion diaria

const ConsultaDotacionDiaria = async (
  userToken,
  empresaId,
  dia,
  cantidad,
  LastEvaluatedKey
) => {
  const path = '/verDotacion';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    dia,
    cantidad,
    LastEvaluatedKey,
  };

  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

//*** marcar asistencias */

const MarcarAsistencia = async (userToken, registro) => {
  const path = '/marcarAsistencia';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const AnularAsistencia = async (userToken, registro) => {
  const path = '/anularAsistencia';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
//**** Validar Usuario*/

const usuarioValidar = async (correo) => {
  const path = '/validarUsuario';
  const myInit = createParams();
  myInit.queryStringParameters = {
    correo,
  };

  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

// añadir horas extra

const empresaService = {
  verOferta,
  consultaRazonSocial,
  eliminarRazonSocial,
  editarRazonSocial,
  crearRazonSocial,
  eliminarUbicacion,
  editarUbicacion,
  crearUbicacion,
  consultaUbicacion,
  consultainvitados,
  consultaMultimedia,
  RegistroMultimedia,
  eliminarMultimedia,
  consultaSaldo,
  consultaUsuarios,
  crearUsuario,
  modificarUsuario,
  desactivarUsuario,
  activarUsuario,
  modificarEmpresa,
  consultaPerfilEmpresa,
  ActivarUbicacion,
  SuspenderUbicacion,
  ActivarRazonSocial,
  SuspenderRazonSocial,
  seleccionarCandidatos,
  deseleccionarCandidatos,
  rechazarCandidatos,
  consultaRubros,
  consultaPermisos,
  consultaHistorico,
  cargarSaldo,
  reActivarCandidato,
  ConsultaDotacionDiaria,
  MarcarAsistencia,
  AnularAsistencia,
  usuarioValidar,
  AprobacionPagosConsulta,
};

export default empresaService;

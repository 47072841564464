import { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { API, Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export default function NuevaClave({ initialEmail }) {
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(values) {
    try {
      setSubmit(true);
      await Auth.forgotPasswordSubmit(
        values.username,
        values.code,
        values.password
      );
      await API.put('trabajador', '/registrar', {
        body: {
          email: values.username,
          clave: values.password,
          tipoCambio: 'recuperar contraseña',
        },
      });
      message.success('Contraseña cambiada correctamente');
      navigate('/auth');
    } catch (error) {
      console.error(error);
      message.error(
        'Ha ocurrido un error, por favor intenta de nuevo más tarde'
      );
    } finally {
      setSubmit(false);
    }
  }

  return (
    <Form
      size="large"
      onFinish={handleSubmit}
      initialValues={{
        username: initialEmail,
      }}
    >
      <Form.Item
        name={'username'}
        rules={[
          {
            required: true,
            message: 'Debes introducir tu correo electrónico',
          },
        ]}
      >
        <Input placeholder="Correo electrónico" />
      </Form.Item>
      <Form.Item
        name={'code'}
        rules={[
          {
            required: true,
            message: 'Debes introducir el código de validación',
          },
        ]}
      >
        <Input placeholder="Código de validación" />
      </Form.Item>
      <Form.Item
        name={'password'}
        rules={[
          {
            required: true,
            message: 'Debes introducir tu nueva contraseña',
          },
        ]}
      >
        <Input.Password placeholder="Nueva contraseña" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={submit}>
          Cambiar contraseña
        </Button>
      </Form.Item>
    </Form>
  );
}

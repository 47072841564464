import {
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Descriptions } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export default function Onboarding({ info }) {
  function FormatText({ children }) {
    return (
      <ReactQuill
        readOnly
        value={children}
        theme={'bubble'}
        style={{ padding: 0 }}
      />
    );
  }

  return (
    <div
      style={{
        maxHeight: 450,
        overflowY: 'scroll',
      }}
    >
      <Descriptions
        column={1}
        layout="vertical"
        labelStyle={{
          fontWeight: 200,
          color: 'rgba(0,0,0,0.5)',
          fontSize: 12,
          marginBottom: -16,
        }}
      >
        <Descriptions.Item label="Primer día">
          <FormatText>{info.primerDia}</FormatText>
        </Descriptions.Item>
        <Descriptions.Item label="Vestimenta">
          <FormatText>{info.vestimenta}</FormatText>
        </Descriptions.Item>
        <Descriptions.Item label="Transporte">
          <FormatText>{info.transporte}</FormatText>
        </Descriptions.Item>
        <Descriptions.Item label="Colación">
          <FormatText>{info.colacion}</FormatText>
        </Descriptions.Item>
        <Descriptions.Item label="Contacto">
          <div className="space-x-2">
            <span>{info.contacto.nombre}</span>
            <a
              href={`https://wa.me/56${info.contacto.telefono}`}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppOutlined />
            </a>
            <a
              href={`tel:+56${info.contacto.telefono}`}
              target="_blank"
              rel="noreferrer"
            >
              <PhoneOutlined />
            </a>

            <a
              href={`mailto:${info.contacto.correo}`}
              target="_blank"
              rel="noreferrer"
            >
              <MailOutlined />
            </a>
          </div>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

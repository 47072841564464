import React from 'react';
import { Table, Col, Row } from 'antd';
import TablaAsistenciaReporte from './TablaAsistenciaReporte';
import CartaMarcacion from '../../jornadaDiaria/components/CartaMarcacion';
import CosultaResolucion from '../../../../altUtils/CosultaResolucion';

export default function CartaAsistencia({ record }) {
  const customMessage = {
    filterSearchPlaceholder: 'Filtrar',
    filterReset: 'Limpiar',
  };

  const expandedRowRender = (datos) => {
    return (
      <div>
        <Row gutter={20}>
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
          <CartaMarcacion
            items={datos?.marcaje?.entrada || null}
            title="Entrada"
          />
          <CartaMarcacion
            items={datos?.marcaje?.justificacionEntrada || null}
            title="Justificación Entrada"
          />
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
        </Row>
        <Row gutter={20} style={{ marginTop: '16px' }}>
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
          <CartaMarcacion
            items={datos?.marcaje?.salida || null}
            title="Salida"
          />
          <CartaMarcacion
            items={datos?.marcaje?.justificacionSalida || null}
            title="Justificación Salida"
          />
          <Col
            span={2}
            hidden={CosultaResolucion(window.outerWidth) || false}
          />
        </Row>
      </div>
    );
  };
  return (
    <Table
      columns={TablaAsistenciaReporte(record)}
      locale={customMessage}
      expandable={{
        expandedRowRender,
      }}
      dataSource={record}
      pagination={false}
    />
  );
}

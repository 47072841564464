import { Button, Space, Tag, message } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { sendMarcajeLlegada } from '../../../services/DotacionService';

export default function LlegoNoLlego({ user }) {
  const { llegada: initial } = user;
  const [llegada, setLlegada] = useState(initial?.length > 0 ? initial : null);
  const [loading, setLoading] = useState(false);

  async function handleClick(value) {
    try {
      setLoading(true);
      await sendMarcajeLlegada({
        trabajadorId: user.correo,
        fechaEmpresaIdPaisConsecutivoOferta:
          user.fecha_empresaId_pais_consecutivoOferta,
        llegada: value,
      });
      setLlegada(value.length > 0 ? value : null);
      if (value.length > 0) message.success('Marcaje exitoso');
    } catch (error) {
      message.error('Hubo un error con el marcaje');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <LoadingOutlined />;
  }

  if (llegada) {
    const props = {
      color: llegada.includes('No') ? 'red' : 'green',
      onClose: (e) => handleClick(''),
      closable: true,
    };
    return <Tag {...props}>{llegada}</Tag>;
  }
  return (
    <Space>
      <Button
        icon={<CheckOutlined />}
        size="small"
        style={{
          fontSize: '0.70rem',
        }}
        onClick={() => handleClick('Llegó')}
      >
        Presente
      </Button>
      <Button
        icon={<CloseOutlined />}
        size="small"
        style={{
          fontSize: '0.70rem',
        }}
        onClick={() => handleClick('No llegó')}
      >
        Ausente
      </Button>
    </Space>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Button,
  message,
  Card,
} from 'antd';
import dtService from '../../altServices/dtService';
import { SearchOutlined } from '@ant-design/icons';
import CosultaResolucion from '../../altUtils/CosultaResolucion';
import FormatDateV2 from '../../altUtils/FormatDateV2';
import { useSelector } from 'react-redux';

export default function BuscadorReportes({
  setFirstTable,
  tipoBuscador = 'asistencia',
  jobsAreLoading,
  setJobsAreLoading,
}) {
  const { enterprise } = useSelector((state) => state.enterprise);
  // ajuste buscador de semana
  const hoy = new Date();
  const semana = new Date();
  semana.setDate(semana.getDate() - 60);

  const [form] = Form.useForm();
  const [desde, setDesde] = useState(semana.toISOString().split('T')[0]);
  const [hasta, setHasta] = useState(hoy.toISOString().split('T')[0]);
  const [cargos, setCargos] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [jornadas, setJornadas] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [disableInput, setDisableInput] = useState(false);
  const [correos, setCorreos] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(true);

  const [trabajadores, setTrabajadores] = useState([]);

  const fechas = (event) => {
    const dates = event.map((item) => {
      const fecha = new Date(item);
      return fecha;
    });
    const desde = dates[0].toISOString().split('T')[0];
    const hasta = dates[1].toISOString().split('T')[0];
    setDesde(desde);
    setHasta(hasta);
  };

  const resetFilters = () => {
    form.resetFields();
    setDisableInput(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === 0) {
      form.resetFields(['fecha']);
      const hoy = new Date();
      const semana = new Date();
      semana.setDate(semana.getDate() - 60);
      const desde = semana.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
    if (e.target.value === 1) {
      const hoy = new Date();
      const semana = new Date();
      semana.setDate(semana.getDate() - 7);
      const desde = semana.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
    if (e.target.value === 2) {
      const hoy = new Date();
      const semanas = new Date();
      semanas.setDate(semanas.getDate() - 14);
      const desde = semanas.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
    if (e.target.value === 3) {
      const hoy = new Date();
      const mes = new Date();
      mes.setDate(mes.getDate() - 30);
      const desde = mes.toISOString().split('T')[0];
      const hasta = hoy.toISOString().split('T')[0];
      setDesde(desde);
      setHasta(hasta);
    }
  };

  const handleSelectChange = (value) => {
    setCorreos(value);
    if (value.length === 1) {
      const lista = trabajadores.filter((item) => item.dni === value[0]);
      const objetoNombre = lista[0];
      setDisableInput(true);
      form.setFieldsValue({
        rut: objetoNombre.dni,
        nombres: objetoNombre.nombre,
        primerApellido: objetoNombre.primerApellido,
        segundoApellido: objetoNombre.segundoApellido,
      });
    } else if (value.length > 1) {
      form.resetFields(['rut', 'nombres', 'primerApellido', 'segundoApellido']);
      setDisableInput(true);
    } else {
      form.resetFields();
      setDisableInput(false);
    }
  };

  const handleSubmit = async (values) => {
    setButtonLoading(true);
    setJobsAreLoading(true);

    let correosValue = '';
    let tipoBucacdor = 'particular';
    if (disableInput) {
      correosValue = correos.toString();
      tipoBucacdor = 'masiva';
    }
    if (values.cargo === undefined) {
      values.cargo = [];
    }
    const [res, err] = await dtService.BuscarTrabajadores(
      tipoBuscador,
      values.nombres,
      values.primerApellido,
      values.segundoApellido,
      values.rut,
      desde,
      hasta,
      values.cargo.toString(),
      values.cargo,
      correosValue,
      tipoBucacdor,
      values.jornada,
      enterprise.empresaId
    );
    if (!err) {
      const resFilter = res.data.filter((item) => item.reporte.length !== 0);

      if (tipoBuscador === 'festivos') {
        setFirstTable(res.data);
      } else {
        setFirstTable(resFilter);
      }
      setJobsAreLoading(false);

      if (res.data.length === 0) {
        message.error('No se encontraron resultados');
      }
    } else {
      message.error('Favor verificar los datos ingresados');
    }
    setButtonLoading(false);
  };

  const ajusteItemSelector = (array) => {
    const arraySelectorJornada = [];
    if (array.length !== 0) {
      array.forEach((obj) => {
        const nexElement = {
          label:
            FormatDateV2('2023-02-12T' + obj.split(' - ')[0]).split('T')[1] +
            ' - ' +
            FormatDateV2('2023-02-12T' + obj.split(' - ')[1]).split('T')[1],
          value: obj,
        };
        arraySelectorJornada.push(nexElement);
      });
      setJornadas(arraySelectorJornada);
    }
  };

  const Verobjetos = async () => {
    const [res, err] = await dtService.consultarinformacionBuscador(
      'cliente',
      enterprise.empresaId
    );

    if (!err) {
      setCargos(res.data.cargos);
      setUbicaciones(res.data.ubicaciones);
      setTrabajadores(res.data.trabajadores);
      ajusteItemSelector(res.data.jornadas || []);
    } else {
      console.error(err);
    }
    setLoadingSelect(false);
  };

  useEffect(() => {
    Verobjetos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      title="Selecciona el trabajador que deseas consultar"
      extra={<Button onClick={() => resetFilters()}>Limpiar buscador</Button>}
      style={{ arginBottom: '10px' }}
    >
      <Form
        form={form}
        layout="horizontal"
        requiredMark={false}
        onFinish={handleSubmit}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}
      >
        <Form.Item
          name="Trabajador"
          label="Lista Trabajadores"
          style={{ marginBottom: '10px' }}
        >
          <Select
            showSearch
            placeholder="Seleccione a los trabajadores"
            size="large"
            mode="multiple"
            loading={loadingSelect}
            allowClear={true}
            onChange={handleSelectChange}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={trabajadores.map((trabajador) => ({
              label: trabajador.nombre,
              // label: `${trabajador.nombre} ${trabajador.primerApellido} ${trabajador.segundoApellido}_${trabajador.dni}`,
              value: trabajador.correo,
              name: 'Trabajador',
            }))}
          />
        </Form.Item>

        <Form.Item
          hidden={!CosultaResolucion(window.outerWidth) || false}
          name="nombres"
          label="Nombres"
        >
          <Input
            disabled={disableInput}
            id="nombres"
            name="nombres"
            type="text"
            placeholder="Nombres"
          />
        </Form.Item>
        <Form.Item
          hidden={!CosultaResolucion(window.outerWidth) || false}
          name="primerApellido"
          label="Primer Apellido"
        >
          <Input
            disabled={disableInput}
            id="primerApellido"
            name="primerApellido"
            type="text"
            placeholder="Primer apellido"
          />
        </Form.Item>
        <Form.Item
          hidden={!CosultaResolucion(window.outerWidth) || false}
          name="segundoApellido"
          label="Segundo Apellido"
        >
          <Input
            disabled={disableInput}
            id="segundoApellido"
            name="segundoApellido"
            type="text"
            placeholder="Segundo apellido"
          />
        </Form.Item>

        <Form.Item
          hidden={CosultaResolucion(window.outerWidth) || false}
          name="nombreCompleto"
          label="Nombre"
          style={{ marginBottom: 0 }}
        >
          <Form.Item
            name="nombres"
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
            }}
          >
            <Input
              disabled={disableInput}
              id="nombres"
              name="nombres"
              type="text"
              placeholder="Nombres"
            />
          </Form.Item>
          <Form.Item
            name="primerApellido"
            style={{
              display: 'inline-block',
              width: 'calc(25% - 8px)',
              margin: '0 8px',
            }}
          >
            <Input
              disabled={disableInput}
              id="primerApellido"
              name="primerApellido"
              type="text"
              placeholder="Primer apellido"
            />
          </Form.Item>
          <Form.Item
            disabled={disableInput}
            name="segundoApellido"
            style={{
              display: 'inline-block',
              width: 'calc(25%)',
            }}
          >
            <Input
              disabled={disableInput}
              id="segundoApellido"
              name="segundoApellido"
              type="text"
              placeholder="Segundo apellido"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item name="rut" label="RUT">
          <Input id="rut" name="rut" type="text" disabled={disableInput} />
        </Form.Item>
        <Form.Item name="cargo" label="Cargo">
          <Select
            showSearch
            placeholder="Selecciona un cargo"
            allowClear={true}
            loading={loadingSelect}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={cargos.map((cargo) => ({
              label: cargo,
              value: cargo,
            }))}
            onClick={() => {
              if (cargos.length === 0) {
                setJobsAreLoading(true);
                // getCargos();
              }
            }}
          />
        </Form.Item>
        <Form.Item name="jornada" label="Jornada">
          <Select
            showSearch
            placeholder="Selecciona una jornada"
            allowClear={true}
            size="large"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={jornadas.map((jornada) => ({
              label: jornada.label,
              // label: `${trabajador.nombre} ${trabajador.primerApellido} ${trabajador.segundoApellido}_${trabajador.dni}`,
              value: jornada.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="ubicacion" label="Ubicación">
          <Select
            showSearch
            placeholder="Selecciona una ubicacion"
            allowClear={true}
            loading={loadingSelect}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={ubicaciones.map((ubicacion) => ({
              label: ubicacion,
              value: ubicacion,
            }))}
            onClick={() => {
              if (ubicaciones.length === 0) {
                setJobsAreLoading(true);
                // getUbicaciones();
              }
            }}
          />
        </Form.Item>
        <Form.Item name="periodo" label="Periodo">
          <Radio.Group onChange={onChange} value={value} defaultValue={0}>
            <Radio value={0}>Seleccionar fecha</Radio>
            <Radio value={1}>Última Semana</Radio>
            <Radio value={2}>Última Quincena</Radio>
            <Radio value={3}>Último Mes</Radio>
          </Radio.Group>
          <p style={{ marginTop: '5px' }}>
            Al no seleccionar una fecha la busqueda se hara por los dos ultimos
            dos meses.
          </p>
        </Form.Item>
        <Form.Item name="fecha" label="Fecha">
          <DatePicker.RangePicker
            id="fecha"
            name="fecha"
            style={{ width: '100%' }}
            placeholder={['Fecha inicio', 'Fecha fin']}
            onChange={fechas}
            disabled={value !== 0}
            allowClear={true}
            format={'DD/MM/YYYY'}
          />
        </Form.Item>
        <div className="flex justify-end">
          <Button
            block
            style={{ marginBottom: '20px' }}
            type="primary"
            htmlType="submit"
            className="mr-2"
            icon={<SearchOutlined />}
            loading={buttonLoading}
          >
            Buscar
          </Button>
        </div>
      </Form>
    </Card>
  );
}

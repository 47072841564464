import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ClockCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  TimePicker,
  message,
} from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { changeSchedules } from '../../../../services/RequisitionsService';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function CambiarHorario({ item, buscarSolicitudes }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  function handleCancel() {
    setShow(false);
    form.resetFields();
  }

  function handleChangeHour(value) {
    // get the hour and minutes of fechaInicio and fechaFin (Format UTC)
    // get the diference between hour of fechaInicio and fechaFin
    // add the diference to the new hour of entrada
    // set the new hour of salida

    const hourInicio = dayjs(item.fechaInicio).hour();
    const minuteInicio = dayjs(item.fechaInicio).minute();
    const hourFin = dayjs(item.fechaFin).hour();
    const minuteFin = dayjs(item.fechaFin).minute();

    const diff = (hourFin - hourInicio) * 60 + (minuteFin - minuteInicio);

    form.setFieldValue('horaSalida', dayjs(value).add(diff, 'minute'));
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      const horaInicio = form.getFieldValue('horaEntrada').tz('UTC');
      const horaFin = form.getFieldValue('horaSalida').tz('UTC');

      function getDiaInicio() {
        const diaInicio = dayjs(item.fechaInicio + 'Z').tz('America/Santiago');
        const diff = diaInicio.utcOffset() / 60;
        const isUTCNextDay =
          dayjs(item?.fechaInicio + 'Z')
            .tz('UTC')
            .hour() +
            diff <
          0;

        // console.log('Diferencia: ', horaInicio.hour() + diff);

        if (isUTCNextDay) {
          if (horaInicio.hour() + diff >= 0) {
            // console.log('se resta un dia');
            return dayjs(item.fechaInicio).subtract(1, 'day');
          }
          // console.log('no se resta un dia');
          return dayjs(item.fechaInicio);
        }

        if (!isUTCNextDay) {
          if (horaInicio.hour() + diff < 0) {
            // console.log('se suma un dia');
            return dayjs(item.fechaInicio).add(1, 'day');
          }
          // console.log('no se suma un dia');
          return dayjs(item.fechaInicio);
        }
      }

      function getDiaFin() {
        const inicio = dayjs(item?.fechaInicio + 'Z').tz('UTC');
        const salida = dayjs(item?.fechaFin + 'Z').tz('UTC');
        const diff = salida.diff(inicio, 'hour');
        return getDiaInicio()
          .set('hour', horaInicio.hour())
          .set('minute', horaInicio.minute())
          .add(diff, 'hour');
      }

      const datos = {
        pais_consecutivoOferta: item.pais_consecutivoOferta,
        empresaId: enterprise.empresaId,
        turnos: [
          {
            fechaInicio: getDiaInicio().format('YYYY-MM-DD'),
            fechaFin: getDiaFin().format('YYYY-MM-DD'),
            horaInicio: horaInicio.format('HH:mm'),
            horaFin: horaFin.format('HH:mm'),
          },
        ],
      };

      await changeSchedules(datos);
      message.success('Horario actualizado');
      buscarSolicitudes();
      handleCancel();
    } catch (error) {
      console.error(error);
      message.error('Error al actualizar horarios');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Space onClick={() => setShow(true)}>
        <ClockCircleOutlined />
        <span>Cambiar Horario</span>
      </Space>
      <Modal
        title="Cambiar horario"
        open={show}
        destroyOnClose
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Cambiar horario
          </Button>,
        ]}
      >
        <Divider />
        <p>
          Selecciona el nuevo horario que tendra esta solicitud. Se mantendrá el
          mismo día y no podras cambiar la cantidad de horas.
        </p>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            solicitud: item?.pais_consecutivoOferta,
            horaEntrada: dayjs(item?.fechaInicio + 'Z').tz('America/Santiago'),
            horaSalida: dayjs(item?.fechaFin + 'Z').tz('America/Santiago'),
          }}
          form={form}
        >
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Hora de entrada"
                name="horaEntrada"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecciona la hora de entrada',
                  },
                ]}
              >
                <TimePicker
                  format={'HH:mm'}
                  placeholder="Hora entrada"
                  minuteStep={15}
                  showNow={false}
                  changeOnBlur
                  onChange={handleChangeHour}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Hora de salida"
                name="horaSalida"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecciona la hora de salida',
                  },
                ]}
              >
                <TimePicker
                  format={'HH:mm'}
                  placeholder="Hora salida"
                  minuteStep={15}
                  showNow={false}
                  changeOnBlur
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Divider,
  Form,
  Modal,
  Select,
  Space,
  message,
} from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import {
  getEtiquetas,
  invitarEtiquetas,
} from '../../../../services/EtiquetasService';

export default function InvitarAPostular({ item }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (show) buscarTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  async function buscarTags() {
    try {
      setTags(null);
      const res = await getEtiquetas(enterprise.empresaId);
      setTags(res);
    } catch (error) {
      setTags([]);
      console.error(error);
      message.error('Error al obtener las etiquetas');
    }
  }

  async function handleSubmit() {
    try {
      const values = await form.validateFields();
      setLoading(true);
      await invitarEtiquetas(
        enterprise.empresaId,
        [values.solicitud],
        [values.etiqueta]
      );
      handleCancel();
      message.success('Invitados correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al invitar a postular');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setShow(false);
    form.resetFields();
  }

  if (
    !(
      item.estadoActual === 'Activa' ||
      item.estadoActual === 'Ejecutando' ||
      item.estadoActual === 'EnPausa'
    )
  )
    return null;

  return (
    <>
      <Space onClick={() => setShow(true)}>
        <UsergroupAddOutlined />
        <span>Invitar a postular</span>
      </Space>
      <Modal
        title="Invitar a postular"
        open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Invitar
          </Button>,
        ]}
      >
        <Divider />
        <p>
          Para invitar a postular a los trabajadores que tengan una etiqueta
          específica, seleccione la etiqueta correspondiente.
        </p>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            solicitud: item?.pais_consecutivoOferta,
            etiqueta: null,
          }}
          form={form}
        >
          <Form.Item
            label="Solicitud de trabajo"
            name="solicitud"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar una solicitud de trabajo',
              },
            ]}
          >
            <Select disabled>
              <Select.Option
                key={item.pais_consecutivoOferta}
                value={item.pais_consecutivoOferta}
              >
                #{item.idOferta} - {item.tipoTrabajo}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Etiqueta"
            name="etiqueta"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar una etiqueta',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una etiqueta"
              loading={!tags}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
            >
              {tags?.map(({ etiquetaId, nombre, color, totalTrabajadores }) => (
                <Select.Option key={nombre} value={etiquetaId}>
                  <Badge
                    color={color}
                    text={`${nombre} (${totalTrabajadores})`}
                  />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

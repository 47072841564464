import dayjs from 'dayjs';
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

// filtrar solicitudes
export default function getFiltereds(solicitudes, filters) {
  let filtered = solicitudes;
  if (filters.id)
    filtered = filtered.filter(
      (item) => parseInt(item.idOferta) === parseInt(filters.id)
    );
  if (filters.cargo)
    filtered = filtered.filter((item) =>
      item.tipoTrabajo.toLowerCase().includes(filters.cargo.toLowerCase())
    );
  if (filters.motivo)
    filtered = filtered.filter((item) =>
      item.nombreContrato.toLowerCase().includes(filters.motivo.toLowerCase())
    );
  if (filters.fecha) {
    filtered = filtered.filter((item) => {
      const fechaInicio = dayjs(item.fechaInicio + 'Z');
      const fechaInicioFormatted = fechaInicio.format('YYYY-MM-DD'); // Formato de fecha compatible con filters.fecha

      return (
        fechaInicioFormatted === filters.fecha[0].format('YYYY-MM-DD') ||
        fechaInicioFormatted === filters.fecha[1].format('YYYY-MM-DD') ||
        fechaInicio.isBetween(filters.fecha[0], filters.fecha[1], null, '[]')
      );
    });
  }

  if (filters.estado)
    filtered = filtered.filter((item) =>
      item.estadoActual.toLowerCase().includes(filters.estado.toLowerCase())
    );

  if (filters.porcubrir)
    filtered = filtered.filter((item) => {
      const { aceptadoXTrabajador, ejecutando, terminado, seleccionadosTurno } =
        item.contador;
      const vacantes = seleccionadosTurno.reduce(
        (a, b) => a + parseInt(b.vacantes),
        0
      );
      const contratados = aceptadoXTrabajador + ejecutando + terminado;
      return contratados < vacantes;
    });
  return filtered;
}

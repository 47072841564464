import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRequisitions } from '../../../services/RequisitionsService';
import { contratarDirecto } from '../../../services/TrabajadoresService';
import { Button, Divider, Form, Input, Modal, Select, message } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';

export default function ContratarDirecto({ seleccionados }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [solicitudes, setSolicitudes] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!form) return;
    if (seleccionados.length < 1) return;
    form.setFieldsValue({
      trabajadores: seleccionados
        .map((t) => `#${t.trabajadorId} - ${t.nombre} ${t.primerApellido}`)
        .join('\n'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seleccionados]);

  useEffect(() => {
    buscarSolicitudes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function buscarSolicitudes() {
    try {
      setSolicitudes(null);
      const res = await getRequisitions(
        enterprise.empresaId
        // 'Activa,EnPausa,Ejecutando'
      );
      setSolicitudes(res);
    } catch (error) {
      setSolicitudes([]);
      message.error('Error al obtener las solicitudes');
    }
  }

  async function handleSubmit() {
    try {
      const values = await form.validateFields();
      setLoading(true);
      await contratarDirecto(
        enterprise.empresaId,
        values.solicitud,
        seleccionados.map((t) => t.trabajadorId)
      );
      handleCancel();
      message.success('Contratados correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al contratar');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setShow(false);
    form.resetFields();
  }

  const activeStates = ['Activa', 'EnPausa', 'Ejecutando'];

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        icon={<FileDoneOutlined />}
        disabled={seleccionados.length < 1}
      >
        Contratar directo
      </Button>
      <Modal
        title="Contratar directo"
        open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Contratar
          </Button>,
        ]}
      >
        <Divider />
        <p>
          Selecciona una solicitud de trabajo a la cual quieres contratar
          directamente los trabajadores seleccionados.
        </p>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            solicitud: null,
            trabajadores: seleccionados
              .map(
                (t) => `#${t.trabajadorId} - ${t.nombre} ${t.primerApellido}`
              )
              .join('\n'),
          }}
          form={form}
        >
          <Form.Item
            label="Solicitud de trabajo"
            name="solicitud"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar una solicitud de trabajo',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una solicitud de trabajo"
              loading={!solicitudes}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                const search = solicitudes?.find(
                  (s) => s.pais_consecutivoOferta === option.value
                );
                return `#${search?.idOferta} - ${search?.tipoTrabajo}`
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase());
              }}
            >
              <Select.OptGroup label="Solicitudes Activas">
                {solicitudes
                  ?.filter((s) => activeStates.includes(s.estado))
                  .map(({ idOferta, tipoTrabajo, pais_consecutivoOferta }) => (
                    <Select.Option
                      key={idOferta}
                      value={pais_consecutivoOferta}
                    >
                      #{idOferta} - {tipoTrabajo}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
              <Select.OptGroup label="Solicitudes Anteriores">
                {solicitudes
                  ?.filter((s) => !activeStates.includes(s.estado))
                  .map(({ idOferta, tipoTrabajo, pais_consecutivoOferta }) => (
                    <Select.Option
                      key={idOferta}
                      value={pais_consecutivoOferta}
                    >
                      #{idOferta} - {tipoTrabajo}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            </Select>
          </Form.Item>
          <Form.Item
            label="Trabajadores seleccionados"
            name="trabajadores"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar al menos un trabajador',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Escribe los trabajadores que quieres contratar"
              autoSize={{ minRows: 3, maxRows: 5 }}
              readOnly
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

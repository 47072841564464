// filtrar contratos
export default function getFiltereds(contratos, filters) {
  let filtered = contratos;
  if (filters.oferta)
    filtered = filtered.filter((item) => {
      return parseInt(item.idOferta) === parseInt(filters.oferta);
    });
  if (filters.id)
    filtered = filtered.filter(
      (item) => parseInt(item.idContrato) === parseInt(filters.id)
    );
  if (filters.rut)
    filtered = filtered.filter((item) =>
      item.dni.toLowerCase().includes(filters.rut.toLowerCase())
    );
  if (filters.nombre)
    filtered = filtered.filter(
      (item) =>
        item.nombre.toLowerCase().includes(filters.nombre.toLowerCase()) ||
        item.correo.toLowerCase().includes(filters.nombre.toLowerCase())
    );
  return filtered;
}

import React from 'react';
import Meta from '../../components/layouts/Meta';
import Header from './components/Header';
import LoginForm from './components/LoginForm';

export default function index() {
  return (
    <div className="h-screen flex items-center justify-center">
      <Meta title="Iniciar sesión" />
      <div className="flex flex-col items-center">
        <Header text="¡Bienvenido a Xinerlink para Empresas!" />
        <div id="body" className="w-80 md:w-96 mt-10">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  enterprise: null,
};

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState,
  reducers: {
    select: (state, action) => {
      state.enterprise = action.payload;
      localStorage.setItem('MantenedorEmpresaId', action.payload.empresaId);
    },
    unselect: (state) => {
      state.enterprise = null;
    },
  },
});

export const { select, unselect } = enterpriseSlice.actions;
export default enterpriseSlice.reducer;

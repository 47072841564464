import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Badge,
  Button,
  ColorPicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  message,
} from 'antd';
import { updateEtiqueta } from '../../../services/EtiquetasService';
import { EditOutlined } from '@ant-design/icons';

export default function EditarEtiquetas({ etiquetas, checkTags, refresh }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  async function handleSubmit() {
    try {
      const values = await form.validateFields();
      const updateTag = {
        nombre: values.nombre,
        color: values.color,
        colorTexto: values.colorTexto,
      };
      setLoading(true);
      const original = etiquetas.find(
        (etiqueta) => etiqueta.etiquetaId === values.etiqueta
      );
      const isSameName = original.nombre === values.nombre;
      if (isSameName) {
        delete updateTag.nombre;
      }
      await updateEtiqueta(enterprise.empresaId, values.etiqueta, updateTag);
      checkTags();
      refresh();
      handleCancel();
      message.success('Etiqueta editada correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al editar la etiqueta');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setShow(false);
    form.resetFields();
  }

  return (
    <>
      <Button onClick={() => setShow(true)} icon={<EditOutlined />}>
        Editar etiquetas
      </Button>
      <Modal
        title="Editar Etiqueta"
        open={show}
        destroyOnClose
        onCancel={handleCancel}
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Guardar
          </Button>,
        ]}
      >
        <Divider />
        <p>
          Selecciona la etiqueta que deseas editar y modifica sus valores en el
          formulario que aparecerá a continuación.
        </p>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Etiqueta"
            name="etiqueta"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar una etiqueta',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una etiqueta"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const etiqueta = etiquetas.find(
                  (etiqueta) => etiqueta.etiquetaId === value
                );
                form.setFieldsValue({
                  nombre: etiqueta?.nombre,
                  color: etiqueta?.color,
                  colorTexto: etiqueta?.colorTexto,
                });
              }}
            >
              {etiquetas?.map((etiqueta) => (
                <Select.Option
                  key={etiqueta.nombre}
                  value={etiqueta.etiquetaId}
                >
                  <Badge color={etiqueta.color} text={etiqueta.nombre} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: 'Debes ingresar un nombre',
              },
            ]}
          >
            <Input placeholder="Se llenará automáticamente cuando selecciones una etiqueta" />
          </Form.Item>
          <Space size="large">
            <Form.Item
              label="Color de Etiqueta"
              name="color"
              rules={[
                {
                  required: true,
                  message: 'Debes seleccionar un color',
                },
              ]}
            >
              <ColorPicker
                showText
                onChangeComplete={(color) => {
                  form.setFieldValue('color', color.toHexString());
                }}
              />
            </Form.Item>
            <Form.Item
              label="Color de Texto"
              name="colorTexto"
              rules={[
                {
                  required: true,
                  message: 'Debes seleccionar un color',
                },
              ]}
            >
              <ColorPicker
                showText
                onChangeComplete={(color) => {
                  form.setFieldValue('colorTexto', color.toHexString());
                }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
}

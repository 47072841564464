import { Progress, Space, Tooltip } from 'antd';
import { getPorcentaje } from '../../utils/getPorcentaje';

export default function Cobertura({ item }) {
  const {
    postulado,
    seleccionadoXEmpresa,
    // provisionadoXEmpresa,
    aceptadoXEmpresa,
    aceptadoXTrabajador,
    ejecutando,
    terminado,
    seleccionadosTurno,
  } = item.contador;

  const vacantes = seleccionadosTurno.reduce(
    (a, b) => a + parseInt(b.vacantes),
    0
  );

  const postulados = postulado + seleccionadoXEmpresa + aceptadoXEmpresa;

  const contratados = aceptadoXTrabajador + ejecutando + terminado;

  return (
    <div>
      <Space size="small">
        <div className="text-xs">
          <Tooltip title="Contratados">
            <strong className="xl:hidden">C: </strong>
            <strong className="hidden xl:inline">Contratados: </strong>
          </Tooltip>
          {contratados}
        </div>
        <div className="text-xs">
          <Tooltip title="Postulados">
            <strong className="xl:hidden">P: </strong>
            <strong className="hidden xl:inline">Postulados: </strong>
          </Tooltip>
          {postulados}
        </div>
        <div className="text-xs">
          <Tooltip title="Vacantes">
            <strong className="xl:hidden">V: </strong>
            <strong className="hidden xl:inline">Vacantes: </strong>
          </Tooltip>
          {vacantes}
        </div>
      </Space>
      <Progress
        percent={getPorcentaje(postulados + contratados, vacantes)}
        success={{ percent: getPorcentaje(contratados, vacantes) }}
        showInfo={false}
        style={{
          width: '100%',
        }}
      />
    </div>
  );
}

import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function getMultimedia(empresaId) {
  const token = await getToken();
  const { data } = await API.get('empresa', '/multimedia', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
    },
  });
  if (data.existe) return data.registro;
  else return [];
}

export async function putMultimedia(registro) {
  const token = await getToken();
  const { data } = await API.put('empresa', '/multimedia', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro,
    },
  });
  if (data.existe) return data;
  else return [];
}

// const eliminarMultimedia = async (userToken, registro) => {
//   const path = '/multimedia';
//   const myInit = createParams();
//   myInit.body = {
//     userToken: await getToken(),
//     registro,
//   };
//   const res = customFetch(() => API.del(apiName, path, myInit));
//   return res;
// };

// export { consultaMultimedia, RegistroMultimedia, eliminarMultimedia };

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { loginUser } from '../../../services/UserService';
import { signIn } from '../../../lib/features/auth';
import { select } from '../../../lib/features/enterprise';

export default function LoginForm() {
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();

  async function handleSubmit(values) {
    try {
      setSubmit(true);
      const { user, empresa } = await loginUser(values);
      dispatch(signIn(user));
      dispatch(select(empresa));
      message.success('Inicio de sesión exitoso');
    } catch (error) {
      console.error(error);
      message.error('Usuario o contraseña incorrectos');
    } finally {
      setSubmit(false);
    }
  }

  return (
    <Form size="large" onFinish={handleSubmit}>
      <Form.Item
        name={'username'}
        rules={[
          {
            required: true,
            message: 'Debes introducir tu correo electrónico',
          },
          {
            type: 'email',
            message: 'Debes introducir un correo electrónico válido',
          },
        ]}
      >
        <Input placeholder="Correo electrónico" />
      </Form.Item>
      <Form.Item
        name={'password'}
        rules={[{ required: true, message: 'Debes introducir tu contraseña' }]}
      >
        <Input.Password placeholder="Contraseña" />
      </Form.Item>
      <Form.Item>
        <Link to="/auth/recuperar">
          <span className="text-sm text-gray-400 underline cursor-pointer">
            ¿Olvidaste la contraseña?
          </span>
        </Link>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={submit}>
          Iniciar sesión
        </Button>
      </Form.Item>
    </Form>
  );
}

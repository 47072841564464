import React from 'react';
import getOptions from '../../../../altUtils/getOptions';
import { Space } from 'antd';
import FormatDateV2 from '../../../../altUtils/FormatDateV2';
import consultaJustificacion from '../../jornadaDiaria/funtions/consultaJustificacion';

export default function TablaAsistenciaReporte(items) {
  return [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'fecha'),
      onFilter: (v, record) => record.fecha.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {FormatDateV2(record.key).split('T')[0]}</span>
          ) : (
            <span style={{ color: 'red' }}>
              {FormatDateV2(record.key).split('T')[0]}
            </span>
          )}
        </Space>
      ),
    },
    {
      title: 'Asistencia',
      dataIndex: 'asistencia',
      key: 'asistencia',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'asistencia'),
      onFilter: (v, record) => record.asistencia.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {text}</span>
          ) : (
            <span style={{ color: 'red' }}>{text}</span>
          )}
        </Space>
      ),
    },
    {
      title: 'Ausencia',
      dataIndex: 'ausencia',
      key: 'ausencia',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'ausencia'),
      onFilter: (v, record) => record.ausencia.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {text}</span>
          ) : (
            <span style={{ color: 'red' }}>{text}</span>
          )}
        </Space>
      ),
    },
    {
      title: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      filterSearch: true,
      aling: 'center',
      width: 120,
      filters: getOptions(items, 'observaciones'),
      onFilter: (v, record) => record.observaciones.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {text}</span>
          ) : (
            <span style={{ color: 'red' }}>{text}</span>
          )}
        </Space>
      ),
    },
  ];
}

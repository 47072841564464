import { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Education, Experience, Info } from './CandidateTabs';
import { Avatar, Modal, Space, Tabs, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const { Text, Title } = Typography;

export default function CandidateCard({ candidate, show, close }) {
  const [cargoFoto, setCargoFoto] = useState(null);
  const { nombre, primerApellido } = candidate;
  const nombreCompleto = `${nombre} ${primerApellido}`;

  useEffect(() => {
    getAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getAvatar() {
    const url = await Storage.get(candidate.perfil.enlaceFoto);
    setCargoFoto(url);
  }

  const items = [
    {
      key: '1',
      label: `Información`,
      children: <Info candidate={candidate} />,
    },
    {
      key: '2',
      label: `Experiencia laboral`,
      children: <Experience candidate={candidate} />,
    },
    {
      key: '3',
      label: `Formación académica`,
      children: <Education candidate={candidate} />,
    },
  ];

  return (
    <Modal open={show} onCancel={close} footer={false}>
      <Space className="mb-4" size="middle">
        <Avatar size={64} icon={<UserOutlined />} src={cargoFoto} />
        <div>
          <Title level={5} style={{ margin: 0 }}>
            {nombreCompleto}
          </Title>
          <Text type="secondary">{candidate.dni}</Text>
        </div>
      </Space>
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
}

import { CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { unhireCandidate } from '../../../../../services/CandidateService';
import { useSelector } from 'react-redux';

export default function RejectCandidate({ candidate, refreshCandidates }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  async function handleSelect() {
    const confirm = window.confirm(
      '¿Está seguro que desea deshacer la aceptación?'
    );
    if (!confirm) return;
    try {
      message.loading('Procesando...');
      const datos = {
        correo: candidate.correo,
        empresaId: enterprise.empresaId,
        pais_consecutivoOferta: candidate.turnoInfo.pais_consecutivoOferta,
      };
      await unhireCandidate(datos);
      message.success('Aceptación deshecha');
      refreshCandidates();
    } catch (error) {
      console.error(error);
      message.error('Error al deshacer aceptación');
    }
  }

  if (candidate.estadoActual === 'AceptadoXEmpresa') {
    return (
      <Tooltip title="Deshacer aceptación">
        <Button
          type="text"
          icon={<CloseOutlined style={{ color: 'red' }} />}
          onClick={handleSelect}
        />
      </Tooltip>
    );
  }
}

import React from 'react';
import getOptions from '../../../../altUtils/getOptions';
import { Space } from 'antd';

export default function TablaJornadaDiariaInfo(items) {
  return [
    {
      title: 'Nombre Trabajador',
      dataIndex: 'nombres',
      key: 'nombres',
      filterSearch: true,
      aling: 'center',
      width: 180,
      filters: getOptions(items, 'nombres'),
      onFilter: (v, record) => record.nombres.indexOf(v) === 0,
      render: (text, record) => <Space>{text}</Space>,
    },
    {
      title: 'Primer apellido',
      dataIndex: 'primerApellido',
      key: 'primerApellido',
      filterSearch: true,
      aling: 'center',
      width: 180,
      filters: getOptions(items, 'primerApellido'),
      onFilter: (v, record) => record.primerApellido.indexOf(v) === 0,
      render: (text, record) => <Space>{text}</Space>,
    },
    {
      title: 'Segundo apellido',
      dataIndex: 'segundoApellido',
      key: 'segundoApellido',
      filterSearch: true,
      aling: 'center',
      width: 180,
      filters: getOptions(items, 'segundoApellido'),
      onFilter: (v, record) => record.segundoApellido.indexOf(v) === 0,
      render: (text, record) => <Space>{text}</Space>,
    },
    {
      title: 'Rut',
      dataIndex: 'rut',
      key: 'rut',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'rut'),
      onFilter: (v, record) => record.rut.indexOf(v) === 0,
      render: (text, record) => <Space>{text}</Space>,
    },
    {
      title: 'Empresa - Rut',
      dataIndex: 'empresaRut',
      key: 'empresaRut',
      filterSearch: true,
      aling: 'center',
      width: 100,
      filters: getOptions(items, 'empresaRut'),
      onFilter: (v, record) => record.empresaRut.indexOf(v) === 0,
      render: (text, record) => <Space>{text}</Space>,
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      filterSearch: true,
      aling: 'center',
      width: 100,
      filters: getOptions(items, 'cargo'),
      onFilter: (v, record) => record.cargo.indexOf(v) === 0,
      render: (text, record) => <Space>{text}</Space>,
    },
  ];
}

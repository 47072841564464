import { useState } from 'react';
import { useSelector } from 'react-redux';
import useSolicitud from '../../../../hooks/useSolicitud';
import { generateRequisition } from '../../../../services/RequisitionsService';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
} from 'antd';
import Mapa from './Mapa';

export default function BasicForm({ next }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const { campos, handleChange, solicitud } = useSolicitud();
  const [procesando, setProcesando] = useState(false);
  const [reemplazado, setReemplazado] = useState(
    solicitud?.infoBasica?.causal?.includes('A.') || false
  );
  const [location, setLocation] = useState(
    solicitud?.infoBasica?.ubicacion || null
  );

  async function handleFinish(values) {
    setProcesando(true);
    try {
      const { pais_consecutivoOferta } = await generateRequisition(
        enterprise.empresaId,
        values,
        solicitud.id ? solicitud.id : ''
      );
      handleChange({ infoBasica: values, id: pais_consecutivoOferta });
      message.info('Borrador guardado');
      next();
    } catch (error) {
      message.error('Error al generar la solicitud');
    } finally {
      setProcesando(false);
    }
  }

  function checkCausal(value) {
    setReemplazado(value.includes('A.'));
  }

  function getPrivacidades() {
    let privacidades = [];
    try {
      const { publica, privada, mi_comunidad } =
        campos?.empresa?.oferta?.privacidad;
      if (publica) privacidades.push({ value: 'publica', label: 'Pública' });
      if (privada) privacidades.push({ value: 'privada', label: 'Privada' });
      if (mi_comunidad)
        privacidades.push({ value: 'mi_comunidad', label: 'Mi Comunidad' });
      return privacidades;
    } catch (error) {
      console.error(error);
      return privacidades;
    }
  }

  return (
    <div className="grid grid-cols-5 gap-20">
      <Form
        layout="vertical"
        className="col-span-2"
        scrollToFirstError
        requiredMark={false}
        onFinish={handleFinish}
        initialValues={solicitud.infoBasica}
      >
        <Form.Item
          data-cy="itemCargo"
          label="¿Que cargo necesitas cubrir?"
          name="cargo"
          tooltip="Si el cargo no se encuentra en la lista contacta con Xinerlink para solicitarlo."
          rules={[
            {
              required: true,
              message: 'Por favor selecciona un cargo',
            },
          ]}
        >
          <Select
            data-cy="selectorCargo"
            placeholder="Selecciona un cargo"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.key.toLowerCase().includes(input.toLowerCase())
            }
          >
            {campos?.cargos
              .sort((a, b) => (a.label > b.label ? 1 : -1))
              .map((cargo) => (
                <Select.Option
                  data-cy={cargo.label}
                  key={cargo.value}
                  value={cargo.value}
                >
                  {cargo.label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          data-cy="itemRazonSocial"
          label="¿A qué razón social se facturará este trabajo?"
          name="razonsocial"
          rules={[
            {
              required: true,
              message: 'Por favor selecciona una razón social',
            },
          ]}
        >
          <Select
            data-cy="selectorRazonSocial"
            placeholder="Selecciona o agrega una razón social"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.key.toLowerCase().includes(input.toLowerCase())
            }
          >
            {campos?.razonesSociales
              .sort((a, b) => (a.label > b.label ? 1 : -1))
              .map((razon) => (
                <Select.Option
                  data-cy={razon.label}
                  key={razon.value}
                  value={razon.value}
                >
                  {razon.label}
                </Select.Option>
              ))}
          </Select>
          {/* <Button type="default" className="mt-2" icon={<PlusOutlined />}>
            Agragar Razón Social
          </Button> */}
        </Form.Item>
        <Form.Item
          data-cy="itemLugarTrabajo"
          label="¿Donde se ejecutará este trabajo?"
          name="ubicacion"
          rules={[
            {
              required: true,
              message: 'Por favor selecciona una ubicación',
            },
          ]}
        >
          <Select
            data-cy="selectorUbicacion"
            placeholder="Selecciona o agrega una ubicación"
            onChange={(e) => setLocation(e)}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option['data-cy'].toLowerCase().includes(input.toLowerCase())
            }
          >
            {campos?.ubicaciones
              .sort((a, b) => (a.label > b.label ? 1 : -1))
              .map((ubicacion) => (
                <Select.Option
                  data-cy={ubicacion.label}
                  key={ubicacion.value}
                  value={ubicacion.value}
                >
                  {ubicacion.label}
                </Select.Option>
              ))}
          </Select>
          {/* <Button type="default" className="mt-2" icon={<PlusOutlined />}>
            Agragar Ubicación
          </Button> */}
        </Form.Item>
        <Form.Item
          data-cy="itemMotivoContrato"
          label="Motivo de Contrato"
          name="motivo"
          tooltip="Este es el nombre legal que aparecerá en el contrato, no es público para los trabajadores."
          rules={[
            {
              required: true,
              message: 'Por favor ingresa el motivo del contrato',
            },
          ]}
        >
          {campos?.empresa?.oferta?.motivos?.tipo === 'select' ? (
            <Select
              data-cy="inputMotivoContrato"
              placeholder="Selecciona el motivo de contratación"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option['data-cy'].toLowerCase().includes(input.toLowerCase())
              }
            >
              {campos?.empresa?.oferta?.motivos?.values?.map((motivo) => (
                <Select.Option data-cy={motivo} key={motivo} value={motivo}>
                  {motivo}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input
              data-cy="inputMotivoContrato"
              placeholder="Ingrese el motivo de contratación"
            />
          )}
        </Form.Item>
        <Form.Item
          data-cy="itemCausalContratacion"
          label="Causal de Contratación"
          name="causal"
          tooltip="Este es la causal de contratación que aparecerá en el contrato, no es pública para los trabajadores."
          rules={[
            {
              required: true,
              message: 'Por favor selecciona una causal de contratación',
            },
          ]}
        >
          <Select
            data-cy="selectorCausalContratacion"
            placeholder="Selecciona la causal de contratación"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option['data-cy'].toLowerCase().includes(input.toLowerCase())
            }
            onChange={checkCausal}
          >
            {campos?.empresa?.oferta?.causales?.map((causal) => (
              <Select.Option data-cy={causal} key={causal} value={causal}>
                {causal}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {reemplazado && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                data-cy="itemNombreReemplazado"
                label="Nombre del reemplazado"
                name="nombreReemplazado"
                tooltip="Este es el nombre del trabajador que será reemplazado."
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa el nombre del reemplazado',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                data-cy="itemRutReemplazado"
                label="RUT del reemplazado"
                allowClear
                name="rutReemplazado"
                tooltip="Este es el RUT del trabajador que será reemplazado."
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa el RUT del reemplazado',
                  },
                  {
                    pattern: /^[0-9]{1,9}-[0-9kK]{1}$/,
                    message: 'Por favor ingresa un RUT válido (12345678-9)',
                  },
                ]}
              >
                <Input placeholder="12345678-9" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item
          data-cy="itemSolicitudPrivada"
          label="Privacidad de la solicitud"
          name="privacidad"
          tooltip="Si la solicitud es privada solo los trabajadores que tu invites podrán verla. Si es pública cualquier trabajador de Xinerlink podrá verla. Si es mi comunidad solo los trabajadores de tu lista de trabajadores podrán verla."
          rules={[
            {
              required: true,
              message: 'Por favor selecciona una privacidad',
            },
          ]}
        >
          <Radio.Group>
            {getPrivacidades().map((privacidad) => (
              <Radio
                data-cy={privacidad.value}
                key={privacidad.value}
                value={privacidad.value}
              >
                {privacidad.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Divider />
        <Form.Item>
          <Button
            data-cy="botonContinuar"
            type="primary"
            htmlType="submit"
            loading={procesando}
          >
            Siguiente
          </Button>
        </Form.Item>
      </Form>
      <div className="bg-gray-50 col-span-3 flex items-center justify-center">
        <Mapa selected={location} locations={campos?.ubicaciones} />
      </div>
    </div>
  );
}

export default function FormatDateV2(fechaUTC, tipo = 'general') {
  // Crear un objeto de fecha a partir de la fecha UTC

  if (fechaUTC === null || fechaUTC === undefined) {
    const mensaje = 'T No se registró';
    if (tipo === 'marca' || tipo === 'jornada') {
      const mensajeMarca = 'T ';
      return mensajeMarca;
    } else {
      return mensaje;
    }
  } else {
    var fecha = new Date(fechaUTC);

    // Convertir la fecha a la hora local
    var fechaLocal = new Date(
      fecha.getTime() - fecha.getTimezoneOffset() * 60000
    );

    // Formatear la fecha local en el formato solicitado
    var anio = fechaLocal.getFullYear();
    var mes = ('0' + (fechaLocal.getMonth() + 1)).slice(-2);
    var dia = ('0' + fechaLocal.getDate()).slice(-2);
    var hora = ('0' + fechaLocal.getHours()).slice(-2);
    var minutos = ('0' + fechaLocal.getMinutes()).slice(-2);
    var segundos = ('0' + fechaLocal.getSeconds()).slice(-2);
    if (tipo === 'excel') {
      var fechaFormateadaExcel =
        anio +
        '-' +
        mes +
        '-' +
        dia +
        ' ' +
        hora +
        ':' +
        minutos +
        ':' +
        segundos;

      // Retornar la fecha formateada
      return fechaFormateadaExcel;
    } else if (tipo === 'jornada') {
      var fechaFormateadaMarca =
        anio +
        '-' +
        mes +
        '-' +
        dia +
        'T' +
        hora +
        ':' +
        minutos +
        ':' +
        segundos;

      // Retornar la fecha formateada
      return fechaFormateadaMarca;
    } else {
      var fechaFormateada =
        dia +
        '/' +
        mes +
        '/' +
        anio +
        'T' +
        hora +
        ':' +
        minutos +
        ':' +
        segundos;

      // Retornar la fecha formateada
      return fechaFormateada;
    }
  }
}

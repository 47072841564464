export function getStatusColor(status) {
  switch (status) {
    case 'Postulado':
      return 'default';
    case 'SeleccionadoXEmpresa':
      return 'warning';
    case 'ProvisionadoXEmpresa':
      return 'warning';
    case 'AceptadoXEmpresa':
      return 'processing';
    case 'PendienteFirmaXTrabajador':
      return 'processing';

    case 'AceptadoXTrabajador':
      return 'success';
    case 'ContratoCerrado':
      return 'success';
    case 'ContratoFirmadoXTrabajador':
      return 'success';
    case 'Ejecutando':
      return 'success';
    case 'Terminado':
      return 'success';
    case 'Validado':
      return 'success';

    case 'ContratoAnuladoXBackoffice':
      return 'error';
    case 'ContratoAnuladoXEmpresa':
      return 'error';
    case 'ContratoAnuladoXTrabajador':
      return 'error';
    case 'DescartadoXEmpresa':
      return 'error';
    case 'DesistidaXTrabajador':
      return 'error';
    case 'DescarteAutomatico':
      return 'error';
    case 'RechazadoXTrabajador':
      return 'error';
    default:
      return 'default';
  }
}

export function getStatusName(status) {
  switch (status) {
    case 'PendienteFirmaXTrabajador':
      //return 'Pendiente de firma';
      return 'Aceptado por la empresa';
    case 'AceptadoXEmpresa':
      return 'Aceptado por la empresa';
    case 'AceptadoXTrabajador':
      return 'Aceptado por el trabajador';
    case 'ContratoAnuladoXBackoffice':
      return 'Contrato anulado por backoffice';
    case 'ContratoAnuladoXEmpresa':
      return 'Contrato anulado por la empresa';
    case 'ContratoAnuladoXTrabajador':
      return 'Contrato anulado por el trabajador';
    case 'ContratoCerrado':
      return 'Contrato cerrado';
    case 'ContratoFirmadoXTrabajador':
      return 'Contrato firmado por el trabajador';
    case 'DescartadoXEmpresa':
      return 'Descartado por la empresa';
    case 'DesistidaXTrabajador':
      return 'Desistida por el trabajador';
    case 'Ejecutando':
      return 'Ejecutando';
    case 'Postulado':
      return 'Postulado';
    case 'ProvisionadoXEmpresa':
      return 'Provisionado por la empresa';
    case 'DescarteAutomatico':
      return 'Descarte Automatico';
    case 'RechazadoXTrabajador':
      return 'Rechazado por el trabajador';
    case 'SeleccionadoXEmpresa':
      return 'Seleccionado por la empresa';
    case 'Terminado':
      return 'Terminado';
    case 'Validado':
      return 'Validado';
    default:
      return 'Postulado';
  }
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "backoffice",
            "endpoint": "https://3pdn142zu3.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "dt",
            "endpoint": "https://7gblbsnvl0.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "empresa",
            "endpoint": "https://hjguezle0g.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "oferta",
            "endpoint": "https://bcd3lz1k27.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "postulacion",
            "endpoint": "https://49t075arbh.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "trabajador",
            "endpoint": "https://fy2iw35mi4.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "xinerlink",
            "endpoint": "https://uu812eyjz9.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vbc6w4njcndxxc24u25syzj3qu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wgwz4dwtrjasrf5cme2fldsb3e",
    "aws_cognito_identity_pool_id": "us-east-1:55942d6e-3912-4f68-b237-44ed01865838",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YrEqpBkeD",
    "aws_user_pools_web_client_id": "5csmmisbh3eitrchserfn1op13",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "xinerlink-repositorio25430-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import { Empty, Image, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import DotacionActions from './DotacionActions';
import { LoadingOutlined } from '@ant-design/icons';

export default function ListadoResponsive({ trabajadores = [] }) {
  if (!trabajadores)
    return (
      <div className="flex flex-col justify-center items-center h-52 space-y-2">
        <LoadingOutlined
          style={{
            color: '#1890ff',
            fontSize: '2rem',
          }}
        />
        <h3 className="text-gray-400 text-center text-xs">Buscando...</h3>
      </div>
    );

  if (trabajadores.length === 0)
    return (
      <div className="flex justify-center items-center h-52">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );

  return (
    <Space
      size="small"
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
      {trabajadores?.map((trabajador) => {
        return (
          <div
            key={trabajador.correo + trabajador.turno}
            className="p-4 rounded-md shadow-sm border border-gray-200 border-solid"
          >
            <div className="flex justify-between w-full items-center">
              <Space size="middle">
                <Image
                  width={45}
                  height={45}
                  src={trabajador.enlaceFoto}
                  alt={trabajador.correo}
                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                />

                <div>
                  <h3 className="text-xs font-bold mb-0">
                    {trabajador.nombre.split(' ')[0]}{' '}
                    {trabajador.primerApellido}
                  </h3>
                  <span className="text-xs">{trabajador.rut}</span>
                </div>
              </Space>
              <div className="text-right">
                <Tag
                  style={{
                    margin: 0,
                  }}
                >
                  #{trabajador.pais_consecutivoOferta.split('#')[1] || 'n/a'}
                </Tag>
                <p className="text-xs text-gray-400 mt-1 mb-0">
                  {trabajador.tipoTrabajo} el{' '}
                  {dayjs(trabajador.entrada + 'Z').format('DD/MM/YYYY')}
                </p>
              </div>
            </div>
            <div className="mt-4 text-xs text-gray-500 flex justify-between">
              <div>
                <h5 className="mb-0 font-bold">Entrada</h5>
                <span>{dayjs(trabajador.entrada + 'Z').format('HH:mm')}</span>
              </div>
              <div className="mr-auto ml-2">
                <h5 className="mb-0 font-bold">Salida</h5>
                <span>{dayjs(trabajador.salida + 'Z').format('HH:mm')}</span>
              </div>
              <DotacionActions record={trabajador} />
            </div>
          </div>
        );
      })}
    </Space>
  );
}

import React from 'react';
import image from '../../assets/images/xinerlink-icon.png';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Loading() {
  return (
    <div className="h-screen flex items-center justify-center select-none">
      <div className="flex flex-col items-center">
        <img src={image} alt="logo" className="w-12 animate-bounce mb-4" />
        <div className="flex space-x-2 items-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
          <span className="text-sm animate-pulse text-gray-400 sr-only">
            Cargando
          </span>
        </div>
      </div>
    </div>
  );
}

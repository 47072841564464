import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { getConfiguracionEmpresa } from './EmpresaService';

export async function checkUser() {
  const current = await Auth.currentSession();
  const { data: dataUsuario } = await API.get('empresa', '/usuarioPermisos', {
    headers: {},
    response: true,
    queryStringParameters: { userToken: current.accessToken.jwtToken },
  });
  const { existe, registro: user } = dataUsuario;
  if (!existe) throw new Error('No existe el usuario');
  if (!user.activo) throw new Error('El usuario no está activo');
  updateUserAttributes(); // No se espera a que termine

  // revisar si tengo una empresa en mi local storage
  // si no la tengo, hacer la consulta
  const empresaId =
    localStorage.getItem('MantenedorEmpresaId') || user.empresaId;

  const responses = await Promise.all([
    API.graphql(
      graphqlOperation(queries.getEmpresa, {
        empresaId,
      })
    ),
    await getConfiguracionEmpresa(empresaId),
  ]);
  return {
    user,
    empresa: {
      ...responses[0].data.getEmpresa,
      configuracionEmpresa: responses[1],
    },
  };
}

export async function loginUser(values) {
  await Auth.signIn(values.username, values.password);
  return await checkUser();
}

export async function logoutUser() {
  await Auth.signOut();
}

async function updateUserAttributes() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:app': 'Cliente', //Trabajador - Backoffice
    });
  } catch (error) {
    console.error(error);
  }
}

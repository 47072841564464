import { CloudDownloadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { getExcelCandidates } from '../../../../services/BackofficeService';
import { useState } from 'react';
import { download } from './download';
import isHired from '../../../../utils/isHired';

export default function DownloadExcel({ solicitud }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [loading, setLoading] = useState(false);

  async function handleDownload(hired = true) {
    try {
      setLoading(true);
      const allCandidates = await getExcelCandidates(
        enterprise.empresaId,
        solicitud.pais_consecutivoOferta
      );
      if (!hired) {
        download(
          allCandidates,
          `Candidatos ${solicitud.pais_consecutivoOferta}`
        );
        message.success(`Descargando candidatos`);
      } else {
        const candidates = allCandidates.filter((c) => isHired(c.estadoActual));
        if (!candidates.length)
          return message.error(`No hay candidatos contratados para descargar`);
        download(candidates, `Contratados ${solicitud.pais_consecutivoOferta}`);
        message.success(`Descargando candidatos contratados`);
      }
    } catch (error) {
      console.error(error);
      message.error('Error al descargar candidatos');
    } finally {
      setLoading(false);
    }
  }

  if (enterprise.empresaId !== process.env.REACT_APP_ID_ENJOY) return null;
  return (
    <Button.Group>
      <Button
        data-cy="botonDescargarTodos"
        icon={<CloudDownloadOutlined />}
        onClick={() => handleDownload(false)}
        loading={loading}
      >
        Descargar Todos
      </Button>
      <Button
        data-cy="botonDescargarContratados"
        icon={<DownloadOutlined />}
        onClick={handleDownload}
        loading={loading}
      >
        Descargar Contratados
      </Button>
    </Button.Group>
  );
}

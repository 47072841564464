import { useState } from 'react';
import { Storage } from 'aws-amplify';
import { logoutUser } from '../../../services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../../lib/features/auth';
import { Avatar, Button, Dropdown, Space } from 'antd';
import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';

export default function NameDropdown() {
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);
  async function getLogo() {
    const path = await Storage.get(enterprise.enlaceLogo);
    setLogo(path);
  }
  getLogo();

  const items = [
    {
      key: '1',
      label: 'Mi Perfil',
      icon: <UserOutlined />,
      disabled: true,
    },
    {
      key: '2',
      label: 'Ajustes Empresa',
      icon: <SettingOutlined />,
      disabled: true,
    },
    // {
    //   key: '3',
    //   label: 'Ajustes',
    //   icon: <SettingOutlined />,
    //   disabled: true,
    // },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: 'Cerrar sesión',
      icon: <LogoutOutlined />,
      onClick: () => {
        logoutUser();
        dispatch(signOut());
      },
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button
        style={{
          height: '45px',
          padding: '5px',
        }}
        type="ghost"
      >
        <Space>
          <Avatar icon={<UserOutlined />} src={logo} />
          <span>{user.nombre}</span>
          <DownOutlined className="opacity-50" />
        </Space>
      </Button>
    </Dropdown>
  );
}

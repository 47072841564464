import React from 'react';
import styles from './jsonStyles.json';
import TablainfoPersonas from './atoms/TablainfoPersonas';
import FormatDateV2 from '../../../altUtils/FormatDateV2';
import sumarRestarHorasExtraordinarias from '../../../altUtils/sumarRestarHorasExtraordinarias';

export default function FormatoReporteJornada({ dataArray }) {
  const cacularTotal = (record) => {
    const arrayreportesemana = [];

    if (record.length !== 0) {
      record.forEach((item) => {
        const nexElement = item;

        if (
          item.jornadaReal.entrada !== null &&
          item.jornadaReal.salida !== null
        ) {
          let h1 = item.jornadaReal.salida.split('T')[1].trim();
          let h2 = item.jornadaReal.entrada.split('T')[1].trim();

          let totalHora = sumarRestarHorasExtraordinarias.restarHoras(h1, h2);

          if (totalHora.charAt(0) === '-') {
            nexElement.diferenciaHoras =
              sumarRestarHorasExtraordinarias.sumarHoras(totalHora, '23:59:99');
          } else {
            nexElement.diferenciaHoras = totalHora;
          }
        } else {
          nexElement.diferenciaHoras = '00:00:00';
        }

        arrayreportesemana.push(nexElement);
      });
    }

    const totalDiferenciasHoras =
      sumarRestarHorasExtraordinarias.sumarRestarHorasExtraordinariasArray(
        arrayreportesemana
      );

    let totalSuma = '00:00:00';
    let [h1, m1, s1] = totalDiferenciasHoras.split(':').map(Number);

    if (h1 >= 45) {
      h1 = h1 - 45;

      totalSuma = sumarRestarHorasExtraordinarias.formatoHora(h1, m1, s1);
    }

    return totalSuma;
  };
  return (
    <div style={styles.tableReponsive}>
      <h4 style={{ font: ' 11pt Arial' }}> Reporte de Jornada Diaria</h4>
      {dataArray.map((item) => (
        <div key={item.key} id={item.key} className="table-responsive">
          <br />
          <br />
          <TablainfoPersonas item={item}></TablainfoPersonas>

          {item.reporte.map((task, index) => (
            <table style={styles.table} key={index}>
              <tr
                style={{
                  backgroundColor: 'DARKblue',
                  color: 'white',
                  border: '1px solid',
                  height: '50px',
                  font: ' 11pt Arial',
                }}
              >
                <th style={styles.td2}>Fecha</th>
                <th style={styles.td2}>Jornada</th>
                <th style={styles.td2}>Jornada Real</th>
                <th style={styles.td2}>Colación</th>
                <th style={styles.td2}>Colación Real</th>
                <th style={styles.td2}>Anomalias</th>
                <th style={styles.td2}>Horas Extraordinarias</th>
                <th style={styles.td2}>Observaciones</th>
              </tr>
              {task.map((element, index) => (
                <tr
                  key={index}
                  style={{
                    border: '1px solid',
                    height: 'auto',
                    font: ' 11pt Arial',
                  }}
                >
                  <td style={styles.td2}>
                    {FormatDateV2(element.key).split('T')[0]}
                  </td>
                  <td style={styles.td2}>
                    <div className="aling-center">
                      {element.jornada !== null ? (
                        <p>
                          {FormatDateV2(element.jornada.entrada, 'jornada')
                            .split('T')[1]
                            .slice(0, 5)}{' '}
                          -{' '}
                          {FormatDateV2(element.jornada.salida, 'jornada')
                            .split('T')[1]
                            .slice(0, 5)}
                        </p>
                      ) : (
                        <p> - </p>
                      )}
                    </div>
                  </td>
                  <td style={styles.td2}>
                    <div className="aling-center">
                      <p>
                        {
                          FormatDateV2(
                            element.jornadaReal.entrada,
                            'marca'
                          ).split('T')[1]
                        }{' '}
                        -{' '}
                        {
                          FormatDateV2(
                            element.jornadaReal.salida,
                            'marca'
                          ).split('T')[1]
                        }
                      </p>
                    </div>
                  </td>
                  <td style={styles.td2}>{element.colacion}</td>
                  <td style={styles.td2}>{element.colacionReal}</td>
                  <td style={styles.td2}>
                    <div className="aling-center">
                      <p>Ingreso:</p>
                      <p>
                        {FormatDateV2(element.anomalias.entrada).split('T')[1]}
                      </p>
                      <p> Salida:</p>
                      <p>
                        {FormatDateV2(element.anomalias.salida).split('T')[1]}
                      </p>
                    </div>
                  </td>
                  <td style={styles.td2}>
                    {element.horasExtraordinarias || '00:00:00'}
                  </td>
                  <td style={styles.td2}>{element.comentarios}</td>
                </tr>
              ))}

              <tr style={{ height: '60px' }}>
                <td colspan="6" style={styles.tdtotales}>
                  Total Horas Extraordinarias Semanales:
                </td>

                <td colspan="2" style={styles.td2}>
                  {' '}
                  {cacularTotal(task)}
                </td>
              </tr>
              <tr style={{ height: '60px' }}>
                <td colspan="6" style={styles.tdtotales}>
                  Compensación:
                </td>

                <td colspan="2" style={styles.td2}>
                  {' '}
                  {cacularTotal(task) === '00:00:00' ? <>No</> : <>Si</>}
                </td>
              </tr>
            </table>
          ))}
        </div>
      ))}
    </div>
  );
}

import { Link } from 'react-router-dom';
import { Button, Empty, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import NotificationCard from './NotificationCard';
import { markAsRead } from '../../../../services/NotificationsService';

export default function NotificationsList({
  notifications,
  setNotifications,
  leerTodas,
}) {
  async function handleClick(notification) {
    try {
      const request = markAsRead(notification);
      await request;
      const clickedId = notification.createdAt + notification.urlDestino;
      const newNotifications = notifications.filter((item) => {
        const customId = item.createdAt + item.urlDestino;
        return customId !== clickedId;
      });
      setNotifications(newNotifications);
    } catch (error) {
      console.error(error);
      message.error('Error al marcar la notificación como leída');
    }
  }

  return (
    <div>
      <div
        className="cursor-pointer flex text-xs text-gray-500 space-x-2 -mt-7 mb-4 justify-end items-center"
        onClick={leerTodas}
      >
        <span>Leer todas</span>
        <CheckCircleOutlined />
      </div>
      <div className="w-96 max-h-96 overflow-y-auto scrollbar-hide mb-2">
        {notifications.length === 0 ? (
          <Empty
            description="No tienes notificaciones sin leer"
            className="p-10 mx-auto"
          />
        ) : (
          notifications.map((notification) => {
            const customId = notification.createdAt + notification.urlDestino;
            return (
              <NotificationCard
                key={customId}
                notification={notification}
                handleClick={handleClick}
              />
            );
          })
        )}
      </div>
      <Link to="/notificaciones">
        <Button type="link" block>
          Ver todas las notificaciones
        </Button>
      </Link>
    </div>
  );
}

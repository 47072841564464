import useWindowWidth from '../../../hooks/useWindowWidth';
import { Button, Divider, Form, Input, Tooltip } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

export function Buscador({ trabajadores, setSearch, search }) {
  const windowWidth = useWindowWidth();
  const [form] = Form.useForm();

  function handleReset() {
    setSearch({
      trabajador: null,
      oferta: null,
    });
    form.resetFields();
  }

  if (trabajadores?.length > 0)
    return (
      <div className="mb-5">
        <Divider />
        <Form layout={windowWidth > 768 ? 'inline' : 'vertical'} form={form}>
          <Form.Item
            label="Buscar Trabajador"
            name="trabajador"
            style={{ marginBottom: 10 }}
          >
            <Input
              placeholder="Nombre Apellido / 000000-0 / @"
              value={search?.trabajador}
              onChange={(e) =>
                setSearch({ ...search, trabajador: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="# Oferta"
            name="oferta"
            style={{ marginBottom: 10 }}
          >
            <Input
              placeholder="---"
              style={{
                width: 80,
              }}
              value={search?.oferta}
              onChange={(e) => {
                // only numbers
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setSearch({ ...search, oferta: e.target.value });
                }
              }}
            />
          </Form.Item>
          <Tooltip title="Limpiar Búsqueda">
            <Button
              type="dashed"
              onClick={handleReset}
              icon={<ClearOutlined />}
            />
          </Tooltip>
        </Form>
      </div>
    );
}

import { useState } from 'react';
import { Button, Space, Tooltip } from 'antd';
import {
  IdcardOutlined,
  WhatsAppOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import CandidateCard from './CandidateCard';
import DownloadDocuments from './DownloadDocuments';
import SelectCandidate from './SelectCandidate';
import isHired from '../../../../../utils/isHired';
import CandidatePostulation from './CandidatePostulation';
import RejectCandidate from './RejectCandidate';
import DiscardCandidate from './DiscardCandidate';
import { useSelector } from 'react-redux';
import ComentariosTrabajador from '../../../../../components/comentarios/ComentariosTrabajador';

export default function CandidatesActions({
  candidate,
  refreshCandidates,
  solicitud,
}) {
  const [showCard, setShowCard] = useState(false);
  const { enterprise } = useSelector((state) => state.enterprise);
  const [showPostulation, setShowPostulation] = useState(false);

  const requiereValidados = Boolean(
    enterprise.configuracionEmpresa?.postulacion?.validaciones?.Postulado?.[
      '/solicitudes'
    ]?.trabajadorValidado ?? false
  );

  return (
    <>
      <Space size="small">
        <Tooltip title="Ver información">
          <Button
            type="text"
            icon={<IdcardOutlined />}
            onClick={() => setShowCard(true)}
          />
        </Tooltip>
        <ComentariosTrabajador
          trabajador={candidate}
          comentariosEmpresaTrabajador={candidate.comentariosEmpresaTrabajador}
        />
        {candidate?.perfil?.requisitosTrabajador.length > 0 && (
          <Tooltip title="Ver Postulación">
            <Button
              type="text"
              icon={<PaperClipOutlined />}
              onClick={() => setShowPostulation(true)}
            />
          </Tooltip>
        )}
        {isHired(candidate.estadoActual) && (
          <>
            <DownloadDocuments candidate={candidate} />
            <ContactActions candidate={candidate} />
          </>
        )}
        {requiereValidados && !candidate.validadoXEST
          ? null
          : candidate.activo &&
            candidate.postulacionesActivas.AceptadoXTrabajador.length < 1 && (
              <SelectCandidate
                candidate={candidate}
                refreshCandidates={refreshCandidates}
              />
            )}
        {candidate.activo &&
        candidate.estadoActual === 'AceptadoXTrabajador' &&
        solicitud.tipoContrato === 'ContratoObraFaena' ? (
          <DiscardCandidate
            candidate={candidate}
            refreshCandidates={refreshCandidates}
          />
        ) : null}

        <RejectCandidate
          candidate={candidate}
          refreshCandidates={refreshCandidates}
        />
      </Space>
      <CandidateCard
        candidate={candidate}
        show={showCard}
        close={() => setShowCard(false)}
      />
      <CandidatePostulation
        candidate={candidate}
        show={showPostulation}
        close={() => setShowPostulation(false)}
      />
    </>
  );
}

function ContactActions({ candidate }) {
  // function to open new tab with any url
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Space size="small">
      <Tooltip title="Chatear en Whastapp">
        <Button
          type="text"
          icon={<WhatsAppOutlined />}
          onClick={() =>
            openInNewTab(
              'https://wa.me/' + candidate?.perfil?.telefono.replace('+', '')
            )
          }
        />
      </Tooltip>
      {/* <Tooltip title="Enviar Correo">
        <Button
          type="text"
          icon={<MailOutlined />}
          onClick={() => openInNewTab('mailto:' + candidate?.correo)}
        />
      </Tooltip>
      <Tooltip title="Llamar">
        <Button
          type="text"
          icon={<PhoneOutlined />}
          onClick={() => openInNewTab('tel:' + candidate?.perfil?.telefono)}
        />
      </Tooltip> */}
    </Space>
  );
}

import dayjs from 'dayjs';
import { Space, Tag, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import {
  getStatusColor,
  getStatusName,
} from '../../../../utils/getStatusCandidate';
import CandidatesActions from './extras/CandidatesActions';
import CandidateOthers from './extras/CandidateOthers';

export function columns(refreshCandidates, id, solicitud) {
  return [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 250,
      render: (text, record) => (
        <div className={!record.activo && 'text-red-500'}>
          {!record.validadoXEST && (
            <Tooltip title="Perfil no validado">
              <WarningOutlined className="text-red-500 mr-2" />
            </Tooltip>
          )}

          {`${record.nombre} ${record.primerApellido}`}
          {!record.activo && (
            <Tag color="red" className="ml-2">
              Suspendido
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'RUT',
      dataIndex: 'dni',
      key: 'dni',
      width: 100,
    },
    {
      title: 'Estado',
      dataIndex: 'estadoActual',
      key: 'estadoActual',
      width: 200,
      filters: [
        { text: 'Postulado', value: 'Postulado' },
        { text: 'Seleccionado', value: 'SeleccionadoXEmpresa' },
        { text: 'Aceptado Empresa', value: 'AceptadoXEmpresa' },
        { text: 'Aceptado Trabajador', value: 'AceptadoXTrabajador' },
        { text: 'Ejecutando', value: 'Ejecutando' },
        { text: 'Terminado', value: 'Terminado' },
        { text: 'Rechazado', value: 'Descarte Automatico' },
        { text: 'Desistido', value: 'DesistidaXTrabajador' },
        { text: 'Descartado', value: 'DescartadoXEmpresa' },
      ],
      onFilter: (value, record) => record.estadoActual.indexOf(value) === 0,
      render: (text, record) => (
        <Space size={5}>
          {record.tipoPostulacion !== 'Normal' && (
            <Tooltip title="Tipo de postulación">
              <Tag
                color={
                  record.tipoPostulacion === 'Invitado' ? 'green' : 'black'
                }
              >
                {record.tipoPostulacion}
              </Tag>
            </Tooltip>
          )}
          <Tag color={getStatusColor(text)}>{getStatusName(text)}</Tag>
          <CandidateOthers
            postulaciones={record.postulacionesActivas}
            id={id?.split('#')[1] || 0}
          />
        </Space>
      ),
    },
    {
      title: 'Fecha de postulación',
      dataIndex: ['historialEstados', 'Postulado'],
      key: 'fechaPostulacion',
      width: 200,
      render: (text) => dayjs(text).format('DD/MM/YYYY HH:mm'),
      sortDirections: ['descend'],
      sorter: (a, b) =>
        dayjs(a.historialEstados.Postulado).isAfter(
          dayjs(b.historialEstados.Postulado)
        ),
    },
    {
      title: 'Experiencia',
      dataIndex: 'contadorTrabajos',
      key: 'contadorTrabajos',
      width: 140,
      sorter: (a, b) =>
        a.record?.asistencia?.cantidad - b.record?.asistencia?.cantidad,
      render: (text, record) => (
        <div className="text-xs">
          <b>{record?.asistencia?.cantidad}</b> trabajo(s) <br />
          <b>
            {(record?.asistencia?.cumplimiento * 100).toFixed(2) === '0.00'
              ? 0
              : (record?.asistencia?.cumplimiento * 100).toFixed(2)}
            %
          </b>{' '}
          de asistencia
        </div>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'acciones',
      key: 'acciones',
      width: 150,
      render: (text, record) => (
        <CandidatesActions
          candidate={record}
          refreshCandidates={refreshCandidates}
          solicitud={solicitud}
        />
      ),
    },
  ];
}

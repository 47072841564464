import { useState } from 'react';
import { Badge, Button, message } from 'antd';
import { firmarContrato } from '../../../services/ContratosService';
import { Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import dayjs from 'dayjs';
import { getBase64 } from '../../../utils/downloadDocuments';

export default function FirmaMasiva({
  firmar,
  seleccion,
  setSeleccion,
  actualizar,
}) {
  const [loading, setLoading] = useState(false);
  async function handleFirmar() {
    try {
      setLoading(true);
      const formato = seleccion.map((contrato) => {
        return {
          empresaId: contrato.empresaId,
          pais_consecutivoOferta: contrato.pais_consecutivoOferta,
          trabajadorId: contrato.correo,
        };
      });
      await firmarContrato(formato[0].empresaId, formato);
      message.success(`Contratos firmados exitosamente`);
      setSeleccion([]);
      actualizar();
    } catch (error) {
      console.error(error);
      message.error('Error al firmar contratos');
    } finally {
      setLoading(false);
    }
  }

  async function handleDescargar() {
    if (seleccion.length < 1)
      return message.error('No hay contratos seleccionados');
    const formato = seleccion.map(async (contrato) => {
      return {
        nombre:
          contrato.pais_consecutivoOferta +
          '#' +
          contrato.nombre +
          '#' +
          contrato.dni,
        url: await Storage.get(contrato.pathContratos.contratoAnpdDefinitivo),
      };
    });
    const contratos = await Promise.all(formato);
    const urls = await Promise.all(
      contratos.map(async ({ nombre, url }) => ({
        nombre: `${nombre}.pdf`,
        base64: getBase64(url),
      }))
    );
    const zip = new JSZip();
    urls.forEach(({ nombre, base64 }) =>
      zip.file(nombre, base64, { base64: true })
    );
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `Contratos - ${dayjs().format('DD-MM-YYYY HH:mm:ss')}`);
    });
    message.success('Contratos descargados exitosamente');
  }

  return (
    <div className="mb-5 flex justify-end space-x-5">
      <Button onClick={handleDescargar} disabled={seleccion.length < 1}>
        Descargar Seleccionados
      </Button>
      {firmar && (
        <Badge count={seleccion.length}>
          <Button
            disabled={seleccion.length < 1}
            type="primary"
            onClick={handleFirmar}
            loading={loading}
          >
            Firmar Seleccionados
          </Button>
        </Badge>
      )}
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Badge, Button, Popover, Spin, message } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import NotificationsList from './notifications/NotificationList';
import {
  getNotifications,
  markAsRead,
  subscribeToNotifications,
} from '../../../services/NotificationsService';
import { useSelector } from 'react-redux';

export default function Notifications() {
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    getNotificaciones();
    const subscription = notificationSubscribe();
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprise]);

  async function getNotificaciones() {
    try {
      const response = await getNotifications(enterprise, {
        leida: { eq: false },
      });
      setNotifications(response);
    } catch (error) {
      console.error(error);
      message.error('Error al obtener las notificaciones');
    }
  }

  function notificationSubscribe() {
    const notificationTypes = {
      alarma: 'warning',
      informativa: 'info',
      check: 'success',
    };
    function callback(notification) {
      if (notification.destinatarioId === enterprise.empresaId) {
        message[notificationTypes[notification.tipoNotificacion]](
          notification.mensaje
        );
        setNotifications((prevNotifications) => [
          notification,
          ...prevNotifications,
        ]);
      }
    }
    if (!user) return null;
    return subscribeToNotifications(callback);
  }

  async function leerTodas() {
    try {
      const promises = notifications.map((notification) => {
        return markAsRead(notification);
      });
      await Promise.all(promises);
      setNotifications([]);
    } catch (error) {
      console.error(error);
      message.error('Error al marcar las notificaciones como leídas');
    }
  }

  return (
    <Popover
      content={
        notifications ? (
          <NotificationsList
            notifications={notifications}
            setNotifications={setNotifications}
            leerTodas={leerTodas}
          />
        ) : (
          <GettingNotifications />
        )
      }
      title="Notificaciones"
      trigger="click"
    >
      <Badge count={notifications?.length || 0}>
        <Button type="text" icon={<BellOutlined />} />
      </Badge>
    </Popover>
  );
}

function GettingNotifications() {
  return (
    <div className="p-10 w-80 text-center">
      <Spin />
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRequisitions } from '../../../services/RequisitionsService';
// components
import { Card, message } from 'antd';
import { PageContainer } from '@ant-design/pro-components';
import itemRender from '../../../components/layouts/components/itemRender';
import Table from './components/Table';
import Filter from '../components/Filter';
import Meta from '../../../components/layouts/Meta';

const initialFilters = {
  estado: null,
  cargo: null,
  motivo: null,
  fechas: null,
  id: null,
};

export default function ArchivoHistorico() {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [solicitudes, setSolicitudes] = useState(null);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    buscarSolicitudes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function buscarSolicitudes() {
    try {
      setSolicitudes(null);
      const res = await getRequisitions(
        enterprise.empresaId,
        'Desierta,Finalizada,Guardada,EsperandoFacturacion,FacturaGenerada,FacturaEnviada,Facturada,'
      );
      setSolicitudes(res);
    } catch (error) {
      setSolicitudes([]);
      message.error('Error al obtener las solicitudes');
    }
  }

  return (
    <PageContainer
      header={{
        title: 'Archivo Histórico',
        ghost: true,
        breadcrumb: {
          routes: [
            { path: '/solicitudes', breadcrumbName: 'Solicitudes' },
            { path: '/archivo', breadcrumbName: 'Archivo' },
          ],
          itemRender,
        },
      }}
    >
      <Meta title="Archivo Histórico" />
      <Card>
        <Filter
          buscarSolicitudes={buscarSolicitudes}
          setFilters={setFilters}
          initialFilters={initialFilters}
          estados={[
            { value: 'Guardada', label: 'Guardada' },
            { value: 'Desierta', label: 'Desierta' },
            { value: 'EsperandoFacturacion', label: 'Esperando Facturación' },
            { value: 'FacturaGenerada', label: 'Factura Generada' },
            { value: 'FacturaEnviada', label: 'Factura Enviada' },
            { value: 'Facturada', label: 'Facturada' },
            { value: 'Finalizada', label: 'Finalizada' },
          ]}
        />
        <Table
          solicitudes={solicitudes}
          filters={filters}
          buscarSolicitudes={buscarSolicitudes}
        />
      </Card>
    </PageContainer>
  );
}

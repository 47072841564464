import React, { useState, useEffect } from 'react';
import { Table, Skeleton } from 'antd';

import BuscadorReportes from '../../../components/buscador/BuscadorReportes';
import TablaPrueba from './components/TablaPrueba';
import TablaAsistenciaInfo from '../asistencia/components/TablaAsistenciaInfo';
import BotonExportarPdf from '../../../components/botones/BotonExportarPdf';
import BotonExportarWord from '../../../components/botones/BotonExportarWord';
import BotonExportarExcel from '../../../components/botones/BotonExportarExcel';

// import FormatoReporteFestivos from "../../../components/botones/components/FormatoReporteFestivos";

import HeaderReportes from '../../../components/botones/headerReportes';

export default function DomigosFestivos() {
  const [firstTable, setFirstTable] = useState([]);
  const [exportarWord, setexportarWord] = useState(false);
  const [exportarPdf, setexportarPdf] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [exportarExcel, setexportarExcel] = useState(false);

  // const [datosprueba] = useState([
  //   [
  //     {
  //       key: "1",
  //       name: "Juan",
  //       observacion: "",
  //       total: 200,
  //       fecha: "2023-02-12",
  //       beneficiario: true,
  //       asistencia: false,
  //     },
  //     {
  //       key: "2",
  //       name: "Pedro",
  //       observacion: "",
  //       total: 200,
  //       fecha: "2023-02-12",
  //       beneficiario: true,
  //       asistencia: true,
  //     },
  //     {
  //       key: "3",
  //       name: "María",
  //       total: 200,
  //       fecha: "2023-02-12",
  //       beneficiario: true,
  //       asistencia: true,
  //     },
  //   ],
  //   [
  //     {
  //       key: "1",
  //       name: "Juan",
  //       observacion: "",
  //       total: 200,
  //       fecha: "2023-02-12",
  //       beneficiario: true,
  //       asistencia: false,
  //     },
  //     {
  //       key: "2",
  //       name: "Pedro",
  //       observacion: "",
  //       total: 200,
  //       fecha: "2023-02-12",
  //       beneficiario: true,
  //       asistencia: true,
  //     },
  //     {
  //       key: "3",
  //       name: "María",
  //       total: 200,
  //       fecha: "2023-02-12",
  //       beneficiario: true,
  //       asistencia: true,
  //     },
  //   ],
  // ]);

  const [jobsAreLoading, setJobsAreLoading] = useState(true);
  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
    },
  };

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-10">
      <HeaderReportes
        name="Reporte domingos y festivos trabajados"
        setexportarExcel={setexportarExcel}
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        firstTable={DatosSeleccionados}
        DatosSeleccionados={DatosSeleccionados}
      />

      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="festivos"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
      />

      {jobsAreLoading ? (
        <Skeleton />
      ) : (
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowKey="key"
          columns={TablaAsistenciaInfo(firstTable)}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                {record.reporte.length === 0 && (
                  <div className="mx-auto mt-1" style={{ maxWidth: '70rem' }}>
                    <p>
                      La jornada de este trabajador no incluye domingos y
                      festivos
                    </p>
                  </div>
                )}
                {record.reporte.length !== 0 && (
                  <TablaPrueba items={record.reporte || []} />
                )}
              </div>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
          dataSource={firstTable}
          scroll={{ x: true }}
        />
      )}

      {exportarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="festivos"
          name={`Reporte Domingos y festivos_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}

      {exportarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="festivos"
          name={`Reporte Domingos y festivos_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}
      {exportarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="festivos"
          name={`Reporte Domingos y festivos__${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}
    </div>
  );
}

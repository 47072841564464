import {
  ClearOutlined,
  CloseCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Space, Image, Table, Tag, Tooltip, Button, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleClose } from './functions/removeTags';
import { getFiltereds } from './functions/getFiltereds';
import { removeTrabajador } from './functions/removeTrabajador';
import ComentariosTrabajador from '../../../components/comentarios/ComentariosTrabajador';

export default function TablaTrabajadores({
  trabajadores,
  filters,
  refresh,
  handleSelect,
  seleccionados,
}) {
  const [data, setData] = useState(trabajadores);
  const { enterprise } = useSelector((state) => state.enterprise);
  useEffect(() => {
    filterTrabajadores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trabajadores, filters]);

  function filterTrabajadores() {
    if (!trabajadores) return;
    const filtered = getFiltereds(trabajadores, filters);
    setData(filtered);
  }

  return (
    <Table
      dataSource={data}
      loading={!trabajadores}
      rowKey={(record) => record.trabajadorId}
      rowSelection={{
        onSelect: (record, selected) => {
          handleSelect(record, selected);
        },
        selectedRowKeys: seleccionados.map((s) => s.trabajadorId),
        onSelectAll: (selected, selectedRows, changeRows) => {
          const rowsToSelect = selected
            ? selectedRows.filter((i) => i !== undefined)
            : changeRows;
          handleSelect(rowsToSelect, selected, true);
        },
      }}
      scroll={{ x: 1100 }}
      columns={[
        {
          title: 'Nombre',
          dataIndex: 'nombre',
          key: 'nombre',
          width: 400,
          render: (text, record) => (
            <Space size="middle">
              <Image
                width={45}
                height={45}
                src={record.enlaceFoto}
                alt={record.correo}
                style={{ objectFit: 'cover', borderRadius: '50%' }}
              />

              <div>
                <h3 className="text-sm font-bold mb-0">
                  {record.nombre} {record.primerApellido}{' '}
                  {record.segundoApellido}
                </h3>
                <span className="text-xs">{record.dni}</span>
              </div>
            </Space>
          ),
        },
        {
          title: 'Edad',
          dataIndex: 'edad',
          key: 'edad',
          width: 75,
        },
        {
          title: 'Contacto',
          dataIndex: 'contacto',
          key: 'contacto',
          width: 200,
          render: (text, record) => (
            <div className="text-xs flex flex-col">
              <a href={`mailto:${record.trabajadorId}`} className="text-black">
                <Space>
                  <MailOutlined />
                  <span>{record.trabajadorId}</span>
                </Space>
              </a>
              <Space>
                <PhoneOutlined />
                <span>{record.telefono || 'Sin teléfono'}</span>
              </Space>
            </div>
          ),
        },
        {
          title: 'Etiquetas',
          dataIndex: 'etiquetas',
          key: 'etiquetas',
          render: (text, record) => (
            <Space size="small">
              {record.etiquetas.map((etiqueta) => (
                <TagRemover
                  key={etiqueta.etiquetaId}
                  etiqueta={etiqueta}
                  refresh={refresh}
                  user={record}
                />
              ))}
            </Space>
          ),
        },
        {
          title: 'Experiencia',
          dataIndex: 'contadorTrabajos',
          key: 'contadorTrabajos',
          width: 140,
          sorter: (a, b) =>
            a.record?.asistencia?.cantidad - b.record?.asistencia?.cantidad,
          render: (text, record) => (
            <div className="text-xs">
              <b>{record?.asistencia?.cantidad}</b> trabajo(s) <br />
              <b>
                {(record?.asistencia?.cumplimiento * 100).toFixed(2) === '0.00'
                  ? 0
                  : (record?.asistencia?.cumplimiento * 100).toFixed(2)}
                %
              </b>{' '}
              de asistencia
            </div>
          ),
        },
        {
          title: 'Acciones',
          dataIndex: 'acciones',
          key: 'acciones',
          width: 150,
          render: (text, record) => (
            <Space size="small">
              <ComentariosTrabajador trabajador={record} />
              <Popconfirm
                title="¿Desea eliminar el trabajador?"
                onConfirm={() =>
                  removeTrabajador(record.trabajadorId, refresh, enterprise)
                }
                okText="Si"
                cancelText="No"
              >
                <Tooltip title="Eliminar trabajador">
                  <Button danger type="text" icon={<ClearOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Space>
          ),
        },
      ]}
    />
  );
}

function TagRemover({ etiqueta, refresh, user }) {
  return (
    <Tag
      color={etiqueta.color}
      onClose={(e) => handleClose(e, user, etiqueta, refresh)}
      closable
      closeIcon={<CloseCircleOutlined color={etiqueta.colorTexto} />}
    >
      <span style={{ color: etiqueta.colorTexto }}>{etiqueta.nombre}</span>
    </Tag>
  );
}

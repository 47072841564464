import { Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { getPlantillas } from '../../../../services/RequisitionsService';
import { useSelector } from 'react-redux';
import { getEtiquetas } from '../../../../services/EtiquetasService';

export default function SelectorBase({
  solicitud,
  setSolicitud,
  setDataSolicitud,
  setEtiquetas,
}) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [solicitudes, setSolicitudes] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChangeSolicitud(value) {
    setSolicitud(null);
    setDataSolicitud(null);
    // await 1 second
    await new Promise((resolve) => setTimeout(resolve, 10));
    const getSolicitud = solicitudes.find(
      (s) => s.pais_consecutivoOferta === value
    );
    setDataSolicitud(getSolicitud);
    setSolicitud(value);
  }

  async function getData() {
    try {
      setSolicitud(null);
      setSolicitudes(null);
      setDataSolicitud(null);
      setEtiquetas(null);
      setIsLoading(true);
      const [res, resEtiquetas] = await Promise.all([
        getPlantillas(enterprise.empresaId),
        getEtiquetas(enterprise.empresaId),
      ]);

      setIsLoading(false);
      setSolicitudes(res);
      setEtiquetas(resEtiquetas);
    } catch (error) {
      setSolicitudes([]);
      setEtiquetas([]);
      setIsLoading(false);
      message.error('Error al obtener las solicitudes');
    }
  }

  return (
    <div>
      <>
        <b>Selecciona una plantilla...</b>
        <div className="my-4 space-y-4 lg:space-y-0 lg:flex justify-between">
          <Select
            placeholder={`Selecciona una plantilla base...`}
            loading={isLoading}
            disabled={isLoading}
            showSearch
            optionFilterProp="children"
            value={solicitud}
            filterOption={(input, option) => {
              const search = solicitudes?.find(
                (s) => s.pais_consecutivoOferta === option.value
              );
              return `#${search?.idOferta} - ${search?.tipoTrabajo}`
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase());
            }}
            style={{
              width: '100%',
              maxWidth: 300,
            }}
            onChange={handleChangeSolicitud}
          >
            {solicitudes
              ?.filter((i) => i.tipoTrabajo)
              .map(
                ({
                  idOferta,
                  tipoTrabajo,
                  pais_consecutivoOferta,
                  nombrePlantilla,
                }) => (
                  <Select.Option
                    key={pais_consecutivoOferta}
                    value={pais_consecutivoOferta}
                  >
                    {nombrePlantilla}
                  </Select.Option>
                )
              )}
          </Select>
        </div>
      </>
      <div className="text-gray-400 text-xs">
        <p>
          <b>Nota: </b>
          Se copiarán los datos de la plantilla base, excepto el motivo de
          contrato, los horarios, las vacantes y el monto a pagar.
        </p>
      </div>
    </div>
  );
}

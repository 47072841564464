import React from 'react';
import getOptions from '../../../../altUtils/getOptions';
import { Space } from 'antd';
import consultaJustificacion from '../funtions/consultaJustificacion';
// import AjusteFormatoFecha from '../../../../altUtils/AjusteFormatoFecha';
import FormatDateV2 from '../../../../altUtils/FormatDateV2';

export default function TablaJornadaDiariaReporte(items) {
  return [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'fecha'),
      onFilter: (v, record) => record.fecha.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {FormatDateV2(record.key).split('T')[0]}</span>
          ) : (
            <span style={{ color: 'red' }}>
              {FormatDateV2(record.key).split('T')[0]}
            </span>
          )}
        </Space>
      ),
    },
    {
      title: 'Jornada',
      dataIndex: 'jornada',
      key: 'jornada',
      filterSearch: true,
      aling: 'center',

      filters: getOptions(items, 'jornada'),
      onFilter: (v, record) => record.jornada.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <div>
              {text !== null ? (
                <p>
                  {FormatDateV2(text.entrada, 'jornada')
                    .split('T')[1]
                    .slice(0, 5)}{' '}
                  -{' '}
                  {FormatDateV2(text.salida, 'jornada')
                    .split('T')[1]
                    .slice(0, 5)}
                </p>
              ) : (
                <p> - </p>
              )}
            </div>
          ) : (
            <div>
              {text !== null ? (
                <p style={{ color: 'red' }}>
                  {FormatDateV2(text.entrada, 'jornada')
                    .split('T')[1]
                    .slice(0, 5)}{' '}
                  -{' '}
                  {FormatDateV2(text.salida, 'jornada')
                    .split('T')[1]
                    .slice(0, 5)}
                </p>
              ) : (
                <p style={{ color: 'red' }}> - </p>
              )}
            </div>
          )}
        </Space>
      ),
    },
    {
      title: 'Jornada Real',
      dataIndex: 'jornadaReal',
      key: 'jornadaReal',
      filterSearch: true,
      aling: 'center',

      filters: getOptions(items, 'jornadaReal'),
      onFilter: (v, record) => record.jornadaReal.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <div>
              <p>
                {FormatDateV2(text.entrada, 'marca').split('T')[1]} -{' '}
                {FormatDateV2(text.salida, 'marca').split('T')[1]}
              </p>
            </div>
          ) : (
            <div>
              <p style={{ color: 'red' }}>
                {FormatDateV2(text.entrada, 'marca').split('T')[1]} -{' '}
                {FormatDateV2(text.salida, 'marca').split('T')[1]}
              </p>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: 'Colación',
      dataIndex: 'colacion',
      key: 'colacion',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'colacion'),
      onFilter: (v, record) => record.colacion.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span>
              {text.split('-')[1]}
              {text}
            </span>
          ) : (
            <span style={{ color: 'red' }}> {text}</span>
          )}
        </Space>
      ),
    },
    {
      title: 'Colación Real',
      dataIndex: 'colacionReal',
      key: 'colacionReal',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'colacionReal'),
      onFilter: (v, record) => record.colacionReal.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {text}</span>
          ) : (
            <span style={{ color: 'red' }}> {text}</span>
          )}
        </Space>
      ),
    },
    {
      title: 'Anomalias',
      dataIndex: 'anomalias',
      key: 'anomalias',
      filterSearch: true,
      aling: 'center',
      width: 120,
      filters: getOptions(items, 'anomalias'),
      onFilter: (v, record) => record.anomalias.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <div>
              <p>
                Ingreso:
                {FormatDateV2(text.entrada).split('T')[1]}
              </p>

              <p>
                Salida:
                {FormatDateV2(text.salida).split('T')[1]}
              </p>
            </div>
          ) : (
            <div>
              <p style={{ color: 'red' }}>
                Ingreso:
                {FormatDateV2(text.entrada).split('T')[1]}
              </p>

              <p style={{ color: 'red' }}>
                Salida:
                {FormatDateV2(text.salida).split('T')[1]}
              </p>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: 'Horas extraordinarias',
      dataIndex: 'horasExtraordinarias',
      key: 'horasExtraordinarias',
      filterSearch: true,
      aling: 'center',
      width: 80,
      filters: getOptions(items, 'horasExtraordinarias'),
      onFilter: (v, record) => record.horasExtraordinarias.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {text}</span>
          ) : (
            <span style={{ color: 'red' }}> {text}</span>
          )}
        </Space>
      ),
    },
    {
      title: 'Comentarios',
      dataIndex: 'comentarios',
      key: 'comentarios',
      filterSearch: true,
      aling: 'center',
      width: 120,
      filters: getOptions(items, 'comentarios'),
      onFilter: (v, record) => record.comentarios.indexOf(v) === 0,
      render: (text, record) => (
        <Space>
          {consultaJustificacion(record.marcaje) ? (
            <span> {text}</span>
          ) : (
            <span style={{ color: 'red' }}> {text}</span>
          )}
        </Space>
      ),
    },
  ];
}

import React, { useState, useEffect } from 'react';
import { Table, Skeleton } from 'antd';
import TablaJornadaDiariaInfo from './components/TablaJornadaDiariaInfo';
import BuscadorReportes from '../../../components/buscador/BuscadorReportes';
import BotonExportarWord from '../../../components/botones/BotonExportarWord';
import BotonExportarPdf from '../../../components/botones/BotonExportarPdf';
import HeaderReportes from '../../../components/botones/headerReportes';
import BotonExportarExcel from '../../../components/botones/BotonExportarExcel';
import sumarRestarHorasExtraordinarias from '../../../altUtils/sumarRestarHorasExtraordinarias';
import CartaRegistrosSemanales from './components/CartaRegistrosSemanales';

export default function JornadaDiaria() {
  const [firstTable, setFirstTable] = useState([]);
  const [jobsAreLoading, setJobsAreLoading] = useState(true);
  const [exportarWord, setexportarWord] = useState(false);
  const [exportarPdf, setexportarPdf] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [exportarExcel, setexportarExcel] = useState(false);
  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
    },
  };

  const customMessage = {
    filterSearchPlaceholder: 'Filtrar',
    filterReset: 'Limpiar',
  };

  const expandedRowRender = (record) => {
    const arrayreportes = [];
    if (record.reporte.length !== 0) {
      record.reporte.forEach((obj) => {
        const arrayreportesemana = [];

        if (obj.length !== 0) {
          obj.forEach((item) => {
            const nexElement = item;

            if (
              item.jornadaReal.entrada !== null &&
              item.jornadaReal.salida !== null
            ) {
              let h1 = item.jornadaReal.salida.split('T')[1].trim();
              let h2 = item.jornadaReal.entrada.split('T')[1].trim();

              let totalHora = sumarRestarHorasExtraordinarias.restarHoras(
                h1,
                h2
              );

              if (totalHora.charAt(0) === '-') {
                nexElement.diferenciaHoras =
                  sumarRestarHorasExtraordinarias.sumarHoras(
                    totalHora,
                    '23:59:99'
                  );
              } else {
                nexElement.diferenciaHoras = totalHora;
              }
            } else {
              nexElement.diferenciaHoras = '00:00:00';
            }

            arrayreportesemana.push(nexElement);
          });
        }

        arrayreportes.push(arrayreportesemana);
      });
    }
    return (
      <div>
        {arrayreportes.map((item) => (
          <CartaRegistrosSemanales record={item} />
        ))}
      </div>
    );
  };

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-10">
      {/* <PageHeader title="Reporte jornada diaria" head="">
        <Button
          className="mr-2"
          icon={<FileExcelOutlined />}
          onClick={() => setexportarWord(true)}
          disabled={firstTable.length === 0}
          // disabled={firstTable.length === 0}
        >
          Exportar a Excel
        </Button>
        <Button
          className="mr-2"
          icon={<FileWordOutlined />}
          onClick={() => setexportarWord(true)}
          disabled={firstTable.length === 0}
          // disabled={firstTable.length === 0}
        >
          Exportar a word
        </Button>
        <Button
          className="mr-2"
          icon={<FilePdfOutlined />}
          onClick={() => setexportarPdf(true)}
          disabled={firstTable.length === 0}
          // disabled={firstTable.length === 0}
        >
          Exportar a pdf
        </Button>
      </PageHeader> */}
      <HeaderReportes
        name="Reporte jornada diaria"
        setexportarExcel={setexportarExcel}
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        firstTable={firstTable}
        DatosSeleccionados={DatosSeleccionados}
      />

      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="jornada"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
      />

      {jobsAreLoading ? (
        <Skeleton />
      ) : (
        <div>
          <Table
            columns={TablaJornadaDiariaInfo(firstTable)}
            locale={customMessage}
            expandable={{
              expandedRowRender,
            }}
            rowSelection={{
              ...rowSelection,
            }}
            dataSource={firstTable}
            scroll={{ x: true }}
          />
        </div>
      )}

      {exportarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="jornada"
          name={`reporte Jornada Diaria_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}

      {exportarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="jornada"
          name={`reporte Jornada Diaria_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}
      {exportarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="jornada"
          name={`reporte Jornada Diaria_${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}
    </div>
  );
}

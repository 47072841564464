import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { HomeFilled } from '@ant-design/icons';

const AnyReactComponent = () => {
  return (
    <div className="bg-blue-600 p-2 w-10 h-10 rounded-md flex justify-center items-center">
      <HomeFilled style={{ fontSize: '20px', color: 'white' }} />
    </div>
  );
};

export default function Mapa({ selected, locations }) {
  const [coords, setCoords] = useState({
    lat: -33.4372,
    lng: -70.6506,
  });

  useEffect(() => {
    handleGetLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  function handleGetLocation() {
    if (!selected || !locations) return;
    const location = locations.find((l) => l.value === selected);
    if (!location) return;
    setCoords({
      lat: location.data.lat,
      lng: location.data.lng,
    });
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        data-cy="mapaCiudad"
        bootstrapURLKeys={{
          key: 'AIzaSyBSRAY_84ShkAdy_pE8_5Kw2mfoKwFb4Kw',
        }}
        center={coords}
        zoom={selected ? 14 : 6}
      >
        {selected && (
          <AnyReactComponent
            lat={coords.lat}
            lng={coords.lng}
            text="My Marker"
          />
        )}
      </GoogleMapReact>
    </div>
  );
}

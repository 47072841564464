import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { saveAs } from 'file-saver';
import { getBase64 } from '../../../../../utils/downloadDocuments';
import JSZip from 'jszip';

export default function DownloadDocuments({ candidate }) {
  const { documentos } = candidate;

  async function handleDescargar() {
    const urls = await Promise.all(
      documentos.map(async ({ url }) => {
        const nombre = url.split('/').pop().split('?')[0];
        return {
          nombre: nombre,
          base64: getBase64(url),
        };
      })
    );
    const zip = new JSZip();
    urls.forEach(({ nombre, base64 }) =>
      zip.file(nombre, base64, { base64: true })
    );
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(
        content,
        `Documentos de ${candidate.nombre} ${candidate.primerApellido}`
      );
    });
    message.success('Documentos descargados');
  }

  if (!documentos) return null;
  return (
    <Tooltip title="Descargar Documentos">
      <Button
        type="text"
        icon={<DownloadOutlined />}
        onClick={handleDescargar}
      />
    </Tooltip>
  );
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { getDotacionDiaria } from '../../services/DotacionService';

import Meta from '../../components/layouts/Meta';
import Filters from './components/Filters';
import TablaDotacion from './components/TablaDotacion';
import ListadoResponsive from './components/ListadoResponsive';

import { PageContainer } from '@ant-design/pro-components';
import { Card, Divider, message } from 'antd';
import { Buscador } from './components/Buscador';

const initialFilters = {
  ubicacion: null,
  cargo: null,
  fecha: null,
};

export default function DotacionDiaria() {
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const navigate = useNavigate();
  const [trabajadores, setTrabajadores] = useState(null);
  const [trabajadoresFiltrados, setTrabajadoresFiltrados] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [search, setSearch] = useState({
    trabajador: null,
    oferta: null,
  });
  const isSupervisor = user.tipoUsuario.includes('Supervisor');

  useEffect(() => {
    if (!isSupervisor) {
      message.error('No tienes permisos para acceder a esta sección');
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupervisor]);

  useEffect(() => {
    buscarDotacion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (search?.trabajador?.length > 0 || search?.oferta?.length > 0) {
      let trabajadoresFiltrados = trabajadores;
      if (search?.trabajador?.length > 0) {
        trabajadoresFiltrados = trabajadores.filter((t) => {
          const nombre = `${t.nombre} ${t.primerApellido} ${t.segundoApellido}`;
          const rut = t.rut.toString();
          return (
            nombre.toLowerCase().includes(search?.trabajador?.toLowerCase()) ||
            rut.includes(search?.trabajador)
          );
        });
      }
      if (search?.oferta?.length > 0) {
        trabajadoresFiltrados = trabajadoresFiltrados.filter((t) => {
          const oferta = t.pais_consecutivoOferta.split('#')[1];
          return oferta.includes(search?.oferta);
        });
      }
      setTrabajadoresFiltrados(trabajadoresFiltrados);
    } else {
      setTrabajadoresFiltrados(trabajadores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  async function buscarDotacion() {
    try {
      setTrabajadores(null);
      setTrabajadoresFiltrados(null);
      const res = await getDotacionDiaria({
        empresaId: enterprise.empresaId,
        fechaInicio:
          filters.fecha?.length > 0
            ? dayjs(filters?.fecha[0]).format('YYYY-MM-DDTHH:mm:ss')
            : null,
        fechaFin:
          filters.fecha?.length > 0
            ? dayjs(filters?.fecha[1]).format('YYYY-MM-DDTHH:mm:ss')
            : null,
        ubicaciones: filters.ubicacion,
        cargos: filters.cargo,
      });
      setTrabajadores(res);
      setTrabajadoresFiltrados(res);
    } catch (error) {
      console.error(error);
      setTrabajadores([]);
      setTrabajadoresFiltrados([]);
      message.error('Error al obtener la dotación');
    }
  }

  const routes = [{ path: '', breadcrumbName: 'Dotación' }];
  return (
    <PageContainer
      header={{
        title: 'Dotación Diaria',
        ghost: true,
        breadcrumb: { routes },
      }}
    >
      <Meta title="Dotación Diaria" />
      <Card>
        <Filters
          setFilters={setFilters}
          initialFilters={initialFilters}
          trabajadores={trabajadores}
        />
        <Buscador
          trabajadores={trabajadores}
          setSearch={setSearch}
          search={search}
        />
        <div className="hidden lg:block">
          <TablaDotacion trabajadores={trabajadoresFiltrados} />
        </div>
        <div className="lg:hidden">
          <Divider />
          <ListadoResponsive trabajadores={trabajadoresFiltrados} />
        </div>
      </Card>
    </PageContainer>
  );
}

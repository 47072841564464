import { useState } from 'react';

import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  ColorPicker,
  Tag,
  message,
} from 'antd';
import Title from 'antd/es/typography';
import { TagOutlined } from '@ant-design/icons';
import { createEtiqueta } from '../../../services/EtiquetasService';
import { useSelector } from 'react-redux';

export default function CrearEtiqueta({ checkTags }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const initialTag = {
    nombre: '',
    color: '#f4f4f4',
    colorTexto: '#000000',
  };

  const [show, setShow] = useState(false);
  const [newTag, setNewTag] = useState(initialTag);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const rules = [{ required: true, message: 'Este campo es requerido' }];

  function handleChanges() {
    setNewTag(form.getFieldsValue());
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      await createEtiqueta(enterprise.empresaId, newTag);
      message.success('Etiqueta creada correctamente');
      handleCancel();
      checkTags();
    } catch (error) {
      console.error(error);
      message.error('Error al crear la etiqueta');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setShow(false);
    setNewTag(initialTag);
    form.resetFields();
  }

  return (
    <>
      <Button
        type="primary"
        icon={<TagOutlined />}
        onClick={() => setShow(true)}
      >
        Crear Etiqueta
      </Button>
      <Modal
        title="Crear Etiqueta"
        open={show}
        destroyOnClose
        onCancel={handleCancel}
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={!newTag.nombre}
          >
            Crear
          </Button>,
        ]}
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onChange={handleChanges}
          initialValues={initialTag}
        >
          <Form.Item label="Nombre" name="nombre" rules={rules}>
            <Input data-cy="CampoTexto" />
          </Form.Item>
          <Space size="large">
            <Form.Item label="Color de Etiqueta" name="color" rules={rules}>
              <ColorPicker
                showText
                onChangeComplete={(color) => {
                  form.setFieldValue('color', color.toHexString());
                  handleChanges();
                }}
              />
            </Form.Item>
            <Form.Item label="Color de Texto" name="colorTexto" rules={rules}>
              <ColorPicker
                showText
                onChangeComplete={(color) => {
                  form.setFieldValue('colorTexto', color.toHexString());
                  handleChanges();
                }}
              />
            </Form.Item>
          </Space>
        </Form>
        <Title level={5} style={{ marginBottom: 15 }}>
          Vista previa
        </Title>
        <Tag
          color={newTag.color || '#f4f4f4'}
          style={{
            transform: 'scale(1.5) translateX(16.5%)',
            marginBottom: '1rem',
          }}
        >
          <span style={{ color: newTag.colorTexto || '#000' }}>
            {newTag.nombre || 'Sin Nombre'}
          </span>
        </Tag>
      </Modal>
    </>
  );
}

import React from 'react';
import { Table, Tag, Space } from 'antd';
import getOptions from '../../../altUtils/getOptions';
import BotonMarcarAsistencia from '../../../components/botones/BotonMarcarAsistencia';
import Labels from '../../../altUtils/Labels.json';
import dayjs from 'dayjs';

export default function TablaJustificacionAsistencias({
  items,
  loading,
  buscar,
}) {
  const columns = [
    {
      title: 'Nro Oferta',
      dataIndex: 'pais_consecutivoOferta',
      key: 'pais_consecutivoOferta',
      width: 100,
      filterSearch: true,
      filters: getOptions(items, 'pais_consecutivoOferta'),
      onFilter: (v, record) => record.pais_consecutivoOferta.indexOf(v) === 0,
    },

    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
      width: 100,
    },
    {
      title: 'Tipo',
      dataIndex: 'unidad',
      key: 'unidad',
      width: 150,
    },
    {
      title: 'Jornada',
      dataIndex: '',
      key: 'ubicacion',
      width: 100,
      render: (text, record) => (
        <Space>
          {text.noProgramado ? (
            <Tag color="red">
              <p className="label text-center">turno</p>
              <p className="label text-center">no programado</p>
            </Tag>
          ) : (
            <Tag>
              <p className="label text-center">turno</p>
              <p className="label text-center">
                {dayjs(`${text.entrada}.00z`).format('DD/MM/YYYY')}
              </p>
              <p>
                {' '}
                {dayjs(`${text.entrada}.00z`).format('HH:mm:ss')} -
                {dayjs(`${text.salida}.00z`).format('HH:mm:ss')}
              </p>
            </Tag>
          )}
        </Space>
      ),
    },

    {
      title: 'Entrada',
      dataIndex: 'estadoEntrada',
      width: 100,
      key: 'estadoEntrada',
      render: (text, record) => (
        <BotonMarcarAsistencia
          tipo="Entrada"
          record={record}
          buscar={buscar}
          key={record.turno}
        />
      ),
    },
    {
      title: 'Salida',
      dataIndex: 'estadoSalida',
      width: 100,
      key: 'estadoSalida',
      render: (text, record) => (
        <BotonMarcarAsistencia
          tipo="Salida"
          record={record}
          buscar={buscar}
          key={record.turno}
        />
      ),
    },
  ];

  return (
    <Table
      locale={Labels['tableLabels']}
      loading={loading}
      rowKey="id"
      dataSource={items}
      columns={columns}
      scroll={{ x: 'max-content' }}
    />
  );
}

export default function sortCandidates(candidates) {
  const result = candidates.sort((a, b) => {
    if (
      a.postulacionesActivas.AceptadoXTrabajador.length > 0 &&
      b.postulacionesActivas.AceptadoXTrabajador.length === 0
    ) {
      return 1;
    }
    if (
      a.postulacionesActivas.AceptadoXTrabajador.length === 0 &&
      b.postulacionesActivas.AceptadoXTrabajador.length > 0
    ) {
      return -1;
    }

    if (a.activo && !b.activo) {
      return -1;
    }
    if (!a.activo && b.activo) {
      return 1;
    }

    if (
      a.estadoActual === 'AceptadoXTrabajador' &&
      b.estadoActual !== 'AceptadoXTrabajador'
    ) {
      return -1;
    }
    if (
      a.estadoActual !== 'AceptadoXTrabajador' &&
      b.estadoActual === 'AceptadoXTrabajador'
    ) {
      return 1;
    }
    if (
      a.estadoActual === 'AceptadoXEmpresa' &&
      b.estadoActual !== 'AceptadoXEmpresa'
    ) {
      return -1;
    }
    if (
      a.estadoActual !== 'AceptadoXEmpresa' &&
      b.estadoActual === 'AceptadoXEmpresa'
    ) {
      return 1;
    }
    if (
      a.estadoActual === 'RechazadoXTrabajador' &&
      b.estadoActual !== 'RechazadoXTrabajador'
    ) {
      return -1;
    }
    if (
      a.estadoActual !== 'RechazadoXTrabajador' &&
      b.estadoActual === 'RechazadoXTrabajador'
    ) {
      return 1;
    }
    if (
      a.estadoActual === 'DesistidaXTrabajador' &&
      b.estadoActual !== 'DesistidaXTrabajador'
    ) {
      return -1;
    }
    if (
      a.estadoActual !== 'DesistidaXTrabajador' &&
      b.estadoActual === 'DesistidaXTrabajador'
    ) {
      return 1;
    }
    if (
      a.estadoActual === 'SeleccionadoXEmpresa' &&
      b.estadoActual !== 'SeleccionadoXEmpresa'
    ) {
      return -1;
    }
    if (
      a.estadoActual !== 'SeleccionadoXEmpresa' &&
      b.estadoActual === 'SeleccionadoXEmpresa'
    ) {
      return 1;
    }
    if (a.estadoActual === 'Postulado' && b.estadoActual !== 'Postulado') {
      return -1;
    }
    if (a.estadoActual !== 'Postulado' && b.estadoActual === 'Postulado') {
      return 1;
    }
    if (a.historialEstados.postulado > b.historialEstados.postulado) {
      return -1;
    }
    if (a.historialEstados.postulado < b.historialEstados.postulado) {
      return 1;
    }
    return 0;
  });
  return result;
}

import dayjs from 'dayjs';

export default function formatRequisition(requisition) {
  if (!requisition) return null;
  return {
    infoBasica: {
      cargo: requisition.rubro + '#' + requisition.tipoTrabajo,
      razonsocial: requisition.razonSocial,
      ubicacion: requisition.ubicacion.pais_region_ciudad_comuna_uuid,
      motivo: requisition.nombreContrato,
      causal: requisition.causalContrato,
      // privacidad: !requisition.isPublic,
      privacidad: requisition.privacidad,
      nombreReemplazado: requisition.nombreReemplazado,
      rutReemplazado: requisition.rutReemplazado,
    },
    turnos: requisition.turnos.map((turno) => {
      const entrada = dayjs(
        turno.fechaInicio + 'T' + turno.horaInicio + ':00Z'
      );
      const salida = dayjs(turno.fechaFin + 'T' + turno.horaFin + ':00Z');
      return {
        dias: turno.diasTrabajo,
        entrada: entrada,
        fechas: {
          inicio: dayjs(turno.fechaInicio),
          fin:
            turno.diasTrabajo.length > 1
              ? dayjs(turno.fechaFin)
              : dayjs(turno.fechaInicio),
        },
        numero: turno.id,
        pago: turno.valorUnitario,
        salida: salida,
        // tipopago: turno.unidad,
        tipopago: 'jornada',
        vacantes: turno.cantidadTrabajadores,
        colacion: turno.tiempoColacion,
        gratificacion: requisition.gratificacion,
        transporte: requisition.transporte,
        bono: turno.bono,
        valorcolacion: requisition.colacion,
      };
    }),
    camposExtras: {},
    multimedia: requisition.multimediaOfertaId,
    requisitos: requisition.requisitos.map((requisito) => {
      return {
        name: requisito.descripcion,
        tipo: requisito.requiereArchivo ? 'file' : 'question',
      };
    }),
    infoOnboarding: requisition.infoOnboarding,
    id: requisition.pais_consecutivoOferta,
  };
}

export default function getEventoDatesString(inicio, fin) {
  if (!inicio || !fin) return 'No definida';
  // si inicio y fin son iguales devolver inicio
  if (
    inicio.format('DD/MM') === fin.format('DD/MM') ||
    fin.isBefore(inicio.add(1, 'day'))
  )
    return inicio.format('DD/MM');
  // si inicio y fin son distintos devolver inicio - fin
  return `${inicio.format('DD/MM')} al ${fin.format('DD/MM')}`;
}

import { formatPesos } from '../../../../../utils/formatPesos';
import { Descriptions, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

export default function TurnosInformation({ turno }) {
  const nameOfDay = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'];

  const inicio = dayjs(turno.fechaInicio + 'T' + turno.horaInicio + ':00Z');
  const fin = dayjs(turno.fechaFin + 'T' + turno.horaFin + ':00Z');

  const cobertura =
    turno.candidatos?.filter((item) =>
      ['AceptadoXTrabajador', 'Ejecutando', 'Terminado'].includes(
        item.estadoActual
      )
    ).length || 0;

  return (
    <Descriptions
      title="Información del turno"
      bordered
      size="middle"
      column={4}
    >
      {/* <Descriptions.Item label="Fecha">{label}</Descriptions.Item> */}
      <Descriptions.Item label="Días de trabajo">
        <Tooltip title={`${turno.horasTotales} horas totales`}>
          <div className="flex items-center space-x-2 max-w-max">
            <span>
              {turno.diasTrabajo?.map((dia) => (
                <Tag key={dia}>{nameOfDay[dia]}</Tag>
              ))}
            </span>
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      </Descriptions.Item>
      <Descriptions.Item label="Horario">
        <Tooltip title={`${turno.tiempoColacion} minutos de colación`}>
          <div className="flex items-center space-x-2 max-w-max">
            <span>
              {inicio.format('HH:mm')} a {fin.format('HH:mm')}
            </span>
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      </Descriptions.Item>
      {/* <Descriptions.Item label="Pago total">
        {formatPesos(turno.liquidoAPagar)}
      </Descriptions.Item> */}
      <Descriptions.Item label="Pago a trabajador">
        <Tooltip
          title={`${formatPesos(turno.valorUnitario)} por ${turno.unidad}`}
        >
          <div className="flex items-center space-x-2 max-w-max">
            <span>{formatPesos(turno.liquidoAPagarXTrabajador)}</span>
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      </Descriptions.Item>
      <Descriptions.Item label="Cobertura">
        {cobertura} / {turno.cantidadTrabajadores}
      </Descriptions.Item>
    </Descriptions>
  );
}

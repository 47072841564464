import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  changeStatusRequisition,
  deleteRequisition,
} from '../../../services/RequisitionsService';
import { Badge, Button, Dropdown, message, Modal, Space } from 'antd';
import {
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  EyeOutlined,
  MobileOutlined,
  MoreOutlined,
  PauseOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';
import InvitarAPostular from './extra/InvitarAPostular';
import ContratarDirecto from './extra/ContratarDirecto';
import CambiarHorario from './extra/CambiarHorario';

export default function TableActions({ item, buscarSolicitudes }) {
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { pais_consecutivoOferta } = item;
  const formatId = pais_consecutivoOferta.replace('#', '-');
  const { REACT_APP_WORKER_URL } = process.env;
  const url = `${REACT_APP_WORKER_URL}/trabajo/${enterprise.empresaId}/${formatId}`;
  const { nuevosPostulantes } = item;

  async function handleClickStatus(action) {
    try {
      const registro = {
        empresaId: enterprise.empresaId,
        pais_consecutivoOferta,
      };
      await changeStatusRequisition(action, registro);
      message.success('Solicitud actualizada');
      buscarSolicitudes();
    } catch (error) {
      message.error('Error al actualizar solicitud');
    }
  }

  async function handleClickDelete() {
    try {
      await deleteRequisition({
        empresaId: enterprise.empresaId,
        pais_consecutivoOferta,
      });
      message.info('Solicitud eliminada');
      buscarSolicitudes();
    } catch (error) {
      console.error(error);
      message.error('Error al actualizar solicitud');
    }
  }

  // const isNoAprobada = item.estadoActual === 'NoAprobada';
  // const isNotNoAprobada = item.estadoActual !== 'NoAprobada';
  // const isActivaEjecutandoEnPausa = [
  //   'Activa',
  //   'Ejecutando',
  //   'EnPausa',
  // ].includes(item.estadoActual);

  const isEditable = ['Guardada', 'NoAprobada'].includes(item.estadoActual);
  const isPublished = ['Activa', 'Ejecutando', 'EnPausa'].includes(
    item.estadoActual
  );
  const isContratable =
    ['Activa', 'Ejecutando', 'EnPausa', 'Desierta'].includes(
      item.estadoActual
    ) ||
    (item.estadoActual === 'Finalizada' && user.esMantenedor);
  const isActive = item.estadoActual === 'Activa';
  const isEnPausa = item.estadoActual === 'EnPausa';
  const isNotEjecutando =
    item.estadoActual !== 'Ejecutando' &&
    !isEditable &&
    item.estadoActual !== 'Finalizada';

  const isObraFaena = item.tipoContrato === 'ContratoObraFaena';

  const items = [
    {
      key: 0,
      label: (
        <Link to={`/solicitudes/${formatId}`}>
          <Space>
            <Badge
              dot
              className={nuevosPostulantes && 'animate-pulse'}
              count={!nuevosPostulantes && 0}>
              <EyeOutlined />
            </Badge>
            <span>Ver detalles</span>
          </Space>
        </Link>
      ),
    },
    ...(isEditable
      ? [
          {
            key: 2,
            label: (
              <Link to={`/solicitudes/formulario?editar=${formatId}`}>
                <Space>
                  <EditOutlined />
                  <span>Editar</span>
                </Space>
              </Link>
            ),
          },
        ]
      : []),
    ...(isPublished
      ? [
          {
            key: 3,
            label: (
              <a href={url} target="_blank" rel="noreferrer">
                <Space>
                  <MobileOutlined />
                  <span>Abrir en APP</span>
                </Space>
              </a>
            ),
          },
        ]
      : []),
    {
      type: 'divider',
    },
    ...(isActive
      ? [
          {
            key: 4,
            label: (
              <ModalConfirm
                title="¿Desea pausar la solicitud?"
                onConfirm={() => handleClickStatus('pausar')}>
                <Space>
                  <PauseOutlined />
                  <span>Pausar</span>
                </Space>
              </ModalConfirm>
            ),
          },
        ]
      : []),
    ...(isEnPausa
      ? [
          {
            key: 5,
            label: (
              <ModalConfirm
                title="¿Desea reactivar la solicitud?"
                onConfirm={() => handleClickStatus('reactivar')}>
                <Space>
                  <PlaySquareOutlined />
                  <span>Reactivar</span>
                </Space>
              </ModalConfirm>
            ),
          },
        ]
      : []),
    {
      key: 6,
      label: (
        <Link to={`/solicitudes/formulario?clonar=${formatId}`}>
          <Space>
            <CopyOutlined />
            <span>Clonar</span>
          </Space>
        </Link>
      ),
    },
    ...(isNotEjecutando
      ? [
          {
            key: 7,
            label: (
              <ModalConfirm
                text="¿Desea cerrar la solicitud?"
                onConfirm={() => handleClickStatus('cerrar')}>
                <Space>
                  <CloseOutlined
                    style={{
                      color: '#ff4d4f',
                    }}
                  />
                  <span>Cerrar</span>
                </Space>
              </ModalConfirm>
            ),
          },
        ]
      : []),
    ...(isPublished || isContratable
      ? [
          {
            type: 'divider',
          },
        ]
      : []),

    ...(isPublished
      ? [
          {
            key: 8,
            label: <InvitarAPostular item={item} />,
          },
        ]
      : []),

    ...(isContratable
      ? [
          {
            key: 9,
            label: <ContratarDirecto item={item} />,
          },
        ]
      : []),

    ...(user.esMantenedor && isObraFaena
      ? [
          {
            type: 'divider',
          },
          {
            key: 10,
            label: <span>Menú Mantenedor</span>,
            disabled: true,
          },
          {
            key: 11,
            label: (
              <CambiarHorario
                item={item}
                buscarSolicitudes={buscarSolicitudes}
              />
            ),
          },
          ...(isNotEjecutando
            ? [
                {
                  key: 12,
                  label: (
                    <ModalConfirm
                      text="¿Desea eliminar la solicitud?"
                      onConfirm={() => handleClickDelete()}>
                      <Space>
                        <CloseOutlined
                          style={{
                            color: '#ff4d4f',
                          }}
                        />
                        <span>Eliminar solicitud</span>
                      </Space>
                    </ModalConfirm>
                  ),
                },
              ]
            : []),
        ]
      : []),
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={'hover'}>
      <Button type="text" icon={<MoreOutlined />} />
    </Dropdown>
  );
}

function ModalConfirm({ children, text, onConfirm }) {
  function handleClick() {
    Modal.confirm({
      title: 'Confirmar',
      content: text,
      okText: 'Si',
      cancelText: 'No',
      onOk: onConfirm,
    });
  }

  return <div onClick={handleClick}>{children}</div>;
}

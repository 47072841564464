import React, { createContext, useState } from 'react';
import { Card } from 'antd';

export const SolicitudContext = createContext();

export default function SolicitudProvider({ children, datos }) {
  const emptyRequisition = {
    infoBasica: {},
    turnos: [],
    camposExtras: {},
    multimedia: [],
    requisitos: [],
    infoOnboarding: {},
    id: '',
  };

  const [solicitud, setSolicitud] = useState(
    Object.keys(datos.requisition).length > 2
      ? datos.requisition
      : emptyRequisition
  );
  const [campos, setCampos] = useState(datos);

  function handleChange(changes) {
    setSolicitud({ ...solicitud, ...changes });
  }

  function reset() {
    setSolicitud(emptyRequisition);
  }

  const contextValue = {
    solicitud,
    handleChange,
    reset,
    setCampos,
    campos,
  };

  return (
    <SolicitudContext.Provider value={contextValue}>
      <Card>{children}</Card>
    </SolicitudContext.Provider>
  );
}

import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import useSolicitud from '../../../../hooks/useSolicitud';

export default function FinishForm() {
  const navigate = useNavigate();
  const { solicitud } = useSolicitud();
  // const { session } = useAuth();
  // const [etiquetas, setEtiquetas] = useState(null);
  // const [filtradas, setFiltradas] = useState(null);

  // useEffect(() => {
  //   loadEtiquetas();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // async function loadEtiquetas() {
  //   try {
  //     const data = await getEtiquetas(session?.user?.empresaId);
  //     setEtiquetas(data);
  //     setFiltradas(data);
  //   } catch (error) {
  //     console.error(error);
  //     message.error('No se pudieron obtener las etiquetas');
  //     setEtiquetas([]);
  //   }
  // }

  // function filtrarEtiquetas(value) {
  //   if (!value) return setFiltradas(etiquetas);
  //   // A futuro reemplazar por un MAP y agregar un atributo show al objeto de la etiqueta para que no se reinicie el estado del botón al filtrar.
  //   const filtered = etiquetas.filter((etiqueta) => {
  //     return etiqueta.nombre.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setFiltradas(filtered);
  // }

  return (
    <>
      <Result
        status="success"
        title="¡Hemos terminado!"
        subTitle="Sólo Falta que la chequemos rápidamente y será publicada."
      />
      {/* <PanelEtiquetas
        etiquetas={filtradas}
        filtrarEtiquetas={filtrarEtiquetas}
      /> */}
      <div className="flex justify-center space-x-4 mb-10">
        <Link to={`/solicitudes/${solicitud.id.replace('#', '-')}`} key="view">
          <Button data-cy="botonVerSolicitud" type="primary">
            Ver Solicitud
          </Button>
        </Link>
        <Button
          data-cy="botonCrearOtraSolicitud"
          key="new"
          onClick={() => navigate(0)}
        >
          Crear otra solicitud
        </Button>
      </div>
    </>
  );
}

// function PanelEtiquetas({ etiquetas, filtrarEtiquetas }) {
//   // create style object with scrollbar styled
//   const style = {
//     maxHeight: 500,
//     overflowY: 'scroll',
//   };

//   return (
//     <div className="max-w-xl mx-auto -mt-6 mb-20 min-h-[300px]">
//       <Title
//         level={5}
//         style={{
//           textAlign: 'center',
//           fontWeight: 'normal',
//           marginBottom: 20,
//         }}
//       >
//         Mientras tanto, puedes invitar a postular o contrata directamente a los
//         trabajadores de tus etiquetas.
//       </Title>

//       <Input
//         onChange={(e) => filtrarEtiquetas(e.target.value)}
//         placeholder="Buscar etiqueta por nombre..."
//         className="mb-5"
//         allowClear
//       />
//       <div className="flex justify-between font-bold mb-5">
//         <span>Etiqueta</span>
//         <span>Acciones</span>
//       </div>
//       <div className="scrollbar-hide" style={style}>
//         {
//           // if etiquetas.length === 0 mostrar mensaje de que no hay etiquetas
//           etiquetas?.length === 0 && (
//             <Empty description="Aún no se han creado etiquetas" />
//           )
//         }
//         {
//           // if etiquetas === null mostrar spinner
//           etiquetas === null && (
//             <Spin
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 height: '100%',
//                 padding: 20,
//               }}
//             />
//           )
//         }
//         {etiquetas?.map((etiqueta) => (
//           <ControladorEtiqueta key={etiqueta.etiquetaId} etiqueta={etiqueta} />
//         ))}
//       </div>
//     </div>
//   );
// }

// function ControladorEtiqueta({ etiqueta }) {
//   const { solicitud } = useSolicitud();
//   const [pulsados, setPulsados] = useState({
//     invitar: false,
//     contratar: false,
//   });

//   async function handleInvitar() {
//     try {
//       setPulsados({ ...pulsados, invitar: true });
//       await invitarEtiquetas(etiqueta.empresaId, solicitud.id, [
//         etiqueta.etiquetaId,
//       ]);
//       message.success(`Trabajadores "${etiqueta.nombre}" invitados`);
//     } catch (error) {
//       message.error('No se pudo invitar a los trabajadores de la etiqueta');
//       console.error(error);
//       setPulsados({ ...pulsados, invitar: false });
//     }
//   }

//   return (
//     <>
//       <div className="flex items-center justify-between">
//         <Tooltip title={etiqueta.nombre.length > 45 ? etiqueta.nombre : ''}>
//           <Tag color={etiqueta.color}>
//             <span style={{ color: etiqueta.colorTexto }}>
//               {/* if etiqueta.nombre.length > 45 resumir el nombre y finalizar con ..., caso contrario mostrar el nombre completo */}
//               {etiqueta.nombre.length > 45
//                 ? etiqueta.nombre.substring(0, 45) + '...'
//                 : etiqueta.nombre}{' '}
//               <span className="text-gray-400">
//                 ({etiqueta.totalTrabajadores})
//               </span>
//             </span>
//           </Tag>
//         </Tooltip>
//         <div className="space-x-2">
//           <Button
//             size="small"
//             onClick={handleInvitar}
//             disabled={pulsados.invitar}
//           >
//             {pulsados.invitar ? 'Invitado' : 'Invitar'}
//           </Button>
//           {/* Habilitar función cuando nico genere la API */}
//           {/* <Button size="small">Contratar</Button>  */}
//         </div>
//       </div>
//       <Divider
//         style={{
//           margin: '10px 0',
//         }}
//       />
//     </>
//   );
// }

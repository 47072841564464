import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function getContratos(empresaId, tipo = 'sinFirmar') {
  const token = await getToken();
  const { data } = await API.get('empresa', '/firmarContratosAnpd', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
      tipo,
    },
  });
  return data.contratos;
}

export async function firmarContrato(empresaId, contratos) {
  const token = await getToken();
  const { data } = await API.post('empresa', '/firmarContratosAnpd', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      empresaId,
      contratos,
    },
  });
  return data;
}

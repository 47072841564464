import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function getRequisitionCandidates(empresaId, id, estado = '') {
  const token = await getToken();
  const { data } = await API.get('oferta', '/verCandidatos', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
      pais_consecutivoOferta: id,
      estadoActual: estado,
      cantidad: 500, // CANTIDAD DE ELEMENTOS QUE SE DESEAN MOSTRAR POR PAGINA (25, 50, 100, 300)
      LastEvaluatedKey: '',
    },
  });
  if (data.existe) return data.registro.Items;
  else return [];
}

export async function selectCandidate(datos) {
  const token = await getToken();
  const { data } = await API.post('empresa', '/seleccionar', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: datos,
    },
  });
  if (!data.existe)
    throw new Error(
      data?.registro?.message || 'Error al seleccionar candidato'
    );
  if (data.existe && data?.registro?.message) return data.registro;
  return true;
}

export async function unselectCandidate(datos) {
  const token = await getToken();
  await API.post('empresa', '/deseleccionar', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: datos,
    },
  });
  return true;
}
export async function discardCandidate(datos) {
  const token = await getToken();
  await API.post('empresa', '/descartar', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      registro: datos,
    },
  });
  return true;
}

export async function hireCandidates(datos, messageApi) {
  const token = await getToken();
  await API.post('xinerlink', '/empresa/v1/contratar', {
    headers: {},
    response: true,
    body: {
      userToken: token,
      ...datos,
    },
  });
  messageApi.open({
    key: 'hiring',
    type: 'loading',
    content: 'Contratando candidatos...',
    duration: 0,
  });
  return true;
}

export async function unhireCandidate(datos) {
  const token = await getToken();
  await API.post('xinerlink', '/empresa/v1/deshacer-contrato', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
    },
    body: datos,
  });
  return true;
}

// OLD VERSION OF HIRE CANDIDATES
// export async function hireCandidates(datos, messageApi) {
//   const token = await getToken();
//   try {
//     // Confirmar
//     await API.post('oferta', '/contratar', {
//       headers: {},
//       response: true,
//       body: {
//         userToken: token,
//         registro: {
//           ...datos,
//           etapa: 'Candidatos',
//         },
//       },
//     });
//     messageApi.open({
//       key: 'hiring',
//       type: 'loading',
//       content: 'Provisionando candidatos...',
//       duration: 0,
//     });
//     // Provisionar
//     await API.post('oferta', '/contratar', {
//       headers: {},
//       response: true,
//       body: {
//         userToken: token,
//         registro: {
//           ...datos,
//           etapa: 'Provisiones',
//         },
//       },
//     });
//   } catch (error) {
//     console.error(
//       'Ocurrió un error en /contratar... aun asi se continua por si el motivo es que ya los candidatos estaban en la etapa de Firma'
//     );
//   }
//   const candidatos = await getRequisitionCandidates(
//     datos.empresaId,
//     datos.pais_consecutivoOferta,
//     'SeleccionadoXEmpresa,ProvisionadoXEmpresa,PendienteXFirma'
//   );
//   // Firmar
//   messageApi.open({
//     key: 'hiring',
//     type: 'loading',
//     content: 'Firmando candidatos...',
//     duration: 0,
//   });
//   await API.post('oferta', '/contratar', {
//     headers: {},
//     response: true,
//     body: {
//       userToken: token,
//       registro: {
//         ...datos,
//         etapa: 'Firmas',
//         candidatos: candidatos.map((c) => c.correo),
//       },
//     },
//   });
//   return true;
// }

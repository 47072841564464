import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Meta from '../../components/layouts/Meta';
import TablaTrabajadores from './components/TablaTrabajadores';

import { PageContainer } from '@ant-design/pro-components';
import { Card, Divider, message } from 'antd';
import { getTrabajadores } from '../../services/TrabajadoresService';
import { getEtiquetas } from '../../services/EtiquetasService';
import Filters from './components/Filters';
import Actions from './components/Actions';

const initialFilters = {
  trabajador: null,
  etiquetas: null,
};

export default function Trabajadores() {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [trabajadores, setTrabajadores] = useState(null);
  const [seleccionados, setSeleccionados] = useState([]);
  const [etiquetas, setEtiquetas] = useState(null);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    buscarTrabajadores();
    obtenerEtiquetas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelect(item, selected, arrays = false) {
    const addItems = (items) => {
      setSeleccionados((prev) => [...prev, ...items]);
    };
    const removeItems = (items) => {
      const ids = items.map((i) => i.trabajadorId);
      setSeleccionados((prev) =>
        prev.filter((s) => !ids.includes(s.trabajadorId))
      );
    };
    const addItem = (item) => {
      setSeleccionados((prev) => [...prev, item]);
    };
    const removeItem = (item) => {
      setSeleccionados((prev) =>
        prev.filter((s) => s.trabajadorId !== item.trabajadorId)
      );
    };

    if (arrays) {
      if (selected) {
        addItems(item);
      } else {
        removeItems(item);
      }
    } else {
      if (selected) {
        addItem(item);
      } else {
        removeItem(item);
      }
    }
  }

  async function buscarTrabajadores() {
    try {
      setTrabajadores(null);
      const res = await getTrabajadores(enterprise.empresaId);
      setTrabajadores(res);
    } catch (error) {
      setTrabajadores([]);
      message.error('Error al obtener los trabajadores');
    }
  }

  async function obtenerEtiquetas() {
    try {
      setEtiquetas(null);
      const res = await getEtiquetas(enterprise.empresaId);
      setEtiquetas(res);
    } catch (error) {
      message.error('No se pudo cargar las etiquetas');
      console.error(error);
      setEtiquetas([]);
    }
  }

  const routes = [{ path: '', breadcrumbName: 'Trabajadores' }];
  return (
    <PageContainer
      header={{
        title: 'Gestión de Trabajadores',
        ghost: true,
        breadcrumb: { routes },
      }}
    >
      <Meta title="Gestión de Trabajadores" />
      <Card>
        <Actions
          checkTags={obtenerEtiquetas}
          seleccionados={seleccionados}
          etiquetas={etiquetas}
          refresh={buscarTrabajadores}
        />
        <Divider />
        <Filters
          setFilters={setFilters}
          initialFilters={initialFilters}
          refresh={buscarTrabajadores}
          etiquetas={etiquetas}
        />
        <TablaTrabajadores
          trabajadores={trabajadores}
          filters={filters}
          refresh={buscarTrabajadores}
          handleSelect={handleSelect}
          seleccionados={seleccionados}
        />
      </Card>
    </PageContainer>
  );
}

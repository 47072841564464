import React from 'react';
import { Table, Space } from 'antd';
import dayjs from 'dayjs';
import FormatDateV2 from '../../../../altUtils/FormatDateV2';

export default function TablaReporteModificaciones({ data = [] }) {
  const columns = [
    {
      title: 'Fecha Asignacion de Turno',
      dataIndex: 'fechaAsignacion',
      key: 'fechaAsignacion',
      width: 100,
      render: (text) => {
        return <Space>{FormatDateV2(text).split('T')[0]}</Space>;
      },
    },
    {
      title: 'Hora turno',
      dataIndex: 'jornadaAsignacion',
      key: 'jornadaAsignacion',
      width: 100,
      render: (text) => {
        return (
          <Space>
            <div className="aling-center">
              <p>
                {dayjs(`${text.entrada}Z`).format('HH:mm')} -{' '}
                {dayjs(`${text.salida}Z`).format('HH:mm')}
              </p>
            </div>
          </Space>
        );
      },
    },
    {
      title: 'Frecuencia (Tipo de turno)',
      dataIndex: 'frecuencia',
      key: 'frecuencia',
      width: 100,
    },
    {
      title: 'Fecha Asignacion de Nuevo turno',
      dataIndex: 'fechaNuevaAsignacion',
      key: 'fechaNuevaAsignacion',
      width: 100,
      render: (text) => {
        return <Space>{FormatDateV2(text).split('T')[0]}</Space>;
      },
    },
    {
      title: 'Horario Nuevo Turno',
      dataIndex: 'jornadaNuevaAsignacion',
      key: 'jornadaNuevaAsignacion',
      width: 100,
      render: (text) => {
        return (
          <Space>
            <div className="aling-center">
              <p>
                {dayjs(`${text.entrada}Z`).format('HH:mm')} -{' '}
                {dayjs(`${text.salida}Z`).format('HH:mm')}
              </p>
            </div>
          </Space>
        );
      },
    },
    {
      title: 'Solicitado',
      dataIndex: 'solicitado',
      key: 'solicitado',
      width: 100,
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={data} bordered pagination={false} />
    </div>
  );
}

import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function consultarCargos(empresaId) {
  const token = await getToken();
  const { data } = await API.get('empresa', '/rubros', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
    },
  });
  if (data.existe) return data.registro;
  else return [];
}

export async function consultarRazones(empresaId) {
  const token = await getToken();
  const { data } = await API.get('empresa', '/razonSocial', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
      identificadorTributario: '',
    },
  });
  if (data.existe) return data.registro;
  else return [];
}

export async function consultarUbicaciones(empresaId) {
  const token = await getToken();
  const { data } = await API.get('empresa', '/ubicaciones', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      empresaId,
      pais_region_ciudad_comuna_uuid: '',
    },
  });
  if (data.existe) return data.registro;
  else return [];
}

export async function getConfiguracionEmpresa(empresaId) {
  const token = await getToken();
  const { data } = await API.get(
    'xinerlink',
    '/empresa/v1/configuracionEmpresa/' + empresaId,
    {
      headers: {},
      response: true,
      queryStringParameters: {
        userToken: token,
      },
    }
  );
  if (data.existe) return data.registro;
  else return null;
}

import dayjs from 'dayjs';
import { getHoras } from '../utils/getHoras';
import { consultarCargos, consultarRazones } from './EmpresaService';
import {
  generateRequisition,
  generateTurns,
  getRequisition,
  saveMultimedia,
  saveOnboarding,
  saveRequeriments,
  validarOferta,
} from './RequisitionsService';
import { v4 as uuidv4 } from 'uuid';
import { calcularBruto } from './RemurenacionesService';
import { message } from 'antd';
// import { notificarPorEtiquetas } from './BackofficeService';
import { invitarEtiquetas } from './EtiquetasService';
import { notificarPorEtiquetas } from './BackofficeService';
export async function massiveGeneration({
  baseId,
  enterprise,
  arrSolicitudes,
  plantillaBase,
}) {
  message.loading('Obteniendo información base');
  const [cargos, razonesSociales, base] = await Promise.all([
    consultarCargos(enterprise.empresaId),
    consultarRazones(enterprise.empresaId),
    getRequisition(enterprise.empresaId, baseId),
  ]);

  message.loading('Validando salarios y horas máximas');
  // Validar que cumple con salario minimo
  const invalidSal = await getInvalidSalaries(base, arrSolicitudes);
  if (invalidSal.length > 0)
    throw new Error(
      `Estas solicitudes no cumplen con el salario: ${invalidSal.join(', ')}`
    );
  // Validar que cumple con las horas maximas
  const invalidHours = getInvalidHours(base, cargos, arrSolicitudes);
  if (invalidHours.length > 0)
    throw new Error(
      `Estas solicitudes no cumplen con las horas máximas: ${invalidHours.join(
        ', '
      )}`
    );

  message.loading('Generando solicitudes');
  for (const solicitud of arrSolicitudes) {
    const infoBasica = {
      cargo: cargos.find(
        (cargo) => cargo.rubro_tipoTrabajo.split('#')[1] === base.tipoTrabajo
      ).rubro_tipoTrabajo,
      razonsocial: base.razonSocial,
      ubicacion: base.ubicacion.pais_region_ciudad_comuna_uuid,
      motivo: solicitud.motivo,
      causal: base.causalContrato,
      nombreReemplazado: base.nombreReemplazado || '',
      rutReemplazado: base.rutReemplazado || '',
      // privacidad: !base.isPublic,
    };

    if (plantillaBase.tipoContrato === 'ContratoObraFaena') {
      infoBasica.privacidad = solicitud.etiquetaId ? 'privada' : 'mi_comunidad';
    } else {
      infoBasica.privacidad = base.privacidad;
    }

    const { pais_consecutivoOferta } = await generateRequisition(
      enterprise.empresaId,
      infoBasica,
      ''
    );

    const fechaFinal = getFinalDate(solicitud);

    const diasLaborales = getWeekdayList(solicitud);

    const horas = getHoras({
      fechas: [solicitud.fecha, fechaFinal],
      dias: diasLaborales,
      entrada: solicitud.entrada,
      salida: solicitud.salida,
      tipopago: base.turnos[0].unidad.toLowerCase(),
      colacion: base.turnos[0].tiempoColacion,
      pago: solicitud.pago,
    });

    const turnos = [
      {
        id: uuidv4(),
        fechaInicio: horas.fechas.inicio.format('YYYY-MM-DD'),
        fechaFin: horas.fechas.fin.format('YYYY-MM-DD'),
        horaInicio: solicitud.entrada.format('HH:mm'),
        horaFin: solicitud.salida.format('HH:mm'),
        horasTotales: horas.fixHoras,
        valorUnitario: horas.unitario,
        cantidadTrabajadores: solicitud.vacantes,
        liquidoAPagar: solicitud.pago * solicitud.vacantes,
        liquidoAPagarXTrabajador: solicitud.pago,
        bono: solicitud.bono || 0,
        diasTrabajo: diasLaborales,
        tiempoColacion: base.turnos[0].tiempoColacion,
        unidad: base.turnos[0].unidad,
      },
    ];

    const totales = await calcularBruto({
      liquido: solicitud.pago * getWeekdayList(solicitud).length,
      gratificacion: base.gratificacion || false,
      colacion: base.colacion || 0,
      transporte: base.transporte || 0,
    });

    const contribucion = razonesSociales.find(
      (razon) => razon.razonSocial === base.razonSocial
    ).margenContribucion;
    const costoServicio =
      (solicitud.pago * 1.33 * contribucion) / (1 - contribucion);

    const infoTurnos = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: pais_consecutivoOferta, // se saca de lo de arriba :/
      turnos,
      gratificacion: base.gratificacion || false,
      transporte: base.transporte || 0,
      colacion: base.colacion || 0,

      liquidoTotal: totales.liquido,
      brutoTotal: solicitud.pago * 1.33,
      costoServicio: costoServicio,
      costoNeto: solicitud.pago * 1.33 + costoServicio,
    };
    await generateTurns(infoTurnos);

    const infoMultimedia = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: pais_consecutivoOferta, // se saca de lo de arriba :/
      etapa: 'Multimedia',
      multimediaOfertaId: base.multimediaOfertaId,
    };
    await saveMultimedia(infoMultimedia);

    const infoRequisitos = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: pais_consecutivoOferta, // se saca de lo de arriba :/
      requisitos: base.requisitos?.map((r) => ({
        requiereArchivo: r.requiereArchivo,
        fechaVencimiento: dayjs().add(1, 'year').format('YYYY-MM-DD'),
        descripcion: r.descripcion,
      })),
      habilidades: [],
    };
    await saveRequeriments(infoRequisitos);

    const infoOnboarding = {
      empresaId: enterprise.empresaId,
      pais_consecutivoOferta: pais_consecutivoOferta, // se saca de lo de arriba :/
      etapa: 'Informacion Onboarding',
      infoOnboarding: base.infoOnboarding,
    };
    await saveOnboarding(infoOnboarding);

    // Unicamente en caso de que el tipo de contrato de la plantilla sea ContratoObraFaena, se haran las siguientes acciones
    if (plantillaBase.tipoContrato === 'ContratoObraFaena') {
      // Las ofertas masivas nacen validadas ya que parten de una plantilla que previamente fue validada
      await validarOferta({
        empresaId: enterprise.empresaId,
        pais_consecutivoOferta: pais_consecutivoOferta,
        aprobada: true,
        comentarios: 'Oferta masiva',
        notificarTrabajadores: false,
      });
    }

    // Si se escogio un grupo de notificion por etiqueta...
    if (solicitud.etiquetaId) {
      await Promise.all([
        // Se realiza la invitación a los trabajadores seleccionados
        invitarEtiquetas(
          enterprise.empresaId,
          [pais_consecutivoOferta],
          [solicitud.etiquetaId],
          [],
          true // Le llegara un mensaje de whatsapp avisando de que hay nuevos trabajos disponibles
        ),
        // Se envía la notificación por whatsapp a los trabajadores que tengan la etiqueta seleccionada
        // notificarPorEtiquetas({
        //   empresaId: enterprise.empresaId,
        //   etiquetas: [solicitud.etiquetaId],
        //   mensajeId: 'Nuevo turno Obra o Faena disponible',
        //   pais_consecutivoOferta,
        // }),
      ]);
    }
  }

  message.success('Solicitudes creadas con exito');
}

function getWeekdayList(solicitud) {
  const dias = [];
  for (let i = 0; i < solicitud.duracion; i++) {
    dias.push(dayjs(solicitud.fecha).add(i, 'day').day());
  }
  return dias;
}

function getFinalDate(solicitud) {
  return solicitud.duracion > 1
    ? dayjs(solicitud.fecha).add(solicitud.duracion - 1, 'day')
    : dayjs(solicitud.fecha);
}

async function getInvalidSalaries(base, arrSolicitudes) {
  const salarioMinimo = 2556;
  const chequeos = await Promise.all(
    arrSolicitudes.map((solicitud) =>
      calcularBruto({
        liquido: solicitud.pago * getWeekdayList(solicitud).length,
        gratificacion: base.gratificacion || false,
        colacion: base.colacion || 0,
        transporte: base.transporte || 0,
      })
    )
  );

  const resultados = arrSolicitudes.map((solicitud, index) => {
    const horas = getHoras({
      fechas: [solicitud.fecha, getFinalDate(solicitud)],
      dias: getWeekdayList(solicitud),
      entrada: solicitud.entrada,
      salida: solicitud.salida,
      tipopago: base.turnos[0].unidad.toLowerCase(),
      colacion: base.turnos[0].tiempoColacion,
      pago: solicitud.pago,
    });
    return { chequeo: chequeos[index], horas, index };
  });

  const incumple = resultados.filter(
    ({ chequeo, horas }) =>
      !(
        chequeo?.bruto / horas?.fixHoras >=
        (chequeo?.minimoHora || salarioMinimo)
      )
  );

  return incumple.map(({ index }) => '#' + (index + 1)) || [];
}

function getInvalidHours(base, cargos, arrSolicitudes) {
  const cargosMap = cargos.reduce((map, cargo) => {
    const tipoTrabajo = cargo.rubro_tipoTrabajo.split('#')[1];
    map[tipoTrabajo] = cargo.duracionMaximaTurno;
    return map;
  }, {});

  const resultados = arrSolicitudes.map((solicitud, index) => {
    const arrDias = getWeekdayList(solicitud);
    const horas = getHoras({
      fechas: [solicitud.fecha, getFinalDate(solicitud)],
      dias: arrDias,
      entrada: solicitud.entrada,
      salida: solicitud.salida,
      tipopago: base.turnos[0].unidad.toLowerCase(),
      colacion: base.turnos[0].tiempoColacion,
      pago: solicitud.pago,
    });

    const horasMaximas = cargosMap[base.tipoTrabajo];
    return horas?.fixHoras > horasMaximas * arrDias.length
      ? '#' + (index + 1)
      : null;
  });

  return resultados.filter(Boolean);
}

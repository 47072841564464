import { useEffect, useState } from 'react';
import {
  getNotifications,
  markAsRead,
  subscribeToNotifications,
} from '../../services/NotificationsService';
import { useSelector } from 'react-redux';
import Meta from '../../components/layouts/Meta';
import { PageContainer } from '@ant-design/pro-components';
import { Card, message } from 'antd';
import ListadoNotificaciones from './components/ListadoNotificaciones';

export default function Notificaciones() {
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const [notifications, setNotifications] = useState(null);
  const routes = [{ path: '', breadcrumbName: 'Notificaciones' }];

  useEffect(() => {
    getNotificaciones();
    const subscription = notificationSubscribe();
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprise]);

  async function getNotificaciones() {
    try {
      const response = await getNotifications(enterprise);
      await leerTodas();
      setNotifications(response);
    } catch (error) {
      console.error(error);
      message.error('Error al obtener las notificaciones');
    }
  }

  function notificationSubscribe() {
    function callback(notification) {
      if (notification.destinatarioId === enterprise.empresaId) {
        setNotifications((prevNotifications) => [
          notification,
          ...prevNotifications,
        ]);
      }
    }
    if (!user) return null;
    return subscribeToNotifications(callback);
  }

  async function leerTodas() {
    try {
      const promises = notifications.map((notification) => {
        if (notification.leida) return Promise.resolve();
        return markAsRead(notification);
      });
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <PageContainer
      header={{
        title: 'Listado de notificaciones',
        ghost: true,
        breadcrumb: { routes },
      }}
      loading={notifications === null}
    >
      <Meta title="Notificaciones" />
      <Card>
        <ListadoNotificaciones notifications={notifications} />
      </Card>
    </PageContainer>
  );
}

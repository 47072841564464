import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Avatar, Empty, Skeleton, Tooltip } from 'antd';

export default function Listado({ comentarios }) {
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    getContentHeight();
    window.addEventListener('resize', getContentHeight);
    return () => {
      window.removeEventListener('resize', getContentHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comentarios]);

  function getContentHeight() {
    const itemA = document.getElementById('identificarComentario');
    const itemB = document.getElementById('formularioComentarios');
    const itemC = document.querySelector('.ant-drawer-header');

    const heightA = itemA?.offsetHeight + 24;
    const heightB = itemB?.offsetHeight;
    const heightC = itemC?.offsetHeight + 24 + 24;

    const totalHeight = heightA + heightB + heightC;
    const disponibleHeight = window.innerHeight - totalHeight;
    setContentHeight(disponibleHeight);
  }

  if (comentarios === null)
    return (
      <Skeleton
        avatar
        paragraph={{
          rows: 4,
        }}
      />
    );
  if (comentarios.length === 0)
    return <Empty description="No hay comentarios" />;

  return (
    <div
      className="overflow-auto overflow-x-hidden"
      style={{
        height: contentHeight,
      }}
    >
      {comentarios
        ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map((comentario) => (
          <Comentario comentario={comentario} />
        ))}
    </div>
  );
}

function Comentario({ comentario }) {
  return (
    <div className="flex space-x-2 mb-4">
      <div>
        <Tooltip title={comentario?.usuarioEmpresa}>
          <Avatar size="large" alt={comentario?.usuarioEmpresa}>
            {comentario?.usuarioEmpresa?.charAt(0).toUpperCase()}
            {comentario?.usuarioEmpresa?.charAt(1).toUpperCase()}
          </Avatar>
        </Tooltip>
      </div>
      <div className="bg-gray-100 p-2 rounded-md rounded-tl-none">
        <span className="text-xs text-gray-400">
          {dayjs(comentario.createdAt).fromNow()}
        </span>
        <p className="mb-1">{comentario.comentario}</p>
      </div>
    </div>
  );
}

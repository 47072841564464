import { Badge, Button, Divider, Form, Modal, Select, message } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRequisitions } from '../../../services/RequisitionsService';
import { invitarEtiquetas } from '../../../services/EtiquetasService';
import { MailOutlined } from '@ant-design/icons';

export default function InvitarAPostular({ etiquetas }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [solicitudes, setSolicitudes] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    buscarSolicitudes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function buscarSolicitudes() {
    try {
      setSolicitudes(null);
      const res = await getRequisitions(enterprise.empresaId, 'Activa');
      setSolicitudes(res);
    } catch (error) {
      setSolicitudes([]);
      message.error('Error al obtener las solicitudes');
    }
  }

  async function handleSubmit() {
    try {
      const values = await form.validateFields();
      setLoading(true);
      await invitarEtiquetas(enterprise.empresaId, values.solicitud, [
        values.etiqueta,
      ]);
      handleCancel();
      message.success('Invitados correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al invitar a postular');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setShow(false);
    form.resetFields();
  }

  return (
    <>
      <Button onClick={() => setShow(true)} icon={<MailOutlined />}>
        Invitar
      </Button>
      <Modal
        title="Invitar a postular"
        open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Invitar
          </Button>,
        ]}
      >
        <Divider />
        <p>
          Para invitar a postular debes escoger una solicitud de trabajo y
          seleccionar la etiqueta que deseas invitar.
        </p>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            solicitud: [],
            etiqueta: null,
          }}
          form={form}
        >
          <Form.Item
            label="Solicitud de trabajo"
            tooltip="Solo se muestran las solicitudes activas, en pausa y ejecutando"
            name="solicitud"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar una solicitud de trabajo',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una solicitud de trabajo"
              loading={!solicitudes}
              showSearch
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const search = solicitudes?.find(
                  (s) => s.pais_consecutivoOferta === option.value
                );
                return `#${search?.idOferta} - ${search?.tipoTrabajo}`
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase());
              }}
            >
              {solicitudes?.map(
                ({ idOferta, tipoTrabajo, pais_consecutivoOferta }) => (
                  <Select.Option key={idOferta} value={pais_consecutivoOferta}>
                    #{idOferta} - {tipoTrabajo}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Etiqueta"
            name="etiqueta"
            rules={[
              {
                required: true,
                message: 'Debes seleccionar una etiqueta',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una etiqueta"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.key.toLowerCase().includes(input.toLowerCase())
              }
            >
              {etiquetas?.map(
                ({ etiquetaId, nombre, color, totalTrabajadores }) => (
                  <Select.Option key={nombre} value={etiquetaId}>
                    <Badge
                      color={color}
                      text={`${nombre} (${totalTrabajadores})`}
                    />
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

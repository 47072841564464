import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function getEtiquetas(empresa) {
  const token = await getToken();
  const { data } = await API.get(
    'xinerlink',
    `/etiquetas/empresas/${empresa}`,
    {
      headers: {},
      response: true,
      queryStringParameters: {
        userToken: token,
      },
    }
  );
  if (data.results) return data.results;
  else return [];
}

export async function createEtiqueta(empresa, body) {
  const token = await getToken();
  const { data } = await API.post(
    'xinerlink',
    `/etiquetas/empresas/${empresa}`,
    {
      headers: {},
      response: true,
      queryStringParameters: {
        userToken: token,
      },
      body: body,
    }
  );
  if (data.nombre) return data.nombre;
  else return data.message;
}

export async function updateEtiqueta(empresa, etiqueta, body) {
  const token = await getToken();
  const { data } = await API.patch(
    'xinerlink',
    `/etiquetas/empresas/${empresa}/${etiqueta}`,
    {
      headers: {},
      response: true,
      queryStringParameters: {
        userToken: token,
      },
      body: body,
    }
  );
  if (data.nombre) return data.nombre;
  else return data.message;
}

export async function removeEtiqueta(empresa, trabajador, etiqueta) {
  const token = await getToken();
  const { data } = await API.del(
    'xinerlink',
    `/etiquetas/empresas/${empresa}/${trabajador}/${etiqueta}`,
    {
      headers: {},
      response: true,
      queryStringParameters: {
        userToken: token,
      },
    }
  );
  if (data.message) return data.message;
  else return data;
}

export async function asignarEtiquetas(empresa, selectedTag, trabajadores) {
  const token = await getToken();

  const promises = trabajadores.map(async ({ trabajadorId }) => {
    const { data } = await API.post(
      'xinerlink',
      `/etiquetas/empresas/${empresa}/${trabajadorId}`,
      {
        headers: {},
        response: true,
        queryStringParameters: {
          userToken: token,
        },
        body: {
          etiquetaId: selectedTag,
        },
      }
    );
    return data;
  });

  const results = await Promise.all(promises);
  return results;
}

export async function invitarEtiquetas(
  empresa,
  oferta,
  tags = [],
  emails = [],
  notificarTrabajadores = true
) {
  const token = await getToken();
  const { data } = await API.post('xinerlink', `/oferta/v1/invitar`, {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
    },
    body: {
      registro: {
        empresaId: empresa,
        pais_consecutivoOferta: oferta,
        invitaciones: tags,
        emails,
        notificarTrabajadores,
      },
    },
  });
  if (data.message) return data.message;
  else return data;
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBackground = /* GraphQL */ `
  mutation CreateBackground(
    $condition: ModelBackgroundConditionInput
    $input: CreateBackgroundInput!
  ) {
    createBackground(condition: $condition, input: $input) {
      anoInicio
      cargoTitulo
      createdAt
      descripcion
      duracionMeses
      institucion
      tipo_uuid
      trabajadorId
      updatedAt
      validadoXEST
    }
  }
`;
export const createCalificacion = /* GraphQL */ `
  mutation CreateCalificacion(
    $condition: ModelCalificacionConditionInput
    $input: CreateCalificacionInput!
  ) {
    createCalificacion(condition: $condition, input: $input) {
      createdAt
      detalles
      empresaId
      fecha
      pais_consecutivoOferta_trabajadorId
      tipo
      updatedAt
    }
  }
`;
export const createComentariosOfertaTrabajo = /* GraphQL */ `
  mutation CreateComentariosOfertaTrabajo(
    $condition: ModelComentariosOfertaTrabajoConditionInput
    $input: CreateComentariosOfertaTrabajoInput!
  ) {
    createComentariosOfertaTrabajo(condition: $condition, input: $input) {
      comentario
      createdAt
      empresaId
      pais_consecutivoOferta_fecha
      updatedAt
    }
  }
`;
export const createComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  mutation CreateComunaTipoTrabajoRecomendaciones(
    $condition: ModelComunaTipoTrabajoRecomendacionesConditionInput
    $input: CreateComunaTipoTrabajoRecomendacionesInput!
  ) {
    createComunaTipoTrabajoRecomendaciones(
      condition: $condition
      input: $input
    ) {
      createdAt
      pais
      region_ciudad_comuna_rubro_tipoTrabajo
      rubro_tipoTrabajo
      updatedAt
      valorMaxRecomendado
      valorMinLegal
      valorMinRecomendado
    }
  }
`;
export const createContador = /* GraphQL */ `
  mutation CreateContador(
    $condition: ModelContadorConditionInput
    $input: CreateContadorInput!
  ) {
    createContador(condition: $condition, input: $input) {
      contadorAceptadoXEmpresa
      contadorAceptadoXTrabajador
      contadorAceptadosTurno
      contadorContratoAnuladoXBackoffice
      contadorContratoAnuladoXTrabajador
      contadorContratoCerrado
      contadorContratoFirmadoXTrabajador
      contadorDesistidaXTrabajador
      contadorEjecutando
      contadorPostulado
      contadorPreGuardado
      contadorProvisionadoXEmpresa
      contadorRechazadoXEmpresa
      contadorRechazadoXTrabajador
      contadorSeleccionadoXEmpresa
      contadorSeleccionadosTurno
      contadorTerminado
      contadorValidado
      contadorVisitasUsuarioAutenticado
      contadorVisitasUsuarioNoAutenticado
      contratoAnuladoXEmpresa
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const createContrato = /* GraphQL */ `
  mutation CreateContrato(
    $condition: ModelContratoConditionInput
    $input: CreateContratoInput!
  ) {
    createContrato(condition: $condition, input: $input) {
      cantidadPersonasAContratar
      createdAt
      empresaId
      estadoActual
      historialEstados
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const createContratoPendiente = /* GraphQL */ `
  mutation CreateContratoPendiente(
    $condition: ModelContratoPendienteConditionInput
    $input: CreateContratoPendienteInput!
  ) {
    createContratoPendiente(condition: $condition, input: $input) {
      createdAt
      empresaId
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const createDetalleFacturacion = /* GraphQL */ `
  mutation CreateDetalleFacturacion(
    $condition: ModelDetalleFacturacionConditionInput
    $input: CreateDetalleFacturacionInput!
  ) {
    createDetalleFacturacion(condition: $condition, input: $input) {
      aprobadoPor
      createdAt
      dni
      duracion
      empresaId
      empresaId_paisConsecutivoOferta
      estadoAprobacion
      estadoEntrada
      estadoFacturacion
      estadoSalida
      factorMultiplicador
      facturacion
      fechaFin
      fechaInicio
      fecha_pais_consecutivoOferta_trabajadorId
      nombre
      primerApellido
      registroEntradaPor
      registroSalidaPor
      segundoApellido
      tipoTrabajo
      trabajadorId
      unidad
      updatedAt
      valorAFacturar
      valorAPagar
      valorBruto
      valorContribucion
      valorIVA
      valorImposicion
      valorLiquidado
      valorNeto
      valorPresupuestado
      valorUnitario
    }
  }
`;
export const createDocumentoEmpresa = /* GraphQL */ `
  mutation CreateDocumentoEmpresa(
    $condition: ModelDocumentoEmpresaConditionInput
    $input: CreateDocumentoEmpresaInput!
  ) {
    createDocumentoEmpresa(condition: $condition, input: $input) {
      createdAt
      empresaId
      llave
      nombre
      updatedAt
    }
  }
`;
export const createDocumentoTrabajador = /* GraphQL */ `
  mutation CreateDocumentoTrabajador(
    $condition: ModelDocumentoTrabajadorConditionInput
    $input: CreateDocumentoTrabajadorInput!
  ) {
    createDocumentoTrabajador(condition: $condition, input: $input) {
      createdAt
      fechaVencimiento
      nombre
      tipo
      trabajadorId
      updatedAt
      url
      validadoXEST
    }
  }
`;
export const createEmpresa = /* GraphQL */ `
  mutation CreateEmpresa(
    $condition: ModelEmpresaConditionInput
    $input: CreateEmpresaInput!
  ) {
    createEmpresa(condition: $condition, input: $input) {
      calificacionPromedio
      contadorOfertaTrabajo
      createdAt
      credito
      descripcion
      empresaId
      enlaceLogo
      estId
      fechaCreacion
      giro
      nombre
      saldo
      updatedAt
    }
  }
`;
export const createFacturacion = /* GraphQL */ `
  mutation CreateFacturacion(
    $condition: ModelFacturacionConditionInput
    $input: CreateFacturacionInput!
  ) {
    createFacturacion(condition: $condition, input: $input) {
      cargo
      createdAt
      empresa
      empresaId
      estadosFacturacion
      facturado
      liquidoFacturado
      liquidoPresupuestado
      mesFacturado
      numeroContable
      pais_consecutivoOferta_fecha
      trabajadores
      ubicacion
      updatedAt
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $condition: ModelFaqConditionInput
    $input: CreateFaqInput!
  ) {
    createFaq(condition: $condition, input: $input) {
      categoria
      createdAt
      faqId
      pregunta
      respuesta
      updatedAt
    }
  }
`;
export const createGeografia = /* GraphQL */ `
  mutation CreateGeografia(
    $condition: ModelGeografiaConditionInput
    $input: CreateGeografiaInput!
  ) {
    createGeografia(condition: $condition, input: $input) {
      createdAt
      idRegion_idCiudad_idComuna
      pais
      region_ciudad_comuna
      updatedAt
    }
  }
`;
export const createHabilidades = /* GraphQL */ `
  mutation CreateHabilidades(
    $condition: ModelHabilidadesConditionInput
    $input: CreateHabilidadesInput!
  ) {
    createHabilidades(condition: $condition, input: $input) {
      createdAt
      descripcion
      estado
      examen
      icono
      id
      nombre
      tipo
      updatedAt
      visibilidad
    }
  }
`;
export const createHistorialPagos = /* GraphQL */ `
  mutation CreateHistorialPagos(
    $condition: ModelHistorialPagosConditionInput
    $input: CreateHistorialPagosInput!
  ) {
    createHistorialPagos(condition: $condition, input: $input) {
      createdAt
      fecha
      historialPagoId
      motivo
      tipoTransaccion
      updatedAt
      valor
    }
  }
`;
export const createHistoricoTrabajador = /* GraphQL */ `
  mutation CreateHistoricoTrabajador(
    $condition: ModelHistoricoTrabajadorConditionInput
    $input: CreateHistoricoTrabajadorInput!
  ) {
    createHistoricoTrabajador(condition: $condition, input: $input) {
      createdAt
      duracionUltimoTrabajo
      empresaId
      fechaUltimoTrabajo
      nombreCortoTipoTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const createIdentificadorFirmante = /* GraphQL */ `
  mutation CreateIdentificadorFirmante(
    $condition: ModelIdentificadorFirmanteConditionInput
    $input: CreateIdentificadorFirmanteInput!
  ) {
    createIdentificadorFirmante(condition: $condition, input: $input) {
      correo
      createdAt
      identificadorTributario
      updatedAt
    }
  }
`;
export const createIdentificadorTributarioEmpresa = /* GraphQL */ `
  mutation CreateIdentificadorTributarioEmpresa(
    $condition: ModelIdentificadorTributarioEmpresaConditionInput
    $input: CreateIdentificadorTributarioEmpresaInput!
  ) {
    createIdentificadorTributarioEmpresa(condition: $condition, input: $input) {
      activo
      comuna
      correoRepresentanteLegal
      createdAt
      direccion
      empresaId
      identificadorTributario
      margenContribucion
      nombreFantasia
      nombreRepresentanteLegal
      razonSocial
      rutRepresentanteLegal
      updatedAt
    }
  }
`;
export const createLogCambiosTrabajador = /* GraphQL */ `
  mutation CreateLogCambiosTrabajador(
    $condition: ModelLogCambiosTrabajadorConditionInput
    $input: CreateLogCambiosTrabajadorInput!
  ) {
    createLogCambiosTrabajador(condition: $condition, input: $input) {
      cambios
      comentarioBackoffice
      correo
      createdAt
      fecha
      updatedAt
    }
  }
`;
export const createMultimediaEmpresa = /* GraphQL */ `
  mutation CreateMultimediaEmpresa(
    $condition: ModelMultimediaEmpresaConditionInput
    $input: CreateMultimediaEmpresaInput!
  ) {
    createMultimediaEmpresa(condition: $condition, input: $input) {
      correoUsuarioEmpresaId_uuid
      createdAt
      empresaId
      llave
      tag
      updatedAt
    }
  }
`;
export const createNotificacion = /* GraphQL */ `
  mutation CreateNotificacion(
    $condition: ModelNotificacionConditionInput
    $input: CreateNotificacionInput!
  ) {
    createNotificacion(condition: $condition, input: $input) {
      createdAt
      destinatarioId
      fecha
      leida
      mensaje
      payload
      remitenteId
      tipoNotificacion
      updatedAt
      urlDestino
    }
  }
`;
export const createOfertaActiva = /* GraphQL */ `
  mutation CreateOfertaActiva(
    $condition: ModelOfertaActivaConditionInput
    $input: CreateOfertaActivaInput!
  ) {
    createOfertaActiva(condition: $condition, input: $input) {
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const createOfertaTrabajo = /* GraphQL */ `
  mutation CreateOfertaTrabajo(
    $condition: ModelOfertaTrabajoConditionInput
    $input: CreateOfertaTrabajoInput!
  ) {
    createOfertaTrabajo(condition: $condition, input: $input) {
      brutoTotal
      causalContrado
      costoNeto
      costoServicio
      createdAt
      descripcionTipoTrabajo
      empresaId
      estadoActual
      facturado
      facturadoRealFinal
      fechaCaducidad
      firmaContrato
      historialEstados
      identificadorTributario
      infoOnboarding
      liquidoTotal
      multimediaOfertaId
      nombreContrato
      nuevosPostulantes
      pais_consecutivoOferta
      provisionFondos
      razonSocial
      rubro
      tipoTrabajo
      totalHoras
      totalVacantes
      turnos
      ubicacion
      updatedAt
      usuariosInvitados
    }
  }
`;
export const createOfertaTrabajoHabilidades = /* GraphQL */ `
  mutation CreateOfertaTrabajoHabilidades(
    $condition: ModelOfertaTrabajoHabilidadesConditionInput
    $input: CreateOfertaTrabajoHabilidadesInput!
  ) {
    createOfertaTrabajoHabilidades(condition: $condition, input: $input) {
      createdAt
      empresaId_pais_consecutivoOferta
      habilidadId
      obligatoria
      updatedAt
    }
  }
`;
export const createPorcentajeImposicionesPais = /* GraphQL */ `
  mutation CreatePorcentajeImposicionesPais(
    $condition: ModelPorcentajeImposicionesPaisConditionInput
    $input: CreatePorcentajeImposicionesPaisInput!
  ) {
    createPorcentajeImposicionesPais(condition: $condition, input: $input) {
      createdAt
      iva
      pais
      porcentaje
      updatedAt
      valorMinimoLegal
    }
  }
`;
export const createReplicaTalana = /* GraphQL */ `
  mutation CreateReplicaTalana(
    $condition: ModelReplicaTalanaConditionInput
    $input: CreateReplicaTalanaInput!
  ) {
    createReplicaTalana(condition: $condition, input: $input) {
      codigo
      creacion
      createdAt
      nombre
      pais
      razonSocial
      rut
      talanaId
      tipo
      tipoReplica
      updatedAt
      vigente
    }
  }
`;
export const createRequisito = /* GraphQL */ `
  mutation CreateRequisito(
    $condition: ModelRequisitoConditionInput
    $input: CreateRequisitoInput!
  ) {
    createRequisito(condition: $condition, input: $input) {
      createdAt
      descripcion
      empresaId
      fechaVencimiento
      pais_consecutivoOferta_uuid
      requiereArchivo
      updatedAt
    }
  }
`;
export const createRequisitosLegales = /* GraphQL */ `
  mutation CreateRequisitosLegales(
    $condition: ModelRequisitosLegalesConditionInput
    $input: CreateRequisitosLegalesInput!
  ) {
    createRequisitosLegales(condition: $condition, input: $input) {
      createdAt
      pais
      porcentajeBase
      porcentajeHoraExtra
      porcentajeParafiscales
      porcentajeSemanaCorrida
      updatedAt
    }
  }
`;
export const createRubroTipoTrabajo = /* GraphQL */ `
  mutation CreateRubroTipoTrabajo(
    $condition: ModelRubroTipoTrabajoConditionInput
    $input: CreateRubroTipoTrabajoInput!
  ) {
    createRubroTipoTrabajo(condition: $condition, input: $input) {
      createdAt
      descripcionRubro
      descripcionTipoTrabajo
      duracionMaximaTurno
      duracionMaximaTurnoParaColacion
      duracionMaximaTurnoXSemana
      habilidades
      horasMaximasXDia
      horasMaximasXMes
      horasMaximasXSemana
      nombreOficial
      pais
      porcentajeCostoFestivo
      rubro_tipoTrabajo
      tiempoColacion
      updatedAt
      valorMaxRecomendado
      valorMinRecomendado
    }
  }
`;
export const createSeguridadSocial = /* GraphQL */ `
  mutation CreateSeguridadSocial(
    $condition: ModelSeguridadSocialConditionInput
    $input: CreateSeguridadSocialInput!
  ) {
    createSeguridadSocial(condition: $condition, input: $input) {
      createdAt
      nombre
      talanaId
      tipo
      trabajadorId
      updatedAt
    }
  }
`;
export const createTipoUsuario = /* GraphQL */ `
  mutation CreateTipoUsuario(
    $condition: ModelTipoUsuarioConditionInput
    $input: CreateTipoUsuarioInput!
  ) {
    createTipoUsuario(condition: $condition, input: $input) {
      createdAt
      dotacionDiaria
      firmaPendiente
      gestionAsistencias
      idTipoUsuario
      menuSolicitudes
      multimediaEmpresa
      nombre
      nuevaSolicitud
      panelDeControl
      perfilEmpresa
      principal
      provisionPendiente
      recargar
      rutsAsociados
      solicitudes
      trabajosEnCurso
      transacciones
      ubicaciones
      updatedAt
      usuarios
      verTodasSolicitudes
    }
  }
`;
export const createTrabajador = /* GraphQL */ `
  mutation CreateTrabajador(
    $condition: ModelTrabajadorConditionInput
    $input: CreateTrabajadorInput!
  ) {
    createTrabajador(condition: $condition, input: $input) {
      activo
      banco
      calificacionPromedio
      canalesNotificaciones
      ciudad
      clave
      comuna
      correo
      createdAt
      descripcion
      direccion
      dni
      enlaceFoto
      estId
      fechaNacimiento
      firebaseToken
      idBanco
      nacionalidad
      noCuentaBancaria
      nombre
      pais
      perfiles
      primerApellido
      region
      segundaClave
      segundoApellido
      telefono
      tipoCuenta
      ultimaFechaConsultaAnuncios
      updatedAt
      validadoXEST
      visibilidadFoto
    }
  }
`;
export const createTrabajadorCalendario = /* GraphQL */ `
  mutation CreateTrabajadorCalendario(
    $condition: ModelTrabajadorCalendarioConditionInput
    $input: CreateTrabajadorCalendarioInput!
  ) {
    createTrabajadorCalendario(condition: $condition, input: $input) {
      aprobado
      confirmado
      createdAt
      duracion
      empresaId
      entrada
      estadoAprobacion
      estadoEntrada
      estadoSalida
      fechaFin
      fecha_empresaId_pais_consecutivoOferta
      pais_consecutivoOferta
      salida
      timeZone
      trabajadorId
      turno
      ubicacionId
      ultimoDia
      unidad
      updatedAt
      valorUnitario
    }
  }
`;
export const createTrabajadorHabilidades = /* GraphQL */ `
  mutation CreateTrabajadorHabilidades(
    $condition: ModelTrabajadorHabilidadesConditionInput
    $input: CreateTrabajadorHabilidadesInput!
  ) {
    createTrabajadorHabilidades(condition: $condition, input: $input) {
      certificado
      correo
      createdAt
      estado
      fechaSolicitud
      habilidadId
      historialEstados
      infoAdicionalCertificado
      resultadoExamen
      tipoHabilidad
      updatedAt
    }
  }
`;
export const createTrabajadorOferta = /* GraphQL */ `
  mutation CreateTrabajadorOferta(
    $condition: ModelTrabajadorOfertaConditionInput
    $input: CreateTrabajadorOfertaInput!
  ) {
    createTrabajadorOferta(condition: $condition, input: $input) {
      contratoEmpresa
      contratoId
      contratoTrabajador
      createdAt
      cumplimientoRequisito
      declaracionJuradaSalud
      desvinculacion
      empresaId
      empresaId_pais_consecutivoOferta
      estadoActual
      finiquito
      historialEstados
      infoOnboardingLeida
      pactoHorasExtra
      pais_consecutivoOferta_trabajadorId
      trabajadorId
      turnos
      turnosExtra
      updatedAt
      valorAPagar
    }
  }
`;
export const createTrabajadorPago = /* GraphQL */ `
  mutation CreateTrabajadorPago(
    $condition: ModelTrabajadorPagoConditionInput
    $input: CreateTrabajadorPagoInput!
  ) {
    createTrabajadorPago(condition: $condition, input: $input) {
      concepto
      createdAt
      empresaId_pais_consecutivoOferta
      fecha_uuid
      historialPagoId
      trabajadorId
      updatedAt
      valor
    }
  }
`;
export const createTrabajoFavorito = /* GraphQL */ `
  mutation CreateTrabajoFavorito(
    $condition: ModelTrabajoFavoritoConditionInput
    $input: CreateTrabajoFavoritoInput!
  ) {
    createTrabajoFavorito(condition: $condition, input: $input) {
      createdAt
      ofertasTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const createTransaccion = /* GraphQL */ `
  mutation CreateTransaccion(
    $condition: ModelTransaccionConditionInput
    $input: CreateTransaccionInput!
  ) {
    createTransaccion(condition: $condition, input: $input) {
      correo
      createdAt
      detalle
      empresaId
      fecha_uuid
      monto
      observacion
      tipo
      updatedAt
    }
  }
`;
export const createUbicacion = /* GraphQL */ `
  mutation CreateUbicacion(
    $condition: ModelUbicacionConditionInput
    $input: CreateUbicacionInput!
  ) {
    createUbicacion(condition: $condition, input: $input) {
      activa
      codigoPostal
      createdAt
      direccion
      empresaId
      nombre
      pais_region_ciudad_comuna_uuid
      timezone
      ubicacion
      updatedAt
    }
  }
`;
export const createUbicacionSupervisor = /* GraphQL */ `
  mutation CreateUbicacionSupervisor(
    $condition: ModelUbicacionSupervisorConditionInput
    $input: CreateUbicacionSupervisorInput!
  ) {
    createUbicacionSupervisor(condition: $condition, input: $input) {
      correo
      createdAt
      pais_region_ciudad_comuna_uuid
      updatedAt
    }
  }
`;
export const createUsuarioBackoffice = /* GraphQL */ `
  mutation CreateUsuarioBackoffice(
    $condition: ModelUsuarioBackofficeConditionInput
    $input: CreateUsuarioBackofficeInput!
  ) {
    createUsuarioBackoffice(condition: $condition, input: $input) {
      cargo
      correo
      createdAt
      nombre
      telefono
      updatedAt
    }
  }
`;
export const createUsuarioEmpresa = /* GraphQL */ `
  mutation CreateUsuarioEmpresa(
    $condition: ModelUsuarioEmpresaConditionInput
    $input: CreateUsuarioEmpresaInput!
  ) {
    createUsuarioEmpresa(condition: $condition, input: $input) {
      activo
      cargo
      clave
      correo
      createdAt
      empresaId
      nombre
      telefono
      tipoUsuario
      updatedAt
    }
  }
`;
export const deleteBackground = /* GraphQL */ `
  mutation DeleteBackground(
    $condition: ModelBackgroundConditionInput
    $input: DeleteBackgroundInput!
  ) {
    deleteBackground(condition: $condition, input: $input) {
      anoInicio
      cargoTitulo
      createdAt
      descripcion
      duracionMeses
      institucion
      tipo_uuid
      trabajadorId
      updatedAt
      validadoXEST
    }
  }
`;
export const deleteCalificacion = /* GraphQL */ `
  mutation DeleteCalificacion(
    $condition: ModelCalificacionConditionInput
    $input: DeleteCalificacionInput!
  ) {
    deleteCalificacion(condition: $condition, input: $input) {
      createdAt
      detalles
      empresaId
      fecha
      pais_consecutivoOferta_trabajadorId
      tipo
      updatedAt
    }
  }
`;
export const deleteComentariosOfertaTrabajo = /* GraphQL */ `
  mutation DeleteComentariosOfertaTrabajo(
    $condition: ModelComentariosOfertaTrabajoConditionInput
    $input: DeleteComentariosOfertaTrabajoInput!
  ) {
    deleteComentariosOfertaTrabajo(condition: $condition, input: $input) {
      comentario
      createdAt
      empresaId
      pais_consecutivoOferta_fecha
      updatedAt
    }
  }
`;
export const deleteComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  mutation DeleteComunaTipoTrabajoRecomendaciones(
    $condition: ModelComunaTipoTrabajoRecomendacionesConditionInput
    $input: DeleteComunaTipoTrabajoRecomendacionesInput!
  ) {
    deleteComunaTipoTrabajoRecomendaciones(
      condition: $condition
      input: $input
    ) {
      createdAt
      pais
      region_ciudad_comuna_rubro_tipoTrabajo
      rubro_tipoTrabajo
      updatedAt
      valorMaxRecomendado
      valorMinLegal
      valorMinRecomendado
    }
  }
`;
export const deleteContador = /* GraphQL */ `
  mutation DeleteContador(
    $condition: ModelContadorConditionInput
    $input: DeleteContadorInput!
  ) {
    deleteContador(condition: $condition, input: $input) {
      contadorAceptadoXEmpresa
      contadorAceptadoXTrabajador
      contadorAceptadosTurno
      contadorContratoAnuladoXBackoffice
      contadorContratoAnuladoXTrabajador
      contadorContratoCerrado
      contadorContratoFirmadoXTrabajador
      contadorDesistidaXTrabajador
      contadorEjecutando
      contadorPostulado
      contadorPreGuardado
      contadorProvisionadoXEmpresa
      contadorRechazadoXEmpresa
      contadorRechazadoXTrabajador
      contadorSeleccionadoXEmpresa
      contadorSeleccionadosTurno
      contadorTerminado
      contadorValidado
      contadorVisitasUsuarioAutenticado
      contadorVisitasUsuarioNoAutenticado
      contratoAnuladoXEmpresa
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const deleteContrato = /* GraphQL */ `
  mutation DeleteContrato(
    $condition: ModelContratoConditionInput
    $input: DeleteContratoInput!
  ) {
    deleteContrato(condition: $condition, input: $input) {
      cantidadPersonasAContratar
      createdAt
      empresaId
      estadoActual
      historialEstados
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const deleteContratoPendiente = /* GraphQL */ `
  mutation DeleteContratoPendiente(
    $condition: ModelContratoPendienteConditionInput
    $input: DeleteContratoPendienteInput!
  ) {
    deleteContratoPendiente(condition: $condition, input: $input) {
      createdAt
      empresaId
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const deleteDetalleFacturacion = /* GraphQL */ `
  mutation DeleteDetalleFacturacion(
    $condition: ModelDetalleFacturacionConditionInput
    $input: DeleteDetalleFacturacionInput!
  ) {
    deleteDetalleFacturacion(condition: $condition, input: $input) {
      aprobadoPor
      createdAt
      dni
      duracion
      empresaId
      empresaId_paisConsecutivoOferta
      estadoAprobacion
      estadoEntrada
      estadoFacturacion
      estadoSalida
      factorMultiplicador
      facturacion
      fechaFin
      fechaInicio
      fecha_pais_consecutivoOferta_trabajadorId
      nombre
      primerApellido
      registroEntradaPor
      registroSalidaPor
      segundoApellido
      tipoTrabajo
      trabajadorId
      unidad
      updatedAt
      valorAFacturar
      valorAPagar
      valorBruto
      valorContribucion
      valorIVA
      valorImposicion
      valorLiquidado
      valorNeto
      valorPresupuestado
      valorUnitario
    }
  }
`;
export const deleteDocumentoEmpresa = /* GraphQL */ `
  mutation DeleteDocumentoEmpresa(
    $condition: ModelDocumentoEmpresaConditionInput
    $input: DeleteDocumentoEmpresaInput!
  ) {
    deleteDocumentoEmpresa(condition: $condition, input: $input) {
      createdAt
      empresaId
      llave
      nombre
      updatedAt
    }
  }
`;
export const deleteDocumentoTrabajador = /* GraphQL */ `
  mutation DeleteDocumentoTrabajador(
    $condition: ModelDocumentoTrabajadorConditionInput
    $input: DeleteDocumentoTrabajadorInput!
  ) {
    deleteDocumentoTrabajador(condition: $condition, input: $input) {
      createdAt
      fechaVencimiento
      nombre
      tipo
      trabajadorId
      updatedAt
      url
      validadoXEST
    }
  }
`;
export const deleteEmpresa = /* GraphQL */ `
  mutation DeleteEmpresa(
    $condition: ModelEmpresaConditionInput
    $input: DeleteEmpresaInput!
  ) {
    deleteEmpresa(condition: $condition, input: $input) {
      calificacionPromedio
      contadorOfertaTrabajo
      createdAt
      credito
      descripcion
      empresaId
      enlaceLogo
      estId
      fechaCreacion
      giro
      nombre
      saldo
      updatedAt
    }
  }
`;
export const deleteFacturacion = /* GraphQL */ `
  mutation DeleteFacturacion(
    $condition: ModelFacturacionConditionInput
    $input: DeleteFacturacionInput!
  ) {
    deleteFacturacion(condition: $condition, input: $input) {
      cargo
      createdAt
      empresa
      empresaId
      estadosFacturacion
      facturado
      liquidoFacturado
      liquidoPresupuestado
      mesFacturado
      numeroContable
      pais_consecutivoOferta_fecha
      trabajadores
      ubicacion
      updatedAt
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $condition: ModelFaqConditionInput
    $input: DeleteFaqInput!
  ) {
    deleteFaq(condition: $condition, input: $input) {
      categoria
      createdAt
      faqId
      pregunta
      respuesta
      updatedAt
    }
  }
`;
export const deleteGeografia = /* GraphQL */ `
  mutation DeleteGeografia(
    $condition: ModelGeografiaConditionInput
    $input: DeleteGeografiaInput!
  ) {
    deleteGeografia(condition: $condition, input: $input) {
      createdAt
      idRegion_idCiudad_idComuna
      pais
      region_ciudad_comuna
      updatedAt
    }
  }
`;
export const deleteHabilidades = /* GraphQL */ `
  mutation DeleteHabilidades(
    $condition: ModelHabilidadesConditionInput
    $input: DeleteHabilidadesInput!
  ) {
    deleteHabilidades(condition: $condition, input: $input) {
      createdAt
      descripcion
      estado
      examen
      icono
      id
      nombre
      tipo
      updatedAt
      visibilidad
    }
  }
`;
export const deleteHistorialPagos = /* GraphQL */ `
  mutation DeleteHistorialPagos(
    $condition: ModelHistorialPagosConditionInput
    $input: DeleteHistorialPagosInput!
  ) {
    deleteHistorialPagos(condition: $condition, input: $input) {
      createdAt
      fecha
      historialPagoId
      motivo
      tipoTransaccion
      updatedAt
      valor
    }
  }
`;
export const deleteHistoricoTrabajador = /* GraphQL */ `
  mutation DeleteHistoricoTrabajador(
    $condition: ModelHistoricoTrabajadorConditionInput
    $input: DeleteHistoricoTrabajadorInput!
  ) {
    deleteHistoricoTrabajador(condition: $condition, input: $input) {
      createdAt
      duracionUltimoTrabajo
      empresaId
      fechaUltimoTrabajo
      nombreCortoTipoTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const deleteIdentificadorFirmante = /* GraphQL */ `
  mutation DeleteIdentificadorFirmante(
    $condition: ModelIdentificadorFirmanteConditionInput
    $input: DeleteIdentificadorFirmanteInput!
  ) {
    deleteIdentificadorFirmante(condition: $condition, input: $input) {
      correo
      createdAt
      identificadorTributario
      updatedAt
    }
  }
`;
export const deleteIdentificadorTributarioEmpresa = /* GraphQL */ `
  mutation DeleteIdentificadorTributarioEmpresa(
    $condition: ModelIdentificadorTributarioEmpresaConditionInput
    $input: DeleteIdentificadorTributarioEmpresaInput!
  ) {
    deleteIdentificadorTributarioEmpresa(condition: $condition, input: $input) {
      activo
      comuna
      correoRepresentanteLegal
      createdAt
      direccion
      empresaId
      identificadorTributario
      margenContribucion
      nombreFantasia
      nombreRepresentanteLegal
      razonSocial
      rutRepresentanteLegal
      updatedAt
    }
  }
`;
export const deleteLogCambiosTrabajador = /* GraphQL */ `
  mutation DeleteLogCambiosTrabajador(
    $condition: ModelLogCambiosTrabajadorConditionInput
    $input: DeleteLogCambiosTrabajadorInput!
  ) {
    deleteLogCambiosTrabajador(condition: $condition, input: $input) {
      cambios
      comentarioBackoffice
      correo
      createdAt
      fecha
      updatedAt
    }
  }
`;
export const deleteMultimediaEmpresa = /* GraphQL */ `
  mutation DeleteMultimediaEmpresa(
    $condition: ModelMultimediaEmpresaConditionInput
    $input: DeleteMultimediaEmpresaInput!
  ) {
    deleteMultimediaEmpresa(condition: $condition, input: $input) {
      correoUsuarioEmpresaId_uuid
      createdAt
      empresaId
      llave
      tag
      updatedAt
    }
  }
`;
export const deleteNotificacion = /* GraphQL */ `
  mutation DeleteNotificacion(
    $condition: ModelNotificacionConditionInput
    $input: DeleteNotificacionInput!
  ) {
    deleteNotificacion(condition: $condition, input: $input) {
      createdAt
      destinatarioId
      fecha
      leida
      mensaje
      payload
      remitenteId
      tipoNotificacion
      updatedAt
      urlDestino
    }
  }
`;
export const deleteOfertaActiva = /* GraphQL */ `
  mutation DeleteOfertaActiva(
    $condition: ModelOfertaActivaConditionInput
    $input: DeleteOfertaActivaInput!
  ) {
    deleteOfertaActiva(condition: $condition, input: $input) {
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const deleteOfertaTrabajo = /* GraphQL */ `
  mutation DeleteOfertaTrabajo(
    $condition: ModelOfertaTrabajoConditionInput
    $input: DeleteOfertaTrabajoInput!
  ) {
    deleteOfertaTrabajo(condition: $condition, input: $input) {
      brutoTotal
      causalContrado
      costoNeto
      costoServicio
      createdAt
      descripcionTipoTrabajo
      empresaId
      estadoActual
      facturado
      facturadoRealFinal
      fechaCaducidad
      firmaContrato
      historialEstados
      identificadorTributario
      infoOnboarding
      liquidoTotal
      multimediaOfertaId
      nombreContrato
      nuevosPostulantes
      pais_consecutivoOferta
      provisionFondos
      razonSocial
      rubro
      tipoTrabajo
      totalHoras
      totalVacantes
      turnos
      ubicacion
      updatedAt
      usuariosInvitados
    }
  }
`;
export const deleteOfertaTrabajoHabilidades = /* GraphQL */ `
  mutation DeleteOfertaTrabajoHabilidades(
    $condition: ModelOfertaTrabajoHabilidadesConditionInput
    $input: DeleteOfertaTrabajoHabilidadesInput!
  ) {
    deleteOfertaTrabajoHabilidades(condition: $condition, input: $input) {
      createdAt
      empresaId_pais_consecutivoOferta
      habilidadId
      obligatoria
      updatedAt
    }
  }
`;
export const deletePorcentajeImposicionesPais = /* GraphQL */ `
  mutation DeletePorcentajeImposicionesPais(
    $condition: ModelPorcentajeImposicionesPaisConditionInput
    $input: DeletePorcentajeImposicionesPaisInput!
  ) {
    deletePorcentajeImposicionesPais(condition: $condition, input: $input) {
      createdAt
      iva
      pais
      porcentaje
      updatedAt
      valorMinimoLegal
    }
  }
`;
export const deleteReplicaTalana = /* GraphQL */ `
  mutation DeleteReplicaTalana(
    $condition: ModelReplicaTalanaConditionInput
    $input: DeleteReplicaTalanaInput!
  ) {
    deleteReplicaTalana(condition: $condition, input: $input) {
      codigo
      creacion
      createdAt
      nombre
      pais
      razonSocial
      rut
      talanaId
      tipo
      tipoReplica
      updatedAt
      vigente
    }
  }
`;
export const deleteRequisito = /* GraphQL */ `
  mutation DeleteRequisito(
    $condition: ModelRequisitoConditionInput
    $input: DeleteRequisitoInput!
  ) {
    deleteRequisito(condition: $condition, input: $input) {
      createdAt
      descripcion
      empresaId
      fechaVencimiento
      pais_consecutivoOferta_uuid
      requiereArchivo
      updatedAt
    }
  }
`;
export const deleteRequisitosLegales = /* GraphQL */ `
  mutation DeleteRequisitosLegales(
    $condition: ModelRequisitosLegalesConditionInput
    $input: DeleteRequisitosLegalesInput!
  ) {
    deleteRequisitosLegales(condition: $condition, input: $input) {
      createdAt
      pais
      porcentajeBase
      porcentajeHoraExtra
      porcentajeParafiscales
      porcentajeSemanaCorrida
      updatedAt
    }
  }
`;
export const deleteRubroTipoTrabajo = /* GraphQL */ `
  mutation DeleteRubroTipoTrabajo(
    $condition: ModelRubroTipoTrabajoConditionInput
    $input: DeleteRubroTipoTrabajoInput!
  ) {
    deleteRubroTipoTrabajo(condition: $condition, input: $input) {
      createdAt
      descripcionRubro
      descripcionTipoTrabajo
      duracionMaximaTurno
      duracionMaximaTurnoParaColacion
      duracionMaximaTurnoXSemana
      habilidades
      horasMaximasXDia
      horasMaximasXMes
      horasMaximasXSemana
      nombreOficial
      pais
      porcentajeCostoFestivo
      rubro_tipoTrabajo
      tiempoColacion
      updatedAt
      valorMaxRecomendado
      valorMinRecomendado
    }
  }
`;
export const deleteSeguridadSocial = /* GraphQL */ `
  mutation DeleteSeguridadSocial(
    $condition: ModelSeguridadSocialConditionInput
    $input: DeleteSeguridadSocialInput!
  ) {
    deleteSeguridadSocial(condition: $condition, input: $input) {
      createdAt
      nombre
      talanaId
      tipo
      trabajadorId
      updatedAt
    }
  }
`;
export const deleteTipoUsuario = /* GraphQL */ `
  mutation DeleteTipoUsuario(
    $condition: ModelTipoUsuarioConditionInput
    $input: DeleteTipoUsuarioInput!
  ) {
    deleteTipoUsuario(condition: $condition, input: $input) {
      createdAt
      dotacionDiaria
      firmaPendiente
      gestionAsistencias
      idTipoUsuario
      menuSolicitudes
      multimediaEmpresa
      nombre
      nuevaSolicitud
      panelDeControl
      perfilEmpresa
      principal
      provisionPendiente
      recargar
      rutsAsociados
      solicitudes
      trabajosEnCurso
      transacciones
      ubicaciones
      updatedAt
      usuarios
      verTodasSolicitudes
    }
  }
`;
export const deleteTrabajador = /* GraphQL */ `
  mutation DeleteTrabajador(
    $condition: ModelTrabajadorConditionInput
    $input: DeleteTrabajadorInput!
  ) {
    deleteTrabajador(condition: $condition, input: $input) {
      activo
      banco
      calificacionPromedio
      canalesNotificaciones
      ciudad
      clave
      comuna
      correo
      createdAt
      descripcion
      direccion
      dni
      enlaceFoto
      estId
      fechaNacimiento
      firebaseToken
      idBanco
      nacionalidad
      noCuentaBancaria
      nombre
      pais
      perfiles
      primerApellido
      region
      segundaClave
      segundoApellido
      telefono
      tipoCuenta
      ultimaFechaConsultaAnuncios
      updatedAt
      validadoXEST
      visibilidadFoto
    }
  }
`;
export const deleteTrabajadorCalendario = /* GraphQL */ `
  mutation DeleteTrabajadorCalendario(
    $condition: ModelTrabajadorCalendarioConditionInput
    $input: DeleteTrabajadorCalendarioInput!
  ) {
    deleteTrabajadorCalendario(condition: $condition, input: $input) {
      aprobado
      confirmado
      createdAt
      duracion
      empresaId
      entrada
      estadoAprobacion
      estadoEntrada
      estadoSalida
      fechaFin
      fecha_empresaId_pais_consecutivoOferta
      pais_consecutivoOferta
      salida
      timeZone
      trabajadorId
      turno
      ubicacionId
      ultimoDia
      unidad
      updatedAt
      valorUnitario
    }
  }
`;
export const deleteTrabajadorHabilidades = /* GraphQL */ `
  mutation DeleteTrabajadorHabilidades(
    $condition: ModelTrabajadorHabilidadesConditionInput
    $input: DeleteTrabajadorHabilidadesInput!
  ) {
    deleteTrabajadorHabilidades(condition: $condition, input: $input) {
      certificado
      correo
      createdAt
      estado
      fechaSolicitud
      habilidadId
      historialEstados
      infoAdicionalCertificado
      resultadoExamen
      tipoHabilidad
      updatedAt
    }
  }
`;
export const deleteTrabajadorOferta = /* GraphQL */ `
  mutation DeleteTrabajadorOferta(
    $condition: ModelTrabajadorOfertaConditionInput
    $input: DeleteTrabajadorOfertaInput!
  ) {
    deleteTrabajadorOferta(condition: $condition, input: $input) {
      contratoEmpresa
      contratoId
      contratoTrabajador
      createdAt
      cumplimientoRequisito
      declaracionJuradaSalud
      desvinculacion
      empresaId
      empresaId_pais_consecutivoOferta
      estadoActual
      finiquito
      historialEstados
      infoOnboardingLeida
      pactoHorasExtra
      pais_consecutivoOferta_trabajadorId
      trabajadorId
      turnos
      turnosExtra
      updatedAt
      valorAPagar
    }
  }
`;
export const deleteTrabajadorPago = /* GraphQL */ `
  mutation DeleteTrabajadorPago(
    $condition: ModelTrabajadorPagoConditionInput
    $input: DeleteTrabajadorPagoInput!
  ) {
    deleteTrabajadorPago(condition: $condition, input: $input) {
      concepto
      createdAt
      empresaId_pais_consecutivoOferta
      fecha_uuid
      historialPagoId
      trabajadorId
      updatedAt
      valor
    }
  }
`;
export const deleteTrabajoFavorito = /* GraphQL */ `
  mutation DeleteTrabajoFavorito(
    $condition: ModelTrabajoFavoritoConditionInput
    $input: DeleteTrabajoFavoritoInput!
  ) {
    deleteTrabajoFavorito(condition: $condition, input: $input) {
      createdAt
      ofertasTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const deleteTransaccion = /* GraphQL */ `
  mutation DeleteTransaccion(
    $condition: ModelTransaccionConditionInput
    $input: DeleteTransaccionInput!
  ) {
    deleteTransaccion(condition: $condition, input: $input) {
      correo
      createdAt
      detalle
      empresaId
      fecha_uuid
      monto
      observacion
      tipo
      updatedAt
    }
  }
`;
export const deleteUbicacion = /* GraphQL */ `
  mutation DeleteUbicacion(
    $condition: ModelUbicacionConditionInput
    $input: DeleteUbicacionInput!
  ) {
    deleteUbicacion(condition: $condition, input: $input) {
      activa
      codigoPostal
      createdAt
      direccion
      empresaId
      nombre
      pais_region_ciudad_comuna_uuid
      timezone
      ubicacion
      updatedAt
    }
  }
`;
export const deleteUbicacionSupervisor = /* GraphQL */ `
  mutation DeleteUbicacionSupervisor(
    $condition: ModelUbicacionSupervisorConditionInput
    $input: DeleteUbicacionSupervisorInput!
  ) {
    deleteUbicacionSupervisor(condition: $condition, input: $input) {
      correo
      createdAt
      pais_region_ciudad_comuna_uuid
      updatedAt
    }
  }
`;
export const deleteUsuarioBackoffice = /* GraphQL */ `
  mutation DeleteUsuarioBackoffice(
    $condition: ModelUsuarioBackofficeConditionInput
    $input: DeleteUsuarioBackofficeInput!
  ) {
    deleteUsuarioBackoffice(condition: $condition, input: $input) {
      cargo
      correo
      createdAt
      nombre
      telefono
      updatedAt
    }
  }
`;
export const deleteUsuarioEmpresa = /* GraphQL */ `
  mutation DeleteUsuarioEmpresa(
    $condition: ModelUsuarioEmpresaConditionInput
    $input: DeleteUsuarioEmpresaInput!
  ) {
    deleteUsuarioEmpresa(condition: $condition, input: $input) {
      activo
      cargo
      clave
      correo
      createdAt
      empresaId
      nombre
      telefono
      tipoUsuario
      updatedAt
    }
  }
`;
export const updateBackground = /* GraphQL */ `
  mutation UpdateBackground(
    $condition: ModelBackgroundConditionInput
    $input: UpdateBackgroundInput!
  ) {
    updateBackground(condition: $condition, input: $input) {
      anoInicio
      cargoTitulo
      createdAt
      descripcion
      duracionMeses
      institucion
      tipo_uuid
      trabajadorId
      updatedAt
      validadoXEST
    }
  }
`;
export const updateCalificacion = /* GraphQL */ `
  mutation UpdateCalificacion(
    $condition: ModelCalificacionConditionInput
    $input: UpdateCalificacionInput!
  ) {
    updateCalificacion(condition: $condition, input: $input) {
      createdAt
      detalles
      empresaId
      fecha
      pais_consecutivoOferta_trabajadorId
      tipo
      updatedAt
    }
  }
`;
export const updateComentariosOfertaTrabajo = /* GraphQL */ `
  mutation UpdateComentariosOfertaTrabajo(
    $condition: ModelComentariosOfertaTrabajoConditionInput
    $input: UpdateComentariosOfertaTrabajoInput!
  ) {
    updateComentariosOfertaTrabajo(condition: $condition, input: $input) {
      comentario
      createdAt
      empresaId
      pais_consecutivoOferta_fecha
      updatedAt
    }
  }
`;
export const updateComunaTipoTrabajoRecomendaciones = /* GraphQL */ `
  mutation UpdateComunaTipoTrabajoRecomendaciones(
    $condition: ModelComunaTipoTrabajoRecomendacionesConditionInput
    $input: UpdateComunaTipoTrabajoRecomendacionesInput!
  ) {
    updateComunaTipoTrabajoRecomendaciones(
      condition: $condition
      input: $input
    ) {
      createdAt
      pais
      region_ciudad_comuna_rubro_tipoTrabajo
      rubro_tipoTrabajo
      updatedAt
      valorMaxRecomendado
      valorMinLegal
      valorMinRecomendado
    }
  }
`;
export const updateContador = /* GraphQL */ `
  mutation UpdateContador(
    $condition: ModelContadorConditionInput
    $input: UpdateContadorInput!
  ) {
    updateContador(condition: $condition, input: $input) {
      contadorAceptadoXEmpresa
      contadorAceptadoXTrabajador
      contadorAceptadosTurno
      contadorContratoAnuladoXBackoffice
      contadorContratoAnuladoXTrabajador
      contadorContratoCerrado
      contadorContratoFirmadoXTrabajador
      contadorDesistidaXTrabajador
      contadorEjecutando
      contadorPostulado
      contadorPreGuardado
      contadorProvisionadoXEmpresa
      contadorRechazadoXEmpresa
      contadorRechazadoXTrabajador
      contadorSeleccionadoXEmpresa
      contadorSeleccionadosTurno
      contadorTerminado
      contadorValidado
      contadorVisitasUsuarioAutenticado
      contadorVisitasUsuarioNoAutenticado
      contratoAnuladoXEmpresa
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const updateContrato = /* GraphQL */ `
  mutation UpdateContrato(
    $condition: ModelContratoConditionInput
    $input: UpdateContratoInput!
  ) {
    updateContrato(condition: $condition, input: $input) {
      cantidadPersonasAContratar
      createdAt
      empresaId
      estadoActual
      historialEstados
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const updateContratoPendiente = /* GraphQL */ `
  mutation UpdateContratoPendiente(
    $condition: ModelContratoPendienteConditionInput
    $input: UpdateContratoPendienteInput!
  ) {
    updateContratoPendiente(condition: $condition, input: $input) {
      createdAt
      empresaId
      pais_consecutivoOferta_uuid
      updatedAt
    }
  }
`;
export const updateDetalleFacturacion = /* GraphQL */ `
  mutation UpdateDetalleFacturacion(
    $condition: ModelDetalleFacturacionConditionInput
    $input: UpdateDetalleFacturacionInput!
  ) {
    updateDetalleFacturacion(condition: $condition, input: $input) {
      aprobadoPor
      createdAt
      dni
      duracion
      empresaId
      empresaId_paisConsecutivoOferta
      estadoAprobacion
      estadoEntrada
      estadoFacturacion
      estadoSalida
      factorMultiplicador
      facturacion
      fechaFin
      fechaInicio
      fecha_pais_consecutivoOferta_trabajadorId
      nombre
      primerApellido
      registroEntradaPor
      registroSalidaPor
      segundoApellido
      tipoTrabajo
      trabajadorId
      unidad
      updatedAt
      valorAFacturar
      valorAPagar
      valorBruto
      valorContribucion
      valorIVA
      valorImposicion
      valorLiquidado
      valorNeto
      valorPresupuestado
      valorUnitario
    }
  }
`;
export const updateDocumentoEmpresa = /* GraphQL */ `
  mutation UpdateDocumentoEmpresa(
    $condition: ModelDocumentoEmpresaConditionInput
    $input: UpdateDocumentoEmpresaInput!
  ) {
    updateDocumentoEmpresa(condition: $condition, input: $input) {
      createdAt
      empresaId
      llave
      nombre
      updatedAt
    }
  }
`;
export const updateDocumentoTrabajador = /* GraphQL */ `
  mutation UpdateDocumentoTrabajador(
    $condition: ModelDocumentoTrabajadorConditionInput
    $input: UpdateDocumentoTrabajadorInput!
  ) {
    updateDocumentoTrabajador(condition: $condition, input: $input) {
      createdAt
      fechaVencimiento
      nombre
      tipo
      trabajadorId
      updatedAt
      url
      validadoXEST
    }
  }
`;
export const updateEmpresa = /* GraphQL */ `
  mutation UpdateEmpresa(
    $condition: ModelEmpresaConditionInput
    $input: UpdateEmpresaInput!
  ) {
    updateEmpresa(condition: $condition, input: $input) {
      calificacionPromedio
      contadorOfertaTrabajo
      createdAt
      credito
      descripcion
      empresaId
      enlaceLogo
      estId
      fechaCreacion
      giro
      nombre
      saldo
      updatedAt
    }
  }
`;
export const updateFacturacion = /* GraphQL */ `
  mutation UpdateFacturacion(
    $condition: ModelFacturacionConditionInput
    $input: UpdateFacturacionInput!
  ) {
    updateFacturacion(condition: $condition, input: $input) {
      cargo
      createdAt
      empresa
      empresaId
      estadosFacturacion
      facturado
      liquidoFacturado
      liquidoPresupuestado
      mesFacturado
      numeroContable
      pais_consecutivoOferta_fecha
      trabajadores
      ubicacion
      updatedAt
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $condition: ModelFaqConditionInput
    $input: UpdateFaqInput!
  ) {
    updateFaq(condition: $condition, input: $input) {
      categoria
      createdAt
      faqId
      pregunta
      respuesta
      updatedAt
    }
  }
`;
export const updateGeografia = /* GraphQL */ `
  mutation UpdateGeografia(
    $condition: ModelGeografiaConditionInput
    $input: UpdateGeografiaInput!
  ) {
    updateGeografia(condition: $condition, input: $input) {
      createdAt
      idRegion_idCiudad_idComuna
      pais
      region_ciudad_comuna
      updatedAt
    }
  }
`;
export const updateHabilidades = /* GraphQL */ `
  mutation UpdateHabilidades(
    $condition: ModelHabilidadesConditionInput
    $input: UpdateHabilidadesInput!
  ) {
    updateHabilidades(condition: $condition, input: $input) {
      createdAt
      descripcion
      estado
      examen
      icono
      id
      nombre
      tipo
      updatedAt
      visibilidad
    }
  }
`;
export const updateHistorialPagos = /* GraphQL */ `
  mutation UpdateHistorialPagos(
    $condition: ModelHistorialPagosConditionInput
    $input: UpdateHistorialPagosInput!
  ) {
    updateHistorialPagos(condition: $condition, input: $input) {
      createdAt
      fecha
      historialPagoId
      motivo
      tipoTransaccion
      updatedAt
      valor
    }
  }
`;
export const updateHistoricoTrabajador = /* GraphQL */ `
  mutation UpdateHistoricoTrabajador(
    $condition: ModelHistoricoTrabajadorConditionInput
    $input: UpdateHistoricoTrabajadorInput!
  ) {
    updateHistoricoTrabajador(condition: $condition, input: $input) {
      createdAt
      duracionUltimoTrabajo
      empresaId
      fechaUltimoTrabajo
      nombreCortoTipoTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const updateIdentificadorFirmante = /* GraphQL */ `
  mutation UpdateIdentificadorFirmante(
    $condition: ModelIdentificadorFirmanteConditionInput
    $input: UpdateIdentificadorFirmanteInput!
  ) {
    updateIdentificadorFirmante(condition: $condition, input: $input) {
      correo
      createdAt
      identificadorTributario
      updatedAt
    }
  }
`;
export const updateIdentificadorTributarioEmpresa = /* GraphQL */ `
  mutation UpdateIdentificadorTributarioEmpresa(
    $condition: ModelIdentificadorTributarioEmpresaConditionInput
    $input: UpdateIdentificadorTributarioEmpresaInput!
  ) {
    updateIdentificadorTributarioEmpresa(condition: $condition, input: $input) {
      activo
      comuna
      correoRepresentanteLegal
      createdAt
      direccion
      empresaId
      identificadorTributario
      margenContribucion
      nombreFantasia
      nombreRepresentanteLegal
      razonSocial
      rutRepresentanteLegal
      updatedAt
    }
  }
`;
export const updateLogCambiosTrabajador = /* GraphQL */ `
  mutation UpdateLogCambiosTrabajador(
    $condition: ModelLogCambiosTrabajadorConditionInput
    $input: UpdateLogCambiosTrabajadorInput!
  ) {
    updateLogCambiosTrabajador(condition: $condition, input: $input) {
      cambios
      comentarioBackoffice
      correo
      createdAt
      fecha
      updatedAt
    }
  }
`;
export const updateMultimediaEmpresa = /* GraphQL */ `
  mutation UpdateMultimediaEmpresa(
    $condition: ModelMultimediaEmpresaConditionInput
    $input: UpdateMultimediaEmpresaInput!
  ) {
    updateMultimediaEmpresa(condition: $condition, input: $input) {
      correoUsuarioEmpresaId_uuid
      createdAt
      empresaId
      llave
      tag
      updatedAt
    }
  }
`;
export const updateNotificacion = /* GraphQL */ `
  mutation UpdateNotificacion(
    $condition: ModelNotificacionConditionInput
    $input: UpdateNotificacionInput!
  ) {
    updateNotificacion(condition: $condition, input: $input) {
      createdAt
      destinatarioId
      fecha
      leida
      mensaje
      payload
      remitenteId
      tipoNotificacion
      updatedAt
      urlDestino
    }
  }
`;
export const updateOfertaActiva = /* GraphQL */ `
  mutation UpdateOfertaActiva(
    $condition: ModelOfertaActivaConditionInput
    $input: UpdateOfertaActivaInput!
  ) {
    updateOfertaActiva(condition: $condition, input: $input) {
      createdAt
      empresaId
      pais_consecutivoOferta
      updatedAt
    }
  }
`;
export const updateOfertaTrabajo = /* GraphQL */ `
  mutation UpdateOfertaTrabajo(
    $condition: ModelOfertaTrabajoConditionInput
    $input: UpdateOfertaTrabajoInput!
  ) {
    updateOfertaTrabajo(condition: $condition, input: $input) {
      brutoTotal
      causalContrado
      costoNeto
      costoServicio
      createdAt
      descripcionTipoTrabajo
      empresaId
      estadoActual
      facturado
      facturadoRealFinal
      fechaCaducidad
      firmaContrato
      historialEstados
      identificadorTributario
      infoOnboarding
      liquidoTotal
      multimediaOfertaId
      nombreContrato
      nuevosPostulantes
      pais_consecutivoOferta
      provisionFondos
      razonSocial
      rubro
      tipoTrabajo
      totalHoras
      totalVacantes
      turnos
      ubicacion
      updatedAt
      usuariosInvitados
    }
  }
`;
export const updateOfertaTrabajoHabilidades = /* GraphQL */ `
  mutation UpdateOfertaTrabajoHabilidades(
    $condition: ModelOfertaTrabajoHabilidadesConditionInput
    $input: UpdateOfertaTrabajoHabilidadesInput!
  ) {
    updateOfertaTrabajoHabilidades(condition: $condition, input: $input) {
      createdAt
      empresaId_pais_consecutivoOferta
      habilidadId
      obligatoria
      updatedAt
    }
  }
`;
export const updatePorcentajeImposicionesPais = /* GraphQL */ `
  mutation UpdatePorcentajeImposicionesPais(
    $condition: ModelPorcentajeImposicionesPaisConditionInput
    $input: UpdatePorcentajeImposicionesPaisInput!
  ) {
    updatePorcentajeImposicionesPais(condition: $condition, input: $input) {
      createdAt
      iva
      pais
      porcentaje
      updatedAt
      valorMinimoLegal
    }
  }
`;
export const updateReplicaTalana = /* GraphQL */ `
  mutation UpdateReplicaTalana(
    $condition: ModelReplicaTalanaConditionInput
    $input: UpdateReplicaTalanaInput!
  ) {
    updateReplicaTalana(condition: $condition, input: $input) {
      codigo
      creacion
      createdAt
      nombre
      pais
      razonSocial
      rut
      talanaId
      tipo
      tipoReplica
      updatedAt
      vigente
    }
  }
`;
export const updateRequisito = /* GraphQL */ `
  mutation UpdateRequisito(
    $condition: ModelRequisitoConditionInput
    $input: UpdateRequisitoInput!
  ) {
    updateRequisito(condition: $condition, input: $input) {
      createdAt
      descripcion
      empresaId
      fechaVencimiento
      pais_consecutivoOferta_uuid
      requiereArchivo
      updatedAt
    }
  }
`;
export const updateRequisitosLegales = /* GraphQL */ `
  mutation UpdateRequisitosLegales(
    $condition: ModelRequisitosLegalesConditionInput
    $input: UpdateRequisitosLegalesInput!
  ) {
    updateRequisitosLegales(condition: $condition, input: $input) {
      createdAt
      pais
      porcentajeBase
      porcentajeHoraExtra
      porcentajeParafiscales
      porcentajeSemanaCorrida
      updatedAt
    }
  }
`;
export const updateRubroTipoTrabajo = /* GraphQL */ `
  mutation UpdateRubroTipoTrabajo(
    $condition: ModelRubroTipoTrabajoConditionInput
    $input: UpdateRubroTipoTrabajoInput!
  ) {
    updateRubroTipoTrabajo(condition: $condition, input: $input) {
      createdAt
      descripcionRubro
      descripcionTipoTrabajo
      duracionMaximaTurno
      duracionMaximaTurnoParaColacion
      duracionMaximaTurnoXSemana
      habilidades
      horasMaximasXDia
      horasMaximasXMes
      horasMaximasXSemana
      nombreOficial
      pais
      porcentajeCostoFestivo
      rubro_tipoTrabajo
      tiempoColacion
      updatedAt
      valorMaxRecomendado
      valorMinRecomendado
    }
  }
`;
export const updateSeguridadSocial = /* GraphQL */ `
  mutation UpdateSeguridadSocial(
    $condition: ModelSeguridadSocialConditionInput
    $input: UpdateSeguridadSocialInput!
  ) {
    updateSeguridadSocial(condition: $condition, input: $input) {
      createdAt
      nombre
      talanaId
      tipo
      trabajadorId
      updatedAt
    }
  }
`;
export const updateTipoUsuario = /* GraphQL */ `
  mutation UpdateTipoUsuario(
    $condition: ModelTipoUsuarioConditionInput
    $input: UpdateTipoUsuarioInput!
  ) {
    updateTipoUsuario(condition: $condition, input: $input) {
      createdAt
      dotacionDiaria
      firmaPendiente
      gestionAsistencias
      idTipoUsuario
      menuSolicitudes
      multimediaEmpresa
      nombre
      nuevaSolicitud
      panelDeControl
      perfilEmpresa
      principal
      provisionPendiente
      recargar
      rutsAsociados
      solicitudes
      trabajosEnCurso
      transacciones
      ubicaciones
      updatedAt
      usuarios
      verTodasSolicitudes
    }
  }
`;
export const updateTrabajador = /* GraphQL */ `
  mutation UpdateTrabajador(
    $condition: ModelTrabajadorConditionInput
    $input: UpdateTrabajadorInput!
  ) {
    updateTrabajador(condition: $condition, input: $input) {
      activo
      banco
      calificacionPromedio
      canalesNotificaciones
      ciudad
      clave
      comuna
      correo
      createdAt
      descripcion
      direccion
      dni
      enlaceFoto
      estId
      fechaNacimiento
      firebaseToken
      idBanco
      nacionalidad
      noCuentaBancaria
      nombre
      pais
      perfiles
      primerApellido
      region
      segundaClave
      segundoApellido
      telefono
      tipoCuenta
      ultimaFechaConsultaAnuncios
      updatedAt
      validadoXEST
      visibilidadFoto
    }
  }
`;
export const updateTrabajadorCalendario = /* GraphQL */ `
  mutation UpdateTrabajadorCalendario(
    $condition: ModelTrabajadorCalendarioConditionInput
    $input: UpdateTrabajadorCalendarioInput!
  ) {
    updateTrabajadorCalendario(condition: $condition, input: $input) {
      aprobado
      confirmado
      createdAt
      duracion
      empresaId
      entrada
      estadoAprobacion
      estadoEntrada
      estadoSalida
      fechaFin
      fecha_empresaId_pais_consecutivoOferta
      pais_consecutivoOferta
      salida
      timeZone
      trabajadorId
      turno
      ubicacionId
      ultimoDia
      unidad
      updatedAt
      valorUnitario
    }
  }
`;
export const updateTrabajadorHabilidades = /* GraphQL */ `
  mutation UpdateTrabajadorHabilidades(
    $condition: ModelTrabajadorHabilidadesConditionInput
    $input: UpdateTrabajadorHabilidadesInput!
  ) {
    updateTrabajadorHabilidades(condition: $condition, input: $input) {
      certificado
      correo
      createdAt
      estado
      fechaSolicitud
      habilidadId
      historialEstados
      infoAdicionalCertificado
      resultadoExamen
      tipoHabilidad
      updatedAt
    }
  }
`;
export const updateTrabajadorOferta = /* GraphQL */ `
  mutation UpdateTrabajadorOferta(
    $condition: ModelTrabajadorOfertaConditionInput
    $input: UpdateTrabajadorOfertaInput!
  ) {
    updateTrabajadorOferta(condition: $condition, input: $input) {
      contratoEmpresa
      contratoId
      contratoTrabajador
      createdAt
      cumplimientoRequisito
      declaracionJuradaSalud
      desvinculacion
      empresaId
      empresaId_pais_consecutivoOferta
      estadoActual
      finiquito
      historialEstados
      infoOnboardingLeida
      pactoHorasExtra
      pais_consecutivoOferta_trabajadorId
      trabajadorId
      turnos
      turnosExtra
      updatedAt
      valorAPagar
    }
  }
`;
export const updateTrabajadorPago = /* GraphQL */ `
  mutation UpdateTrabajadorPago(
    $condition: ModelTrabajadorPagoConditionInput
    $input: UpdateTrabajadorPagoInput!
  ) {
    updateTrabajadorPago(condition: $condition, input: $input) {
      concepto
      createdAt
      empresaId_pais_consecutivoOferta
      fecha_uuid
      historialPagoId
      trabajadorId
      updatedAt
      valor
    }
  }
`;
export const updateTrabajoFavorito = /* GraphQL */ `
  mutation UpdateTrabajoFavorito(
    $condition: ModelTrabajoFavoritoConditionInput
    $input: UpdateTrabajoFavoritoInput!
  ) {
    updateTrabajoFavorito(condition: $condition, input: $input) {
      createdAt
      ofertasTrabajo
      trabajadorId
      updatedAt
    }
  }
`;
export const updateTransaccion = /* GraphQL */ `
  mutation UpdateTransaccion(
    $condition: ModelTransaccionConditionInput
    $input: UpdateTransaccionInput!
  ) {
    updateTransaccion(condition: $condition, input: $input) {
      correo
      createdAt
      detalle
      empresaId
      fecha_uuid
      monto
      observacion
      tipo
      updatedAt
    }
  }
`;
export const updateUbicacion = /* GraphQL */ `
  mutation UpdateUbicacion(
    $condition: ModelUbicacionConditionInput
    $input: UpdateUbicacionInput!
  ) {
    updateUbicacion(condition: $condition, input: $input) {
      activa
      codigoPostal
      createdAt
      direccion
      empresaId
      nombre
      pais_region_ciudad_comuna_uuid
      timezone
      ubicacion
      updatedAt
    }
  }
`;
export const updateUbicacionSupervisor = /* GraphQL */ `
  mutation UpdateUbicacionSupervisor(
    $condition: ModelUbicacionSupervisorConditionInput
    $input: UpdateUbicacionSupervisorInput!
  ) {
    updateUbicacionSupervisor(condition: $condition, input: $input) {
      correo
      createdAt
      pais_region_ciudad_comuna_uuid
      updatedAt
    }
  }
`;
export const updateUsuarioBackoffice = /* GraphQL */ `
  mutation UpdateUsuarioBackoffice(
    $condition: ModelUsuarioBackofficeConditionInput
    $input: UpdateUsuarioBackofficeInput!
  ) {
    updateUsuarioBackoffice(condition: $condition, input: $input) {
      cargo
      correo
      createdAt
      nombre
      telefono
      updatedAt
    }
  }
`;
export const updateUsuarioEmpresa = /* GraphQL */ `
  mutation UpdateUsuarioEmpresa(
    $condition: ModelUsuarioEmpresaConditionInput
    $input: UpdateUsuarioEmpresaInput!
  ) {
    updateUsuarioEmpresa(condition: $condition, input: $input) {
      activo
      cargo
      clave
      correo
      createdAt
      empresaId
      nombre
      telefono
      tipoUsuario
      updatedAt
    }
  }
`;

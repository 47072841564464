import { useEffect, useState } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export function ChileanClock() {
  const [time, setTime] = useState(dayjs());

  useEffect(() => {
    setInterval(() => {
      setTime(dayjs());
    }, 1000);
  }, []);

  if (dayjs.tz.guess().includes('America/Santiago')) return null;

  return (
    <div className="hidden md:flex space-x-1 items-center">
      <ClockCircleOutlined />
      <div className="text-xs  w-12">
        {time.tz('America/Santiago').format('HH:mm:ss')}
      </div>
    </div>
  );
}

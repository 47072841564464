import { useEffect, useState } from 'react';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import ConfigureDates from './subcomponents/ConfigureDates';
import ConfigureValores from './subcomponents/ConfigureValores';
import Calculate from './subcomponents/Calculate';

import useSolicitud from '../../../../hooks/useSolicitud';
import {
  getDifferenceBetweenTwoHours,
  getEndDay,
  getStartDay,
  getTotalDaysBetweenToDates,
} from '../../../../utils/getTurnTimes';

export default function TurnForm({ prev, next }) {
  const { campos, solicitud } = useSolicitud();

  const [showColation, setShowColation] = useState(true);
  const [validation, setValidation] = useState({
    values: {
      id: solicitud?.turnos[0]?.numero || uuidv4(),
    },
    validate: false,
  });
  const [isValidate, setIsValidate] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (solicitud.turnos.length > 0) {
      const turno = form.getFieldsValue();
      handleChanges(null, turno);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleColacion() {
    // const needColation = getNeedColation(form);
    setShowColation(true);
    // if (!needColation) {
    //   form.resetFields(['colacion']);
    // }
  }

  function getReglas() {
    return campos.cargos.find((c) => c.value === solicitud.infoBasica.cargo)
      ?.reglas;
  }

  function getNeedColation(form) {
    const tipoPago = form.getFieldValue('tipopago');
    const fechas = form.getFieldValue('fechas');
    const dias = form.getFieldValue('dias');
    const entrada = form.getFieldValue('entrada');
    const salida = form.getFieldValue('salida');
    if (!tipoPago || !fechas || !dias || !entrada || !salida) return false;
    const startDate = getStartDay(fechas[0], dias);
    const endDate = getEndDay(fechas[1], dias);
    const start = dayjs(startDate).hour(entrada.hour());
    const end = dayjs(endDate).hour(salida.hour());
    const adjustedEndDate = end.isBefore(start)
      ? dayjs(endDate).add(1, 'day')
      : endDate;

    const horas =
      getDifferenceBetweenTwoHours(start, end) *
      getTotalDaysBetweenToDates(startDate, adjustedEndDate, dias);

    return tipoPago === 'jornada' || horas >= getReglas().horasParaColacion;
  }

  function handleChanges(changedValues, allValues) {
    setIsValidate(null);

    if (
      (changedValues?.salida || changedValues?.tipopago) &&
      getNeedColation(form)
    ) {
      form.validateFields(['colacion']);
      return;
    }

    const nuevoObjeto = { ...allValues };
    delete nuevoObjeto.colacion;
    const validate = Object.values(nuevoObjeto).every((v) => v !== undefined);
    if (!validate) return;

    const { ...validationValues } = getHoras(allValues);

    setValidation({
      values: { ...validation.values, ...allValues, ...validationValues },
      validate: false,
    });
  }

  function getHoras(allValues) {
    const { fechas, dias, entrada, salida, tipopago, colacion, pago } =
      allValues;
    const startDate = getStartDay(fechas[0], dias);
    const endDate = getEndDay(fechas[1], dias);
    const start = dayjs(startDate).hour(entrada.hour());
    const end = dayjs(startDate).hour(salida.hour());
    const adjustedEndDate = dayjs(endDate).hour(salida.hour()).isBefore(start)
      ? dayjs(endDate).add(1, 'day')
      : endDate;
    const totalDias = getTotalDaysBetweenToDates(
      startDate,
      adjustedEndDate,
      dias
    );
    const horas = getDifferenceBetweenTwoHours(start, end) * totalDias;
    const totalHoras = getTotalHoras(horas, colacion * totalDias);

    const liquido =
      tipopago === 'jornada' ? pago * totalDias : pago * totalHoras;

    return {
      fechas: { inicio: startDate, fin: adjustedEndDate },
      fixHoras: totalHoras,
      pago: Math.round(liquido),
      unitario: pago,
    };
  }

  return (
    <div>
      <ConfigureDates
        form={form}
        handleColacion={handleColacion}
        handleChanges={handleChanges}
      />
      <ConfigureValores
        form={form}
        showColation={showColation}
        handleColacion={handleColacion}
        reglas={getReglas()}
        handleChanges={handleChanges}
      />
      <Calculate
        validation={validation}
        isValidate={isValidate}
        setIsValidate={setIsValidate}
        next={next}
        prev={prev}
      />
    </div>
  );
}

function getTotalHoras(horas, colacion) {
  if (!horas) return 0;
  if (colacion) {
    return horas - colacion / 60;
  }
  return horas;
}

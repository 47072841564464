export default function mapRequisition(requisition, candidates) {

  console.log(requisition)
  const turnos = requisition.turnos.map((turno) => ({
    ...turno,
    candidatos: formatCandidates(requisition, turno, candidates),
  }));

  return {
    ...requisition,
    turnos,
  };
}

function formatCandidates(requisition, turno, candidates) {
  if (!candidates) return [];
  const candidatesFiltered = candidates.filter((candidate) => {
    return candidate.turnos.find((element) => element.id === turno.id);
  });

  return candidatesFiltered.map((candidate) => {
    function getStatus() {
      if (
        candidate.estadoActual === 'SeleccionadoXEmpresa' ||
        candidate.estadoActual === 'Postulado'
      ) {
        const turnoActual = candidate.turnos.find((t) => t.id === turno.id);
        if (turnoActual.seleccionado) return 'SeleccionadoXEmpresa';
        else return 'Postulado';
      }
      return candidate.estadoActual;
    }
    return {
      ...candidate,
      estadoActual: getStatus(),
      turnoInfo: {
        ...turno,
        pais_consecutivoOferta: requisition.pais_consecutivoOferta,
        estado: requisition.estadoActual,
      },
    };
  });
}

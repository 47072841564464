import React from 'react';
import FormatDateV2 from '../../../altUtils/FormatDateV2';
import TablainfoPersonas from './atoms/TablainfoPersonas';
import styles from './jsonStyles.json';
export default function FormatoReportesAsistencias({ dataArray }) {
  return (
    <div
      className="table-responsive"
      style={styles.tableReponsive}
      id="tablaPrincipal"
    >
      <h4 style={{ font: ' 11pt Arial' }}>Reporte de Asistencia</h4>

      {dataArray.map((item) => (
        <div id={item.key} key={item.key} className="table-responsive">
          <br />
          <br />
          <TablainfoPersonas item={item}></TablainfoPersonas>

          <table style={styles.table}>
            <tr
              style={{
                backgroundColor: 'DARKblue',
                color: 'white',
                height: '50px',
                border: '1px solid',
              }}
            >
              <th style={styles.td2}>Fecha</th>
              <th style={styles.td2}>Asistencia</th>
              <th style={styles.td2}>Ausencia</th>
              <th style={styles.td2}>Observaciones</th>
            </tr>
            {item.reporte.map((task) => (
              <tr
                key={task.jornada}
                style={{
                  border: '1px solid',
                  height: '50px',
                  padding: '2px',
                  font: ' 11pt Arial',
                }}
              >
                <td style={styles.td2}>
                  {FormatDateV2(task.key).split('T')[0]}
                </td>
                <td style={styles.td2}>{task.asistencia || ''}</td>
                <td style={styles.td2}> {task.ausencia || ''}</td>

                <td style={styles.td2}>{task.observaciones || ''}</td>
              </tr>
            ))}
          </table>
        </div>
      ))}
    </div>
  );
}

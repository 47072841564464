import React from 'react';
import Meta from '../../../components/layouts/Meta';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import NuevaClave from './components/NuevaClave';

export default function Confirmar() {
  const location = useLocation();
  return (
    <div className="h-screen flex items-center justify-center">
      <Meta title="Recuperar contraseña" />
      <div className="flex flex-col items-center">
        <Header text="Recuperar contraseña" />
        <div id="body" className="w-80 md:w-96 mt-10">
          <p className="text-gray-500 text-sm">
            Introduce el código que te hemos enviado a tu correo electrónico y
            la nueva contraseña.
          </p>
          <NuevaClave
            initialEmail={location?.search?.replace('?correo=', '')}
          />
        </div>
      </div>
    </div>
  );
}

import { Navigate, Outlet } from 'react-router-dom';
import Application from '../components/layouts/Application';
import { useSelector } from 'react-redux';

export default function PrivateProvider() {
  const { user } = useSelector((state) => state.auth);
  if (!user) return <Navigate to="/auth" replace />;
  return (
    <Application>
      <Outlet />
    </Application>
  );
}

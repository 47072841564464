import { Excel } from 'antd-table-saveas-excel';

/**
 * It takes in a list of columns, a list of data, a client name, and a name for
 * the file, and then it creates an excel file with the data and columns, and
 * saves it as a file with the name of the file.
 * @param {Array} columns - an array of objects with the column properties like - [
 *  {
 *     title: "Column title",
 *     width: 300,
 *     dataIndex: "columnKey",
 *     key: "columnKey",
 *  }
 * ].
 * @param {Array} data - The data to be exported like - [
 *   {
 *     columnKey: "Some data here."
 *   }
 * ].
 * @param {String} name - The name of the file.
 */
const downloadExcel = (columns, data, name) => {
  const excel = new Excel();
  excel.setRowHeight(8, 'px');
  excel.setTHeadStyle({
    background: '3b82f6',
    color: 'FFFFFFFF',
    fontSize: 11,
    bold: true,
    fontName: 'Calibri',
  });
  excel.setTBodyStyle({
    fontSize: 11,
    wrapText: true,
    shrinkToFit: true,
    fontName: 'Calibri',
    v: 'center',
    h: 'left',
  });
  excel
    .addSheet('format')
    .addColumns(columns)
    .addDataSource(data)
    .setRowHeight(10)
    .saveAs(`${name}.xlsx`, 'blob', true);
};

export { downloadExcel };

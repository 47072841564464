import { Space } from 'antd';
import CrearEtiqueta from './CrearEtiqueta';
import AsignarEtiqueta from './AsignarEtiqueta';
import InvitarAPostular from './InvitarAPostular';
import EditarEtiquetas from './EditarEtiquetas';
import AgregarTrabajador from './AgregarTrabajador';
import ContratarDirecto from './ContratarDirecto';
import NotificarComunidad from './NotificarComunidad';

export default function Actions({
  checkTags,
  seleccionados,
  etiquetas,
  refresh,
}) {
  return (
    <div>
      <b>Acciones para trabajadores seleccionados</b>
      <div className="flex justify-between mt-4">
        <Space>
          <AsignarEtiqueta
            seleccionados={seleccionados}
            etiquetas={etiquetas}
            refresh={refresh}
          />
          <ContratarDirecto seleccionados={seleccionados} />
          <InvitarAPostular etiquetas={etiquetas} />
          <NotificarComunidad seleccionados={seleccionados} />
          <AgregarTrabajador refresh={refresh} />
        </Space>
        <Space>
          <EditarEtiquetas
            etiquetas={etiquetas}
            checkTags={checkTags}
            refresh={refresh}
          />
          <CrearEtiqueta checkTags={checkTags} />
        </Space>
      </div>
    </div>
  );
}

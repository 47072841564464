import React, { useState, useEffect } from 'react';
import { Table, Skeleton } from 'antd';
import BuscadorReportes from '../../../components/buscador/BuscadorReportes';
import TablaAsistenciaInfo from '../asistencia/components/TablaAsistenciaInfo';
import TablaReporteModificaciones from './components/TablaReporteModificaciones';
import BotonExportarPdf from '../../../components/botones/BotonExportarPdf';
import BotonExportarWord from '../../../components/botones/BotonExportarWord';
import HeaderReportes from '../../../components/botones/headerReportes';
import BotonExportarExcel from '../../../components/botones/BotonExportarExcel';

export default function ModificacionesAlteraciones() {
  const [firstTable, setFirstTable] = useState([]);
  const [jobsAreLoading, setJobsAreLoading] = useState(true);
  const [expotarWord, setexportarWord] = useState(false);
  const [expotarPdf, setexportarPdf] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [expotarExcel, setexportarExcel] = useState(false);
  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
    },
  };

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-10">
      <HeaderReportes
        name="Reporte modificaciones y alteraciones de turnos"
        setexportarExcel={setexportarExcel}
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        firstTable={DatosSeleccionados}
        DatosSeleccionados={DatosSeleccionados}
      />

      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="modificaciones"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
      />

      {jobsAreLoading ? (
        <Skeleton />
      ) : (
        <div>
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={TablaAsistenciaInfo(firstTable)}
            expandable={{
              expandedRowRender: (record) => (
                <TablaReporteModificaciones data={record.reporte || []} />
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            dataSource={firstTable}
            scroll={{ x: true }}
          />
        </div>
      )}

      {expotarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="modificaciones"
          name={`reporte Modificacion de turnos_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}

      {expotarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="modificaciones"
          name={`reporte Modificacion de turnos_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}
      {expotarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="modificaciones"
          name={`reporte Modificacion de Turno_${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}
    </div>
  );
}

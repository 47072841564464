import React, { useState, useEffect } from 'react';
import { Table, Skeleton } from 'antd';
import TablaAsistenciaInfo from './components/TablaAsistenciaInfo';
import BuscadorReportes from '../../../components/buscador/BuscadorReportes';
import HeaderReportes from '../../../components/botones/headerReportes';
import BotonExportarWord from '../../../components/botones/BotonExportarWord';
import BotonExportarPdf from '../../../components/botones/BotonExportarPdfV2Pro';
import BotonExportarExcel from '../../../components/botones/BotonExportarExcel';
import CartaAsistencia from './components/CartaAsistencia';
// import { downloadExcel } from "../../utils/DownloadExcelFile";

export default function Asistencia() {
  const [firstTable, setFirstTable] = useState([]);
  const [jobsAreLoading, setJobsAreLoading] = useState(true);

  const [currentDate, setCurrentDate] = useState('');
  const [exportarExcel, setexportarExcel] = useState(false);
  const [exportarWord, setexportarWord] = useState(false);
  const [exportarPdf, setexportarPdf] = useState(false);

  const [DatosSeleccionados, setDatosSeleccionados] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDatosSeleccionados(selectedRows);
    },
  };

  const customMessage = {
    filterSearchPlaceholder: 'Filtrar',
    filterReset: 'Limpiar',
  };

  const expandedRowRender = (record) => {
    return <CartaAsistencia record={record.reporte} />;
  };

  useEffect(() => {
    let date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      setCurrentDate(`${year}-0${month}-${day}`);
    } else {
      setCurrentDate(`${year}-${month}-${day}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-10">
      <HeaderReportes
        name="Reporte asistencia"
        setexportarPdf={setexportarPdf}
        setexportarWord={setexportarWord}
        setexportarExcel={setexportarExcel}
        firstTable={firstTable}
        DatosSeleccionados={DatosSeleccionados}
      />

      <BuscadorReportes
        setFirstTable={setFirstTable}
        tipoBuscador="asistencia"
        jobsAreLoading={jobsAreLoading}
        setJobsAreLoading={setJobsAreLoading}
      />

      {jobsAreLoading ? (
        <Skeleton />
      ) : (
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={TablaAsistenciaInfo(firstTable)}
          locale={customMessage}
          expandable={{
            expandedRowRender,
          }}
          dataSource={firstTable}
          scroll={{ x: true }}
        />
      )}

      {exportarExcel && (
        <BotonExportarExcel
          dataArray={DatosSeleccionados}
          formato="asistencia"
          name={`reporte Asistencia_${currentDate}`}
          setexportarExcel={setexportarExcel}
        />
      )}

      {exportarWord && (
        <BotonExportarWord
          dataArray={DatosSeleccionados}
          formato="asistencia"
          name={`reporte Asistencia_${currentDate}`}
          setexportarWord={setexportarWord}
        />
      )}
      {exportarPdf && (
        <BotonExportarPdf
          dataArray={DatosSeleccionados}
          formato="asistencia"
          name={`reporte Asistencia_${currentDate}`}
          setexportarpdf={setexportarPdf}
        />
      )}
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getContratos } from '../../services/ContratosService';

import Meta from '../../components/layouts/Meta';
import Filter from './components/Filter';
import ContratosTable from './components/Tabla';

import { PageContainer } from '@ant-design/pro-components';
import { Card, message } from 'antd';

const initialFilters = {
  oferta: null,
  id: null,
  rut: null,
  nombre: null,
};

export default function Anpd() {
  const [contratos, setContratos] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const navigate = useNavigate();
  const isFirmante = user.tipoUsuario.includes('Firmante');

  useEffect(() => {
    if (!isFirmante) {
      message.error('No tienes permisos para acceder a esta sección');
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirmante]);

  useEffect(() => {
    buscarContratos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function buscarContratos() {
    try {
      setContratos(null);
      const res = await getContratos(enterprise.empresaId);
      setContratos(res);
    } catch (error) {
      setContratos([]);
      message.error('Error al obtener los contratos');
    }
  }

  const routes = [{ path: '', breadcrumbName: 'Firma Digital' }];

  return (
    <PageContainer
      header={{
        title: 'Pendientes de Firma',
        ghost: true,
        breadcrumb: { routes },
      }}
    >
      <Meta title="Contratos Pendientes de Firma" />
      <Card>
        <Filter
          buscarContratos={buscarContratos}
          setFilters={setFilters}
          initialFilters={initialFilters}
        />
        <ContratosTable
          contratos={contratos}
          filters={filters}
          buscarContratos={buscarContratos}
        />
      </Card>
    </PageContainer>
  );
}

import dayjs from 'dayjs';
import { Checkbox, Col, DatePicker, Form, Row, TimePicker } from 'antd';
import {
  arrDays,
  getAvailableDays,
  getDisabledDate,
  getDisabledHours,
} from '../../../../../utils/getTurnTimes';
import useSolicitud from '../../../../../hooks/useSolicitud';
import { useEffect, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ConfigureDates({
  form,
  handleColacion,
  handleChanges,
}) {
  const { campos, solicitud } = useSolicitud();
  const { reglas } = campos.cargos.find(
    (c) => c.value === solicitud.infoBasica.cargo
  );
  const [days, setDays] = useState(arrDays);
  const [selectedDates, setSelectedDates] = useState(null);
  const [maxTime, setMaxTime] = useState({
    disabledHours: () => [],
    disabledMinutes: () => [],
  });

  useEffect(() => {
    if (solicitud?.turnos[0]?.fechas) {
      const arr = [
        solicitud?.turnos[0]?.fechas?.inicio,
        solicitud?.turnos[0]?.fechas?.fin,
      ];
      handleDaysChange(arr, true);
    }
    if (solicitud?.turnos[0]?.entrada) {
      handleMaxTime(null, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDaysChange(value, initial = false) {
    if (!value) return;
    const newDays = getAvailableDays(days, value);
    setDays(newDays);
    if (!initial) form.resetFields(['dias']);
  }

  function disabledTimeInitial() {
    const valorFecha = form.getFieldValue('fechas');
    if (!valorFecha)
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    const diaInicial = valorFecha[0];
    const currentTime = dayjs();
    const currentHour = currentTime.hour();
    const currentMinute = currentTime.minute();
    if (diaInicial.format('YYYY-MM-DD') !== currentTime.format('YYYY-MM-DD')) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }
    return {
      disabledHours: () => {
        const hours = [];
        for (let i = 0; i < currentHour; i++) {
          hours.push(i);
        }
        if (currentMinute > 45) hours.push(currentHour);
        return hours;
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === currentHour) {
          const minutes = [];
          for (let i = 0; i < currentMinute; i += 15) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      },
    };
  }

  function handleMaxTime(value, initial = false) {
    const disabledHours = getDisabledHours(
      value || form.getFieldValue('entrada'),
      reglas.horasMaximas
    );
    const lastMinute = dayjs(value || form.getFieldValue('entrada')).minute();
    const lastHour = dayjs(value || form.getFieldValue('entrada'))
      .add(reglas.horasMaximas, 'hours')
      .hour();
    if (!initial) {
      form.resetFields(['salida']);
    }
    setMaxTime({
      disabledHours: () => {
        return disabledHours;
      },
      disabledMinutes: (selectedHour) =>
        selectedHour === lastHour
          ? [0, 15, 30, 45, 60].filter((x) => x > lastMinute)
          : [],
    });
  }

  function getMaxTime() {
    return maxTime;
  }

  const maxDays = campos?.empresa?.oferta?.duracionMaxima || 1;

  return (
    <div className="max-w-4xl mx-auto">
      <Form
        layout="vertical"
        className="mt-5"
        form={form}
        validateTrigger={['onBlur', 'onChange']}
        onValuesChange={handleChanges}
        initialValues={{
          fechas: solicitud?.turnos[0]?.fechas
            ? [
                solicitud?.turnos[0]?.fechas.inicio,
                solicitud?.turnos[0]?.fechas.fin,
              ]
            : null,
          entrada: solicitud?.turnos[0]?.entrada.tz('America/Santiago'),
          salida: solicitud?.turnos[0]?.salida.tz('America/Santiago'),
          dias: solicitud?.turnos[0]?.dias,
        }}
      >
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item
              data-cy="itemRangoFechas"
              label="Rango de fechas"
              name="fechas"
              autoComplete="off"
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona un rango de fechas',
                },
              ]}
              tooltip="Te permitirá habilitar los dias que quieras agregar al turno"
            >
              <DatePicker.RangePicker
                data-cy="datePickerRangoFechas"
                disabledDate={(current) =>
                  getDisabledDate(current, selectedDates, maxDays)
                }
                onCalendarChange={(value) => setSelectedDates(value)}
                onChange={handleDaysChange}
                onOpenChange={(open) => {
                  if (open) {
                    setSelectedDates([null, null]);
                  } else {
                    setSelectedDates(null);
                  }
                }}
                format={'DD/MM/YYYY'}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              data-cy="itemHorario"
              label="Horario"
              required
              style={{
                marginBottom: 0,
              }}
            >
              <Row gutter={10}>
                <Col>
                  <Form.Item
                    data-cy="itemHoraEntrada"
                    name="entrada"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <TimePicker
                      data-cy="timePickerHoraEntrada"
                      format={'HH:mm'}
                      placeholder="Hora entrada"
                      minuteStep={15}
                      showNow={false}
                      disabledTime={disabledTimeInitial}
                      onChange={(v) => handleMaxTime(v)}
                      changeOnBlur
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    data-cy="itemHoraSalida"
                    name="salida"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <TimePicker
                      data-cy="timePickerHoraSalida"
                      format={'HH:mm'}
                      placeholder="Hora salida"
                      minuteStep={15}
                      showNow={false}
                      onChange={handleColacion}
                      disabledTime={getMaxTime}
                      changeOnBlur
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              data-cy="itemDiasTrabajo"
              label="Dias a trabajar"
              name="dias"
              rules={[
                {
                  required: true,
                  message: 'Por favor selecciona al menos un dia',
                },
              ]}
              tooltip="Selecciona los días laborales del turno"
            >
              <Checkbox.Group>
                <Row gutter={5}>
                  {days.map((dia) => (
                    <Col key={dia.label}>
                      <Checkbox
                        data-cy={'checkBox' + dia.label}
                        value={dia.value}
                        disabled={dia.disabled}
                      >
                        {dia.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>{' '}
          </Col>
        </Row>
      </Form>
    </div>
  );
}

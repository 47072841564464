import dayjs from 'dayjs';
import {
  getDifferenceBetweenTwoHours,
  getEndDay,
  getStartDay,
  getTotalDaysBetweenToDates,
} from './getTurnTimes';

export function getHoras(allValues) {
  const { fechas, dias, entrada, salida, tipopago, colacion, pago } = allValues;
  const startDate = getStartDay(fechas[0], dias);
  const endDate = getEndDay(fechas[1], dias);
  const start = dayjs(startDate).hour(entrada.hour()).minute(entrada.minute());
  const end = dayjs(startDate).hour(salida.hour()).minute(salida.minute());
  const adjustedEndDate = dayjs(endDate).hour(salida.hour()).isBefore(start)
    ? dayjs(endDate).add(1, 'day')
    : endDate;
  const totalDias = getTotalDaysBetweenToDates(
    startDate,
    adjustedEndDate,
    dias
  );

  const horas = getDifferenceBetweenTwoHours(start, end) * totalDias;

  const totalHoras = getTotalHoras(horas, colacion * totalDias);

  const liquido = tipopago === 'jornada' ? pago * totalDias : pago * totalHoras;

  return {
    fechas: { inicio: startDate, fin: adjustedEndDate },
    fixHoras: totalHoras,
    pago: Math.round(liquido),
    unitario: pago,
  };
}

function getTotalHoras(horas, colacion) {
  if (!horas) return 0;
  if (colacion) {
    return horas - colacion / 60;
  }
  return horas;
}

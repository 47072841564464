import React from 'react';
import { Card, Col, Image } from 'antd';
import svgDocumentos from '../../../../assets/document.svg';
import dayjs from 'dayjs';

export default function CartaMarcacion({ items, title = 'Entrada' }) {
  if (
    items === null ||
    items === 'No se registró entrada' ||
    items === 'No se registró justificación de entrada' ||
    items === 'No se registró justificación de salida' ||
    items === 'No se registró salida'
  ) {
    return (
      <Col span={10}>
        <Card title={title} bordered={false}>
          <Image width={100} height={50} src={svgDocumentos} preview={false} />
          <p>{items}</p>
        </Card>
      </Col>
    );
  } else {
    return (
      <Col span={10}>
        <Card
          title={title}
          bordered={false}
          // style={{
          //   width: 300,
          // }}
        >
          <p>
            <b>Fecha Marcaje: </b>

            {dayjs(`${items.fechaMarcaje}Z`).format('DD/MM/YYYY HH:mm:ss')}
          </p>

          <p>
            <b>Hash: </b>
            {items.hash || 165156}
          </p>
          <p>
            <b>Ubicación: </b>
            {items.ubicacion || 165156}
          </p>

          {title === 'Justificación Entrada' && (
            <p>
              <b>Justificación: </b>
              {items.justificacion || 'sin Justificación'}
            </p>
          )}
          {title === 'Justificación Salida' && (
            <p>
              <b>Justificación: </b>
              {items.justificacion || 'sin Justificación'}
            </p>
          )}
        </Card>
      </Col>
    );
  }
}

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import { useDispatch, useSelector } from 'react-redux';
import { Select, notification } from 'antd';
import { getConfiguracionEmpresa } from '../../../services/EmpresaService';
import { select } from '../../../lib/features/enterprise';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;

export function SwitchEnterprise() {
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  async function handleSwitchEnterprise(value) {
    try {
      setLoading(true);
      const responses = await Promise.all([
        API.graphql(
          graphqlOperation(queries.getEmpresa, {
            empresaId: value,
          })
        ),
        await getConfiguracionEmpresa(value),
      ]);
      dispatch(
        select({
          ...responses[0].data.getEmpresa,
          configuracionEmpresa: responses[1],
        })
      );
      navigate('/', { replace: true });
    } catch (error) {
      notification.error({
        message: 'Error al cambiar de empresa',
        description: error.message ?? 'Error desconocido',
      });
    }
    setLoading(false);
  }

  if (!user.esMantenedor) return null;

  return (
    <Select
      showSearch
      value={enterprise.empresaId}
      allowClear={false}
      style={{ width: 200 }}
      placeholder="Seleccione una empresa"
      optionFilterProp="children"
      loading={Loading}
      disabled={Loading}
      onChange={handleSwitchEnterprise}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {user.empresas.map((empresa) => {
        if (!empresa.empresaId) return null;
        return (
          <Option key={empresa.empresaId} value={empresa.empresaId}>
            {empresa.nombre}
          </Option>
        );
      })}
    </Select>
  );
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getConfiguracionEmpresa } from '../../../services/EmpresaService';

import DownloadExcel from './DownloadExcel';

import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Select,
  Space,
  Tooltip,
  message,
} from 'antd';
import useWindowWidth from '../../../hooks/useWindowWidth';
const { Option } = Select;

export default function Filters({ setFilters, initialFilters, trabajadores }) {
  const { user } = useSelector((state) => state.auth);
  const { enterprise } = useSelector((state) => state.enterprise);
  const [form] = Form.useForm();
  const [cargos, setCargos] = useState(null);
  const windowWidth = useWindowWidth();

  function handleSubmit(values) {
    setFilters(values);
  }

  function handleReset() {
    setFilters(initialFilters);
    form.resetFields();
  }

  useEffect(() => {
    getCargos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCargos() {
    try {
      const { cargos: listadoCargos } = await getConfiguracionEmpresa(
        enterprise.empresaId
      );
      const format = listadoCargos.map(
        (cargo) => cargo.rubro_tipoTrabajo.split('#')[1]
      );
      setCargos(format);
    } catch (error) {
      message.error('No se pudo cargar los cargos');
      console.error(error);
      setCargos([]);
    }
  }

  return (
    <div>
      <b>Buscar registros</b>
      <div className="my-5 space-y-4 lg:space-y-0 lg:flex justify-between">
        <Form
          layout={windowWidth > 768 ? 'inline' : 'vertical'}
          initialValues={initialFilters}
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item label="Fecha" name="fecha" style={{ marginBottom: 10 }}>
            <DatePicker.RangePicker
              placeholder={['Desde', 'Hasta']}
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
          <Form.Item
            label="Ubicación"
            name="ubicacion"
            style={{ marginBottom: 10 }}
          >
            <Select placeholder="Buscar ubicación" allowClear>
              {/* Cambiar en el backend */}
              {user?.ubicaciones?.map((ubicacion) => (
                <Option
                  key={ubicacion.pais_region_ciudad_comuna_uuid}
                  value={ubicacion.pais_region_ciudad_comuna_uuid}
                >
                  {ubicacion.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Cargo" name="cargo" style={{ marginBottom: 10 }}>
            <Select placeholder="Buscar cargo" allowClear loading={!cargos}>
              {cargos?.map((cargo, index) => (
                <Option key={index} value={cargo}>
                  {cargo}
                </Option>
              ))}
            </Select>
          </Form.Item>{' '}
          <Space size="small" style={{ marginBottom: 10 }}>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Buscar
            </Button>
            <Tooltip title="Limpiar Filtros">
              <Button
                type="dashed"
                onClick={handleReset}
                icon={<ClearOutlined />}
              />
            </Tooltip>
          </Space>
        </Form>
        <DownloadExcel trabajadores={trabajadores} />
      </div>
    </div>
  );
}

import { API, Auth } from 'aws-amplify';

import createParams from '../utils/createApiParams';
import customFetch from '../utils/customFetch';

const apiName = 'backoffice';

async function getToken() {
  const { accessToken } = await Auth.currentSession();
  return accessToken.jwtToken;
}

// invitaciones
const consultarSolicitudes = async (
  userToken,
  cantidad,
  tipo,
  LastEvaluatedKey
) => {
  const path = '/verOfertas';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),

    cantidad,
    tipo,
    LastEvaluatedKey,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const validarOferta = async (userToken, registro) => {
  const path = '/validarOferta';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/* servicio de habilidades */

const consultarHabilidades = async (userToken) => {
  const path = '/habilidades';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const crearHabilidades = async (userToken, registro) => {
  const path = '/habilidades';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.put(apiName, path, myInit));
  return res;
};
const editarHabilidades = async (userToken, registro) => {
  const path = '/habilidades';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
const cambioEstadoHabilidad = async (userToken, registro) => {
  const path = '/cambiarEstadoHabilidad';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

// funciones para crear y editar usuario
const consultarTrabajador = async (
  userToken,
  empresaId,
  correo,
  cantidad,
  LastEvaluatedKey
) => {
  const path = '/trabajador';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    correo,
    cantidad,
    LastEvaluatedKey,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};
const crearTrabajador = async (userToken, registro) => {
  const path = '/trabajador';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.put(apiName, path, myInit));
  return res;
};
const editarTrabajador = async (userToken, registro) => {
  const path = '/trabajador';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/*** aprobar trabajadores */
const cambioEstadoTrabajador = async (userToken, registro) => {
  const path = '/cambiarEstadoTrabajador';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/**** Historial trabajador */

const historialTrabajador = async (userToken, correo) => {
  const path = '/trabajadorHistorial';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    correo,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

/*** Editar informacion Trbajadores */
const editarInformacionTrabajador = async (userToken, registro) => {
  const path = '/trabajador';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
/*** pagos masivos*/
const enviarPagosMasivos = async (userToken, registro, motivo) => {
  const path = '/pagosMasivos';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
    motivo,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/*** caraga masiva Trabajadores*/
const cargaMasivaTrabajadores = async (userToken, registro, motivo) => {
  const path = '/cargaMasivaTrabajadores';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
    motivo,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/*** impersonar */
const impersonar = async (app, empresaId, correo, clientId) => {
  const path = '/impersonar';
  const myInit = createParams();
  myInit.body = {
    app,
    empresaId,
    correo,
    clientId,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const actualizarFacturacion = async ({
  empresaId,
  pais_consecutivoOferta_fecha,
  estado,
}) => {
  const path = '/actualizarFacturacion';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    empresaId,
    pais_consecutivoOferta_fecha,
    estado,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const listarUsuariosClientes = async () => {
  const path = '/listarUsuariosClientes';
  // const myInit = createParams();
  const res = customFetch(() => API.get(apiName, path));
  return res;
};
// conusltar conadidatos backOffice
const consultarCandidatos = async (
  userToken,
  empresaId,
  pais_consecutivoOferta,
  estadoActual,
  cantidad = 500,
  LastEvaluatedKey
) => {
  const path = '/verCandidatos';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId,
    pais_consecutivoOferta,
    estadoActual,
    cantidad,
    LastEvaluatedKey,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const cambiarEstadoUsuario = async (correo, accion) => {
  const path = '/cambiarEstadoUsuario';
  const myInit = createParams();
  myInit.body = {
    empresaId: '',
    correo,
    accion,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/*** notificacion particular*/
const notificacionParticular = async (tipo, data, url, asunto, mensaje) => {
  const path = '/notificacionParticular';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    tipo,
    data,
    url,
    asunto,
    mensaje,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

const contratosTrabajador = async () => {
  const path = '/contratosTrabajador';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const firmarContratos = async (tipoContratos, contratos) => {
  const path = '/contratosTrabajador';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    tipoContratos,
    contratos,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};

/// api crear UsusariosBackOffice
const listarUsuariosBackOffice = async (correo = '', tipoUsuario = []) => {
  const path = '/usuario';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    empresaId: '',
    correo: '',
    tipoUsuario: '',
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const editarUsuarioBackOffice = async (registro) => {
  const path = '/usuario';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.post(apiName, path, myInit));
  return res;
};
const crearUsuarioBackOffice = async (registro) => {
  const path = '/usuario';
  const myInit = createParams();
  myInit.body = {
    userToken: await getToken(),
    registro,
  };
  const res = customFetch(() => API.put(apiName, path, myInit));
  return res;
};

// api consulta asistencia por usuario

const buscarAsistenciasUsuario = async (
  correo = '',
  fechaInicio = '',
  fechaFin = '',
  empresaId
) => {
  const path = '/buscarAsistencia';
  const myInit = createParams();
  myInit.queryStringParameters = {
    userToken: await getToken(),
    correo,
    fechaInicio,
    fechaFin,
    empresaId,
  };
  const res = customFetch(() => API.get(apiName, path, myInit));
  return res;
};

const backofficeService = {
  consultarSolicitudes,
  validarOferta,
  consultarHabilidades,
  crearHabilidades,
  editarHabilidades,
  cambioEstadoHabilidad,
  consultarTrabajador,
  crearTrabajador,
  editarTrabajador,
  cambioEstadoTrabajador,
  historialTrabajador,
  editarInformacionTrabajador,
  enviarPagosMasivos,
  cargaMasivaTrabajadores,
  impersonar,
  actualizarFacturacion,
  listarUsuariosClientes,
  consultarCandidatos,
  cambiarEstadoUsuario,
  contratosTrabajador,
  notificacionParticular,
  listarUsuariosBackOffice,
  editarUsuarioBackOffice,
  crearUsuarioBackOffice,
  firmarContratos,
  buscarAsistenciasUsuario,
};

export default backofficeService;

import { useState } from 'react';
import { Button, Empty, List, Modal, Tabs, Typography } from 'antd';
import Onboarding from './Onboarding';
import Multimedia from './Multimedia';
const { Title } = Typography;

export default function MoreDetails({ details }) {
  const [showModal, setShowModal] = useState(false);
  function handleModal() {
    setShowModal(!showModal);
  }
  return (
    <>
      <Button onClick={handleModal}>Más detalles</Button>
      <ModalDetails show={showModal} handle={handleModal} details={details} />
    </>
  );
}

function ModalDetails({ show, handle, details }) {
  const items = [
    {
      key: '1',
      label: `Información Onboarding`,
      children: <Onboarding info={details.infoOnboarding} />,
    },
    {
      key: '2',
      label: `Requisitos`,
      children: <Requirements content={details.requisitos} />,
    },
    {
      key: '3',
      label: `Multimedia`,
      children: <Multimedia content={details.multimediaOfertaId} />,
    },
  ];

  return (
    <Modal open={show} onCancel={handle} footer={false}>
      <Title level={5}>Más detalles</Title>
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
}

function Requirements({ content }) {
  if (content.length === 0)
    return (
      <div className="p-10">
        <Empty description="Esta oferta no tiene requisitos" />
      </div>
    );
  else
    return (
      <List
        bordered
        dataSource={content}
        renderItem={(item) => (
          <List.Item>
            <b>{item.requiereArchivo ? 'Archivo' : 'Pregunta'}</b> -{' '}
            {item.descripcion}
          </List.Item>
        )}
      />
    );
}

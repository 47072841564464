import { Button, Space, Tooltip, message } from 'antd';
import { EyeOutlined, HighlightOutlined } from '@ant-design/icons';
import { firmarContrato } from '../../../services/ContratosService';
import { Storage } from 'aws-amplify';

export default function Acciones({ contrato, actualizar, firmar }) {
  async function handleFirmar() {
    try {
      const formato = [
        {
          empresaId: contrato.empresaId,
          pais_consecutivoOferta: contrato.pais_consecutivoOferta,
          trabajadorId: contrato.correo,
        },
      ];
      await firmarContrato(contrato.empresaId, formato);
      message.success(`Contrato #${contrato.idContrato} firmado`);
      actualizar();
    } catch (error) {
      console.error(error);
      message.error('Error al firmar contrato');
    }
  }

  async function handleVer() {
    try {
      const url = await Storage.get(
        contrato.pathContratos.contratoAnpdDefinitivo
      );
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
      message.error('Error al obtener el contrato');
    }
  }

  return (
    <Space>
      <Tooltip title="Ver contrato">
        <Button type="text" icon={<EyeOutlined />} onClick={handleVer} />
      </Tooltip>
      {firmar && (
        <Tooltip title="Firmar">
          <Button
            type="text"
            icon={<HighlightOutlined />}
            onClick={handleFirmar}
          />
        </Tooltip>
      )}
    </Space>
  );
}

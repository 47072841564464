export function getNationalityFromAbreviation(nationality) {
  // array of nationalities from south america, latin countries, and north america with their two letters abreviations and full names
  const nationalities = [
    { abreviation: 'AR', name: 'Argentina' },
    { abreviation: 'BO', name: 'Bolivia' },
    { abreviation: 'BR', name: 'Brasil' },
    { abreviation: 'CL', name: 'Chile' },
    { abreviation: 'CO', name: 'Colombia' },
    { abreviation: 'EC', name: 'Ecuador' },
    { abreviation: 'GY', name: 'Guyana' },
    { abreviation: 'PY', name: 'Paraguay' },
    { abreviation: 'PE', name: 'Perú' },
    { abreviation: 'SR', name: 'Surinam' },
    { abreviation: 'UY', name: 'Uruguay' },
    { abreviation: 'VE', name: 'Venezuela' },
    { abreviation: 'US', name: 'Estados Unidos' },
    { abreviation: 'CA', name: 'Canadá' },
    { abreviation: 'MX', name: 'México' },
    { abreviation: 'GT', name: 'Guatemala' },
    { abreviation: 'HN', name: 'Honduras' },
    { abreviation: 'SV', name: 'El Salvador' },
    { abreviation: 'NI', name: 'Nicaragua' },
    { abreviation: 'CR', name: 'Costa Rica' },
    { abreviation: 'PA', name: 'Panamá' },
    { abreviation: 'DO', name: 'República Dominicana' },
    { abreviation: 'CU', name: 'Cuba' },
    { abreviation: 'HT', name: 'Haití' },
    { abreviation: 'JM', name: 'Jamaica' },
    { abreviation: 'PR', name: 'Puerto Rico' },
    { abreviation: 'ES', name: 'España' },
    { abreviation: 'FR', name: 'Francia' },
    { abreviation: 'IT', name: 'Italia' },
    { abreviation: 'DE', name: 'Alemania' },
    { abreviation: 'GB', name: 'Reino Unido' },
    { abreviation: 'PT', name: 'Portugal' },
  ];
  return nationalities.find((n) => n.abreviation === nationality).name;
}

export function getStatusColor(status) {
  switch (status) {
    case 'Finalizada':
      return 'success';
    case 'Guardada':
      return 'warning';
    case 'NoAprobada':
      return 'error';
    case 'Ejecutando':
      return 'processing';
    case 'Activa':
      return 'success';
    case 'EsperandoValidacion':
      return 'warning';
    case 'EsperandoFacturacion':
      return 'processing';
    case 'FacturaGenerada':
      return 'processing';
    case 'FacturaEnviada':
      return 'processing';
    case 'Facturada':
      return 'success';
    default:
      return 'default';
  }
}

export function getStatusName(status) {
  switch (status) {
    case 'Finalizada':
      return 'Finalizada';
    case 'Guardada':
      return 'Guardada';
    case 'NoAprobada':
      return 'No Aprobada';
    case 'Ejecutando':
      return 'Ejecutando';
    case 'Activa':
      return 'Activa';
    case 'EsperandoValidacion':
      return 'Pendiente de Validación';
    case 'EsperandoFacturacion':
      return 'Esperando de Facturación';
    case 'FacturaGenerada':
      return 'Factura Generada';
    case 'FacturaEnviada':
      return 'Factura Enviada';
    case 'Facturada':
      return 'Facturada';
    case 'EnPausa':
      return 'En Pausa';
    default:
      return 'Desierta';
  }
}

// { value: 'Guardada', label: 'Guardada' },
// { value: 'Desierta', label: 'Desierta' },
// { value: 'EsperandoFacturacion', label: 'Esperando Facturación' },
// { value: 'FacturaGenerada', label: 'Factura Generada' },
// { value: 'FacturaEnviada', label: 'Factura Enviada' },
// { value: 'Facturada', label: 'Facturada' },
// { value: 'Finalizada', label: 'Finalizada' },

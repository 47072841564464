import React from 'react';
import styles from './jsonStyles.json';
import TablainfoPersonas from './atoms/TablainfoPersonas';
import dayjs from 'dayjs';
import FormatDateV2 from '../../../altUtils/FormatDateV2';

export default function FormatoReporteModificaciones({
  dataArray,
  widthTable = '800px',
}) {
  return (
    <div style={styles.tableReponsive}>
      <h4 style={{ font: ' 11pt Arial' }}>
        {' '}
        Reporte de Modificación de Asistencias
      </h4>
      {dataArray.map((item) => (
        <div
          key={item.key}
          id={item.key}
          className="table-responsive"
          // style={{ margin: "20px" }}
        >
          <br />
          <br />
          <TablainfoPersonas item={item}></TablainfoPersonas>

          <table style={styles.table}>
            <tr
              style={{
                backgroundColor: 'DARKblue',
                color: 'white',
                border: '1px solid',
                height: '50px',
              }}
            >
              <th style={styles.td2}>Fecha Asignacion de Turno</th>
              <th style={styles.td2}>Hora turno</th>
              <th style={styles.td2}>Frecuencia (Tipo de turno)</th>
              <th style={styles.td2}>Fecha Asignacion de Nuevo turno</th>
              <th style={styles.td2}>Horario Nuevo Turno</th>
              <th style={styles.td2}>Solicitado</th>
            </tr>
            {item.reporte.map((task) => (
              <tr
                key={task.jornada}
                style={{
                  border: '1px solid',
                  height: '50px',
                  padding: '2px',
                }}
              >
                <td style={styles.td2}>
                  {FormatDateV2(task.fechaAsignacion).split('T')[0]}
                </td>
                <td style={styles.td2}>
                  <div className="aling-center">
                    <p>
                      {dayjs(`${task.jornadaAsignacion.entrada}Z`).format(
                        'HH:mm'
                      )}{' '}
                      -{' '}
                      {dayjs(`${task.jornadaAsignacion.salida}Z`).format(
                        'HH:mm'
                      )}
                    </p>
                  </div>
                </td>
                <td style={styles.td2}>{task.frecuencia || ''}</td>
                <td style={styles.td2}>
                  {FormatDateV2(task.fechaNuevaAsignacion).split('T')[0]}
                </td>
                <td style={styles.td2}>
                  <div className="aling-center">
                    <p>
                      {dayjs(`${task.jornadaNuevaAsignacion.entrada}Z`).format(
                        'HH:mm'
                      )}{' '}
                      -{' '}
                      {dayjs(`${task.jornadaNuevaAsignacion.salida}Z`).format(
                        'HH:mm'
                      )}
                    </p>
                  </div>
                </td>
                <td style={styles.td2}>{task.solicitado || ''}</td>
              </tr>
            ))}
          </table>
        </div>
      ))}
    </div>
  );
}

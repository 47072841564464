import { List, Modal, message } from 'antd';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

export default function CandidatePostulation({ candidate, show, close }) {
  return (
    <Modal
      open={show}
      onCancel={close}
      footer={false}
      destroyOnClose={true}
      title="Respuestas a requisitos"
    >
      <Postulation candidate={candidate} />
    </Modal>
  );
}

function Postulation({ candidate }) {
  return (
    <List
      itemLayout="horizontal"
      dataSource={candidate?.perfil?.requisitosTrabajador}
      style={{ marginTop: '20px' }}
      renderItem={(item, index) => (
        <List.Item key={index} style={{ padding: '10px 0px' }}>
          <List.Item.Meta
            title={item.descripcion}
            description={
              <span>
                <b>{item.requiereArchivo ? 'Archivo' : 'Respuesta'}:</b>{' '}
                {item.requiereArchivo ? (
                  <DownloadButton archivo={item.respuesta} />
                ) : (
                  item.respuesta
                )}
              </span>
            }
          />
        </List.Item>
      )}
    />
  );
}

function DownloadButton({ archivo }) {
  const [fullUrl, setFullUrl] = useState(null);
  useEffect(() => {
    getFullUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getFullUrl() {
    try {
      const url = await Storage.get(archivo);
      setFullUrl(url);
    } catch (error) {
      message.error('Error al obtener archivo');
    }
  }

  if (!fullUrl) return <span>Obteniendo...</span>;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      Descargar
    </a>
  );
}

import { Excel } from 'antd-table-saveas-excel';

export function download(candidates, name) {
  const excel = new Excel();
  excel.setRowHeight(8, 'px');
  excel.setTHeadStyle({
    background: '3b82f6',
    color: 'FFFFFFFF',
    fontSize: 11,
    bold: true,
    fontName: 'Calibri',
  });
  excel.setTBodyStyle({
    fontSize: 11,
    wrapText: true,
    shrinkToFit: true,
    fontName: 'Calibri',
    v: 'center',
    h: 'left',
  });
  excel
    .addSheet('format')
    .addColumns(columns)
    .addDataSource(candidates)
    .setRowHeight(10)
    .saveAs(`${name}.xlsx`, 'blob', true);
}

const columns = [
  {
    title: 'EMPRESA',
    dataIndex: 'EMPRESA',
    key: 'EMPRESA',
  },
  {
    title: 'CLIENTE',
    dataIndex: 'CLIENTE',
    key: 'CLIENTE',
  },
  {
    title: 'PROYECTO',
    dataIndex: 'PROYECTO',
    key: 'PROYECTO',
  },
  {
    title: 'SUBPROYECTO',
    dataIndex: 'SUBPROYECTO',
    key: 'SUBPROYECTO',
  },
  {
    title: 'RUT',
    dataIndex: 'RUT',
    key: 'RUT',
  },
  {
    title: 'DV',
    dataIndex: 'DV',
    key: 'DV',
  },
  {
    title: 'EXTRANJERO',
    dataIndex: 'EXTRANJERO',
    key: 'EXTRANJERO',
  },
  {
    title: 'PATERNO',
    dataIndex: 'PATERNO',
    key: 'PATERNO',
  },
  {
    title: 'MATERNO',
    dataIndex: 'MATERNO',
    key: 'MATERNO',
  },
  {
    title: 'NOMBRES',
    dataIndex: 'NOMBRES',
    key: 'NOMBRES',
  },
  {
    title: 'SEXO',
    dataIndex: 'SEXO',
    key: 'SEXO',
  },
  {
    title: 'NACIMIENTO',
    dataIndex: 'NACIMIENTO',
    key: 'NACIMIENTO',
  },
  {
    title: 'REGION',
    dataIndex: 'REGION',
    key: 'REGION',
  },
  {
    title: 'COMUNA',
    dataIndex: 'COMUNA',
    key: 'COMUNA',
  },
  {
    title: 'CIUDAD',
    dataIndex: 'CIUDAD',
    key: 'CIUDAD',
  },
  { title: 'CALLE', dataIndex: 'CALLE', key: 'CALLE' },
  { title: 'NUMERO', dataIndex: 'NUMERO', key: 'NUMERO' },
  { title: 'DEPTO', dataIndex: 'DEPTO', key: 'DEPTO' },
  {
    title: 'NIVEL_EDUCACIONAL',
    dataIndex: 'NIVEL_EDUCACIONAL',
    key: 'NIVEL_EDUCACIONAL',
  },
  {
    title: 'TELEFONO',
    dataIndex: 'TELEFONO',
    key: 'TELEFONO',
  },
  {
    title: 'CELULAR',
    dataIndex: 'CELULAR',
    key: 'CELULAR',
  },
  {
    title: 'EMAIL',
    dataIndex: 'EMAIL',
    key: 'EMAIL',
  },
  {
    title: 'ESTADO_CIVIL',
    dataIndex: 'ESTADO_CIVIL',
    key: 'ESTADO_CIVIL',
  },
  {
    title: 'NACIONALIDAD',
    dataIndex: 'NACIONALIDAD',
    key: 'NACIONALIDAD',
  },

  {
    title: 'INGRESO',
    dataIndex: 'INGRESO',
    key: 'INGRESO',
  },

  {
    title: 'TERMINO',
    dataIndex: 'TERMINO',
    key: 'TERMINO',
  },
  {
    title: 'HORARIO_ENCODE',
    dataIndex: 'HORARIO_ENCODE',
    key: 'HORARIO_ENCODE',
  },

  {
    title: 'TIPOCONTRATO',
    dataIndex: 'TIPOCONTRATO',
    key: 'TIPOCONTRATO',
  },

  {
    title: 'LUGAR_DE_TRABAJO',
    dataIndex: 'LUGAR_DE_TRABAJO',
    key: 'LUGAR_DE_TRABAJO',
  },
  {
    title: 'CCOSTO_CLIENTE',
    dataIndex: 'CCOSTO_CLIENTE',
    key: 'CCOSTO_CLIENTE',
  },
  {
    title: 'HORASDIA',
    dataIndex: 'HORASDIA',
    key: 'HORASDIA',
  },

  {
    title: 'FORMA_PAGO',
    dataIndex: 'FORMA_PAGO',
    key: 'FORMA_PAGO',
  },

  {
    title: 'BANCO',
    dataIndex: 'BANCO',
    key: 'BANCO',
  },
  {
    title: 'CTA_CTE',
    dataIndex: 'CTA_CTE',
    key: 'CTA_CTE',
  },
  {
    title: 'ID_AFP',
    dataIndex: 'ID_AFP',
    key: 'ID_AFP',
  },

  {
    title: 'ID_ISAPRE',
    dataIndex: 'ID_ISAPRE',
    key: 'ID_ISAPRE',
  },

  {
    title: 'ARTICULO22',
    dataIndex: 'ARTICULO22',
    key: 'ARTICULO22',
  },

  {
    title: 'ID_CARGO',
    dataIndex: 'ID_CARGO',
    key: 'ID_CARGO',
  },

  {
    title: 'ID_FECHA_PAGO',
    dataIndex: 'ID_FECHA_PAGO',
    key: 'ID_FECHA_PAGO',
  },
  {
    title: 'CONTROL_ASISTENCIA',
    dataIndex: 'CONTROL_ASISTENCIA',
    key: 'CONTROL_ASISTENCIA',
  },

  {
    title: 'SUELDO_BASE',
    dataIndex: 'SUELDO_BASE',
    key: 'SUELDO_BASE',
  },
  {
    title: 'BONOS_ENCODE',
    dataIndex: 'BONOS_ENCODE',
    key: 'BONOS_ENCODE',
  },

  {
    title: 'TIPO_SUELDO',
    dataIndex: 'TIPO_SUELDO',
    key: 'TIPO_SUELDO',
  },
  {
    title: 'ID_COLACION',
    dataIndex: 'ID_COLACION',
    key: 'ID_COLACION',
  },
  {
    title: 'COLACION',
    dataIndex: 'COLACION',
    key: 'COLACION',
  },
  {
    title: 'MOVILIZACION',
    dataIndex: 'MOVILIZACION',
    key: 'MOVILIZACION',
  },
  {
    title: 'MARGEN',
    dataIndex: 'MARGEN',
    key: 'MARGEN',
  },
  {
    title: 'GAV',
    dataIndex: 'GAV',
    key: 'GAV',
  },

  {
    title: 'TARIFA',
    dataIndex: 'TARIFA',
    key: 'TARIFA',
  },

  {
    title: 'MONEDA',
    dataIndex: 'MONEDA',
    key: 'MONEDA',
  },
  {
    title: 'IVA',
    dataIndex: 'IVA',
    key: 'IVA',
  },

  {
    title: 'FACTURA_HORAS_EXTRAS',
    dataIndex: 'FACTURA_HORAS_EXTRAS',
    key: 'FACTURA_HORAS_EXTRAS',
  },

  {
    title: 'TARIFA_HORAS_EXTRA_50%',
    dataIndex: 'TARIFA_HORAS_EXTRAS_50%',
    key: 'TARIFA_HORAS_EXTRAS_50%',
  },
  {
    title: 'TARIFA_HORAS_EXTRA_75%',
    dataIndex: 'TARIFA_HORAS_EXTRA_75%',
    key: 'TARIFA_HORAS_EXTRA_75%',
  },
  {
    title: 'TARIFA_HORAS_EXTRA_100%',
    dataIndex: 'TARIFA_HORAS_EXTRA_100%',
    key: 'TARIFA_HORAS_EXTRA_100%',
  },
  { title: 'FUNCION', dataIndex: 'FUNCION', key: 'FUNCION' },
  { title: 'CAUSAL', dataIndex: 'CAUSAL', key: 'CAUSAL' },
  { title: 'REEMPLAZO', dataIndex: 'REEMPLAZO', key: 'REEMPLAZO' },
  {
    title: 'JEFE_DIRECTO',
    dataIndex: 'JEFE_DIRECTO',
    key: 'JEFE_DIRECTO',
  },
  {
    title: 'RECLUTADOR',
    dataIndex: 'RECLUTADOR',
    key: 'RECLUTADOR',
  },
  {
    title: 'FUENTE_RECLUTAMIENTO',
    dataIndex: 'FUENTE_RECLUTAMIENTO',
    key: 'FUENTE_RECLUTAMIENTO',
  },
  {
    title: 'OBSERVACIONES',
    dataIndex: 'OBSERVACIONES',
    key: 'OBSERVACIONES',
  },
  { title: 'ID_SUCURSAL', dataIndex: 'ID_SUCURSAL', key: 'ID_SUCURSAL' },
  {
    title: 'MODALIDAD_TRABAJO',
    dataIndex: 'MODALIDAD_TRABAJO',
    key: 'MODALIDAD_TRABAJO',
  },
];

import React from 'react';
import TablainfoPersonas from './atoms/TablainfoPersonas';
import styles from './jsonStyles.json';
import FormatDateV2 from '../../../altUtils/FormatDateV2';

export default function FormatoReporteFestivos({
  dataArray,
  widthTable = '500px',
}) {
  function calcularSumaLongitudArrays(arrayDeArrays) {
    let sumaLongitud = 0;

    for (let i = 0; i < arrayDeArrays.length; i++) {
      const arrayActual = arrayDeArrays[i];

      if (Array.isArray(arrayActual)) {
        const asistencias = arrayActual.filter(
          (item) => item.asistencia === 'SI'
        );
        sumaLongitud += asistencias.length;
      }
    }

    return sumaLongitud;
  }

  return (
    <div style={styles.tableReponsive}>
      <h4 style={{ font: ' 11pt Arial' }}> Reporte de Domingos y Festivos</h4>
      {dataArray.map((item) => (
        <div id={item.key} key={item.key} className="table-responsive">
          <br />
          <br />
          <TablainfoPersonas item={item}></TablainfoPersonas>

          <div>
            {item.reporte.length === 0 && (
              <div className="mx-auto mt-1" style={{ maxWidth: '70rem' }}>
                <p style={styles.td2}>
                  La jornada de este trabajador no incluye domingos y festivos
                </p>
              </div>
            )}
            {item.reporte.length !== 0 && (
              <>
                {item.reporte.map((task, index) => (
                  <table style={styles.table} key={index}>
                    <tr
                      style={{
                        backgroundColor: 'DARKblue',
                        color: 'white',
                        border: '1px solid',

                        font: '11pt Arial',
                      }}
                    >
                      <th style={styles.td2}>
                        Beneficiarios de descansos o dominicales adicionales
                      </th>
                      <th style={styles.td2}>Domingo y/o festivos</th>
                      <th style={styles.td2}>Asistencia</th>
                      <th style={styles.td2}>Ausencia</th>
                      <th style={styles.td2}>Observaciones</th>
                    </tr>
                    {task.map((data, index) => (
                      <tr
                        key={task.jornada}
                        style={{
                          border: '1px solid',
                          height: '50px',
                          font: '11pt Arial',
                        }}
                      >
                        {' '}
                        <td style={styles.td2}> {data.beneficiario || ''}</td>
                        <td style={styles.td2}>
                          {FormatDateV2(data.fecha).split('T')[0] || ''}

                          {}
                        </td>
                        <td style={styles.td2}>{data.asistencia || ''}</td>
                        <td style={styles.td2}>{data.ausencia || ''}</td>
                        <td style={styles.td2}>{data.observaciones || ''}</td>
                      </tr>
                    ))}

                    <tr>
                      <td colspan="3" style={styles.tdtotales}>
                        TOTAL DOMINGO Y FESTIVO
                      </td>

                      <td colspan="2" style={styles.td2}>
                        {' '}
                        {task.filter((item) => item.asistencia === 'SI')
                          .length || 0}
                      </td>
                    </tr>
                  </table>
                ))}
              </>
            )}
          </div>

          <table
            style={{
              border: '1px solid',
              textAlign: 'center',
              marginTop: '10px',
            }}
          >
            <tr>
              <td
                colspan="3"
                style={{
                  border: '1px solid',
                  backgroundColor: 'DARKblue',
                  color: 'white',
                }}
              >
                TOTAL DOMINGO Y FESTIVO GLOBAL:{' '}
              </td>
              <td colspan="2">{calcularSumaLongitudArrays(item.reporte)}</td>
            </tr>
          </table>
        </div>
      ))}
    </div>
  );
}

import { Link } from 'react-router-dom';

export default function itemRender(route, params, routes, paths) {
  const last =
    routes.map((r) => r.path).indexOf(route.path) === routes.length - 1;
  return last ? (
    <span>{route.title}</span>
  ) : (
    <Link to={'/' + paths.join('/')}>{route.title}</Link>
  );
}

import { API } from 'aws-amplify';
import getToken from '../utils/getToken';

export async function getDotacionDiaria(body) {
  const token = await getToken();
  const { data } = await API.get('empresa', '/verDotacion', {
    headers: {},
    response: true,
    queryStringParameters: {
      userToken: token,
      ...body,
    },
  });
  if (data.existe) return data.registro?.Items;
  else return [];
}

export async function sendMarcajeLlegada(body) {
  const { data } = await API.post(
    'xinerlink',
    '/empresa/v1/dotacion/marcarAsistencia',
    {
      headers: {},
      response: true,
      body,
    }
  );
  return data;
}

import React, { useEffect, useRef } from 'react';
import html2pdf from 'html2pdf.js';
import FormatoReportesAsistencias from './components/FormatoReportesAsistencias';
import FormatoReporteJornada from './components/FormatoReporteJornada';
import FormatoReporteFestivos from './components/FormatoReporteFestivos';
import FormatoReporteModificaciones from './components/FormatoReporteModificaciones';

export default function BotonExportarPDF({
  name = 'jornada',
  formato = 'jornada',
  dataArray = [],
  setexportarpdf,
}) {
  const exportContentRef = useRef(null);

  useEffect(() => {
    const exportToPDF = async () => {
      const exportContent = exportContentRef.current;
      const options = {
        filename: `${name}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      await html2pdf().set(options).from(exportContent).save();

      setexportarpdf(false);
    };

    exportToPDF();
  }, [name, formato, dataArray, setexportarpdf]);

  const getFormatoComponent = () => {
    if (formato === 'jornada') {
      return <FormatoReporteJornada dataArray={dataArray} widthTable="600px" />;
    } else if (formato === 'asistencia') {
      return (
        <FormatoReportesAsistencias dataArray={dataArray} widthTable="600px" />
      );
    } else if (formato === 'festivos') {
      return (
        <FormatoReporteFestivos dataArray={dataArray} widthTable="600px" />
      );
    } else if (formato === 'modificaciones') {
      return (
        <FormatoReporteModificaciones
          dataArray={dataArray}
          widthTable="600px"
        />
      );
    }

    return null;
  };

  return (
    <div
      ref={exportContentRef}
      style={{
        width: '21cm',
        margin: '10mm 0mm 0mm 0mm',
      }}
    >
      {getFormatoComponent()}
    </div>
  );
}

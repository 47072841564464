import { Badge, Button, Divider, Modal, Select, Space, message } from 'antd';
import { useState } from 'react';
import { asignarEtiquetas } from '../../../services/EtiquetasService';
import { useSelector } from 'react-redux';
import { AppstoreAddOutlined } from '@ant-design/icons';

export default function AsignarEtiqueta({ seleccionados, etiquetas, refresh }) {
  const { enterprise } = useSelector((state) => state.enterprise);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  async function handleSubmit() {
    try {
      setLoading(true);
      await asignarEtiquetas(enterprise.empresaId, selectedTag, seleccionados);
      refresh();
      handleCancel();
      message.success('Etiqueta asignada correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al asignar la etiqueta');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    setSelectedTag(null);
    setShow(false);
  }

  return (
    <>
      <Button
        disabled={seleccionados.length < 1}
        onClick={() => setShow(true)}
        icon={<AppstoreAddOutlined />}
      >
        Asignar etiqueta
      </Button>
      <Modal
        title="Asignar etiqueta"
        open={show}
        onCancel={handleCancel}
        destroyOnClose
        closable={!loading}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={!selectedTag}
          >
            Asignar
          </Button>,
        ]}
      >
        <Divider />
        <p>
          A continuación debes escoger una etiqueta para asignar a los
          trabajadores seleccionados ({seleccionados.length}).
        </p>
        <Select
          style={{ width: '100%' }}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.key.toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Selecciona una o varias etiquetas"
          onChange={(value) => setSelectedTag(value)}
        >
          {etiquetas?.map(({ etiquetaId, nombre, color }) => (
            <Select.Option key={nombre} value={etiquetaId}>
              <Space>
                <span role="img" aria-label={nombre}>
                  <Badge color={color} />
                </span>
                {nombre}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}

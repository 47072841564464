import { removeEtiqueta } from '../../../../services/EtiquetasService';
import { message } from 'antd';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export function handleClose(e, user, etiqueta, refresh) {
  e.preventDefault();
  confirm({
    title: 'Remover etiqueta a ' + user.nombre + ' ' + user.primerApellido,
    icon: <ExclamationCircleOutlined />,
    content: `¿Desea eliminar la etiqueta ${etiqueta.nombre} a ${user.nombre} ${user.primerApellido}? Puede volver a añadirla más tarde.`,
    okText: 'Eliminar',
    okType: 'danger',
    closable: true,
    async onOk() {
      try {
        message.loading('Eliminando etiqueta...');
        await handleRemove(user, etiqueta);
        refresh();
        message.success('Etiqueta eliminada correctamente');
      } catch (error) {
        message.error('No se ha podido eliminar la etiqueta');
        console.error(error);
      }
    },
  });
}

export async function handleRemove(user, etiqueta) {
  await removeEtiqueta(
    etiqueta.empresaId,
    user.trabajadorId,
    etiqueta.etiquetaId
  );
  return;
}

export function getFiltereds(trabajadores, filters) {
  const { buscar, etiquetas } = filters;
  let filtered = trabajadores;
  if (buscar) {
    filtered = filtered.filter((trabajador) => {
      const nombreCompleto =
        `${trabajador.nombre} ${trabajador.primerApellido} ${trabajador.segundoApellido}`.toLowerCase();
      return (
        nombreCompleto.includes(buscar.toLowerCase().trim()) ||
        trabajador.trabajadorId.includes(buscar.toLowerCase().trim()) ||
        trabajador.dni.includes(buscar.toLowerCase().trim())
      );
    });
  }
  if (etiquetas) {
    filtered = filtered.filter((trabajador) =>
      trabajador.etiquetas.some((etiqueta) =>
        etiqueta.etiquetaId
          .toLowerCase()
          .includes(etiquetas.toLowerCase().trim())
      )
    );
  }
  return filtered;
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, signOut } from '../../lib/features/auth';
import { select } from '../../lib/features/enterprise';
import { checkUser, logoutUser } from '../../services/UserService';
import { message } from 'antd';
import Loading from '../layouts/Loading';

export default function AuthProvider({ children }) {
  const { isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) handleCheckUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleCheckUser() {
    try {
      const { user, empresa } = await checkUser();
      if (user === null) dispatch(signOut());
      else {
        dispatch(signIn(user));
        dispatch(select(empresa));
      }
    } catch (error) {
      await logoutUser();
      dispatch(signOut());
      message.error(error.message);
      console.error(error);
    }
  }

  if (isLoading) {
    return <Loading />;
  }
  return children;
}

import { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export default function SolicitarCodigo() {
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(values) {
    try {
      setSubmit(true);
      await Auth.forgotPassword(values.username);
      message.success('Código enviado correctamente');
      navigate('/auth/recuperar/confirmar?correo=' + values.username);
    } catch (error) {
      console.error(error);
      if (error.message === 'Username/client id combination not found.') {
        message.error('Usuario no encontrado');
      } else {
        message.error(
          'Ha ocurrido un error, por favor intenta de nuevo más tarde'
        );
      }
    } finally {
      setSubmit(false);
    }
  }

  return (
    <Form size="large" onFinish={handleSubmit}>
      <Form.Item
        name={'username'}
        rules={[
          {
            required: true,
            message: 'Debes introducir tu correo electrónico',
          },
        ]}
      >
        <Input placeholder="Correo electrónico" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={submit}>
          Solicitar código
        </Button>
      </Form.Item>
    </Form>
  );
}

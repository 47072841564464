import React, { useEffect, useState } from 'react';
import { Divider, Image, Space } from 'antd';
import { Storage } from 'aws-amplify';

export default function Identificador({ trabajador }) {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (trabajador) getAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trabajador]);

  async function getAvatar() {
    if (trabajador.enlaceFoto) setAvatar(trabajador.enlaceFoto);
    else {
      const urlAvatar = await Storage.get(
        trabajador.perfil.enlaceFoto || trabajador.enlaceFoto
      );
      setAvatar(urlAvatar);
    }
  }

  function nombreCompleto() {
    return `${trabajador.nombre} ${trabajador.primerApellido} ${trabajador.segundoApellido}`;
  }

  return (
    <div id="identificarComentario">
      <Space size="middle">
        <Image
          width={45}
          height={45}
          src={avatar}
          alt={trabajador.correo || trabajador.trabajadorId}
          style={{ objectFit: 'cover', borderRadius: '50%' }}
        />

        <div>
          <h3 className="text-sm font-bold mb-0">{nombreCompleto()}</h3>
          <span className="text-xs">{trabajador.dni}</span>
        </div>
      </Space>
      <Divider />
    </div>
  );
}
